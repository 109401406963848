import FaqSchemaList from "../../components/FAQ/FaqListData";
import FaqSchemaSubHeader from "../../components/FAQ/FaqSubHeader";
import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";

const Faqs = () => {
    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <FaqSchemaSubHeader />
                        <FaqSchemaList />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Faqs;