export const GET_ATTRIBUTES = "GET_ATTRIBUTES";
export const SET_ATTRIBUTES = "SET_ATTRIBUTES";
export const GET_ATTRIBUTE = "GET_ATTRIBUTE";
export const SET_ATTRIBUTE = "SET_ATTRIBUTE";
export const GET_ATTRIBUTE_BY_ID = "GET_ATTRIBUTE_BY_ID";
export const CREATE_ATTRIBUTE = "CREATE_ATTRIBUTE";
export const UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE";
export const DELETE_ATTRIBUTE_SCHEMA = "DELETE_ATTRIBUTE_SCHEMA";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const SET_ATTRIBUTE_LOADER = "SET_ATTRIBUTE_LOADER";
export const GET_PRODUCT_COUNT = "GET_PRODUCT_COUNT";
export const DOWNLOAD_ATTRIBUTE_DUMP = "DOWNLOAD_ATTRIBUTE_DUMP";
export const SET_DOWNLOAD_DUMP_LOADER = "SET_DOWNLOAD_DUMP_LOADER";
export const BULK_UPLOAD_ATTRIBUTE_FILE = "BULK_UPLOAD_ATTRIBUTE_FILE";
