import React, { useEffect, useState } from "react";
import { RiDeleteBin4Fill } from "react-icons/ri";


const AddAttributeForm = (props: any) => {
    const { staticFormFields, formAttribute, formAtrributes, setFormAttributes, removeFieldHandler } = props
    const [fieldKey, setFieldKey] = useState(staticFormFields[0].attributeKey)
    const [fieldType, setFieldType] = useState("")
    const [fieldValue, setFieldValue] = useState("")
    const [showFileUploadField, setShowFileUploadField] = useState(false)
    const [showTextField, setShowTextField] = useState(false)
    const [showBooleanField, setShowBooleanField] = useState(false)

    //change fieldType as per fieldKey
    useEffect(() => {
        if (fieldKey) {
            const attribute = staticFormFields.find((s: any) => s.attributeKey == fieldKey)
            if (attribute) {
                //File upload
                if ([27, 28].includes(attribute.id)) {
                    setShowFileUploadField(true)
                    setShowBooleanField(false)
                    setShowTextField(false)
                }
                //Boolean
                else if ([12, 18, 19].includes(attribute.id)) {
                    setShowBooleanField(true)
                    setShowFileUploadField(false)
                    setShowTextField(false)
                }
                //Text 
                else {
                    setShowTextField(true)
                    setShowFileUploadField(false)
                    setShowBooleanField(false)
                }
                setFieldType(attribute.attributeType)
            }

        }
    }, [fieldKey])

    useEffect(() => {
        if (fieldValue && fieldKey && fieldType) {
            const attribute = staticFormFields.find((s: any) => s.attributeKey == fieldKey)
            if (attribute) {
                const currentAttributeIndex = formAtrributes.findIndex((d: any) => d.id === formAttribute.id)
                if (currentAttributeIndex !== -1) {
                    let tempHolder = formAtrributes[currentAttributeIndex]
                    formAtrributes[currentAttributeIndex] = {
                        "id": tempHolder["id"],
                        "attributeKeyId": attribute["id"],
                        "attributeValue": fieldValue
                    }
                    setFormAttributes([...formAtrributes])
                }
                else {
                    formAtrributes[0]["attributeKeyId"] = attribute["id"]
                    formAtrributes[0]["attributeValue"] = fieldValue
                    setFormAttributes([...formAtrributes])
                }
            }
        }
    }, [fieldKey, fieldType, fieldValue])

    const imagehandler = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            setFieldValue(img);
        }
    };

    return (<>
        <div className="form-saperator-line"></div>
        <div className="form-field mt-4 pr-5">
            <label>Field Key</label>
            <select onChange={(e) => setFieldKey(e.target.value)}>
                {staticFormFields.map((d: any, index: number) =>
                    <option key={index} value={d.attributeKey}>{d.attributeKey}</option>
                )}
            </select>
        </div>

        <div className="form-field mt-4 pr-5">
            <label>Field Type</label>
            <select>
                <option disabled={true} selected={true} value={fieldType}>{fieldType}</option>
            </select>
        </div>

        {showFileUploadField &&
            <div className="form-field ">
                <label>Choose File:</label>
                <input onChange={imagehandler} accept=".jpg,.png,.jpeg" type="file" />
            </div>
        }

        {showBooleanField &&
            <div className="form-field mt-4 pr-5">
                <label>Field Value</label>
                <select value={fieldValue} onChange={(e) => setFieldValue(e.target.value)} >
                    <option>Select</option>
                    <option value="true" >True</option>
                    <option value="false" >False</option>
                </select>
            </div>
        }

        {
            showTextField &&
            <div className="form-field mt-4 pr-5">
                <label>Field Value</label>
                <input value={fieldValue} onChange={(e) => setFieldValue(e.target.value)} type="text" placeholder="Enter value here.." />
            </div>
        }
        <div onClick={() => removeFieldHandler(formAttribute.id)} className="action-field cursor-pointer flex items-center justify-around h-[30px] w-[30px] rounded mt-4 "><RiDeleteBin4Fill /></div>
    </>)
}

export default AddAttributeForm