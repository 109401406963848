import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import PlaFeedForm from "../../components/PlaFeed/PlaFeedForm";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getPlaFeed,
  setPlaFeed,
} from "../../../core/actionCreators/plaFeedActionCreator";
import { useSelector } from "react-redux";

const PlaFeed = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pageMode, setPageMode] = useState("");
  const { plaFeed } = useSelector((s: any) => s.plaFeedStore);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === "/plaFeed/create") {
      setPageMode("CREATE");
    } else {
      if (params.id) {
        setPageMode("EDIT");
        dispatch(getPlaFeed(`${params.id}`, null));
      }
    }
    return () => {
      dispatch(setPlaFeed({}));
    };
  }, [params, window]);

  const leftArrorClickHandler = () => {
    navigate("/plaFeed");
  };
  return (
    <>
      <div className="flex mainWrapper w-full">
        <div className="sidenav-wrapper">
          <SideNavbar />
        </div>
        <div className="right-container">
          <div className="header-container">
            <Header />
          </div>
          <div className="body-container">
            <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
              <p className="text-xl font-bold text-[#43425D] flex items-center">
                <ArrowLeftSLineIcon
                  onClick={leftArrorClickHandler}
                  size={24}
                  className="mr-4"
                />
                {pageMode === "CREATE"
                  ? "Create PlaFeed"
                  : `Update PlaFeed ${params.id}`}
              </p>
            </div>
            <PlaFeedForm pageMode={pageMode} plaFeed={plaFeed} />
          </div>
        </div>
      </div>
    </>
  );
};
export default PlaFeed;
