import GenerateProductListItem from "./GenerateProductListItem";

const GenerateProductList = (props: any) => {
    return (

        <>
            <tbody>
                {props?.data?.map((d: any, index: number) =>
                    <GenerateProductListItem key={index} _data={d} />
                )}
            </tbody>
        </>
    )


}
export default GenerateProductList;
