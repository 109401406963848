import * as actions from '../actionTypes/campaignActionTypes';

export const setCampaignLoader = (_data: any) => {
    return {
        type: actions.SET_CAMPAIGN_LOADER,
        payload: _data
    }
}

export const delayCommunicationViaWhatsapp = (_data: any, _cb: any) => {
    return {
        type: actions.DELAY_COMMUNICATION_VIA_WHATSAPP,
        payload: _data,
        callback: _cb
    }
}