import { useNavigate, useLocation } from "react-router-dom";

const Blocks = [
    {
        id: "1",
        label: 'Brand',
        path: "/blocks/brand"
    },
    {
        id: "2",
        label: 'Category',
        path: "/blocks/category"

    },
    {
        id: `3`,
        label: 'StaticHtml',
        path: "/blocks/staticHtml"

    },
    {
        id: "4",
        label: 'Image',
        path: "/blocks/image"
    },
    {
        id: "5",
        label: 'Product',
        path: '/blocks/product'

    },
    {
        id: "6",
        label: 'Layout',
        path: '/blocks/layout'

    },
    {
        id: `7`,
        label: 'LayoutType',
        path: '/blocks/layoutType'

    },
    {
        id: `8`,
        label: 'Template',
        path: '/blocks/template'

    },
    {
        id: `9`,
        label: 'General',
        path: '/blocks/general'

    }
]


const BlocksSubHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const navigateHandler = (item: any) => {
        navigate(item.path)
    }

    return (
        <>
            <div className="campaign-header bg-white shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                <ul className="flex items-center w-full">
                    {
                        Blocks.map(item => (
                            <li key={item.id} className={`px-1.5 ${location.pathname === item.path ? 'border-b-2 border-[red]' : 'border-b-0 text-gray-400'} `}>
                                <p onClick={() => navigateHandler(item)} className="p-[15px] text-xs cursor-pointer">{item.label}</p>
                            </li>
                        ))
                    }

                </ul>
            </div>
        </>
    )
}
export default BlocksSubHeader;