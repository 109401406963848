import * as actions from '../actionTypes/faqActionTypes';

export const setFaqLoader = (_data: any) => {
    return {
        type: actions.SET_FAQ_LOADER,
        payload: _data
    }
}

export const getFaqs = (_data: any, _callback: any) => {
    return {
        type: actions.GET_FAQS,
        payload: _data,
        callback: _callback
    }
}

export const setFaqs = (_data: any) => {
    return {
        type: actions.SET_FAQS,
        payload: _data,
    }
}

export const getFaq = (_data: any, _callback: any) => {
    return {
        type: actions.GET_FAQ,
        payload: _data,
        callback: _callback
    }
}

export const setFaq = (_data: any) => {
    return {
        type: actions.SET_FAQ,
        payload: _data
    }
}

export const downloadFaqDump = (_data: any, _callback: any) => {
    return {
        type: actions.DOWNLOAD_FAQ_DUMP,
        payload: _data,
        callback: _callback
    }
}

export const createFaq = (_data: any, _callback: any) => {
    return {
        type: actions.CREATE_FAQ,
        payload: _data,
        callback: _callback
    }
}

export const updateFaq = (_data: any, _callback: any) => {
    return {
        type: actions.UPDATE_FAQ,
        payload: _data,
        callback: _callback
    }
}

export const deleteFaqSchema = (_data: any, _callback: any) => {
    return {
        type: actions.DELETE_FAQ_SCHEMA,
        payload: _data,
        callback: _callback
    }
}
