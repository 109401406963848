import { useDispatch } from "react-redux";
import LayoutBlockElementForm from "./LayoutBlockElementForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { createBlockLayout, updateBlockLayout } from "../../../core/actionCreators/blocksActionCreator";
import { deleteBlockLayoutElementService, getBlockLayoutGroups, getBlockLayoutTemplates } from "../../../core/services/blocksServices";
import Loader from "../Loader/Loader";

const LayoutBlockParentForm = (props: any) => {
    const { pageMode, blockData, loaderFlag } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((s: any) => s.authStore);

    const [layoutTemplates, setLayoutTemplates] = useState([])
    const [layoutGroups, setLayoutGroups] = useState([])
    const [layoutBlockPayload, setLayoutBlockPayload] = useState({
        layoutId: "",
        layoutCode: "",
        layoutName: "",
        metaTitle: "",
        metaDescription: "",
        layoutUrl: "",
        layoutGroup: 1,
        layoutTemplate: 1,
        status: "true",
        layoutBlockList: [{
            id: uuidv4(),
            blockId: "",
            type: "",
            blockLabel: "",
            blockOrder: "",
            visible: true
        }],
    } as any);


    //Dropdowns
    useEffect(() => {
        getBlockLayoutTemplates().then((resp: any) => {
            const { data } = resp
            if (data.status && data.data && Array.isArray(data.data) && data.data.length) {
                setLayoutTemplates(data.data)
            }
        }).catch((err: any) => {
            console.log(err)
            toast.error('Something went wrong!')
        })

        getBlockLayoutGroups().then((resp: any) => {
            const { data } = resp
            if (data.status && data.data && Array.isArray(data.data) && data.data.length) {
                setLayoutGroups(data.data)
            }
        }).catch((err: any) => {
            console.log(err)
            toast.error('Something went wrong!')
        })
    }, [])


    useEffect(() => {
        if (blockData && Object.keys(blockData).length) {
            setLayoutBlockPayload({ ...blockData });
        } else {
            setLayoutBlockPayload({
                ...layoutBlockPayload,
                layoutBlockList: [
                    {
                        id: uuidv4(),
                        layoutId: "",
                        blockId: "",
                        type: "",
                        blockLabel: "",
                        blockOrder: "",
                        typeName: "",
                        associatedLayouts: [],
                        visible: false
                    },
                ],
            });
        }
    }, [blockData]);

    const createLayoutBlockHandler = () => {
        let formError: boolean = false
        // Deep clone the layoutBlockPayload object

        const payload = JSON.parse(JSON.stringify(layoutBlockPayload));

        delete payload.associatedLayouts
        delete payload.layoutCode
        delete payload.layoutId

        // Parse status and layoutGroup to appropriate data types
        payload.status = JSON.parse(payload.status);
        payload.layoutGroup = parseInt(payload.layoutGroup);

        // Convert layoutTemplate to number
        payload.layoutTemplate = Number(payload.layoutTemplate);

        // Iterate over layoutBlockList and parse relevant fields to integers
        payload.layoutBlockList.forEach((blockItem: any) => {
            if (!blockItem.blockOrder) {
                formError = true
                return
            }
            delete blockItem.id
            delete blockItem.layoutId
            delete blockItem.typeName
            delete blockItem.associatedLayouts
            blockItem.blockId = parseInt(blockItem.blockId);
            blockItem.type = parseInt(blockItem.type);
            blockItem.blockOrder = parseInt(blockItem.blockOrder);
            blockItem.visible = JSON.parse(blockItem.visible)
        });
        if (formError) {
            toast.error("Form contain errors!")
            return

        }
        let headers = {
            userId: user.id || 33,
        };
        dispatch(
            createBlockLayout({ data: payload, headers: headers }, (resp: any) => {
                toast.success("Created successfully.");
                navigate("/blocks/layout");
            })
        );
    };

    const updateLayoutBlockHandler = () => {
        let headers = {
            userId: user.id || 33,
        };
        let params = {
            id: layoutBlockPayload.layoutId,
        };
        delete layoutBlockPayload.associatedLayouts
        layoutBlockPayload.layoutBlockList.forEach((blockItem: any) => {
            if (typeof blockItem.id === 'string') {
                delete blockItem.id
            }
            blockItem.blockId = parseInt(blockItem.blockId);
            blockItem.type = parseInt(blockItem.type);
            blockItem.blockOrder = parseInt(blockItem.blockOrder);
            blockItem.visible = JSON.parse(blockItem.visible)
        });
        dispatch(updateBlockLayout({ data: layoutBlockPayload, headers: headers, params: params }, (resp: any) => {
            toast.success("Updated successfully.");
        }))
    }

    const removeElementHandler = (elementId: any) => {
        const isConfirmed = window.confirm('Are you sure want to delete?')
        if (!isConfirmed) {
            return
        }

        if (layoutBlockPayload?.layoutBlockList && Array.isArray(layoutBlockPayload?.layoutBlockList)) {
            const elementIndex = layoutBlockPayload?.layoutBlockList?.findIndex(
                (d: any) => d.id === elementId
            );
            if (elementIndex === -1) {
                toast.error("Something went wrong.");
                return;
            }
            else if (typeof elementId !== 'string') {
                deleteBlockLayoutElementService({
                    blockId: layoutBlockPayload.layoutId,
                    blockElementId: elementId,
                    userId: user.id || 33,
                }).then((res) => {
                    layoutBlockPayload.layoutBlockList.splice(elementIndex, 1);
                    setLayoutBlockPayload({ ...layoutBlockPayload })
                }).catch((err) => {
                    console.log(err)
                    toast.error("Something went wrong!")
                })
            }
            else {
                layoutBlockPayload.layoutBlockList.splice(elementIndex, 1);
                setLayoutBlockPayload({ ...layoutBlockPayload })
            }
        }
    }

    const addElementHandler = () => {
        layoutBlockPayload.layoutBlockList.push({
            id: uuidv4(),
            blockId: "",
            type: "",
            blockLabel: "",
            blockOrder: "",
            visible: true
        },);
        setLayoutBlockPayload({ ...layoutBlockPayload });
    };


    return (
        <>
            <div className="p-4 pb-8">
                <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5">
                    <form action="">
                        <div className="flex w-full mb-6 last:mb-0">
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Name*</label>
                                <input value={layoutBlockPayload.layoutName}
                                    onChange={(e) =>
                                        setLayoutBlockPayload({
                                            ...layoutBlockPayload,
                                            layoutName: e.target.value,
                                        })
                                    }
                                    className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                    type="text"
                                />
                            </div>
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Template*</label>
                                <select value={layoutBlockPayload.layoutTemplate}
                                    onChange={(e) =>
                                        setLayoutBlockPayload({
                                            ...layoutBlockPayload,
                                            layoutTemplate: e.target.value,
                                        })
                                    } className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                    {layoutTemplates?.map((d: any) =>
                                        <option value={d.id}>{d.name}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                        <div className="flex w-full mb-6 last:mb-0">
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Meta Title</label>
                                <textarea value={layoutBlockPayload.metaTitle}
                                    onChange={(e) =>
                                        setLayoutBlockPayload({
                                            ...layoutBlockPayload,
                                            metaTitle: e.target.value,
                                        })
                                    } rows={3} className="w-full rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                </textarea>
                            </div>
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Meta Description</label>
                                <textarea value={layoutBlockPayload.metaDescription}
                                    onChange={(e) =>
                                        setLayoutBlockPayload({
                                            ...layoutBlockPayload,
                                            metaDescription: e.target.value,
                                        })
                                    } rows={3} className="w-full rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                </textarea>
                            </div>
                        </div>
                        <div className="flex w-full mb-6 last:mb-0">
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Layout Group*</label>
                                <select value={layoutBlockPayload.layoutGroup}
                                    onChange={(e) =>
                                        setLayoutBlockPayload({
                                            ...layoutBlockPayload,
                                            layoutGroup: e.target.value,
                                        })
                                    } className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                    {layoutGroups?.map((d: any) =>
                                        <option value={d.id}>{d.name}</option>
                                    )}
                                </select>
                            </div>
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Status*</label>
                                <select value={layoutBlockPayload.status}
                                    onChange={(e) =>
                                        setLayoutBlockPayload({
                                            ...layoutBlockPayload,
                                            status: e.target.value,
                                        })
                                    } className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                    <option value={"true"}>Active</option>
                                    <option value={"false"}>Inactive</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
                {layoutBlockPayload.layoutBlockList.map((d: any, index: number) => (
                    <LayoutBlockElementForm
                        key={index}
                        data={d}
                        layoutBlockPayload={layoutBlockPayload}
                        setLayoutBlockPayload={setLayoutBlockPayload}
                        removeElementHandler={removeElementHandler}
                        pageMode={pageMode}
                    />
                ))}
                <div className="mt-5">
                    <button onClick={addElementHandler} className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ">Add Block</button>
                    {loaderFlag ? <Loader /> : <button onClick={pageMode === "CREATE" ? createLayoutBlockHandler : updateLayoutBlockHandler} className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ml-5">{pageMode === "CREATE" ? "Create" : "Update"}</button>}
                </div>
            </div>
        </>
    )
}
export default LayoutBlockParentForm;