import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import store from "../../core/store/configureStore";
import setAuthToken from "../../core/services/setAuthToken";
import {
  loginWithMicrosoft,
  setUser,
} from "../../core/actionCreators/authActionCreators";
import { useSelector } from "react-redux";

import LayoutListPage from "../pages/LayoutListPage/LayoutListPage";
import LayoutPage from "../pages/LayoutPage/LayoutPage";
import SearchComponent from "../pages/ComponentList/ComponentList";
import ComponentPage from "../pages/ComponentPage/ComponentPage";
import CampaignListPage from "../pages/CampaignList/CampaignListPage";
import Campaign from "../pages/Campaign/Campaign";

import QuestionAnswer from "../pages/QuestionAnswerpage/QuestionAnswer";
import ProductTagging from "../pages/ProductTags/ProductTagging";
import CreateTagingPage from "../pages/CreateTagingPage/CreateTagingPage";

import { isAuthorizedUser } from "../../utils/helper";
import { USER_ROLES } from "../../utils/constants";
import WhatsappCampaign from "../pages/WhatsappCampaign/WhatsappCampaign";
import AiPowered from "../pages/AiPowered/AiPowered";

import BlockCustomer from "../pages/BlockCustomer/BlockCustomer";
import Serviceability from "../pages/Serviceability/Serviceability";
import BrandBlocks from "../pages/Blocks/Brand/BrandBlocks";
import BrandBlock from "../pages/Blocks/Brand/BrandBlock";
import Fbts from "../pages/FBT/Fbts";
import Fbt from "../pages/FBT/Fbt";
import CategoryBlocks from "../pages/Blocks/Category/CategoryBlocks";
import Faqs from "../pages/FAQ/Faqs";
import Faq from "../pages/FAQ/Faq";
import CategoryBlock from "../pages/Blocks/Category/CategoryBlock";
import StaticHtmls from "../pages/Blocks/StaticHtml/StaticHtmls";
import StaticHtml from "../pages/Blocks/StaticHtml/StaticHtml";
import Images from "../pages/Blocks/Image/Images";
import Image from "../pages/Blocks/Image/Image";
import ProductBlocks from "../pages/Blocks/Product/ProductBlocks";
import LayoutTypes from "../pages/Blocks/LayoutType/LayoutTypes";
import LayoutType from "../pages/Blocks/LayoutType/LayoutType";
import Generals from "../pages/Blocks/General/GeneralBlocks";
import General from "../pages/Blocks/General/GeneralBlock";
import LayoutBlocks from "../pages/Blocks/LayoutBlock/LayoutBlocks";
import LayoutBlock from "../pages/Blocks/LayoutBlock/LayoutBlock";
import ProductBlock from "../pages/Blocks/Product/ProductBlock";
import PlaFeeds from "../pages/PlaFeed/PlaFeeds";
import PlaFeed from "../pages/PlaFeed/PlaFeed";
import CacheDelete from "../pages/CacheDelete/CacheDelete";
import PageMappings from "../pages/PageMapping/PageMappings";
import PageMaping from "../pages/PageMapping/PageMaping";
import AttributeListings from "../pages/AttributeListing/AttributeListings";
import AttributeListing from "../pages/AttributeListing/AttributeListing";
import UserManagement from "../pages/UserManagement/UserManagement";
import Review from "../pages/ReviewsModeration/Review";
import Reviews from "../pages/ReviewsModeration/Reviews";
import ArticleForms from "../pages/Article/Article";
import ArticleListing from "../pages/Article/Articles";
import BlockTemplates from "../pages/Blocks/Template/BlockTemplates";
import BlockTemplate from "../pages/Blocks/Template/BlockTemplate";

const bootstrap = () => {
  const authToken = window.localStorage.getItem("CMS_USER_AUTH_TOKEN");
  if (authToken) {
    const decoded: any = jwt_decode(authToken);
    store.dispatch(setUser(decoded));
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      localStorage.removeItem("CMS_USER_AUTH_TOKEN");
      window.location.href = "/";
    } else {
      setAuthToken(authToken);
      store.dispatch(loginWithMicrosoft(authToken, null));
    }
  }
};

process.env.REACT_APP_ENVIRONMENT !== "DEVELOPMENT" && bootstrap();

const ProtectedRoutes = () => {
  const authStore = useSelector((s: any) => s.authStore);
  const { user } = authStore;
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !authStore.isAuthenticated &&
      process.env.REACT_APP_ENVIRONMENT !== "DEVELOPMENT"
    ) {
      navigate("/");
    }
  }, [authStore.isAuthenticated]);

  return (
    <>
      <Routes>
        <Route path="/layout" element={<LayoutListPage />}></Route>
        <Route path="/layout/:layoutId" element={<LayoutPage />}></Route>
        <Route
          path="/layout/:layoutId/component"
          element={<SearchComponent />}
        ></Route>
        <Route
          path="/layout/:layoutId/:componentName/:componentId"
          element={<ComponentPage />}
        ></Route>

        {isAuthorizedUser(user.roleList, USER_ROLES.MASTER_ADMIN) && (
          <Route path="/campaign" element={<CampaignListPage />}></Route>
        )}
        <Route path="/campaign/create" element={<Campaign />}></Route>
        <Route path="/campaign/:campaignId" element={<Campaign />}></Route>
        {isAuthorizedUser(user.roleList, USER_ROLES.MASTER_ADMIN) && (
          <Route path="/campaign" element={<CampaignListPage />}></Route>
        )}

        <Route path="/campaign/create" element={<Campaign />}></Route>
        <Route path="/campaign/:campaignId" element={<Campaign />}></Route>

        <Route path="/whatsappCampaign" element={<WhatsappCampaign />}></Route>
        <Route path="/aiPowered" element={<AiPowered />}></Route>


        <Route path="/blockCustomer" element={<BlockCustomer />}></Route>

        <Route path="/fbt" element={<Fbts />}></Route>
        <Route path="/fbt/create" element={<Fbt />}></Route>
        <Route path="/fbt/:id" element={<Fbt />}></Route>

        <Route path="/blocks/brand" element={<BrandBlocks />}></Route>
        <Route path="/blocks/brand/create" element={<BrandBlock />}></Route>
        <Route path="/blocks/brand/:id" element={<BrandBlock />}></Route>

        <Route path="/blocks/category" element={<CategoryBlocks />}></Route>
        <Route path="/blocks/category/create" element={<CategoryBlock />}></Route>
        <Route path="/blocks/category/:id" element={<CategoryBlock />}></Route>

        <Route path="/blocks/image" element={<Images />}></Route>
        <Route path="/blocks/image/create" element={<Image />}></Route>
        <Route path="/blocks/image/:id" element={<Image />}></Route>

        <Route path="/blocks/staticHtml" element={<StaticHtmls />}></Route>
        <Route path="/blocks/staticHtml/create" element={<StaticHtml />}></Route>
        <Route path="/blocks/staticHtml/:id" element={<StaticHtml />}></Route>

        <Route path="/blocks/product" element={<ProductBlocks />}></Route>
        <Route path="/blocks/product/create" element={<ProductBlock />}></Route>
        <Route path="/blocks/product/:id" element={<ProductBlock />}></Route>

        <Route path="/blocks/layout" element={<LayoutBlocks />}></Route>
        <Route path="/blocks/layout/create" element={<LayoutBlock />}></Route>

        <Route path="/blocks/layoutType" element={<LayoutTypes />}></Route>
        <Route
          path="/blocks/layoutType/create"
          element={<LayoutType />}
        ></Route>
        <Route
          path="/blocks/layoutType/:id"
          element={<LayoutType />}
        ></Route>

        <Route path="/blocks/template" element={<BlockTemplates />}></Route>
        <Route path="/blocks/template/:id" element={<BlockTemplate />}></Route>
        <Route path="/blocks/template/create" element={<BlockTemplate />}></Route>

        <Route path="/blocks/general" element={<Generals />}></Route>
        <Route path="/blocks/general/:id" element={<General />}></Route>
        <Route path="/blocks/general/create" element={<General />}></Route>

        <Route path="/blocks/product" element={<ProductBlocks />}></Route>
        <Route path="/blocks/product/create" element={<ProductBlock />}></Route>
        <Route path="/blocks/product/create/:id" element={<ProductBlock />}></Route>

        <Route path="/blocks/layout" element={<LayoutBlocks />}></Route>
        <Route path="/blocks/layout/create" element={<LayoutBlock />}></Route>
        <Route path="/blocks/layout/:id" element={<LayoutBlock />}></Route>

        <Route path="/blocks/layoutType" element={<LayoutTypes />}></Route>
        <Route
          path="/blocks/layoutType/create"
          element={<LayoutType />}
        ></Route>

        <Route path="/faq" element={<Faqs />}></Route>
        <Route path="/faq/create" element={<Faq />}></Route>
        <Route path="/faq/:id" element={<Faq />}></Route>

        <Route path="/review" element={<Reviews />}></Route>
        <Route path="/review/:id" element={<Review />}></Route>

        <Route path="/attributeListing" element={<AttributeListings />}></Route>
        <Route
          path="/attributeListing/create"
          element={<AttributeListing />}
        ></Route>
        <Route
          path="/attributeListing/:id"
          element={<AttributeListing />}
        ></Route>

        <Route path="/questionAnswer" element={<QuestionAnswer />}></Route>

        {/* <Route path="/clickStream" element={<ClickStream />}></Route> */}

        <Route path="/productTagging" element={<ProductTagging />}></Route>
        <Route
          path="/productTagging/create"
          element={<CreateTagingPage />}
        ></Route>

        <Route path="/serviceability" element={<Serviceability />}></Route>

        <Route path="/plaFeed" element={<PlaFeeds />}></Route>
        <Route path="/plaFeed/create" element={<PlaFeed />}></Route>
        <Route path="/plaFeed/:id" element={<PlaFeed />}></Route>

        <Route path="/cacheDelete" element={<CacheDelete />}></Route>

        <Route path="/userManagement" element={<UserManagement />}></Route>

        <Route path="/pageMapping" element={<PageMappings />}></Route>
        <Route path="/pageMapping/create" element={<PageMaping />}></Route>
        <Route path="/pageMapping/:id" element={<PageMaping />}></Route>

        <Route path="/article" element={<ArticleListing />}></Route>
        <Route path="/article/create" element={<ArticleForms />}></Route>
        <Route path="/article/:id" element={<ArticleForms />}></Route>

      </Routes>
    </>
  );
};

export default ProtectedRoutes;
