import axios from 'axios';
import { apis } from './params';

export function delayCommunicationViaWhatsappService(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.DELAY_COMMUNICATION_VIA_WHATSAPP,
        "data": _body
    })
}
