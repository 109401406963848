
import { useState, useEffect } from "react";

const ArticleForm = (props: any) => {

    const { articlePayload, setArticlePayload } = props;
    const [showForm, setShowForm] = useState(false);

    const handleDropdownChange = () => {
        setShowForm((prevShowForm) => !prevShowForm);
    };

    return (
        <>
            <div className="py-4 pb-8 ">
                <div className="container bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.4)] rounded mb-5 cursor-pointer">
                    <form action="">
                        <div className="flex w-full mb-6 last:mb-0">
                            <div className="w-full px-2">
                                <p className="w-full h-9 px-2.5 outline-none text-sm font-light header"
                                    onClick={handleDropdownChange}>Click to fill SEO details</p>

                                {showForm && (
                                    <>
                                        <div className='w-full py-2'>
                                            <div className="w-full px-2 py-1">
                                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                                                    Title
                                                </label>
                                                <input
                                                    className="bottom-border w-full h-9 text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                                    type="text" value={articlePayload.seoTitle}
                                                    onChange={(e) =>
                                                        setArticlePayload({
                                                            ...articlePayload,
                                                            seoTitle: e.target.value,
                                                        })}
                                                />
                                            </div>
                                            <div className="w-full px-2">
                                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                                                    Meta Description
                                                </label>
                                                <input
                                                    className="bottom-border w-full h-9 text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                                    type="text" value={articlePayload.seoDescription}
                                                    onChange={(e) =>
                                                        setArticlePayload({
                                                            ...articlePayload,
                                                            seoDescription: e.target.value,
                                                        })}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ArticleForm;



