import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import Header from "../../../components/Header/Header";
import SideNavbar from "../../../components/Navbar/SideNavbar";
import TemplateBlockForm from "../../../components/TemplateBlock/TemplateBlockForm";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTemplateBlock, setTemplateBlock } from "../../../../core/actionCreators/blocksActionCreator";

const BlockTemplate = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [pageMode, setPageMode] = useState("");
    const { templateBlock, isLoading } = useSelector((s: any) => s.blocksStore);

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (currentPath === "/blocks/template/create") {
            setPageMode("CREATE");
        } else {
            if (params.id) {
                setPageMode("EDIT");
                dispatch(getTemplateBlock(params.id, null));
            }
        }
        return () => {
            dispatch(setTemplateBlock({}));
        };
    }, [params, window]);
    const backArrowClickHadler = () => {
        navigate('/blocks/template');
    }
    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                            <div className="flex items-center w-full">
                                <ArrowLeftSLineIcon onClick={backArrowClickHadler} size={24} className="mr-4" />
                                <p className="text-xl font-bold text-[#43425D] flex items-center">{pageMode === "EDIT" ? "Update" : "Create"} Template</p>
                            </div>
                        </div>
                        <TemplateBlockForm pageMode={pageMode} blockData={templateBlock} loaderFlag={isLoading} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlockTemplate;