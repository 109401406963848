import { useRef } from 'react';
import JoditEditor from 'jodit-react';

const TextEditor = (props: any) => {
  const { articlePayload, setArticlePayload } = props;
  const editor = useRef(null);

  return (
    <>
      <JoditEditor className='w-1/2'
        ref={editor}
        value={articlePayload.htmlContent}
        onChange={(newContent) => {
          setArticlePayload({ ...articlePayload, htmlContent: newContent })
        }}
      />
    </>
  )
}

export default TextEditor;