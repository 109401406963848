import axios from "axios";
import csv from 'csvtojson';
import { apis } from './params';

export function uploadImageService(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.UPLOAD_IMAGE,
        "data": _body
    })
}

export function convertToWebp(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.CONVERT_TO_WEBP,
        "data": _body
    })
}

export function uploadFileService(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.UPLOAD_FILE,
        "data": _body
    })
}

export function downloadFileService(_param: string) {
    return axios({
        "method": "Get",
        "url": apis.DOWNLOAD_FILE + _param
    })
}

export function deleteFileService(_param: string) {
    return axios({
        "method": "Delete",
        "url": apis.DELETE_FILE + _param
    })
}

export function parseCSVService(csvData: any) {
    return csv().fromString(csvData)
};

export const downloadFile = async (fileUrl: string, filename: string) => {
    try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};


export function sleep(ms: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}
