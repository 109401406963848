import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createTemplateBlock, updateTemplateBlock } from "../../../core/actionCreators/blocksActionCreator";
import Loader from "../Loader/Loader";

const TemplateBlockForm = (props: any) => {
    const { pageMode, blockData, loaderFlag } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((s: any) => s.authStore);

    const [templateBlockPayload, setTemplateBlockPayload] = useState({
        id: "",
        name: "",
        templateFile: "",
        templateCss: "",
        templateJs: "",
        status: true,
    } as any);

    useEffect(() => {
        if (pageMode === "EDIT" && Object.keys(blockData).length) {
            setTemplateBlockPayload(blockData);
        }
    }, [blockData]);

    const createTemplateBlockHandler = () => {
        //  setButtonInfo(buttonStates.LOADING);
        let data: any = { ...templateBlockPayload };
        delete data.id;
        let headers = {
            userId: user.id || 33,
        };
        dispatch(
            createTemplateBlock({ data: data, headers: headers }, () => {
                toast.success("Created successfully.");
                navigate("/blocks/template");
            })
        );
    };

    const updateTemplateBlockHandler = () => {
        let data: any = { ...templateBlockPayload };
        let headers = {
            userId: user.id || 33,
        };
        let params = {
            id: data.id,
        };
        dispatch(
            updateTemplateBlock({ params, data: data, headers }, () => {
                toast.success("Updated successfully.");
            })
        );
    };


    return (
        <>
            <div className="p-4 pb-8">
                <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5">
                    <form action="">
                        <div className="flex w-full mb-6 last:mb-0">
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Name*</label>
                                <input type="text" value={templateBlockPayload.name}
                                    onChange={(e) =>
                                        setTemplateBlockPayload({
                                            ...templateBlockPayload,
                                            name: e.target.value
                                        })
                                    }
                                    className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                />
                            </div>
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Template File*</label>
                                <textarea value={templateBlockPayload.templateFile}
                                    onChange={(e) =>
                                        setTemplateBlockPayload({
                                            ...templateBlockPayload,
                                            templateFile: e.target.value
                                        })
                                    } rows={3} className="w-full rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                </textarea>
                            </div>
                        </div>
                        <div className="flex w-full mb-6 last:mb-0">
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Template Css</label>
                                <textarea value={templateBlockPayload.templateCss}
                                    onChange={(e) =>
                                        setTemplateBlockPayload({
                                            ...templateBlockPayload,
                                            templateCss: e.target.value
                                        })
                                    } rows={3} className="w-full rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                </textarea>
                            </div>
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Template Js</label>
                                <textarea value={templateBlockPayload.templateJs}
                                    onChange={(e) =>
                                        setTemplateBlockPayload({
                                            ...templateBlockPayload,
                                            templateJs: e.target.value
                                        })
                                    } rows={3} className="w-full rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                </textarea>
                            </div>
                        </div>
                        <div className="flex w-full mb-6 last:mb-0">
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Status*</label>
                                <select value={templateBlockPayload.status}
                                    onChange={(e) => setTemplateBlockPayload({ ...templateBlockPayload, status: e.target.value })
                                    } className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                    <option value={"true"}>Active</option>
                                    <option value={"false"}>Inactive</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>

                <div className="mt-5">
                    {loaderFlag ? <Loader /> : <button type="button" onClick={pageMode === "EDIT" ? updateTemplateBlockHandler : createTemplateBlockHandler}
                        className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none">{pageMode === "EDIT" ? "Update" : "Create"}</button>}
                </div>
            </div>
        </>
    )
}
export default TemplateBlockForm;