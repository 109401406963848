import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-hot-toast";
import { CREATE_FBT, DOWNLOAD_FBT_DUMP, GET_FBTS, GET_FBT_BY_ID, UPDATE_FBT, UPLOAD_FBT_FILE } from "../actionTypes/fbtActionTypes";
import { setDownloadDumpLoader, setFbt, setFbtLoader, setFbts } from "../actionCreators/fbtActionCreator";
import { createFbtService, downloadFbtDumpService, getFbtByIdService, getFbtsService, updateFbtService, uploadFbtFileService } from "../services/fbtServices";
import { setBlocksLoader } from "../actionCreators/blocksActionCreator";

function* _getFbtById(_data: any) {
    try {
        yield put(setFbtLoader(true));
        const { data } = yield call(getFbtByIdService, _data.payload);
        yield put(setBlocksLoader(false));
        if (data.status == true) {
            yield put(setFbt(data.data))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setBlocksLoader(false));
        console.log("_getFbtById: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getFbts(_data: any) {
    try {
        yield put(setFbtLoader(true));
        const { data } = yield call(getFbtsService, _data.payload);
        yield put(setFbtLoader(false));
        if (data.status == true) {
            yield put(setFbts({
                totalCount: data?.data?.totalCount,
                pageSize: data?.data?.pageSize,
                currentPage: data?.data?.currentPage,
                totalPage: data?.data?.totalPage,
                data: data?.data?.fbtResponses
            }))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setFbtLoader(false));
        console.log("_getFbts: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _createFbt(_data: any) {
    try {
        yield put(setFbtLoader(true));
        const { data } = yield call(createFbtService, _data.payload);
        yield put(setFbtLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(true)
        }
        else {
            toast.error(data.statusDescription)
        }
    }
    catch (err: any) {
        yield put(setFbtLoader(false));
        console.log("_createFbt: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _updateFbt(_data: any) {
    try {
        yield put(setFbtLoader(true));
        const { data } = yield call(updateFbtService, _data.payload);
        yield put(setFbtLoader(false));
        if (data.status == true) {
            yield put(setFbt(data.data))
            _data.callback && _data.callback(true)
        }
        else {
            toast.error(data.statusDescription)
        }
    }
    catch (err: any) {
        yield put(setFbtLoader(false));
        console.log("_updateFbt: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _downloadFbtDump(_data: any) {
    try {
        yield put(setDownloadDumpLoader(true));
        const { data } = yield call(downloadFbtDumpService, _data.payload);
        yield put(setDownloadDumpLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(data)
        }
        else {
            toast.error(data.statusDescription)
        }
    }
    catch (err: any) {
        yield put(setDownloadDumpLoader(false));
        console.log("_downloadFbtDump: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _uploadFbtFile(_data: any) {
    try {
        yield put(setFbtLoader(true));
        const { data } = yield call(uploadFbtFileService, _data.payload);
        yield put(setFbtLoader(false));
        if (data.status == true) {
            yield put(setFbt(data.data))
            _data.callback && _data.callback(data)
        }
        else {
            toast.error(data.statusDescription)
        }
    }
    catch (err: any) {
        yield put(setFbtLoader(false));
        console.log("_uploadFbtFile: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}


export default function* fbtSaga() {
    yield all([
        takeLatest(GET_FBTS, _getFbts),
        takeLatest(GET_FBT_BY_ID, _getFbtById),
        takeLatest(CREATE_FBT, _createFbt),
        takeLatest(UPDATE_FBT, _updateFbt),
        takeLatest(DOWNLOAD_FBT_DUMP, _downloadFbtDump),
        takeLatest(UPLOAD_FBT_FILE, _uploadFbtFile)
    ])
}