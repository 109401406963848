import { CREATE_ARTICLE, GET_ARTICLE_LISTING_BY_ID, GET_ARTICLE_LISTING, PUBLISH_ARTICLE, UPDATE_ARTICLE, DELETE_ARTICLE_LIST } from "../actionTypes/articleActionTypes";
import { call, put, takeLatest, all } from "redux-saga/effects";
import { createArticleService, getArticleListingService, getArticleByIdService, publishArticleService, updateArticleService } from "../services/articleServices";
import { toast } from "react-hot-toast";
import { setArticleListing, setArticle, setArticleLoader, deleteArticleList, setDeleteArticleList } from "../actionCreators/articleActionCreator";
import { uploadImageService } from "../services/utilService";
import { deleteArticleService } from "../services/articleServices"


function* _createArticle(_data: any) {
  try {
    yield put(setArticleLoader(true));


    //Relative path to absolute path mapping (desktop image)
    if (_data.payload.desktopImageUrl && typeof _data.payload.desktopImageUrl === "object") {
      const formData = new FormData()
      formData.append("file", _data.payload.desktopImageUrl)
      const { data } = yield call(uploadImageService, formData)
      if (data.status && data.statusCode === 200) {
        _data.payload.desktopImageUrl = data.data.s3FileName
      }
    }
    else {
      if (!_data.payload.desktopImageUrl) {
        _data.payload.desktopImageUrl = "null"
      }
    }

    //Relative path to absolute path mapping (desktop image)
    if (_data.payload.pwaImageUrl && typeof _data.payload.pwaImageUrl === "object") {
      const formData = new FormData()
      formData.append("file", _data.payload.pwaImageUrl)
      const { data } = yield call(uploadImageService, formData)
      if (data.status && data.statusCode === 200) {
        _data.payload.pwaImageUrl = data.data.s3FileName
      }
    }
    else {
      if (!_data.payload.pwaImageUrl) {
        _data.payload.pwaImageUrl = "null"
      }
    }

    const { data } = yield call(createArticleService, _data.payload);
    yield put(setArticleLoader(false));
    if (data.status == true && data.statusCode == 200) {
      _data.callback && _data.callback(true)
    } else {
      _data.callback && _data.callback(false)
      toast.error(data.statusDescription)
    }
  }
  catch (err: any) {
    yield put(setArticleLoader(false));
    _data.callback && _data.callback(false)
    console.log("_createArticle: exceptionError:", err)
    toast.error("Something went wrong.")
  }
}

function* _getArticleListing(_data: any) {
  try {
    yield put(setArticleLoader(true));
    const { data } = yield call(getArticleListingService, _data.payload);
    yield put(setArticleLoader(false));
    if (data.status === true && data.code === 200) {
      yield put(setArticleListing({
        totalCount: data?.totalCount,
        data: data?.data

      }))
    } else {
      _data.callback && _data.callback(data)
    }
  }
  catch (err: any) {
    yield put(setArticleLoader(false));
    console.log("_getArticleListing: exceptionError: ", err);
  }

}

function* _getArticleById(_data: any) {
  try {
    yield put(setArticleLoader(true));
    const { data } = yield call(getArticleByIdService, _data.payload);
    yield put(setArticleLoader(false));
    if (data.status == true) {
      yield put(setArticle(data.data));
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setArticleLoader(false));
    console.log("_getArticleById: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}


function* _publishArticle(_data: any) {
  try {
    yield put(setArticleLoader(true));
    const { data } = yield call(publishArticleService, _data.payload);
    yield put(setArticleLoader(false));
    if (data.status == true && data.statusCode == 200) {
      yield put(setArticle(data.data));
      _data.callback && _data.callback(true)
    } else {
      _data.callback && _data.callback(false)
      toast.error(data.statusDescription)
    }
  }
  catch (err: any) {
    yield put(setArticleLoader(false));
    _data.callback && _data.callback(false)
    console.log("_publishArticle: exceptionError:", err)
    toast.error("Something went wrong.")
  }
}

function* _updateArticle(_data: any) {
  try {
    yield put(setArticleLoader(true));

    //Relative path to absolute path mapping (desktop image)
    if (typeof _data.payload.desktopImageUrl === "object") {
      const formData = new FormData()
      formData.append("file", _data.payload.desktopImageUrl)
      const { data } = yield call(uploadImageService, formData)
      if (data.status && data.statusCode === 200) {
        _data.payload.desktopImageUrl = data.data.s3FileName
      }
    }

    //Relative path to absolute path mapping (desktop image)
    if (typeof _data.payload.pwaImageUrl === "object") {
      const formData = new FormData()
      formData.append("file", _data.payload.pwaImageUrl)
      const { data } = yield call(uploadImageService, formData)
      if (data.status && data.statusCode === 200) {
        _data.payload.pwaImageUrl = data.data.s3FileName
      }
    }

    const { data } = yield call(updateArticleService, _data.payload);
    yield put(setArticleLoader(false));
    if (data.status == true) {
      yield put(setArticle(data.data));
      _data.callback && _data.callback(true);
    } else {
      _data.callback && _data.callback(false);
    }
  } catch (err: any) {
    _data.callback && _data.callback(false);
    yield put(setArticleLoader(false));
    console.log("_updateArticle: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _deleteArticle(_data: any) {
  try {
    const { data } = yield call(deleteArticleService, _data.payload);
    if (data.status) {
      yield put(setDeleteArticleList(_data.payload.id));
    }
    _data.callback && _data.callback(data);
  } catch (err: any) {
    console.log("_deleteArticle: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

export default function* articleSaga() {
  yield all([
    takeLatest(CREATE_ARTICLE, _createArticle),
    takeLatest(GET_ARTICLE_LISTING, _getArticleListing),
    takeLatest(GET_ARTICLE_LISTING_BY_ID, _getArticleById),
    takeLatest(PUBLISH_ARTICLE, _publishArticle),
    takeLatest(UPDATE_ARTICLE, _updateArticle),
    takeLatest(DELETE_ARTICLE_LIST, _deleteArticle),
  ])
}