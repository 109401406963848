import Article from "../../components/Article/Article";
import ArticleList from "../../components/Article/ArticleList";
import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";




const ArticleListing = () => {

    return (
        <>
             <div className="flex mainWrapper w-full">
        <div className="sidenav-wrapper">
          <SideNavbar />
        </div>
        <div className="right-container">
          <div className="header-container">
            <Header />
          </div>
          <div className="body-container">
           <ArticleList/>
          </div>
        </div>
      </div>
        </>
    )
}

export default ArticleListing;