import * as actions from '../actionTypes/productTagsActionTypes';

export const setProductTagsLoader = (_data: any) => {
    return {
        type: actions.SET_PRODUCT_TAGS_LOADER,
        payload: _data
    }
}

export const getProductTags = (_data: any, _callback: any) => {
    return {
        type: actions.GET_PRODUCT_TAGS,
        payload: _data,
        callback: _callback
    }
}

export const setProductTags = (_data: any) => {
    return {
        type: actions.SET_PRODUCT_TAGS,
        payload: _data,
    }
}

export const setProductTag = (_data: any) => {
    return {
        type: actions.SET_PRODUCT_TAG,
        payload: _data,
    }
}

export const getProductTagDetails = (_data: any, _callback: any) => {
    return {
        type: actions.GET_PRODUCT_TAGS_DETAILS,
        payload: _data,
        callback: _callback
    }
}

export const setProductTagDetails = (_data: any) => {
    return {
        type: actions.SET_PRODUCT_TAGS_DETAILS,
        payload: _data,
    }
}

export const uploadImage = (_data: any, _callback: any) => {
    return {
        type: actions.UPLOAD_IMAGE,
        payload: _data,
        callback: _callback
    }
}

export const createTag = (_data: any, _callback: any) => {
    return {
        type: actions.CREATE_PRODUCT_TAG,
        payload: _data,
        callback: _callback
    }
}

export const updateTag = (_data: any, _callback: any) => {
    return {
        type: actions.EDIT_PRODUCT_TAG,
        payload: _data,
        callback: _callback
    }
}

export const updateTagData = (_data: any) => {
    return {
        type: actions.UPDATE_PRODUCT_TAG,
        payload: _data
    }
}