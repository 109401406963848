import {
  SET_PRODUCT_TAGS,
  SET_PRODUCT_TAG,
  SET_PRODUCT_TAGS_DETAILS,
  SET_PRODUCT_TAGS_LOADER,
  UPDATE_PRODUCT_TAG,
} from "../actionTypes/productTagsActionTypes";

const initialState: any = {
  productTags: [],
  productTagDetails: {},
  isLoading: false,
};

const productTagsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PRODUCT_TAGS_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_PRODUCT_TAGS:
      return {
        ...state,
        productTags: action.payload,
      };
    case SET_PRODUCT_TAG:
      return {
        ...state,
        productTags: [...state.productTags, ...action.payload],
      };
    case SET_PRODUCT_TAGS_DETAILS:
      return {
        ...state,
        productTagDetails: action.payload,
      };
    case UPDATE_PRODUCT_TAG:
      return {
        ...state,
        productTags: [
          ...state.productTags,
          state.productTags?.map((d: any) => {
            if (d.tagId === action.payload.tagId) {
              d.name = action.payload.name;
              return d;
            } else {
              return d;
            }
          }),
        ],
      };
    default:
      return state;
  }
};

export default productTagsReducer;
