import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";

//Routes
import Login from "../pages/Login/Login";
import Test from "../pages/Test/Test";

const PublicRoutes = () => {
    const authStore = useSelector((s: any) => s.authStore)
    const navigate = useNavigate();

    useEffect(() => {
        if (authStore.isAuthenticated && (process.env.REACT_APP_ENVIRONMENT !== "DEVELOPMENT")) {
            if (window.location.pathname === "/") {
                navigate("/layout")
            }
            else {
                navigate(window.location.pathname + window.location.search)
            }
        }
    }, [authStore.isAuthenticated])

    return <>
        <Routes>
            <Route path="/" element={<Login />}> </Route>
            <Route path="/test" element={<Test />}> </Route>
        </Routes>
    </>
}

export default PublicRoutes