import {
  SET_BRAND_BLOCKS,
  SET_BRAND_BLOCK,
  SET_BLOCKS_LOADER,
  SET_CATEGORY_BLOCKS,
  SET_CATEGORY_BLOCK,
  SET_IMAGE_BLOCKS,
  SET_IMAGE_BLOCK,
  SET_PRODUCT_BLOCKS,
  SET_PRODUCT_BLOCK,
  SET_DELETE_BRAND_BLOCK,
  // SET_DELETE_CATEGORY_BLOCK,
  SET_DELETE_IMAGE_BLOCK,
  SET_DELETE_PRODUCT_BLOCK,
  SET_STATIC_HTML_BLOCKS,
  SET_STATIC_HTML_BLOCK,
  SET_GENERAL_BLOCKS,
  SET_GENERAL_BLOCK,
  SET_TEMPLATE_BLOCKS,
  SET_TEMPLATE_BLOCK,
  SET_DELETE_TEMPLATE_BLOCK,
  SET_DELETE_GENERAL_BLOCK,
  SET_BLOCK_TYPE_BLOCK,
  SET_BLOCK_TYPE_BLOCKS,
  SET_DELETE_BLOCK_TYPE_BLOCK,
  SET_BLOCK_LAYOUTS,
  SET_BLOCK_LAYOUT,
  SET_DELETE_CATEGORY_BLOCK,
  SET_DELETE_STATIC_HTML_BLOCK
} from "../actionTypes/blocksActionTypes";

const initialState: any = {
  brandBlocks: {},
  brandBlock: {},
  isLoading: false,
  categoryBlocks: {},
  categoryBlock: {},
  imageBlocks: {},
  imageBlock: {},
  productBlocks: {},
  productBlock: {},
  staticHtmlBlocks: {},
  staticHtmlBlock: {},
  generalBlocks: {},
  generalBlock: {},
  templateBlocks: {},
  templateBlock: {},
  blockTypeBlocks: {},
  blockTypeBlock: {},
  blockLayouts: {},
  blockLayout: {}
};

const blocksReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_BLOCKS_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_BRAND_BLOCKS:
      return {
        ...state,
        brandBlocks: action.payload,
      };
    case SET_BRAND_BLOCK:
      return {
        ...state,
        brandBlock: action.payload,
      };
    case SET_CATEGORY_BLOCKS:
      return {
        ...state,
        categoryBlocks: action.payload,
      };
    case SET_CATEGORY_BLOCK:
      return {
        ...state,
        categoryBlock: action.payload,
      };
    case SET_IMAGE_BLOCKS:
      return {
        ...state,
        imageBlocks: action.payload,
      };
    case SET_IMAGE_BLOCK:
      return {
        ...state,
        imageBlock: action.payload,
      };
    case SET_PRODUCT_BLOCKS:
      return {
        ...state,
        productBlocks: action.payload,
      };
    case SET_PRODUCT_BLOCK:
      return {
        ...state,
        productBlock: action.payload,
      };
    case SET_STATIC_HTML_BLOCKS:
      return {
        ...state,
        staticHtmlBlocks: action.payload
      }
    case SET_STATIC_HTML_BLOCK:
      return {
        ...state,
        staticHtmlBlock: action.payload
      }
    case SET_DELETE_BRAND_BLOCK:
      return {
        ...state,
        brandBlocks: {
          data: state.brandBlocks?.data?.filter((obj: any) => {
            return obj.id !== action.payload;
          }),
        },
      };
    case SET_DELETE_CATEGORY_BLOCK:
      return {
        ...state,
        categoryBlocks: {
          data: state.categoryBlocks?.data?.filter((obj: any) => {
            return obj.id !== action.payload;
          }),
        },
      };
    case SET_DELETE_STATIC_HTML_BLOCK:
      return {
        ...state,
        staticHtmlBlocks: {
          data: state.staticHtmlBlocks?.data?.filter((obj: any) => {
            return obj.id !== action.payload;
          }),
        },
      };
    case SET_DELETE_IMAGE_BLOCK:
      return {
        ...state,
        imageBlocks: {
          data: state.imageBlocks?.data?.filter((obj: any) => {
            return obj.id !== action.payload;
          }),
        },
      };
    case SET_DELETE_PRODUCT_BLOCK:
      return {
        ...state,
        productBlocks: {
          data: state.productBlocks?.data?.filter((obj: any) => {
            return obj.id !== action.payload;
          }),
        },
      };

    case SET_IMAGE_BLOCKS:
      return {
        ...state,
        imageBlocks: action.payload
      }
    case SET_IMAGE_BLOCK:
      return {
        ...state,
        imageBlock: action.payload
      }
    case SET_PRODUCT_BLOCKS:
      return {
        ...state,
        productBlocks: action.payload
      }
    case SET_PRODUCT_BLOCK:
      return {
        ...state,
        productBlock: action.payload
      }
    case SET_STATIC_HTML_BLOCKS:
      return {
        ...state,
        staticHtmlBlocks: action.payload
      }
    case SET_STATIC_HTML_BLOCK:
      return {
        ...state,
        staticHtmlBlock: action.payload
      }
    case SET_TEMPLATE_BLOCKS:
      return {
        ...state,
        templateBlocks: action.payload
      }
    case SET_TEMPLATE_BLOCK:
      return {
        ...state,
        templateBlock: action.payload
      }
    case SET_DELETE_TEMPLATE_BLOCK:
      return {
        templateBlocks: {
          data: state.templateBlocks?.data?.filter((obj: any) => {
            return obj.id !== action.payload;
          }),
        },
      }
    case SET_GENERAL_BLOCKS:
      return {
        generalBlocks: action.payload
      }
    case SET_GENERAL_BLOCK:
      return {
        generalBlock: action.payload
      }
    case SET_DELETE_GENERAL_BLOCK:
      return {
        generalBlocks: {
          data: state.generalBlocks?.data?.filter((obj: any) => {
            return obj.id !== action.payload;
          }),
        },
      }
    case SET_BLOCK_TYPE_BLOCK:
      return {
        blockTypeBlock: action.payload
      }
    case SET_BLOCK_TYPE_BLOCKS:
      return {
        blockTypeBlocks: action.payload
      }
    case SET_DELETE_BLOCK_TYPE_BLOCK:
      return {
        blockTypeBlocks: {
          data: state.blockTypeBlocks?.data?.filter((obj: any) => {
            return obj.id !== action.payload;
          }),
        },
      }
    case SET_BLOCK_LAYOUTS:
      return {
        blockLayouts: action.payload
      }
    case SET_BLOCK_LAYOUT:
      return {
        blockLayout: action.payload
      }
    default:
      return state;
  }
}




export default blocksReducer;
