import React from 'react';
import './Loader.scss';

const Loader = () => {
    return (<>
        <div className="loader-container flex flex-col items-center justify-center h-screen w-full z-10 absolute top-0 left-50">
            <div className="loader flex flex-col items-center justify-center">
                <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                </div>
                <div className="mt-[5px]">&nbsp; Loading...</div>
            </div>
        </div>
    </>)
}

export default Loader