import { Link } from "react-router-dom"
import UserProfile from "./UserProfile"
import { IoIosArrowBack } from "react-icons/io"

const ComponentPageHeader = (props: any) => {
    const { componentName, layoutId, setAddComponentPopup } = props
    return (<>
        <>
            <div className="header">
                <div className="header-inner">
                    <div className="main-header flex items-center p-[15px]">
                        <div className="header-left">
                        </div>
                        <UserProfile />
                    </div>
                    <div className="haderVarient2 items-center flex justify-between p-[15px]">
                        <div className="pagename-n-input flex items-center ">
                            <Link to={`/layout/${layoutId}`}><IoIosArrowBack /></Link>

                            <div className="pagename-sec">
                                <h2 className='font-bold'>{componentName}
                                </h2>
                            </div>
                        </div>
                        <div className="right-buttons items-center flex justify-end">
                            <button onClick={() => setAddComponentPopup(true)} className=' py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'>Add Element</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    </>)
}

export default ComponentPageHeader