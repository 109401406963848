export const removeDuplicates = (arr: any, key: any) => {
    return arr.filter((obj: any, index: any, self: any) =>
        index === self.findIndex((t: any) => (
            t[key] === obj[key]
        ))
    );
}

export const convertEpochToDDMMYYYY = (epoch: number) => {
    const date = new Date(epoch * 1000); // Convert epoch to milliseconds
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
    return formattedDate;
}

export const dateToEpoch = (date: any) => {
    return Math.floor(date.getTime() / 1000);
}

export const removeDuplicatesFromArrayOfString = (arr: Array<string>) => {
    return arr.filter((item,
        index) => arr.indexOf(item) === index);
}

export const isAuthorizedUser = (roles: any, roleId: Number) => {
    return true
    if (process.env.REACT_APP_ENVIRONMENT === "DEVELOPMENT") {
        return true
    }
    const role = roles.find((d: any) => d.id == roleId)
    if (role) {
        return true
    }
    return false
}

export const formatTimestamp = (timestamp: any) => {
    const date = new Date(timestamp);

    const formattedDate = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    }).format(date);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedTime = `${hours}:${minutes}:${String(seconds).padStart(
        2,
        "0"
    )}`;

    return `${formattedDate} ${formattedTime}`;
};

export const removeEmptyStringKeys = (obj: any) => {
    const trimmedObj: any = {};
    for (const key in obj) {
        const value = obj[key];
        if (typeof value === 'string') {
            const trimmedValue = value.trim();
            if (trimmedValue !== '') {
                trimmedObj[key] = trimmedValue;
            }
        } else if (typeof value === 'number' || typeof value === 'boolean') {
            trimmedObj[key] = value;
        }
        // Add more conditions for other data types if needed (e.g., arrays, objects)
    }
    return trimmedObj;
}

export const isEqual = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export const convertJsonToCsv = (jsonData: Array<object>, filename: string) => {
    if (jsonData.length === 0) {
        console.error('No data to export.');
        return;
    }

    // Extract headers from the first object in the JSON array
    const headers = Object.keys(jsonData[0]);

    // Create CSV content
    const csvContent = [
        headers.join(','),
        ...jsonData.map((row: any) => headers.map(header => row[header]).join(','))
    ].join('\n');

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element and trigger a click to download the file
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        console.error('Downloading is not supported in this browser.');
    }
};



export const convertTextToCSV = (textInput: string) => {
    // Split the text by newline character or any other delimiter if needed
    const lines = textInput.split('\n');

    // Convert the text lines to CSV format
    const csvContent = lines.map((line) => line.split(',').join(';')).join('\n');

    // Create a Blob object with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Create a temporary URL for the Blob object
    const url = window.URL.createObjectURL(blob);

    // Create a link element to trigger the CSV download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.csv');
    document.body.appendChild(link);

    // Simulate a click on the link to trigger the download
    link.click();

    // Clean up resources
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};

export const transformString = (input: string) => {
    // Replace spaces with hyphens and convert to lowercase
    const output = input.replace(/\s+/g, '-').toLowerCase();
    return output;
}

export const replaceExtensionFromJpegOrPngToWebp = (filename: string) => {
    if (filename.endsWith('.png')) {
        return filename.slice(0, -4) + '.webp';
    }

    if (filename.endsWith('.jpeg')) {
        return filename.slice(0, -5) + '.webp';
    }
    return filename;
}

export const getFileNameWithoutExtension = (filePath: string) => {
    const fileNameWithExtension: any = filePath.split('/').pop();
    const fileNameWithoutExtension = fileNameWithExtension.split('.').slice(0, -1).join('.');
    return fileNameWithoutExtension;
}

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
