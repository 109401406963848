
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Edit2FillIcon from "remixicon-react/Edit2FillIcon";
import toast from "react-hot-toast";
import DeleteBin5FillIcon from "remixicon-react/DeleteBin5FillIcon";
import { deleteArticleList } from "../../../core/actionCreators/articleActionCreator"
// import Download2FillIcon from "remixicon-react/Download2FillIcon";
// import { getArticleByIdService } from "../../../core/services/articleServices";
// import { convertJsonToCsv } from "../../../utils/helper";


const ArticleListItem = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((s: any) => s.authStore);
  const { data } = props;

  const updateNavigateHandler = (id: any) => {
    navigate(`/article/${id}`);
  };


  const deleteArticleHandler = (id: any) => {
    const isConfirmed = window.confirm('Are you sure want to delete?')
    if (isConfirmed) {
      dispatch(deleteArticleList({ id: id, email: user.email || 'dev@moglix.com' }, () => {
        toast.success("Deleted successfully");
        window.location.reload()
      }))
    }
  };

  // const downloadFileClickHandler = (id: string) => {
  //   // setLoader(true)
  //   getArticleByIdService(id).then(({ data }: any) => {
  //     // convertJsonToCsv(data) 
  //     // setLoader(false)
  //     toast.success("Downloaded successfully")
  //   }).catch((err: any) => {
  //     // setLoader(false)
  //     console.log("downloadArticleHandler: exceptionError:", err)
  //     toast.error('Something went wrong')

  //   })
  // }

  // const downloadFileClickHandler = (id: string) => {
  //   // setLoader(true)
  //   getArticleByIdService(id).then(({ data }: any) => {
  //     // Convert JSON to CSV
  //     const csvContent = convertJsonToCsv(data);

  //     // Create a Blob from the CSV content
  //     const blob = new Blob([csvContent], { type: 'text/csv' });

  //     // Create a download link
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = 'article.csv'; // Set the filename to "article.csv"

  //     // Trigger the download
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     window.URL.revokeObjectURL(url);

  //     // setLoader(false)
  //     toast.success('Downloaded successfully');
  //   }).catch((err: any) => {
  //     // setLoader(false)
  //     console.log('downloadArticleHandler: exceptionError:', err);
  //     toast.error('Something went wrong');
  //   });
  // };

  // Function to convert JSON to CSV
  // const convertJsonToCsv = (json: any[]) => {
  //   const header = Object.keys(json[0]).join(',');
  //   const rows = json.map(obj => Object.values(obj).join(',')).join('\n');
  //   return `${header}\n${rows}`;
  // };

  return (
    <>
      {data.map((_d: any) => (
        <tr className="[&:nth-child(odd)]:bg-[#f7f7f7]">
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d?.id}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d?.title}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d?.layoutCode}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d?.categoryCode}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d?.friendlyUrl}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d?.status ? "True" : "False"}
          </td>

          <td className="flex justify-center flex-row">
            <td
              onClick={() => updateNavigateHandler(_d.id)}
              className=" text-[#337ab7] cursor-pointer flex justify-center items-center h-9">
              <Edit2FillIcon size={14} />
            </td>
            <td onClick={() => deleteArticleHandler(_d.id)}
              className=" text-[#337ab7] cursor-pointer flex justify-center items-center h-9">
              <DeleteBin5FillIcon size={14} />
            </td>
          </td>
        </tr>

      ))}

    </>
  );
};
export default ArticleListItem;
