import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import { getCampaigns } from '../../../core/actionCreators/adsenseActionCreator';
import { GET_CAMPAIGN_DEFAULT_PAGE_SIZE } from '../../../utils/constants';

const searchCampaignCriterias = [{
    "key": "Campaign Name",
    "value": "campaignName"
},
{
    "key": "Created By",
    "value": "createdByEmail"
},
{
    "key": "Start Date",
    "value": "startDate"
},
{
    "key": "End Date",
    "value": "endDate"
},
{
    "key": "Campaign Type",
    "value": "campaignType"
},
{
    "key": "Status",
    "value": "status"
},
{
    "key": "Platform",
    "value": "platform"
}]


const CampaignListPageHeader = (props: any) => {
    const { lookups } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const createPageBtnClickHandler = () => {
        navigate("/campaign/create")
    }
    const [searchKey, setSearchKey] = useState(searchCampaignCriterias[0].value)
    const [searchValue, setSearchValue] = useState("")
    const [searchInputType, setSearchInputType] = useState("INPUT")

    useEffect(() => {
        if (searchValue.length == 0) {
            dispatch(getCampaigns({
                "where": {},
                "page": 0,
                "limit": GET_CAMPAIGN_DEFAULT_PAGE_SIZE,
                "sortCriteria": {
                    "field": "_id",
                    "direction": "DESC"
                }
            }, null))
        }
    }, [searchValue])


    useEffect(() => {
        setSearchValue("")
        if (["startDate", "endDate"].includes(searchKey)) {
            setSearchInputType("DATE")
        }
        else if (["status", "campaignType", "platform"].includes(searchKey)) {
            setSearchInputType("SELECT")
        }
        else {
            setSearchInputType("TEXT")
        }
    }, [searchKey])

    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            searchClickHandler();
        }
    };

    const searchClickHandler = () => {
        if (!searchValue) {
            dispatch(getCampaigns({
                "where": {},
                "page": 0,
                "limit": GET_CAMPAIGN_DEFAULT_PAGE_SIZE,
                "sortCriteria": {
                    "field": "_id",
                    "direction": "DESC"
                }
            }, null))
            return
        }
        //Add search criteria
        const whereClause: any = {}
        whereClause[searchKey] = searchValue
        if (["startDate", "endDate"].includes(searchKey)) {
            whereClause[searchKey] = new Date(searchValue).toISOString().replace('Z', '+00:00')
        }
        dispatch(getCampaigns({
            "where": whereClause
        }, null))
        return
    }

    return (
        <>
            <div className="campaign-header bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                <div className="flex items-center justify-between w-full">
                    <p className="text-xl font-bold text-[#43425D] w-1/5">Manage Campaign</p>
                    <div className="flex item-center w-[calc(100%-20%)] justify-end ">
                        <div className='flex w-2/5'>
                            <div className="relative inline-block text-left">
                                <div className="campaign-form-field">
                                    <select onChange={(e) => setSearchKey(e.target.value)}>
                                        {searchCampaignCriterias.map((d: any, index) =>
                                            <option key={index} value={d.value}>{d.key}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className='relative w-full'>
                                {searchInputType === "TEXT" &&
                                    <input onKeyDown={handleKeyDown} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} type="search" placeholder='Search Here' className='w-full border border-gray-300 border-l-0 h-9 outline-none pl-2.5 text-xs' />
                                }
                                {searchInputType === "DATE" &&
                                    <div className="relative">
                                        <input value={searchValue} onChange={(e) => setSearchValue(e.target.value)} type="date" className='w-full border pr-[280px] border-gray-300 border-l-0 h-9 outline-none pl-2.5 text-xs text-[#9ca3af]' />
                                    </div>
                                }
                                {searchInputType === "SELECT" &&
                                    <div className="relative">
                                        <div className="select-form-field">
                                            <select onChange={(e) => setSearchValue(e.target.value)}>
                                                <option value={""}>Select</option>
                                                {searchKey === "status" && lookups?.CampaignStatus?.map((d: any, index: number) =>
                                                    <option key={index} value={d}>{d}</option>
                                                )}
                                                {searchKey === "campaignType" && lookups?.CampaignType?.map((d: any, index: number) =>
                                                    <option key={index} value={d}>{d}</option>
                                                )}
                                                {searchKey === "platform" && lookups?.PlatformType?.map((d: any, index: number) =>
                                                    <option key={index} value={d}>{d}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                }

                                <div onClick={searchClickHandler} className='absolute inset-y-0 right-0 flex items-center justify-center w-9 bg-blue-500 rounded-tr-sm rounded-br-sm'>
                                    <SearchLineIcon size={16} className="text-white" />
                                </div>
                            </div>
                        </div>
                        <div className='pl-12'>
                            <button onClick={createPageBtnClickHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">Create Campaign</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CampaignListPageHeader;