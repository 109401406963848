import React, { useState } from "react"
import { WHATSAPP_CAMPAIGN_TYPES } from "../../../utils/constants";
import SideNavbar from "../../components/Navbar/SideNavbar";
import Header from "../../components/Header/Header";
import WhatsappCampaignHeader from "../../components/Header/WhatsappCampaignHeader";
import DelayCommunication from "../../components/WhatsappCampaign/DelayCommunication/DelayCommunication";

const WhatsappCampaign = () => {
    const [whatsappCamapaignType, setWhatsappCampaignType] = useState("")

    return (<>
        <div className="flex mainWrapper w-full">
            <div className="sidenav-wrapper">
                <SideNavbar />
            </div>
            <div className="right-container">
                <div className="header-container">
                    <Header />
                </div>
                <div className="body-container">
                    <WhatsappCampaignHeader setWhatsappCampaignType={setWhatsappCampaignType}
                        whatsappCampaignTypes={Object.keys(WHATSAPP_CAMPAIGN_TYPES)} />

                    {/* DELAY COMMUNICATION */}
                    {whatsappCamapaignType === WHATSAPP_CAMPAIGN_TYPES.DELAY_COMMUNICATION && <DelayCommunication />}
                </div>
            </div>
        </div>
    </>)
}


export default WhatsappCampaign