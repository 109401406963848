import { useState, useRef, useEffect } from "react";
import Loader from "../Loader/Loader";
import ArticleSubHeader from "./ArticleSubHeader";
import ArticleListItem from "./ArticleListItem";
import { useSelector, useDispatch } from "react-redux";
import { getArticleListing } from "../../../core/actionCreators/articleActionCreator";
import CommonPagination from "../CommonPagination/CommonPagination";
import { GET_ARTICLE_PAGE_SIZE } from "../../../utils/constants";
import queryString from "query-string";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";

const ArticleList = () => {
    const dispatch = useDispatch();
    const { isLoading, articleListing } = useSelector((s: any) => s.articleStore)

    const [searchCriteria, setSearchCriteria] = useState({
        pageNumber: 0,
        pageSize: GET_ARTICLE_PAGE_SIZE,
        layoutCode: "",
        friendlyUrl: "",

    } as any);

    const previousSearchCriteriaRef = useRef(searchCriteria);

    useEffect(() => {
        const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
        const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
        const previousCriteria = previousSearchCriteriaRef.current;
        const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

        if (searchFilterChanged) {
            const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
            for (let key in parsedSearchCriteria) {
                if (["friendlyUrl", "layoutCode"].includes(key)) {
                    parsedSearchCriteria.pageNumber = 0
                    break
                }
            }
            dispatch(getArticleListing(queryString.stringify(parsedSearchCriteria), null));
            previousSearchCriteriaRef.current = updatedSearchCriteria;
        }
    }, [searchCriteria]);

    const onPageChange = (pageNumber: any) => {
        setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
    }


    return (
        <>
            <ArticleSubHeader />
            <div className="mt-4 campaign-list bg-white mb-[54px]">
                <table className="w-full">
                    <thead className="bg-[#343434]">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">
                                ID
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]">
                                Title
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">
                                Layout Code
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">
                                Category Code
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]">
                                Friendly Url
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">
                                Status
                            </th>
                            <th className="px-4 py-3 text-center text-xs font-semibold text-white w-[10%]">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[20%]">
                                <input
                                    value={searchCriteria.layoutCode}
                                    onChange={(e) =>
                                        setSearchCriteria({ ...searchCriteria, layoutCode: e.target.value })
                                    }
                                    className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td></td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[20%]">
                                <input
                                    value={searchCriteria.friendlyUrl}
                                    onChange={(e) =>
                                        setSearchCriteria({
                                            ...searchCriteria,
                                            friendlyUrl: e.target.value,
                                        })
                                    }
                                    className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                        </tr>
                        {isLoading ? (<Loader />) : (<ArticleListItem data={articleListing?.data || []} />)}
                    </tbody>
                </table>
            </div>
            {articleListing?.data && (<CommonPagination data={articleListing} PAGE_SIZE={GET_ARTICLE_PAGE_SIZE} onPageChange={onPageChange} />)}
        </>
    )

}
export default ArticleList;