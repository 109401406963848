import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import FaqForm from "../../components/FAQ/FaqForm";
import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFaq, setFaq } from "../../../core/actionCreators/faqActionCreator";

const Faq = () => {
    const params = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [pageMode, setPageMode] = useState("")
    const { faq } = useSelector((s: any) => s.faqStore)

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (currentPath === "/faq/create") {
            setPageMode("CREATE")
        }
        else {
            if (params.id) {
                setPageMode("EDIT")
                dispatch(getFaq(`categoryCode=${params.id}`, null))
            }
        }
        return () => {
            dispatch(setFaq({}))
        }
    }, [params, window])

    const leftArrorClickHandler = () => {
        navigate("/faq")
    }
    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                            <p className="text-xl font-bold text-[#43425D] flex items-center">
                                <ArrowLeftSLineIcon onClick={leftArrorClickHandler} size={24} className="mr-4 cursor-pointer" />
                                {pageMode === "CREATE" ? "Create Faq" : `Update Faq ${params.id}`}
                            </p>
                        </div>
                        <FaqForm pageMode={pageMode} faq={faq} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Faq;