import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-hot-toast";
import { setFaq, setFaqLoader, setFaqs } from "../actionCreators/faqActionCreator";
import { createFaqService, deleteFaqSchemaService, downloadFaqDumpService, getFaqService, getFaqsService } from "../services/faqServices";
import { CREATE_FAQ, DELETE_FAQ_SCHEMA, DOWNLOAD_FAQ_DUMP, GET_FAQ, GET_FAQS } from "../actionTypes/faqActionTypes";

function* _getFaq(_data: any) {
    try {
        yield put(setFaqLoader(true));
        const { data } = yield call(getFaqService, _data.payload);
        yield put(setFaqLoader(false));
        if (data.status == true) {
            yield put(setFaq(data.data))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setFaqLoader(false));
        console.log("_getFaq: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getFaqs(_data: any) {
    try {
        yield put(setFaqLoader(true));
        const { data } = yield call(getFaqsService, _data.payload);
        yield put(setFaqLoader(false));
        if (data.status == true) {
            yield put(setFaqs({
                totalCount: data?.data?.totalCount,
                // pageSize: data?.data?.pageSize,
                // currentPage: data?.data?.currentPage,
                // totalPage: data?.data?.totalPage,
                data: data?.data?.faqCategorySchemaList
            }))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setFaqLoader(false));
        console.log("_getFaqs: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _createFaq(_data: any) {
    try {
        yield put(setFaqLoader(true));
        const { data } = yield call(createFaqService, _data.payload);
        yield put(setFaqLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(true)
        }
        else {
            toast.error(data.statusDescription)
        }
    }
    catch (err: any) {
        yield put(setFaqLoader(false));
        console.log("_createFaq: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _deleteFaqSchema(_data: any) {
    try {
        // yield put(setFaqLoader(true));
        const { data } = yield call(deleteFaqSchemaService, _data.payload);
        // yield put(setFaqLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(true)
        }
        else {
            toast.error(data.statusDescription)
        }
    }
    catch (err: any) {
        // yield put(setFaqLoader(false));
        console.log("_deleteFaqSchema: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _downloadFaqDump(_data: any) {
    try {
        const { data } = yield call(downloadFaqDumpService, _data.payload);
        _data.callback(data)
    }
    catch (err: any) {
        _data.callback(null)
        console.log("_downloadFaqDump: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}


export default function* faqSaga() {
    yield all([
        takeLatest(GET_FAQS, _getFaqs),
        takeLatest(GET_FAQ, _getFaq),
        takeLatest(CREATE_FAQ, _createFaq),
        takeLatest(DOWNLOAD_FAQ_DUMP, _downloadFaqDump),
        takeLatest(DELETE_FAQ_SCHEMA, _deleteFaqSchema)
    ])
}