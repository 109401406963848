import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid';
import { createFaq, deleteFaqSchema } from "../../../core/actionCreators/faqActionCreator";
import toast from "react-hot-toast";


const FaqForm = (props: any) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { pageMode, faq } = props

    const { user } = useSelector((s: any) => s.authStore);

    const [faqPayload, setFaqPayload] = useState({
        categoryCode: "",
        categoryName: "",
        modifiedBy: user.id || 33,
        addQuestions: [],
        updateQuestions: []
    } as any)

    useEffect(() => {
        if (pageMode === "EDIT" && Object.keys(faq).length) {
            setFaqPayload({
                ...faqPayload,
                ...faq,
                updateQuestions: faq.faqCategorySchemas
            })
        }
    }, [faq])

    const addFaqSchemaHandler = () => {
        setFaqPayload({
            ...faqPayload,
            addQuestions: [...faqPayload.addQuestions, {
                questionText: "",
                answerText: "",
                id: uuidv4()
            }]
        })
    }

    const deleteUnsavedFaqSchema = (id: string) => {
        setFaqPayload({
            ...faqPayload,
            addQuestions: faqPayload.addQuestions.filter((d: any) => d.id !== id)
        })
    }

    const deleteSavedFaqSchema = (id: string) => {
        let res = window.confirm("Are you sure want to delete?")
        if (res) {
            setFaqPayload({
                ...faqPayload,
                updateQuestions: faqPayload.updateQuestions.filter((d: any) => d.id !== id)
            })
            dispatch(deleteFaqSchema({ id: id }, null))
        }
        return
    }

    const onFaqSchemaChange = (id: string, type: string, value: string, action: string) => {
        let curentFaqSchemaIndex = faqPayload[action].findIndex((d: any) => d.id == id)
        if (curentFaqSchemaIndex === -1) {
            return
        }
        let curentFaqSchema = faqPayload[action][curentFaqSchemaIndex]
        curentFaqSchema[type] = value
        faqPayload[action][curentFaqSchemaIndex] = curentFaqSchema
        setFaqPayload({
            ...faqPayload,
            addQuestions: [...faqPayload.addQuestions],
            updateQuestions: [...faqPayload.updateQuestions]
        })
    }

    const saveFaqSchemaHandler = () => {
        faqPayload.addQuestions.forEach((d: any) => delete d.id)
        dispatch(createFaq(faqPayload, () => {
            toast.success("Saved successfully")
            navigate("/faq")
        }))
    }

    return (
        <>
            <div className="p-4">
                <div className="w-full bg-white py-6 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5">
                    <form action="">
                        <div className="flex w-full mb-6">
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Category Code*</label>
                                <input disabled={pageMode === "EDIT"} required value={faqPayload.categoryCode} onChange={(e) => setFaqPayload({ ...faqPayload, categoryCode: e.target.value })}
                                    className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                    type="text"
                                    placeholder="Category Code"
                                />
                            </div>
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Category Name*</label>
                                <input disabled={pageMode === "EDIT"} required value={faqPayload.categoryName} onChange={(e) => setFaqPayload({ ...faqPayload, categoryName: e.target.value })}
                                    className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                    type="text"
                                    placeholder="Category Name"
                                />
                            </div>
                        </div>
                    </form>
                </div>
                {faqPayload.updateQuestions && faqPayload.updateQuestions.map((d: any) =>
                    <>
                        <input onChange={(e) => onFaqSchemaChange(d.id, "questionText", e.target.value, "updateQuestions")} value={d.questionText} placeholder="Queston.." />
                        <input onChange={(e) => onFaqSchemaChange(d.id, "answerText", e.target.value, "updateQuestions")} value={d.answerText} placeholder="Answer.." />
                        <button onClick={() => deleteSavedFaqSchema(d.id)}>Delete</button>
                        <br />
                    </>
                )}
                {faqPayload.addQuestions && faqPayload.addQuestions.map((d: any) =>
                    <>
                        <input onChange={(e) => onFaqSchemaChange(d.id, "questionText", e.target.value, "addQuestions")} value={d.questionText} placeholder="Queston.." />
                        <input onChange={(e) => onFaqSchemaChange(d.id, "answerText", e.target.value, "addQuestions")} value={d.answerText} placeholder="Answer.." />
                        <button onClick={() => deleteUnsavedFaqSchema(d.id)}>Delete</button>
                        <br />
                    </>
                )}
                <div className="mt-5">
                    <button onClick={addFaqSchemaHandler} className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none">Add Question</button>
                    <button onClick={saveFaqSchemaHandler} className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ml-4">Save</button>
                </div>
            </div>
        </>
    )
}
export default FaqForm;