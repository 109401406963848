import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createLayout, getLayouts } from "../../../core/actionCreators/lmsActionCreator";
import { PAGE_SIZE, PLATFORMS } from "../../../utils/constants";
import "../Popup/AddPagePopup.scss";

const AddPagePopup = (props: any) => {
    const dispatch = useDispatch()
    let { user } = useSelector((s: any) => s.authStore);
    const { addPagePopup, setAddPagePopup } = props
    const [device, setDevice] = useState("Desktop")
    const [layoutName, setLayoutName] = useState("")

    //Create Layout
    const addPageHandler = () => {
        if (!device || !layoutName) {
            alert("Fields are empty.")
            return;
        }

        let requestPayload = {
            "layout": {
                "layoutName": layoutName,
                "isParentStructure": false,
                "status": true,
                "device": device
            },
            "modifiedBy": user.id || -1
        }

        let callback = (r: any) => {
            //Close popup
            setAddPagePopup(false)

            //Handle if layour added successfully
            if (!r) {
                dispatch(getLayouts(`pageNumber=0&pageSize=${PAGE_SIZE}`, null))
                return
            }
        }
        dispatch(createLayout(requestPayload, callback))
    }

    return (
        <>
            <div className="popup-container">
                <div className="overlay">
                    <div className="popup-content">
                        <div className="popup-content-inner">
                            <h2 className="text-2xl">Set Page Name</h2>
                            <p className="text-xs">Fill in the below details..</p>
                            <div className="form-field">
                                <label>Platform</label>
                                <select onChange={(e) => setDevice(e.target.value)}>
                                    {PLATFORMS.map((d, index) =>
                                        <option key={index} value={d.value}>{d.title}</option>
                                    )}
                                </select>
                            </div>
                            <div className="form-field">
                                <label>Page Name</label>
                                <input value={layoutName} onChange={(e) => setLayoutName(e.target.value)} type="text" placeholder="Enter Page Name" />
                            </div>
                            <div className="bottom-btn-cta flex mt-6 justify-end items-center">
                                <button onClick={() => setAddPagePopup(false)} className="bg-transparent font-semibold text-slate-800  py-2 px-6">
                                    Cancel
                                </button>
                                <button onClick={addPageHandler} className="bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 py-2 px-6">
                                    Save Page
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default AddPagePopup;