import axios from "axios";
import { apis } from './params';


export  function getPageMappingsService(_param:any) {
  return axios({
    "method": "Get",
    "url": apis.GET_PAGEMAPPINGS + _param,  //_param is a dynamic url and + sign is used for concatination the whole url
  })

}

export function getPageMappingByIdService(_param: any) {
  return axios({
      "method": "Get",
      "url": apis.GET_PAGEMAPPING_BY_ID + _param
  })
}

export  function getPageMappingService(_param:any) {
  return axios({
    "method": "Get",
    "url": apis.GET_PAGEMAPPING + _param, 
  })

}
export  function createPageMappingService(_data:any) {
  return axios({
    "method": "Post",
    "url": apis.CREATE_PAGEMAPPING ,
    "data": _data
  })

}
export  function updatePageMappingService(_data:any) {
  return axios({
    "method": "Post",
    "url": apis.UPDATE_PAGEMAPPING,  
    "data": _data
  })

}

export function deletePageMappingService(_data: any) {
  return axios({
      "method": "Post",
      "url": apis.DELETE_PAGEMAPPING_SCHEMA,
      "data": _data
  })
}



