import { useEffect, useState } from "react";
import CreateBrandBlockSubHeader from "../../../components/BrandBlock/CreateBrandBlockSubHeader";
import Header from "../../../components/Header/Header";
import SideNavbar from "../../../components/Navbar/SideNavbar";
import { useDispatch } from "react-redux";
import { getBrandBlock, setBrandBlock } from "../../../../core/actionCreators/blocksActionCreator";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import BrandBlocksParentForm from "../../../components/BrandBlock/BrandBlocksParentForm";

const BrandBlock = () => {
    const params = useParams();
    const dispatch = useDispatch()
    const [pageMode, setPageMode] = useState("")
    const { brandBlock, isLoading } = useSelector((s: any) => s.blocksStore)


    useEffect(() => {
        const currentPath = window.location.pathname;
        if (currentPath === "/blocks/brand/create") {
            setPageMode("CREATE")
        }
        else {
            if (params.id) {
                setPageMode("EDIT")
                dispatch(getBrandBlock(params.id, null))
            }
        }
        return () => {
            dispatch(setBrandBlock({}))
        }
    }, [params, window])


    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <CreateBrandBlockSubHeader pageMode={pageMode} />
                        <BrandBlocksParentForm pageMode={pageMode} blockData={brandBlock} loaderFlag={isLoading} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default BrandBlock;