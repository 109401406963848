import React, { useState } from 'react'
import './Header.scss';
import { AiOutlineDown } from 'react-icons/ai';
import { BsBell } from 'react-icons/bs';
import { RxAvatar } from 'react-icons/rx';
import { SlOptions } from 'react-icons/sl';
import { FiSearch } from 'react-icons/fi';
import { IoIosArrowBack } from 'react-icons/io';

import AddPagePopup from '../Popup/AddPagePopup';
import UserProfile from './UserProfile';


const Header = () => {

    const [addPagePopup, setAddPagePopup] = useState(false)

    return (
        <>
            {addPagePopup && <AddPagePopup addPagePopup={addPagePopup} setAddPagePopup={setAddPagePopup} />}
            <div className="header">
                <div className="header-inner">
                    <div className="main-header flex items-center p-[15px]">
                        <div className="header-left">
                            <div className="searchbar flex items-center cursor-pointer">
                                {/* <div className="form-field header-search">
                                <div className="input-field">
                                    <input type="text" className="if-search-left" placeholder="Search transactions, invoices or help" />
                                    <div className="search-icon-left flex justify-around items-center">
                                        <FiSearch size={16} className="text-gray-400" />
                                    </div>
                                </div>
                            </div> */}
                                {/* <span className='text-gray-400'><FiSearch size={20} /></span>&nbsp; <span className='text-gray-400 text-sm'>Search transactions, invoices or help</span> */}
                            </div>
                        </div>
                        <UserProfile />
                    </div>
                    {/* <div className="subheader items-center flex justify-between p-[15px]">
                <h2 className='font-bold'>Page Name</h2>
                <button className='py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'>Button Name</button>
            </div> */}
                    {/* <div className="haderVarient2 items-center flex justify-between p-[15px] ">
                        <div className="pagename-n-input flex items-center">
                            <IoIosArrowBack />
                            <div className="pagename-sec">
                                <h2 className='font-bold'>Page Name</h2>
                                <p className='text-xs p-subname'>Desktop</p>
                            </div>
                            <div className="form-field">
                                <div className="input-field ">
                                    <input type="text" className="if-search-left" placeholder="Search by ID, Label, Name, Category…" />
                                    <div className="search-icon-left flex justify-around items-center">
                                        <FiSearch size={16} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right-buttons items-center flex justify-end">
                            <button onClick={() => setAddPagePopup(true)} className=' py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'>Create Page</button>
                            <div className="right-bordered-sec"></div>
                        <button className="cancel-btn font-semibold text-slate-800  py-2 px-6 rounded">Cancel</button>
                        <button className="bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 py-2 px-6">Save</button>
                        <td className=""><SlOptions /></td>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )

}

export default Header
