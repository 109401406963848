import { useDispatch } from "react-redux";
import AttributeListItem from "./AttributeListItem";
import AttributeListingSubHeader from "./AttributeListingSubHeader";
import { useSelector } from "react-redux";
import { GET_ATTRIBUTE_DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import { useEffect, useRef, useState } from "react";
import queryString from "query-string";
import { getAttributes } from "../../../core/actionCreators/attributeActionCreator";
import CommonPagination from "../CommonPagination/CommonPagination";
import Loader from "../Loader/Loader";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";

const AttributeList = () => {
  const dispatch = useDispatch();
  const { attributes, isLoading } = useSelector((s: any) => s.attributeStore);

  const [searchCriteria, setSearchCriteria] = useState({
    pageNumber: 0,
    pageSize: GET_ATTRIBUTE_DEFAULT_PAGE_SIZE,
    title: "",
    category: "",
    isActive: "",
  } as any);

  const previousSearchCriteriaRef = useRef(searchCriteria);


  useEffect(() => {
    const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
    const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
    const previousCriteria = previousSearchCriteriaRef.current;
    const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

    if (searchFilterChanged) {
      const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
      for (let key in parsedSearchCriteria) {
        if (["title", "category"].includes(key)) {
          parsedSearchCriteria.pageNumber = 0
          break
        }
      }
      dispatch(getAttributes(queryString.stringify(parsedSearchCriteria), null));
      previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
    }
  }, [searchCriteria]);


  const onPageChange = (pageNumber: any) => {
    setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
  }


  return (
    <>
      <AttributeListingSubHeader searchCriteria={searchCriteria} />
      <div className="mt-4 campaign-list bg-white mb-[54px]">
        <table className="w-full">
          <thead className="bg-[#343434]">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[8%]">
                Sno
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[27%]">
                Title
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]">
                Category
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">
                Product Count
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]">
                Status
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-3 text-left text-xs text-white w-[8%]">
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[27%]">
                <input
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                  type="text"
                  value={searchCriteria.title}
                  onChange={(e) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      title: e.target.value,
                    })
                  }
                />
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[20%]">
                <input
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                  type="text"
                  value={searchCriteria.category}
                  onChange={(e) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      category: e.target.value,
                    })
                  }
                />
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[15%]">
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[20%]">
                <select
                  value={searchCriteria.isActive}
                  onChange={(e) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      isActive: e.target.value,
                    })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs bg-white"
                >
                  <option>Select An Option</option>
                  <option value={"true"}>True</option>
                  <option value={"false"}>False</option>
                </select>
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[10%]"></td>
            </tr>
            {isLoading ? (
              <Loader />
            ) : (
              <AttributeListItem data={attributes?.data || []} />
            )}
          </tbody>
        </table>
      </div>
      {attributes.data && (
        <CommonPagination
          data={attributes}
          PAGE_SIZE={GET_ATTRIBUTE_DEFAULT_PAGE_SIZE}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};
export default AttributeList;
