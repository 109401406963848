import * as actions from "../actionTypes/attributeActionTypes";

export const setAttributeLoader = (_data: any) => {
  return {
    type: actions.SET_ATTRIBUTE_LOADER,
    payload: _data,
  };
};

export const getAttributeById = (_data: any, _callback: any) => {
  return {
    type: actions.GET_ATTRIBUTE_BY_ID,
    payload: _data,
    callback: _callback,
  };
};
export const getAttributes = (_data: any, _callback: any) => {
  return {
    type: actions.GET_ATTRIBUTES,
    payload: _data,
    callback: _callback,
  };
};

export const setAttributes = (_data: any) => {
  return {
    type: actions.SET_ATTRIBUTES,
    payload: _data,
  };
};

export const getAttribute = (_data: any, _callback: any) => {
  return {
    type: actions.GET_ATTRIBUTE,
    payload: _data,
    callback: _callback,
  };
};
export const setAttribute = (_data: any) => {
  return {
    type: actions.SET_ATTRIBUTE,
    payload: _data,
  };
};

export const createAttribute = (_data: any, _callback: any) => {
  return {
    type: actions.CREATE_ATTRIBUTE,
    payload: _data,
    callback: _callback,
  };
};

export const updateAttribute = (_data: any, _callback: any) => {
  return {
    type: actions.UPDATE_ATTRIBUTE,
    payload: _data,
    callback: _callback,
  };
};

export const deleteAttributeSchema = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_ATTRIBUTE_SCHEMA,
    payload: _data,
    callback: _callback,
  };
};
export const deleteAttribute = (_data: any) => {
  return {
    type: actions.DELETE_ATTRIBUTE,
    payload: _data,
  };
};

export const getProductCount = (_data: any, _callback: any) => {
  return {
    type: actions.GET_PRODUCT_COUNT,
    payload: _data,
    callback: _callback,
  };
};

export const setDownloadDumpLoader = (_data: any) => {
  return {
    type: actions.SET_DOWNLOAD_DUMP_LOADER,
    payload: _data,
  };
};

export const downloadAttributeDump = (_data: any, _callback: any) => {
  return {
    type: actions.DOWNLOAD_ATTRIBUTE_DUMP,
    payload: _data,
    callback: _callback,
  };
};
export const bulkUploadAttributeFile = (_data: any, _callback: any) => {
  return {
    type: actions.BULK_UPLOAD_ATTRIBUTE_FILE,
    payload: _data,
    callback: _callback,
  };
};
