import * as actions from "../actionTypes/pageMappingActionTypes"


export const setPageMappingLoader = (_data: any) => {
    return {
        type: actions.SET_PAGEMAPPING_LOADER,
        payload: _data
    }
}

export const getPageMappingById = (_data: any, _callback: any) => {
    return {
        type: actions.GET_PAGEMAPPING_BY_ID,
        payload: _data,
        callback: _callback
    }
}
export const getPageMappings = (_data: any, _callback: any) => {
    return {
        type: actions.GET_PAGEMAPPINGS,
        payload: _data,
        callback: _callback
    }
}

export const setPageMappings = (_data: any) => {
    return {
        type: actions.SET_PAGEMAPPINGS,
        payload: _data,
    }
}

export const getPageMapping = (_data: any, _callback: any) => {
    return {
        type: actions.GET_PAGEMAPPING,
        payload: _data,
        callback: _callback
    }
}

export const setPageMapping = (_data: any) => {
    return {
        type: actions.SET_PAGEMAPPING,
        payload: _data
    }
}

export const createPageMapping = (_data: any, _callback: any) => {
    return {
        type: actions.CREATE_PAGEMAPPING,
        payload: _data,
        callback: _callback
    }
}

export const updatePageMapping = (_data: any, _callback: any) => {
    return {
        type: actions.UPDATE_PAGEMAPPING,
        payload: _data,
        callback: _callback
    }
}

export const deletePageMappingSchema = (_data: any, _callback: any) => {
    return {
        type: actions.DELETE_PAGEMAPPING_SCHEMA,
        payload: _data,
        callback: _callback
    }
}

export const deletePageMapping = (_data: any) => {
    return {
        type: actions.DELETE_PAGEMAPPING,
        payload: _data
    }
}