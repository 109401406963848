import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  GET_PRODUCT_TAGS,
  GET_PRODUCT_TAGS_DETAILS,
  UPLOAD_IMAGE,
  CREATE_PRODUCT_TAG,
  EDIT_PRODUCT_TAG,
} from "../actionTypes/productTagsActionTypes";
import {
  setProductTagsLoader,
  setProductTags,
  setProductTag,
  setProductTagDetails,
  updateTagData,
  createTag,
  uploadImage,
} from "../actionCreators/productTagsActionCreator";
import {
  getProductTagsDetails,
  getProductTags,
  uploadProductTagImage,
  createProductTag,
  updateProductTag,
} from "../services/productTagsService";
import { toast } from "react-hot-toast";

function* _getProductTags(_data: any) {
  try {
    yield put(setProductTagsLoader(true));
    const { data } = yield call(getProductTags);
    const tagId = (data?.data[0].tagId as string) || null;
    if (tagId) {
      const { data } = yield call(getProductTagsDetails, tagId);
      if (data?.status === true) {
        yield put(setProductTagDetails(data.data[0]));
      }
    }
    yield put(setProductTagsLoader(false));
    if (data.status === true) {
      yield put(setProductTags(data.data));
    } else {
      _data.callback && _data.callback(data.data);
    }
  } catch (err: any) {
    yield put(setProductTagsLoader(false));
    console.log("_getProductTags: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getProductTagsDetails(_data: any) {
  try {
    yield put(setProductTagsLoader(true));
    const { data } = yield call(getProductTagsDetails, _data.payload);
    yield put(setProductTagsLoader(false));
    if (data.status === true) {
      yield put(setProductTagDetails(data.data[0]));
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setProductTagsLoader(false));
    console.log("_getProductTagsDetails: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _uploadImage(_data: any) {
  try {
    // yield put(setProductTagsLoader(true));
    const { data } = yield call(uploadProductTagImage, _data.payload);
    // yield put(setProductTagsLoader(false));
    if (data.status == true) {
      _data.callback && _data.callback(data.data);
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    // yield put(setProductTagsLoader(false));
    console.log("_uploadImage: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _createProductTag(_data: any) {
  try {
    yield put(setProductTagsLoader(true));
    const { data } = yield call(createProductTag, _data.payload);
    yield put(setProductTagsLoader(false));
    if (data.status === true) {
      _data.callback && _data.callback(data);
      yield put(setProductTag(data.data));
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setProductTagsLoader(false));
    console.log("_createProductTag: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _updateProductTag(_data: any) {
  try {
    yield put(setProductTagsLoader(true));
    const { data } = yield call(updateProductTag, _data.payload);
    yield put(setProductTagsLoader(false));
    if (data.status === true) {
      yield put(setProductTagsLoader(false));
      yield put(setProductTagDetails(data.data[0]));
      yield put(updateTagData(data.data[0]));
      _data.callback && _data.callback(data);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setProductTagsLoader(false));
    console.log("_updateProductTag: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

export default function* productTagSaga() {
  yield all([
    takeLatest(GET_PRODUCT_TAGS, _getProductTags),
    takeLatest(GET_PRODUCT_TAGS_DETAILS, _getProductTagsDetails),
    takeLatest(CREATE_PRODUCT_TAG, _createProductTag),
    takeLatest(EDIT_PRODUCT_TAG, _updateProductTag),
    takeLatest(UPLOAD_IMAGE, _uploadImage),
  ]);
}
