import { toast } from "react-hot-toast"
import '../../../../src/index.scss'
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import { getFileNameWithoutExtension } from "../../../utils/helper";

const BrandBlocksElementForm = (props: any) => {
    const { data, brandBlockPayload, setBrandBlockPayload, removeBrandBlockElementHandler } = props

    const formHandler = (formType: any, e: any) => {
        const elementIndex = brandBlockPayload.brandElementList.findIndex((d: any) => d.id === data.id)
        if (elementIndex === -1) {
            toast.error("Something went wrong.")
            return
        }
        const element = brandBlockPayload.brandElementList[elementIndex]
        if (formType === "NAME") {
            element.name = e.target.value
        }
        else if (formType === "LABEL") {
            element.label = e.target.value
        }
        else if (formType === "ELEMENT_ORDER") {
            element.elementOrder = e.target.value
        }
        else if (formType === "URL") {
            element.url = e.target.value
        }
        else if (formType === "FILE") {
            if (e.target.files && e.target.files[0]) {
                let file = e.target.files[0];
                element.localFile = file
                element.fileName = getFileNameWithoutExtension(e.target.files[0].name)
            }
        }
        else {
            toast.error("Something went wrong.")
        }
        brandBlockPayload.brandElementList[elementIndex] = { ...element }
        setBrandBlockPayload({ ...brandBlockPayload })
    }

    return (<>
        <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-4">
            <div >
                <CloseCircleFillIcon onClick={() => removeBrandBlockElementHandler(data.id)} className="ml-auto cursor-pointer" size={22} />
            </div>

            <form action="">
                <div className="flex w-full mb-6 last:mb-0">
                    <div className="w-1/2 px-2">
                        <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Name</label>
                        <input value={data.name} onChange={(e) => formHandler("NAME", e)}
                            className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                            type="text"
                        />
                    </div>
                    <div className="w-1/2 px-2">
                        <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Label</label>
                        <input value={data.label} onChange={(e) => formHandler("LABEL", e)}
                            className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                            type="text"
                        />
                    </div>
                </div>
                <div className="flex w-full mb-6 last:mb-0">
                    <div className="w-1/2 px-2">
                        <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Order</label>
                        <input value={data.elementOrder} onChange={(e) => formHandler("ELEMENT_ORDER", e)}
                            className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                            type="text"
                        />
                    </div>
                    <div className="w-1/2 px-2">
                        <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">URL</label>
                        <input value={data.url} onChange={(e) => formHandler("URL", e)}
                            className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                            type="text"
                        />
                    </div>
                </div>
                <div className="flex w-full mb-6 last:mb-0">
                    <div className="w-1/2 px-2">
                        {data.s3ImageUrl && (
                            <div className="thumbnail-container">
                                <img className="thumbnail" src={data.s3ImageUrl} alt="Preview" />
                            </div>
                        )}
                        {data.s3ImageUrl && (<a className="text-[10px] text-[#3c3c3c]" href={data.s3ImageUrl} target="_blank">File</a>)}
                    </div>
                    <div className="w-1/2 px-2">
                        <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Image</label>
                        <input onChange={(e) => formHandler("FILE", e)}
                            className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                            type="file"
                        />
                    </div>
                </div>
            </form>
        </div>
    </>)
}

export default BrandBlocksElementForm