import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  CREATE_PLA_FEED,
  UPDATE_PLA_FEED,
  GET_PLA_FEED,
  GET_PLA_BY_ID,
  GET_PLA_FEEDS,
  DOWNLOAD_PLA_DUMP,
  BULK_PLA_FILE,
} from "../actionTypes/plaFeedActionTypes";
import {
  setPlaFeed,
  setPlaFeeds,
  setPlaLoader,
  updatePlaFeed,
  setDownloadDumpLoader,
} from "../actionCreators/plaFeedActionCreator";
import {
  downloadPlaDumpService,
  bulkPlaService,
  createPlaFeedService,
  getPlaFeedByIdService,
  getPlaFeedsService,
  updatePlaFeedService,
} from "../services/plaFeedServices";
import { toast } from "react-hot-toast";

function* _getPlaFeed(_data: any) {
  try {
    yield put(setPlaLoader(true));
    const { data } = yield call(getPlaFeedByIdService, _data.payload);
    yield put(setPlaLoader(false));
    if (data.status == true) {
      yield put(setPlaFeed(data.data));
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setPlaLoader(false));
    console.log("_getPlaFeed: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getPlaFeeds(_data: any) {
  try {
    yield put(setPlaLoader(true));
    const { data } = yield call(getPlaFeedsService, _data.payload);
    yield put(setPlaLoader(false));
    if (data.status == true) {
      yield put(
        setPlaFeeds({
          totalCount: data?.data?.totalCount,
          // pageSize: data.pageSize,
          // currentPage: data.currentPage,
          // totalPage: data.totalPage,
          data: data?.data?.plaFeedResponse,
        })
      );
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setPlaLoader(false));
    console.log("_getPlaFeeds: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _createPlaFeed(_data: any) {
  try {
    yield put(setPlaLoader(true));
    const { data } = yield call(createPlaFeedService, _data.payload);
    yield put(setPlaLoader(false));
    if (data.status == true) {
      _data.callback && _data.callback(true);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setPlaLoader(false));
    console.log("_createPlaFeed: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _updatePlaFeed(_data: any) {
  try {
    yield put(setPlaLoader(true));
    const { data } = yield call(updatePlaFeedService, _data.payload);
    yield put(setPlaLoader(false));
    if (data.status == true) {
      // yield put(updatePlaFeed(data.data, null))  //check
      yield put(setPlaFeed(data.data));
      _data.callback && _data.callback(true);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setPlaLoader(false));
    console.log("_updatePlaFeed: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _downloadPlaDump(_data: any) {
  try {
    yield put(setDownloadDumpLoader(true));
    const { data } = yield call(downloadPlaDumpService, _data.payload);
    yield put(setDownloadDumpLoader(false));
    if (data.status == true) {
      _data.callback && _data.callback(data);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setDownloadDumpLoader(false));
    console.log("_downloadPlaDump: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _bulkPlaFile(_data: any) {
  try {
    yield put(setPlaLoader(true));
    const { data } = yield call(bulkPlaService, _data.payload);
    yield put(setPlaLoader(false));
    if (data.status == true) {
      yield put(setPlaFeed(data.data));
      _data.callback && _data.callback(data);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setPlaLoader(false));
    console.log("_bulkPlaFile: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getPlaById(_data: any) {
  try {
    yield put(setPlaLoader(true));
    const { data } = yield call(getPlaFeedByIdService, _data.payload);
    yield put(setPlaLoader(false));
    if (data.status == true) {
      yield put(setPlaFeed(data.data));
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setPlaLoader(false));
    console.log("_getPlaById: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}
export default function* blocksSaga() {
  yield all([
    takeLatest(GET_PLA_FEEDS, _getPlaFeeds),
    takeLatest(GET_PLA_FEED, _getPlaFeed),
    takeLatest(CREATE_PLA_FEED, _createPlaFeed),
    takeLatest(UPDATE_PLA_FEED, _updatePlaFeed),
    takeLatest(DOWNLOAD_PLA_DUMP, _downloadPlaDump),
    takeLatest(BULK_PLA_FILE, _bulkPlaFile),
    takeLatest(GET_PLA_BY_ID, _getPlaById),
  ]);
}
