import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { RiDeleteBin4Fill } from 'react-icons/ri';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from "react-redux";
import { uploadImage } from "../../../core/actionCreators/lmsActionCreator";


const AddAttributePopup = (props: any) => {
    const dispatch = useDispatch()
    const { addAttributePopup, setAttributepopup, attributes, setAttributes } = props
    let { staticFormFields } = useSelector((s: any) => s.lmsStore);
    const [fieldKey, setFieldKey] = useState(staticFormFields[0]?.attributeKey)
    const [fieldType, setFieldType] = useState("")
    const [fieldValue, setFieldValue] = useState("")
    const [fieldId, setFieldId] = useState(-1)
    const [showFileUploadField, setShowFileUploadField] = useState(false)
    const [showTextField, setShowTextField] = useState(false)
    const [showBooleanField, setShowBooleanField] = useState(false)
    const [buttonValue, setButtonValue] = useState("Add")



    //change fieldType as per fieldKey
    useEffect(() => {
        if (fieldKey) {
            const attribute = staticFormFields.find((s: any) => s.attributeKey == fieldKey)
            if (attribute) {
                //File upload
                if ([27, 28].includes(attribute.id)) {
                    setShowFileUploadField(true)
                    setShowBooleanField(false)
                    setShowTextField(false)
                }
                //Boolean
                else if ([12, 18, 19].includes(attribute.id)) {
                    setShowBooleanField(true)
                    setShowFileUploadField(false)
                    setShowTextField(false)
                }
                //Text 
                else {
                    setShowTextField(true)
                    setShowFileUploadField(false)
                    setShowBooleanField(false)
                }
                setFieldId(attribute.id)
                setFieldType(attribute.attributeType)
            }
        }
    }, [fieldKey])

    const imagehandler = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            setFieldValue(img);
        }
    };

    const addPageHandler = () => {
        //To Handle file upload
        if ([27, 28].includes(fieldId)) {
            const formData = new FormData();
            formData.append("file", fieldValue);
            setButtonValue("Uploading...")
            dispatch(uploadImage(formData, (res: any) => {
                setFieldValue(res.s3ImageUrl)
                const newAttribute = {
                    "id": uuidv4(),
                    "attributeKeyId": fieldId,
                    "attributeKey": fieldKey,
                    "attributeType": fieldType,
                    "attributeValue": res.s3ImageUrl
                }
                setAttributes([...attributes, newAttribute])
                setAttributepopup(false)
            }))
        }
        else {
            const newAttribute = {
                "id": uuidv4(),
                "attributeKeyId": fieldId,
                "attributeKey": fieldKey,
                "attributeType": fieldType,
                "attributeValue": fieldValue
            }
            setAttributes([...attributes, newAttribute])
            setAttributepopup(false)
        }
    }

    return (<>
        <div className="popup-container">
            <div className="overlay">
                <div className="popup-content">
                    <div className="popup-content-inner">
                        <div className="form-field">
                            <label>Field Key</label>
                            <select onChange={(e) => setFieldKey(e.target.value)}>
                                {staticFormFields.map((d: any, index: number) =>
                                    <option key={index} value={d.attributeKey}>{d.attributeKey}</option>
                                )}
                            </select>
                        </div>
                        <div className="form-field">
                            <label>Field Type</label>
                            <select>
                                <option disabled={true} selected={true} value={fieldType}>{fieldType}</option>
                            </select>
                        </div>

                        {showFileUploadField &&
                            <div className="form-field mt-4">
                                <label>Choose File:</label>
                                <input accept=".jpg,.png,.jpeg" type="file" onChange={imagehandler} />
                            </div>
                        }

                        {showBooleanField &&
                            <div className="form-field">
                                <label>Field Value</label>
                                <select value={fieldValue} onChange={(e) => setFieldValue(e.target.value)} >
                                    <option>Select</option>
                                    <option value="true" >True</option>
                                    <option value="false" >False</option>
                                </select>
                            </div>
                        }

                        {
                            showTextField &&
                            <div className="form-field">
                                <label>Field Value</label>
                                <input value={fieldValue} onChange={(e) => setFieldValue(e.target.value)} type="text" placeholder="Enter value here.." />
                            </div>
                        }
                        <div className="bottom-btn-cta flex mt-6 justify-end items-center">
                            <button onClick={() => setAttributepopup(false)} className="bg-transparent font-semibold text-slate-800  py-2 px-6">
                                Cancel
                            </button>
                            <button onClick={addPageHandler} className="bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 py-2 px-6">
                                {buttonValue}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default AddAttributePopup