import React from "react";
import PublicRoutes from "./routes/PublicRoutes";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import { Toaster } from "react-hot-toast";

function App() {
    return (
        <>
            <React.Suspense>
                <Toaster position="top-right"
                    reverseOrder={false} />
                <PublicRoutes />
                <ProtectedRoutes />
            </React.Suspense>
        </>
    );
}

export default App;
