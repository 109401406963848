import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import Header from "../../../components/Header/Header";
import SideNavbar from "../../../components/Navbar/SideNavbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProductBlock, setProductBlock } from "../../../../core/actionCreators/blocksActionCreator";

import ProductBlockParentForm from "../../../components/ProductBlock/ProductBlockParentForm";

const ProductBlock = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageMode, setPageMode] = useState("");
  const { productBlock, isLoading } = useSelector((s: any) => s.blocksStore);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === "/blocks/product/create") {
      setPageMode("CREATE");
    } else {
      if (params.id) {
        setPageMode("EDIT");
        dispatch(getProductBlock(params.id, null));
      }
    }
    return () => {
      dispatch(setProductBlock({}));
    };
  }, [params, window]);

  const backArrowClickHadler = () => {
    navigate("/blocks/product");
  };
  return (
    <>
      <div className="flex mainWrapper w-full">
        <div className="sidenav-wrapper">
          <SideNavbar />
        </div>
        <div className="right-container">
          <div className="header-container">
            <Header />
          </div>
          <div className="body-container">
            <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
              <div className="flex items-center w-full">
                <ArrowLeftSLineIcon
                  onClick={backArrowClickHadler}
                  size={24}
                  className="mr-4 cursor-pointer"
                />
                <p className="text-xl font-bold text-[#43425D] flex items-center">
                  {pageMode === "CREATE" ? "Create" : "Update"} Product Block
                </p>
              </div>
            </div>
            <ProductBlockParentForm
              pageMode={pageMode}
              blockData={productBlock}
              loaderFlag={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductBlock;
