import { call, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-hot-toast";
import { setCampaignLoader } from "../actionCreators/campaignActionCreator";
import { delayCommunicationViaWhatsappService } from "../services/whatsappCampaignService";
import { DELAY_COMMUNICATION_VIA_WHATSAPP } from "../actionTypes/campaignActionTypes";
import { sleep } from "../services/utilService";

function* delayComunicationViaWhatsappSaga(_data: any) {
    try {
        yield put(setCampaignLoader(true));
        const { data } = yield call(delayCommunicationViaWhatsappService, _data.payload);
        yield put(setCampaignLoader(false));
        _data.callback && _data.callback(null)
    }
    catch (err: any) {
        yield put(setCampaignLoader(false));
        console.log("_updateCampaignStatus: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

export default function* campaginSaga() {
    yield all([takeLatest(DELAY_COMMUNICATION_VIA_WHATSAPP, delayComunicationViaWhatsappSaga)])
}