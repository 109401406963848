import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createBrandBlock,
  deleteBrandBlockElement,
  updateBrandBlock,
} from "../../../core/actionCreators/blocksActionCreator";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import BrandBlocksElementForm from "./BrandBlocksElementForm";
import { getCategories } from "../../../core/actionCreators/adsenseActionCreator";
import Loader from "../Loader/Loader";


const BrandBlocksParentForm = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageMode, blockData, loaderFlag } = props;

  const { user } = useSelector((s: any) => s.authStore);


  const [brandBlockPayload, setBrandBlockPayload] = useState({
    id: "",
    label: "",
    name: "",
    title: "",
    category: "",
    elementCount: "",
    status: "true",
    associatedLayouts: [],
    brandElementList: [{
      id: uuidv4(),
      name: "",
      label: "",
      elementOrder: "1",
      url: "",
      file: ""
    },],
  } as any);

  useEffect(() => {
    if (pageMode === "EDIT" && Object.keys(blockData).length) {
      setBrandBlockPayload(blockData);
    } else {
      dispatch(getCategories(null, null));
    }
  }, [blockData]);

  const createBrandBlockHandler = () => {
    if (brandBlockPayload.label.trim() === "") {
      toast.error("Label cannot be blank.");
      return;
    }
    if (brandBlockPayload.name.trim() === "") {
      toast.error("Name cannot be blank.");
      return;
    }
    const formData = new FormData();
    let data: any = { ...brandBlockPayload };

    delete data.associatedLayouts
    //TypeCast to number
    if (data.elementCount) {
      data.elementCount = parseInt(data.elementCount)
    }
    data.status = JSON.parse(data.status)

    delete data.id;
    let headers = {
      userId: user.id || 33,
    };

    data.brandElementList.forEach((d: any) => {
      if (d.localFile) {
        let file = d.localFile;
        formData.append("files", file);
        delete d.file
        delete d.id;
        delete d.localFile
      }
      if (d.elementOrder) {
        d.elementOrder = parseInt(d.elementOrder)
      }
    });

    formData.append("json", JSON.stringify(data));
    dispatch(
      createBrandBlock({ data: formData, headers: headers }, (resp: any) => {
        if (resp.status == true) {
          toast.success("Created successfully.");
          navigate("/blocks/brand");
        } else {
          toast.error(resp.statusDescription);
          setBrandBlockPayload({ ...brandBlockPayload, brandElementList: [] })
        }
      })
    );
  };

  const updateBrandBlockHandler = () => {
    const formData = new FormData();
    let data: any = { ...brandBlockPayload };

    //TypeCast to number
    if (data.elementCount) {
      data.elementCount = parseInt(data.elementCount)
    }

    data.status = JSON.parse(data.status)
    delete data.associatedLayouts
    let headers = {
      userId: user.id || 33,
    };
    let params = {
      id: data.id,
    };

    data.brandElementList.forEach((d: any) => {
      if (d.localFile) {
        let file = d.localFile;
        formData.append("files", file);
        delete d.localFile
      }
      if (typeof d.id === 'string') {
        delete d.id
        delete d.file
      }
      if (d.elementOrder) {
        d.elementOrder = parseInt(d.elementOrder)
      }
    });
    formData.append("json", JSON.stringify(data));
    dispatch(
      updateBrandBlock({ params, data: formData, headers }, (resp: any) => {
        if (resp.status) {
          toast.success("Updated successfully.");
          window.location.reload();
        } else {
          setBrandBlockPayload(blockData);
          toast.error(resp.statusDescription);
        }
      })
    );
  };

  const addElementHandler = () => {
    let newElement = {
      id: uuidv4(),
      name: "",
      label: "",
      elementOrder: "1",
      url: "",
      file: "",
    };
    brandBlockPayload.brandElementList.push(newElement);
    setBrandBlockPayload({ ...brandBlockPayload });
  };

  const removeBrandBlockElementHandler = (blockElementId: any) => {
    const isConfirmed = window.confirm('Are you sure want to delete?')
    if (!isConfirmed) {
      return
    }
    const elementIndex = brandBlockPayload.brandElementList.findIndex(
      (d: any) => d.id === blockElementId
    );
    if (elementIndex === -1) {
      toast.error("Something went wrong.");
      return;
    }

    if (pageMode === "EDIT" && typeof blockElementId !== 'string') {
      //Call api to delete from db
      dispatch(deleteBrandBlockElement({
        blockId: brandBlockPayload.id,
        blockElementId: blockElementId,
        userId: user.id || 33
      }, (resp: any) => {
        if (!resp.status) {
          toast.error(resp.statusDescription)
          return
        }
        brandBlockPayload.brandElementList.splice(elementIndex, 1);
        setBrandBlockPayload({ ...brandBlockPayload });
        return
      }))
    }
    else {
      brandBlockPayload.brandElementList.splice(elementIndex, 1);
      setBrandBlockPayload({ ...brandBlockPayload });
    }
  };

  const navigateToLayoutPageHandler = (layoutId: number) => {
    navigate(`/blocks/layout/${layoutId}`);
  }

  return (
    <>
      <div className="p-4 pb-8">
        <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5">
          <form action="">
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Label*
                </label>
                <input
                  value={brandBlockPayload.label}
                  onChange={(e) =>
                    setBrandBlockPayload({
                      ...brandBlockPayload,
                      label: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Name*
                </label>
                <input
                  value={brandBlockPayload.name}
                  onChange={(e) =>
                    setBrandBlockPayload({
                      ...brandBlockPayload,
                      name: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Title
                </label>
                <input
                  value={brandBlockPayload.title}
                  onChange={(e) =>
                    setBrandBlockPayload({
                      ...brandBlockPayload,
                      title: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Category
                </label>
                <input
                  value={brandBlockPayload.category}
                  onChange={(e) =>
                    setBrandBlockPayload({
                      ...brandBlockPayload,
                      category: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Elements Count
                </label>
                <input
                  value={brandBlockPayload.elementCount}
                  onChange={(e) =>
                    setBrandBlockPayload({
                      ...brandBlockPayload,
                      elementCount: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Status
                </label>
                <select
                  value={brandBlockPayload.status}
                  onChange={(e) =>
                    setBrandBlockPayload({
                      ...brandBlockPayload,
                      status: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light"
                >
                  <option value={"true"}>Active</option>
                  <option value={"false"}>Inactive</option>
                </select>
              </div>

            </div>

            {/* Show Associated Layout if edit mode */}
            {pageMode === "EDIT" && brandBlockPayload.associatedLayouts && Array.isArray(brandBlockPayload.associatedLayouts) && brandBlockPayload.associatedLayouts.length > 0 && <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Associated Layouts</label>
                <ul className="list-none p-0">
                  {brandBlockPayload.associatedLayouts.map((d: any, index: number) =>
                    <li key={index} className="mb-1 last:mb-0">
                      <p onClick={() => navigateToLayoutPageHandler(d.layout_id)} className="text-blue-500 hover:underline">{d.layoutName}</p>
                    </li>
                  )}
                </ul>
              </div>
            </div>}

          </form>
        </div>
        {brandBlockPayload?.brandElementList?.map((d: any, index: number) => (
          <BrandBlocksElementForm
            key={index}
            data={d}
            brandBlockPayload={brandBlockPayload}
            setBrandBlockPayload={setBrandBlockPayload}
            removeBrandBlockElementHandler={removeBrandBlockElementHandler}
          />
        ))}
        <div className="mt-5">
          <button
            onClick={addElementHandler}
            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none "
          >
            Add Element
          </button>
          {loaderFlag ? <Loader /> : <button
            onClick={
              pageMode === "EDIT"
                ? updateBrandBlockHandler
                : createBrandBlockHandler
            }
            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ml-5"
          >
            {pageMode === "CREATE" ? "Create" : "Update"}
          </button>}
        </div>
      </div>
    </>
  );
};
export default BrandBlocksParentForm;
