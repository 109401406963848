import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  createPageMapping,
  updatePageMapping,
} from "../../../core/actionCreators/pageMappingActionCreator";
import { toast } from "react-hot-toast";

const PageMappingForm = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pageMode, pageMaping } = props;


  const [pageMappingPayload, setPageMappingPayload] = useState({
    id: "",
    requestParam: "",
    layoutCodes: "",
    layoutType: "",
    pageTitle: "",
    redisKey: "",
    type: "",
    isWebView: "",
  } as any);

  useEffect(() => {
    if (
      pageMode === "EDIT" &&
      Object.keys(pageMaping).length &&
      typeof pageMaping !== "string"
    ) {
      setPageMappingPayload(pageMaping);
    } else {
      setPageMappingPayload(pageMappingPayload);
    }
  }, [pageMaping]);

  const formSubmitHandler = () => {
    //required field added...................................
    if (pageMappingPayload.requestParam.trim() === "") {
      toast.error("Request Param is required.");
      return;
    }

    if (pageMappingPayload.isWebView === "") {
      toast.error("WebView Request is required.");
      return;
    }
    //.......................................................

    if (pageMode === "CREATE") {
      let payload = { ...pageMappingPayload };
      delete payload.id;
      dispatch(
        createPageMapping(payload, () => {
          toast.success("Saved successfully.");
          navigate("/pageMapping");
        })
      );
    } else {
      let payload = { ...pageMappingPayload };

      dispatch(
        updatePageMapping(payload, () => {
          toast.success("Updated successfully.");
          //  navigate("/pageMapping");
        })
      );
    }
  };

  return (
    <>
      <div className="p-4 pb-8">
        <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-4">
          <form action="">
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Request Param*
                </label>
                <input
                  value={pageMappingPayload.requestParam}
                  onChange={(e) =>
                    setPageMappingPayload({
                      ...pageMappingPayload,
                      requestParam: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Layout Code(s)
                </label>
                <input
                  value={pageMappingPayload.layoutCodes}
                  onChange={(e) =>
                    setPageMappingPayload({
                      ...pageMappingPayload,
                      layoutCodes: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                  type="text"
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Layout Type
                </label>
                <input
                  value={pageMappingPayload.layoutType}
                  onChange={(e) =>
                    setPageMappingPayload({
                      ...pageMappingPayload,
                      layoutType: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Page Title
                </label>
                <input
                  value={pageMappingPayload.pageTitle}
                  onChange={(e) =>
                    setPageMappingPayload({
                      ...pageMappingPayload,
                      pageTitle: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                  type="text"
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Redis Key
                </label>
                <input
                  value={pageMappingPayload.redisKey}
                  onChange={(e) =>
                    setPageMappingPayload({
                      ...pageMappingPayload,
                      redisKey: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Request Type
                </label>
                <input
                  value={pageMappingPayload.type}
                  onChange={(e) =>
                    setPageMappingPayload({
                      ...pageMappingPayload,
                      type: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                  type="text"
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  WebView Request*
                </label>
                <select
                  value={pageMappingPayload.isWebView}
                  onChange={(e) =>
                    setPageMappingPayload({
                      ...pageMappingPayload,
                      isWebView: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-5">
          <button
            onClick={formSubmitHandler}
            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none "
          >
            {pageMode === "CREATE" ? "Create" : "Update"}
          </button>
        </div>
      </div>
    </>
  );
};
export default PageMappingForm;
