import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { useDispatch } from 'react-redux';

import IMAGES from '../../../assets/img/image';
import '../Login/Login.scss';
import { loginHelper } from './loginHelper';
import { loginWithMicrosoft } from "../../../core/actionCreators/authActionCreators";


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const parsedData = queryString.parse(window.location.search);
        if (parsedData.isAuthenticated == "true") {
            dispatch(loginWithMicrosoft(parsedData.token, () => {
                navigate("/layout")
            }))
        }
        if (parsedData.isAuthenticated == "false") {
            alert("Please Wait for Admin's Approval!")
        }
    }, [navigate])

    return (<>
        <div className="login-container ">
            <div className="login-header flex items-center p-[15px]">
                <img src={IMAGES.LOGO.darklogo} alt="" className='img-responsive' />
            </div>
            <div className="login-body flex w-full">
                <div className="login-inner flex flex-col w-1/4  items-center m-auto p-[15px] rounded-md bg-white">
                    <h2 className="text-2xl">Sign In with your</h2>

                    {/* Login with microsoft button */}
                    <button onClick={loginHelper.loginWithMicrosoftHandler} className="flex w-full items-center justify-center bg-blue-500 p-[15px] py-2 text-white  rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 mt-4"><img src={IMAGES.WNIMG.wnimg} alt="" /> &nbsp;&nbsp;&nbsp;&nbsp;Microsoft</button>

                    <button className="google-btn flex w-full items-center justify-center bg-white p-[15px] py-2  rounded-md  focus:outline-none mt-4"><img src={IMAGES.GIMG.gimg} alt="" /> &nbsp;&nbsp;&nbsp;&nbsp;Google</button>
                    <p className="text-xs mt-6">Sign in to work from your account</p>
                </div>
            </div>
            <img src={IMAGES.GIMG.gimg} alt="" />
        </div>
    </>)
}

export default Login