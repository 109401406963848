import { useEffect, useState } from "react";
import BannerItem from "./BannerItem";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-hot-toast";
import { CAMPAIGN_SECTION_TYPES, CREATE_CAMPAIGN_FORM_ERRORS } from "../../../utils/constants";
import DeleteBin6FillIcon from "remixicon-react/DeleteBin6FillIcon"
import AddFillIcon from "remixicon-react/AddFillIcon"
const Section = (props: any) => {

    const { currentSection, campaign, setcampaign, lookups, sections, setSections,
        addSectionHandler, removeSectionHandler, pageMode
    } = props

    const [sectionType, setSectionType] = useState("")
    const [_msns, _setMsns] = useState("")
    const [_banners, _setBanners] = useState([{
        "id": uuidv4(),
        "bannerType": "",
        "pictureName": "",
        "pictureUrl": "",
        "externalPictureLink": "",
        "landingPageUrl": "",
        "file": "",
        "videoName": "",
        "videoUrl": "",
        "externalVideoLink": "",
        "categoryName": "",
        "errors": {}
    }])

    useEffect(() => {
        if (props.currentSection.sectionType) {
            let { sectionType } = props.currentSection
            setSectionType(sectionType)
            if (sectionType === CAMPAIGN_SECTION_TYPES.BANNER_AD_UNIT) {
                _setBanners(campaign.bannerAdUnit.banners)
            }
            if (sectionType === CAMPAIGN_SECTION_TYPES.PROMOTED_BRAND_UNIT) {
                _setBanners(campaign.promotedBrandUnit.banners)
            }
            if (sectionType === CAMPAIGN_SECTION_TYPES.VIDEO_UNIT) {
                _setBanners(campaign.videoUnit.banners)
            }
            if (sectionType === CAMPAIGN_SECTION_TYPES.FEATURED_PRODUCT_UNIT) {
                _setMsns(campaign.featuredProductUnit.msns)
            }
        }
    }, [currentSection])

    const addBannerHandler = () => {
        if (!sectionType) {
            toast.error("First please select section type.")
            return
        }

        let lastbanner: any = _banners[_banners.length - 1];
        if (lastbanner) {
            if (sectionType === CAMPAIGN_SECTION_TYPES.VIDEO_UNIT && (!lastbanner.externalVideoLink || !lastbanner.videoName)) {
                toast.error(CREATE_CAMPAIGN_FORM_ERRORS.PLEASE_FILL_CURRENT_BANNER_DETAILS)
                return
            }
            if (sectionType === CAMPAIGN_SECTION_TYPES.BANNER_AD_UNIT && ((!lastbanner.file && !lastbanner.pictureUrl) || !lastbanner.landingPageUrl || !lastbanner.bannerType)) {
                toast.error(CREATE_CAMPAIGN_FORM_ERRORS.PLEASE_FILL_CURRENT_BANNER_DETAILS)
                return
            }
            if (sectionType === CAMPAIGN_SECTION_TYPES.PROMOTED_BRAND_UNIT && ((!lastbanner.file && !lastbanner.pictureUrl) || !lastbanner.categoryName || !lastbanner.landingPageUrl)) {
                toast.error(CREATE_CAMPAIGN_FORM_ERRORS.PLEASE_FILL_CURRENT_BANNER_DETAILS)
                return
            }
        }
        _setBanners([..._banners, {
            "id": uuidv4(),
            "bannerType": "",
            "pictureName": "",
            "pictureUrl": "",
            "externalPictureLink": "",
            "landingPageUrl": "",
            "file": "",
            "videoName": "",
            "videoUrl": "",
            "externalVideoLink": "",
            "categoryName": "",
            "errors": {}
        }])
        return
    }

    const removeBannerHandler = (id: String) => {
        // if (_banners.length === 1) {
        //     toast.error("Please rethink about it.")
        //     return
        // }
        _setBanners([..._banners.filter((d: any) => d.id !== id)])
        return
    }

    const updateBannerHandler = (type: string, id: string, val: any) => {
        _banners.forEach((element: any) => {
            if (element.id === id) {
                if (type === "LANDING_PAGE_URL") {
                    element.landingPageUrl = val
                }
                if (type === "FILE") {
                    element.file = val
                }
                if (type === "BANNER_TYPE") {
                    element.bannerType = val
                }
                if (type === "CATEGORY_NAME") {
                    element.categoryName = val
                }
                if (type === "VIDEO_NAME") {
                    element.videoName = val
                }
                if (type === "EXTERNAL_VIDEO_LINK") {
                    element.externalVideoLink = val
                }
                if (type === "FORM_ERRORS") {
                    element.errors = val
                }
            }
        })
        _setBanners(_banners)
    }

    const sectionDisableHelper = (section: string) => {
        let disable = false;
        if (sections.find((d: any) => d.sectionType === section)) {
            disable = true
        }
        return disable
    }

    useEffect(() => {
        if (sectionType) {
            //Handle for FEATURED_PRODUCT_UNIT
            if (sectionType === "BANNER_AD_UNIT") {
                sections.forEach((elem: any, indx: number) => {
                    if (elem.id === currentSection.id) {
                        sections[indx] = {
                            "id": elem.id,
                            "sectionType": "BANNER_AD_UNIT"
                        }
                    }
                })
                setSections([...sections])
                setcampaign({
                    ...campaign,
                    bannerAdUnit: {
                        sectionType: sectionType,
                        banners: _banners
                    }
                })
            }
            if (sectionType === "FEATURED_PRODUCT_UNIT") {
                sections.forEach((elem: any, indx: number) => {
                    if (elem.id === currentSection.id) {
                        sections[indx] = {
                            "id": elem.id,
                            "sectionType": "FEATURED_PRODUCT_UNIT"
                        }
                    }
                })
                setSections([...sections])
                setcampaign({
                    ...campaign,
                    featuredProductUnit: {
                        sectionType: sectionType,
                        msns: _msns
                    }
                })
            }
            if (sectionType === "PROMOTED_BRAND_UNIT") {
                sections.forEach((elem: any, indx: number) => {
                    if (elem.id === currentSection.id) {
                        sections[indx] = {
                            "id": elem.id,
                            "sectionType": "PROMOTED_BRAND_UNIT"
                        }
                    }
                })
                setSections([...sections])
                setcampaign({
                    ...campaign,
                    promotedBrandUnit: {
                        sectionType: sectionType,
                        banners: _banners
                    }
                })
            }
            if (sectionType === "VIDEO_UNIT") {
                sections.forEach((elem: any, indx: number) => {
                    if (elem.id === currentSection.id) {
                        sections[indx] = {
                            "id": elem.id,
                            "sectionType": "VIDEO_UNIT"
                        }
                    }
                })
                setSections([...sections])
                setcampaign({
                    ...campaign,
                    videoUnit: {
                        sectionType: sectionType,
                        banners: _banners
                    }
                })
            }
        }
    }, [_banners, sectionType, _msns])

    return (
        <>
            < div className="mx-5 mb-2.5 bg-white p-5 rounded" >
                <div className="flex items-center justify-between mb-2.5">
                    <div className="flex items-center w-full mb-7 last:mb-0">
                        <p className="text-xs font-semibold text-[#303030] w-48">Select Section Type* :</p>
                        <div className="w-2/5">
                            <div className="relative">
                                <div className="form-field full-width">
                                    <select disabled={!["CREATE", "EDIT"].includes(pageMode)} value={sectionType} onChange={(e) => setSectionType(e.target.value)}>
                                        <option value={""}>Select</option>
                                        {lookups?.CampaignSectionType?.map((d: any, index: number) =>
                                            <option disabled={sectionDisableHelper(d)} key={index} value={d}>{d}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="ml-auto flex items-center">
                            {["CREATE", "EDIT"].includes(pageMode) && <button className="text-sm font-bold flex " onClick={addSectionHandler}><AddFillIcon size={20} className="mr-1" /> ADD</button>}
                            {["CREATE", "EDIT"].includes(pageMode) && <button className="ml-6 text-sm text-[#d9232d] font-bold flex" onClick={() => removeSectionHandler(currentSection)}><DeleteBin6FillIcon size={20} className="mr-1" />REMOVE</button>}
                        </div>
                    </div>
                </div>


                {/* Banner Section */}
                {[CAMPAIGN_SECTION_TYPES.BANNER_AD_UNIT, CAMPAIGN_SECTION_TYPES.PROMOTED_BRAND_UNIT, CAMPAIGN_SECTION_TYPES.VIDEO_UNIT].includes(sectionType) &&
                    <div>
                        <div className="flex w-full items-end mb-2.5 last:mb-0 mt-10">
                            <div className="flex items-center w-[90%] pr-8">
                                <div>
                                    {["CREATE", "EDIT"].includes(pageMode) && <p className="text-xs font-semibold text-[#3B86FF]" onClick={addBannerHandler}>{(CAMPAIGN_SECTION_TYPES.BANNER_AD_UNIT === sectionType) ? "+Add Banner" : CAMPAIGN_SECTION_TYPES.VIDEO_UNIT === sectionType ? "+Add Video" : "+Add Section"}</p>}
                                </div>
                            </div>

                        </div>
                        {/* <div className="flex w-full items-end mb-2.5 last:mb-0">
                        </div> */}
                        {
                            _banners?.map((d: any, index: number) =>
                                <BannerItem {...props} campaign={campaign} setcampaign={setcampaign} platform={campaign.platform}
                                    pageMode={pageMode} key={index} data={d} lookups={lookups} sectionType={sectionType}
                                    deleteBannerbtnClickHandler={removeBannerHandler} updateBannersHandler={updateBannerHandler} />
                            )
                        }
                    </div>}


                {/* Enter Msn's Section */}
                {
                    [CAMPAIGN_SECTION_TYPES.FEATURED_PRODUCT_UNIT].includes(sectionType) && <>
                        <div className="flex items-center w-full mb-7 last:mb-0 mt-8">
                            <p className="text-xs font-semibold text-[#303030] w-48">Enter MSN ID :</p>
                            <div className="w-2/5">
                                <input disabled={!["CREATE", "EDIT"].includes(pageMode)} value={_msns} onChange={(e) => _setMsns(e.target.value)} type="text" placeholder="E.g. MSN Name 1, MSN Name 2 (Enter Upto 20 MSN ID)" className="w-full rounded-sm border border-gray-300 outline-none text-xs px-2.5 py-1.5 text-[#303030]" />
                            </div>
                        </div>
                    </>
                }
            </div >
        </>

    )
}

export default Section