import { combineReducers } from "redux";
import authReducer from "./authReducer";
import lmsReducer from "./lmsReducer";
import adsenseReducer from "./adsenseReducer";
import campaignReducer from "./campaignReducer";
import blocksReducer from "./blocksReducer";
import commonReducer from "./commonReducer";
import productTagsReducer from "./productTagsReducer"
import fbtReducer from "./fbtReducer";
import rmReducer from "./rmReducer";
import serviceabilityReducer from "./serviceabilityReducer";
import qnaReducer from "./qnaReducer";
import plaFeedReducer from "./plaFeedReducer";
import faqReducer from "./faqReducer";
import attributeReducer from "./attributeReducer";
import pageMappingReducer from "./pageMappingReducer";
import articleReducer from "./articleReducer";
import aiPoweredReducer from "./aiPoweredReducer";


const rootReducer = combineReducers({
    authStore: authReducer,
    lmsStore: lmsReducer,
    serviceabilityStore: serviceabilityReducer,
    adsenseStore: adsenseReducer,
    campaignStore: campaignReducer,
    aiStore: aiPoweredReducer,
    blocksStore: blocksReducer,
    commonStore: commonReducer,
    productTagsStore: productTagsReducer,
    fbtStore: fbtReducer,
    rmStore: rmReducer,
    qnaStore: qnaReducer,
    plaFeedStore: plaFeedReducer,
    faqStore: faqReducer,
    attributeStore: attributeReducer,
    pageMappingStore: pageMappingReducer,
    articleStore: articleReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
