import { toast } from "react-hot-toast";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import { getFileNameWithoutExtension } from "../../../utils/helper";


const ProductBlockElementForm = (props: any) => {
  const {
    data,
    productBlockPayload,
    setProductBlockPayload,
    removeElementHandler,
  } = props;

  const formHandler = (formType: any, e: any) => {
    const elementIndex = productBlockPayload.productElementList.findIndex(
      (d: any) => d.id === data.id
    );
    if (elementIndex === -1) {
      toast.error("Something went wrong.");
      return;
    }
    const element = productBlockPayload.productElementList[elementIndex];
    if (formType === "MSN") {
      element.elementId = e.target.value;
    } else if (formType === "LABEL") {
      element.label = e.target.value;
    } else if (formType === "NAME") {
      element.name = e.target.value;
    } else if (formType === "URL") {
      element.url = e.target.value;
    } else if (formType === "ELEMENT_ORDER") {
      element.elementOrder = e.target.value;
    } else if (formType === "IMAGE") {
      if (e.target.files && e.target.files[0]) {
        let file = e.target.files[0];
        element.image = file;
        element.fileName = getFileNameWithoutExtension(e.target.files[0].name)
      }
    } else {
      toast.error("Something went wrong.");
    }
    productBlockPayload.productElementList[elementIndex] = element;
    setProductBlockPayload({ ...productBlockPayload });
  };

  return (
    <>
      <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-4">
        <div >
          <CloseCircleFillIcon onClick={() => removeElementHandler(data.id)} className="ml-auto cursor-pointer" size={22} />
        </div>
        <form action="">
          <div className="flex w-full mb-6 last:mb-0">
            <div className="w-1/2 px-2">
              <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                MSN
              </label>
              <input
                value={data.elementId}
                onChange={(e) => formHandler("MSN", e)}
                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                type="text"
              />
            </div>
            <div className="w-1/2 px-2">
              <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                Label
              </label>
              <input
                value={data.label}
                onChange={(e) => formHandler("LABEL", e)}
                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                type="text"
              />
            </div>
          </div>
          <div className="flex w-full mb-6 last:mb-0">
            <div className="w-1/2 px-2">
              <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                Name
              </label>
              <input
                value={data.name}
                onChange={(e) => formHandler("NAME", e)}
                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                type="text"
              />
            </div>
            <div className="w-1/2 px-2">
              <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                URL
              </label>
              <input
                value={data.url}
                onChange={(e) => formHandler("URL", e)}
                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                type="text"
              />
            </div>
          </div>
          <div className="flex w-full mb-6 last:mb-0">
            <div className="w-1/2 px-2">
              {data.s3ImageUrl && (
                <div className="thumbnail-container">
                  <img
                    className="thumbnail"
                    src={data.s3ImageUrl}
                    alt="Preview"
                  />
                </div>
              )}
              {data.s3ImageUrl && (
                <a
                  className="text-[10px] text-[#3c3c3c]"
                  href={data.s3ImageUrl}
                  target="_blank"
                >
                  File
                </a>
              )}


              <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                Image
              </label>
              <input
                onChange={(e) => formHandler("IMAGE", e)}
                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                type="file"
              />
            </div>

            <div className="w-1/2 px-2">
              <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                Order
              </label>
              <input
                value={data.elementOrder}
                onChange={(e) => formHandler("ELEMENT_ORDER", e)}
                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                type="text"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default ProductBlockElementForm;
