import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import { CAMPAIGN_FILE_DIMENSION_CONFIG_UI } from "../../../utils/constants";


const ViewBannerDimensionPopup = (props: any) => {
    const { setViewBannerDimension } = props
    return (
        <>
            <div className=" ">
                <div className="fixed inset-0 bg-black bg-opacity-40 z-50 ">
                    <div className="bg-white absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4 min-w-[55%] max-h-[75%] pb-4 rounded-lg px-4 overflow-auto">
                        <div className="top-0 sticky bg-white pt-4 pb-px">
                            <CloseCircleFillIcon onClick={() => setViewBannerDimension(false)} size={20} className="ml-auto" />
                        </div>
                        <div className="">
                            <div className=" mb-5">
                                <p className="text-xs font-semibold text-[#303030] pb-2">For PWA</p>
                                <table className="w-full border border-gray-300">
                                    <thead className="bg-[#EFEFF5]">
                                        <tr className="border-b border-gray-300">
                                            <th className="text-xs font-semibold text-[#303030] p-2.5 border-r border-gray-300">Page Type</th>
                                            <th className="text-xs font-semibold text-[#303030] p-2.5 border-r border-gray-300">Banner Name</th>
                                            <th className="text-xs font-semibold text-[#303030] p-2.5">Size of Ad Unit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {CAMPAIGN_FILE_DIMENSION_CONFIG_UI.PWA.map(d =>
                                            <tr className="border-b border-gray-300 last:border-b-0">
                                                <td className="text-xs text-[#303030] p-2.5 border-r border-gray-300">{d.pageType}</td>
                                                <td className="text-xs text-[#303030] p-2.5 border-r border-gray-300">{d.bannerName}</td>
                                                <td className="text-xs text-[#303030] p-2.5">{d.dimension} px</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <p className="text-xs font-semibold text-[#303030] pt-2">Note: For PWA CMS, users will be either able to select the inline_rectangle or leaderboard for one specific position.</p>
                            </div>
                            <div className="">
                                <p className="text-xs font-semibold text-[#303030] pb-2">For Desktop</p>
                                <table className="w-full border border-gray-300">
                                    <thead className="bg-[#EFEFF5]">
                                        <tr className="border-b border-gray-300">
                                            <th className="text-xs font-semibold text-[#303030] p-2.5 border-r border-gray-300">Page Type</th>
                                            <th className="text-xs font-semibold text-[#303030] p-2.5 border-r border-gray-300">Banner Name</th>
                                            <th className="text-xs font-semibold text-[#303030] p-2.5">Size of Ad Unit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {CAMPAIGN_FILE_DIMENSION_CONFIG_UI.DESKTOP.map(d =>
                                            <tr className="border-b border-gray-300 last:border-b-0">
                                                <td className="text-xs text-[#303030] p-2.5 border-r border-gray-300">{d.pageType}</td>
                                                <td className="text-xs text-[#303030] p-2.5 border-r border-gray-300">{d.bannerName}</td>
                                                <td className="text-xs text-[#303030] p-2.5">{d.dimension.join(", ")} px</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};
export default ViewBannerDimensionPopup;