import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatTimestamp } from "../../../utils/helper";
import Edit2FillIcon from "remixicon-react/Edit2FillIcon";
import DeleteBin5FillIcon from "remixicon-react/DeleteBin5FillIcon";
import { deleteGeneralBlock } from "../../../core/actionCreators/blocksActionCreator";
import toast from "react-hot-toast";

const GeneralBlockListItem = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((s: any) => s.authStore);
    const { data } = props;

    const updateGeneralBlockNavigateHandler = (id: any) => {
        navigate(`/blocks/general/${id}`);
    };

    const deleteGeneralBlockHandler = (id: any) => {
        const isConfirmed = window.confirm('Are you sure want to delete?')
        if (isConfirmed) {
            dispatch(deleteGeneralBlock({ id: id, userId: user.id || 33 }, (resp: any) => {
                if (!resp.status) {
                    toast.error(resp.statusDescription)
                    return
                }
                toast.success("Deleted successfully")
                window.location.reload()
            }))
        }
        return
    };

    return (
        <>
            {data.map((_d: any) => (
                <tr className="[&:nth-child(odd)]:bg-[#f7f7f7]">
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[8%]">
                        {_d.id}
                    </td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[12%]">
                        {_d.label}
                    </td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[28%]">
                        {_d.name}
                    </td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[10%]">
                        {_d.status ? "True" : "False"}
                    </td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[13%]">
                        {formatTimestamp(_d.createdAt)}
                    </td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[13%]">
                        {formatTimestamp(_d.updatedAt)}
                    </td>
                    <td
                        className="px-4 py-3 text-left text-xs text-[#337ab7] font-semibold cursor-pointer w-[8%]"
                        onClick={() => updateGeneralBlockNavigateHandler(_d.id)}
                    >
                        <Edit2FillIcon size={14} />
                    </td>
                    <td onClick={() => deleteGeneralBlockHandler(_d.id)}
                        className="px-4 py-3 text-left text-xs text-[#337ab7] font-semibold cursor-pointer w-[8%]"
                    >
                        {" "}
                        <DeleteBin5FillIcon size={14} />
                    </td>
                </tr>
            ))}
        </>
    )
}
export default GeneralBlockListItem;