import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { downloadFaqDump } from "../../../core/actionCreators/faqActionCreator";
import { useState } from "react";

const FaqSchemaSubHeader = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [downloadDumpLoader, setDownloadDumpLoader] = useState(false)
    const CreateFaqhandler = () => {
        navigate('/faq/create');
    }

    const downloadDumpHandler = () => {
        setDownloadDumpLoader(true)
        dispatch(downloadFaqDump(null, (res: any) => {
            setDownloadDumpLoader(false)
            if (res && res.status && res.data) {
                window.open(res.data)
            }
        }))
    }
    return (
        <>
            <div className="campaign-header bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                <div className="flex items-center justify-between w-full">
                    <p className="text-xl font-bold text-[#43425D] w-1/5">FAQ</p>
                    <div className="flex w-4/5 ml-auto justify-end">
                        <button onClick={downloadDumpHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-1.5"> {downloadDumpLoader ? "Loading.." : "Download Dump"}</button>
                        <button onClick={CreateFaqhandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-5">Create Faq Schema</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FaqSchemaSubHeader;