import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import UserProfile from "./UserProfile"
import { IoIosArrowBack } from "react-icons/io"
import { FiSearch } from "react-icons/fi"
import { useDispatch } from "react-redux"
import { getComponents } from "../../../core/actionCreators/lmsActionCreator"

const ComponentHeader = (props: any) => {
    const dispatch = useDispatch()
    const [searchComponent, setSearchComponent] = useState("")
    const { layoutId, layoutCode, layoutName, device } = props



    useEffect(() => {
        dispatch(getComponents(`componentName=${searchComponent}`, null))
    }, [searchComponent])


    return (<>
        <div className="header">
            <div className="header-inner">
                <div className="main-header flex items-center p-[15px]">
                    <div className="header-left">
                    </div>
                    <UserProfile />
                </div>
                <div className="haderVarient2 items-center flex justify-between p-[15px] ">
                    <div className="pagename-n-input flex items-center ">
                        <Link to={`/layout/${layoutId}`}><IoIosArrowBack /></Link>

                        <div className="pagename-sec">
                            <h2 className='font-bold'>{layoutName}</h2>
                            <p className='text-xs p-subname'>{device} | {layoutCode}</p>
                        </div>
                        <div className="form-field">
                            <div className="input-field ">
                                <input onChange={(e) => setSearchComponent(e.target.value)} type="text" className="if-search-left" placeholder="Search component.." />
                                <div className="search-icon-left flex justify-around items-center">
                                    <FiSearch size={16} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="right-buttons items-center flex justify-end">
                        <button className=' py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'>Select/Copy Component</button>
                    </div> */}
                </div>
            </div>
        </div>
    </>)


}

export default ComponentHeader