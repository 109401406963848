import React from "react"

const CommonForm = (props: any) => {
    const { campaign, setcampaign, lookups, pageMode } = props
    return (
        <>
            <div className="mx-5 mt-5 mb-2.5 bg-white p-5 rounded border border-[#EAEAEA]">
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Campaign Name :</p>
                    <div className="w-2/5">
                        <input disabled={!["CREATE", "EDIT"].includes(pageMode)} required value={campaign.campaignName} onChange={(e) => setcampaign({ ...campaign, campaignName: e.target.value })} type="text" placeholder="Enter Campaign Name" className="w-full rounded-sm border border-gray-300 outline-none text-xs px-2.5 py-1.5 text-[#303030]" />
                    </div>
                </div>
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Campaign Date* :</p>
                    <div className="w-2/5 flex items-center">
                        <div className="relative w-full">
                            <input disabled={!["CREATE", "EDIT"].includes(pageMode)} value={campaign.startDate && new Date(campaign.startDate).toISOString().split('T')[0]} min={new Date().toISOString().split('T')[0]} onChange={(e) => setcampaign({ ...campaign, startDate: e.target.value })} type="date" placeholder="Start Date" className="w-full rounded-sm border border-gray-300 outline-none text-xs px-2.5 py-1.5 text-[#303030]" />
                        </div>
                        <p className="px-10 text-xs text-[#303030] font-semibold">To</p>
                        <div className="relative w-full">
                            <input disabled={!["CREATE", "EDIT"].includes(pageMode)} value={campaign.endDate && new Date(campaign.endDate).toISOString().split('T')[0]} min={new Date().toISOString().split('T')[0]} onChange={(e) => setcampaign({ ...campaign, endDate: e.target.value })} type="date" placeholder="End Date" className="w-full rounded-sm border border-gray-300 outline-none text-xs px-2.5 py-1.5 text-[#303030]" />
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Select Platform* :</p>
                    <div className="w-2/5">
                        <div className="relative">
                            <div className="form-field full-width">
                                <select disabled={!["CREATE", "EDIT"].includes(pageMode)} value={campaign.platform} onChange={(e) => setcampaign({ ...campaign, platform: e.target.value })}>
                                    <option value={""}>Select</option>
                                    {lookups?.PlatformType?.map((d: any, index: number) =>
                                        <option key={index} value={d}>{d}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Show this section if selected platform is PWA and page is on CREATE mode */}
                {"CREATE" === pageMode && campaign.platform === "PWA" && <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Applicable for APP :</p>
                    <div className="w-2/5">
                        <div className="relative">
                            <div className="form-field full-width">
                                <select onChange={(e) => setcampaign({ ...campaign, isApplicableForApp: e.target.value })}>
                                    <option selected={campaign.isApplicableForApp == false} value={"false"}>False</option>
                                    <option selected={campaign.isApplicableForApp == true} value={"true"}>True</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Campaign Type* :</p>
                    <div className="w-2/5">
                        <div className="relative">
                            <div className="form-field full-width">
                                <select disabled={!["CREATE", "EDIT"].includes(pageMode)} value={campaign.campaignType} onChange={(e) => setcampaign({ ...campaign, campaignType: e.target.value })}>
                                    <option value={""}>Select</option>
                                    {lookups?.CampaignType?.map((d: any, index: number) =>
                                        <option key={index} value={d}>{d}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>)
}

export default CommonForm