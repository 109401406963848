import React, { useState, useEffect, useRef } from 'react';
import ArticleSubHeader from './ArticleFormSubHeader';
import TextEditor from './TextEditor';
import ArticleForm from './ArticleForm';
import SideImage from './SideImage';
import TopSellers from './TopSellers';
import { useDispatch, useSelector } from "react-redux";
import { getArticleCategoryIdService } from '../../../core/services/articleServices'
import { setArticle, getArticleListingById } from '../../../core/actionCreators/articleActionCreator';
import { useNavigate, useParams } from "react-router-dom";
import { transformString } from '../../../utils/helper';

const Article = () => {
    const dispatch = useDispatch();
    const [pageMode, setPageMode] = useState("");
    const { articleForm } = useSelector((s: any) => s.articleStore);
    const params = useParams();

    const [articlePayload, setArticlePayload] = useState({
        userId: "",
        articleTitle: "",
        categoryCode: "",
        categoryName: "",
        friendlyUrl: "",
        htmlContent: "",
        seoTitle: "",
        seoDescription: "",
        desktopImageUrl: "",
        pwaImageUrl: "",
        msns: ""
    } as any);


    useEffect(() => {
        const fetchData = async () => {
            try {
                if (articlePayload?.categoryCode?.length >= 5) {
                    const response = await getArticleCategoryIdService(articlePayload.categoryCode);
                    const categoryDetails = response.data.categoryDetails;

                    if (categoryDetails) {
                        const categoryNameFromApi = categoryDetails.categoryName;

                        setArticlePayload({
                            ...articlePayload,
                            categoryName: categoryNameFromApi,
                        });
                    }

                }
                else if (articlePayload?.categoryCode?.length === 0) {
                    setArticlePayload({
                        ...articlePayload,
                        categoryName: "",
                    });
                }
            } catch (error) {
                console.error('Error fetching category details:', error);
            }
        };

        fetchData();
    }, [articlePayload.categoryCode]);

    //Fetch and populate data via api
    useEffect(() => {
        const currentPath = window.location.pathname;
        if (currentPath === "/article/create") {
            setPageMode("CREATE");
        } else {
            if (params.id) {
                setPageMode("EDIT");
                dispatch(getArticleListingById(params.id, null));
            }
        }
        return () => {
            dispatch(setArticle({}));
        };
    }, [params, window]);

    useEffect(() => {
        if (
            pageMode === "EDIT" &&
            Object.keys(articleForm).length &&
            typeof articleForm !== "string"
        ) {
            setArticlePayload(articleForm);
        } else {
            setArticlePayload(articlePayload);
        }
    }, [articleForm]);

    useEffect(() => {
        setArticlePayload({ ...articlePayload, friendlyUrl: transformString(articlePayload.articleTitle) })
    }, [articlePayload.articleTitle])

    return (
        <>
            <ArticleSubHeader articlePayload={articlePayload} setArticlePayload={setArticlePayload} pageMode={pageMode} articleForm={articleForm} />
            <div className="flex">
                <div className="main p-4 pb-8 bg-white px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5 mt-5 ml-2">
                    <div className=" bg-white py-4 px-2.5 mb-5">
                        <form action="">
                            <div className="flex w-full mb-6 last:mb-0">
                                <div className="w-1/2 px-2">
                                    <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                                        Article Title*
                                    </label>
                                    <input className=" bottom-border w-full h-9 text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light" type="text"
                                        value={articlePayload.articleTitle}
                                        onChange={(e) =>
                                            setArticlePayload({
                                                ...articlePayload,
                                                articleTitle: e.target.value,
                                            })}
                                    />
                                </div>

                            </div>
                            <div className="flex w-full mb-6 last:mb-0">
                                <div className="w-1/2 px-2">
                                    <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                                        Category Code*
                                    </label>
                                    <input
                                        className=" dotted-border w-1/2 h-9 text-[#555] px-2.5 outline-none text-sm font-light"
                                        type="text" value={articlePayload.categoryCode}
                                        onChange={(e) =>
                                            setArticlePayload({
                                                ...articlePayload,
                                                categoryCode: e.target.value,
                                            })}
                                    />
                                </div>

                                <div className="w-1/2 px-2 ml-10">
                                    <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                                        Category Name
                                    </label>
                                    <input
                                        className="bottom-border w-full h-9  text-[#555] px-2.5 outline-none text-sm font-light"
                                        type="text" value={articlePayload.categoryName}
                                        onChange={(e) =>
                                            setArticlePayload({
                                                ...articlePayload,
                                                categoryName: e.target.value,
                                            })}
                                    />
                                </div>
                            </div>
                            <div className="flex w-full mb-6 last:mb-0">

                                <div className="w-1/2 px-2">
                                    <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                                        Friendly Url*
                                    </label>
                                    <input
                                        className=" bottom-border w-full h-9 text-[#555]  px-2.5 outline-none text-sm font-light mb-6"
                                        type="text" value={articlePayload.friendlyUrl}
                                        onChange={(e) =>
                                            setArticlePayload({
                                                ...articlePayload,
                                                friendlyUrl: e.target.value,
                                            })}
                                    />

                                </div>

                            </div>
                        </form>

                    </div>
                    <TextEditor articlePayload={articlePayload} setArticlePayload={setArticlePayload} />
                    <ArticleForm articlePayload={articlePayload} setArticlePayload={setArticlePayload} articleForm={articleForm} />
                    <TopSellers articlePayload={articlePayload} setArticlePayload={setArticlePayload} />
                </div>
                <SideImage articlePayload={articlePayload} setArticlePayload={setArticlePayload} />
            </div>
        </>
    )
}

export default Article;