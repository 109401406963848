export const GET_PRODUCT_TAGS = "GET_PRODUCT_TAGS"
export const SET_PRODUCT_TAGS = "SET_PRODUCT_TAGS"
export const SET_PRODUCT_TAG = "SET_PRODUCT_TAG"
export const GET_PRODUCT_TAGS_DETAILS = "GET_PRODUCT_TAGS_DETAILS"
export const SET_PRODUCT_TAGS_DETAILS = "SET_PRODUCT_TAGS_DETAILS"
export const SET_PRODUCT_TAGS_LOADER = "SET_BLOCKS_LOADER"
export const UPLOAD_IMAGE = "UPLOAD_IMAGE_PRODUCT_TAG";
export const CREATE_PRODUCT_TAG = "CREATE_PRODUCT_TAG";
export const EDIT_PRODUCT_TAG = "EDIT_PRODUCT_TAG";
export const UPDATE_PRODUCT_TAG = "UPDATE_PRODUCT_TAG";
