import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import CloseCircleLineIcon from "remixicon-react/CloseCircleLineIcon";

import {
  createAttribute,
  updateAttribute,
} from "../../../core/actionCreators/attributeActionCreator";

const AttributeListingForm = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageMode, attribute } = props;

  const [attributePayload, setAttributePayload] = useState({
    id: "",
    title: "",
    friendlyUrl: "",
    metaTitle: "",
    metaDescription: "",
    titleDescription: "",
    pageDescription: "",
    category: "",
    categoryName: "",
    searchTerm: "",
    isActive: "",
    attributes: [
      {
        id: uuidv4(),
        key: "",
        value: "",
      },
    ],
  } as any);

  const addGeneralBlockHandler = () => {
    setAttributePayload({
      ...attributePayload,
      attributes: [
        ...attributePayload.attributes,
        {
          id: uuidv4(),
          key: "",
          value: "",
        },
      ],
    });
  };

  const onGeneralBlockChangeHandler = (
    id: string,
    type: string,
    value: string
  ) => {
    const documentIndex = attributePayload.attributes.findIndex(
      (d: any) => d.id === id
    );
    if (documentIndex === -1) {
      toast.error("Invalid field");
      return;
    }
    const document = attributePayload.attributes[documentIndex];
    if (type === "KEY") {
      document.key = value;
    }
    if (type === "VALUE") {
      document.value = value;
    }
    attributePayload.attributes[documentIndex] = document;
    setAttributePayload({ ...attributePayload });
  };

  const removeGeneralBlockHandler = (id: string) => {
    if (attributePayload.attributes.length === 1) {
      toast.error("Please rethink about it.");
      return;
    }
    const filteredGeneralBlocks = attributePayload.attributes.filter(
      (d: any) => d.id !== id
    );
    setAttributePayload({
      ...attributePayload,
      attributes: filteredGeneralBlocks,
    });
  };

  useEffect(() => {
    if (pageMode === "EDIT" && Object.keys(attribute).length && typeof attribute !== "string") {
      if (!Array.isArray(attribute.attributes)) {
        attribute.attributes = [];
      }
      setAttributePayload(attribute);
    } else {
      setAttributePayload(attributePayload);
    }
  }, [attribute]);

  const formSubmitHandler = () => {
    if (pageMode === "CREATE") {
      let payload = { ...attributePayload };
      delete payload.id;
      if (payload.isActive !== "") {
        payload.isActive = JSON.parse(payload.isActive);
      }
      dispatch(
        createAttribute(payload, () => {
          toast.success("Saved successfully.");
          // navigate("/attributeListing/update");
        })
      );
    } else {
      let payload = { ...attributePayload };
      dispatch(
        updateAttribute(payload, () => {
          toast.success("Updated successfully.");
        })
      );
    }
  };

  const resetFormHandler = () => {
    setAttributePayload({
      id: "",
      title: "",
      friendlyUrl: "",
      metaTitle: "",
      metaDescription: "",
      titleDescription: "",
      pageDescription: "",
      category: "",
      categoryName: "",
      searchTerm: "",
      isActive: "",
      attributes: [
        {
          id: uuidv4(),
          key: "",
          value: "",
        },
      ],
    });
  };
  return (
    <>
      <div className="p-4 pb-8">
        <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5">
          <form>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Title
                </label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                  value={attributePayload.title}
                  onChange={(e) =>
                    setAttributePayload({
                      ...attributePayload,
                      title: e.target.value,
                    })
                  }
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Friendly URL
                </label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                  value={attributePayload.friendlyUrl}
                  onChange={(e) =>
                    setAttributePayload({
                      ...attributePayload,
                      friendlyUrl: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Meta Title
                </label>
                <textarea
                  rows={3}
                  className="w-full rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light"
                  value={attributePayload.metaTitle}
                  onChange={(e) =>
                    setAttributePayload({
                      ...attributePayload,
                      metaTitle: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Meta Title Description
                </label>
                <textarea
                  rows={3}
                  className="w-full rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light"
                  value={attributePayload.metaDescription}
                  onChange={(e) =>
                    setAttributePayload({
                      ...attributePayload,
                      metaDescription: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Title Description
                </label>
                <textarea
                  rows={3}
                  className="w-full rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light"
                  value={attributePayload.titleDescription}
                  onChange={(e) =>
                    setAttributePayload({
                      ...attributePayload,
                      titleDescription: e.target.value,
                    })
                  }
                ></textarea>
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Page Description
                </label>
                <textarea
                  rows={3}
                  className="w-full rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light"
                  value={attributePayload.pageDescription}
                  onChange={(e) =>
                    setAttributePayload({
                      ...attributePayload,
                      pageDescription: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Category Code
                </label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                  value={attributePayload.category}
                  onChange={(e) =>
                    setAttributePayload({
                      ...attributePayload,
                      category: e.target.value,
                    })
                  }
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Category Name
                </label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                  value={attributePayload.categoryName}
                  onChange={(e) =>
                    setAttributePayload({
                      ...attributePayload,
                      categoryName: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Search Term
                </label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                  value={attributePayload.searchTerm}
                  onChange={(e) =>
                    setAttributePayload({
                      ...attributePayload,
                      searchTerm: e.target.value,
                    })
                  }
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  In Active
                </label>
                <div className="flex items-end">
                  <div className="flex items-center h-9">
                    <input
                      className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                      type="radio"
                      value={"true"}
                      onChange={(e) =>
                        setAttributePayload({
                          ...attributePayload,
                          isActive: e.target.value,
                        })
                      }
                      checked={attributePayload.isActive == "true"}
                    />
                    <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                      Active
                    </span>
                  </div>
                  <div className="flex items-center ml-4 h-9">
                    <input
                      className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                      type="radio"
                      value={"false"}
                      onChange={(e) =>
                        setAttributePayload({
                          ...attributePayload,
                          isActive: e.target.value,
                        })
                      }
                      checked={attributePayload.isActive == "false"}
                    />
                    <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                      Inactive
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              onClick={addGeneralBlockHandler}
              className="h-9 py-2 px-4 mx-2.5 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 mb-6 last:mb-0"
            >
              ADD GENERAL BLOCK
            </button>

            {/* Wrapped */}
            {Array.isArray(attributePayload?.attributes) &&
              attributePayload.attributes.map((d: any, index: number) => (
                <div key={index} className="flex w-full mb-6 last:mb-0">
                  <div className="w-1/2 px-2">
                    <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                      Key
                    </label>
                    <input
                      onChange={(e) =>
                        onGeneralBlockChangeHandler(d.id, "KEY", e.target.value)
                      }
                      value={d.key}
                      className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                      type="text"
                    />
                  </div>
                  <div className="w-1/2 px-2">
                    <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                      Value
                    </label>
                    <input
                      onChange={(e) =>
                        onGeneralBlockChangeHandler(
                          d.id,
                          "VALUE",
                          e.target.value
                        )
                      }
                      value={d.value}
                      className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                      type="text"
                    />
                  </div>



                  <div
                    className="cursor-pointer mt-7"
                    onClick={() => removeGeneralBlockHandler(d.id)}
                  > <CloseCircleLineIcon size={22} /></div>

                </div>
              ))}
          </form>
        </div>
        <div className="mt-5">
          <button
            onClick={formSubmitHandler}
            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none "
          >
            Submit
          </button>
          <button
            onClick={resetFormHandler}
            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ml-5"
          >
            Reset Cache
          </button>
        </div>
      </div>
    </>
  );
};
export default AttributeListingForm;
