import { useEffect, useState, useRef } from "react";
import PlaFeedListItem from "./PlaFeedListItem";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPlaFeeds } from "../../../core/actionCreators/plaFeedActionCreator";
import queryString from "query-string";
import Loader from "../Loader/Loader";
import CommonPagination from "../CommonPagination/CommonPagination";
import { GET_PLA_FEED_DEFAULT_SIZE } from "../../../utils/constants";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";

const PlaFeedList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { plaFeeds, isLoading } = useSelector((s: any) => s.plaFeedStore);

  const [searchCriteria, setSearchCriteria] = useState({
    id: "",
    customLabel0: "",
    countryCode: "",
    pageSize: GET_PLA_FEED_DEFAULT_SIZE,
    pageNumber: 0,
  } as any);


  const previousSearchCriteriaRef = useRef(searchCriteria);


  useEffect(() => {
    const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
    const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
    const previousCriteria = previousSearchCriteriaRef.current;
    const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

    if (searchFilterChanged) {
      const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
      for (let key in parsedSearchCriteria) {
        if (["id", "customLabel0", "countryCode"].includes(key)) {
          parsedSearchCriteria.pageNumber = 0
          break
        }
      }
      dispatch(getPlaFeeds(queryString.stringify(parsedSearchCriteria), null));
      previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
    }
  }, [searchCriteria]);


  const onPageChange = (pageNumber: any) => {
    setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
  }


  return (
    <>
      <div className="mt-4 campaign-list bg-white mb-[54px]">
        <table className="w-full">
          <thead className="bg-[#343434]">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[14%]">
                MSN
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]">
                L1 Category
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">
                Last Level Category
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">
                Target Country
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">
                Stock Status
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[8%]">
                Status
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">
                Updated At
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[8%]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-3 text-left text-xs text-white w-[14%]">
                <input
                  value={searchCriteria.id}
                  onChange={(e) =>
                    setSearchCriteria({ ...searchCriteria, id: e.target.value })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                  type="text"
                />
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[20%]">
                <input
                  value={searchCriteria.customLabel0}
                  onChange={(e) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      customLabel0: e.target.value,
                    })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                  type="text"
                />
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[15%]"></td>
            </tr>
            {isLoading ? (
              <Loader />
            ) : (
              <PlaFeedListItem data={plaFeeds?.data || []} />
            )}
          </tbody>
        </table>
      </div>
      {plaFeeds?.data && (
        <CommonPagination
          data={plaFeeds}
          PAGE_SIZE={GET_PLA_FEED_DEFAULT_SIZE}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};
export default PlaFeedList;
