import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import UserManagementForm from "../../components/UserManagement/UserManagementForm";
import UserManagementList from "../../components/UserManagement/UserManagementList";

const UserManagement = () => {
    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <div className="pt-4 px-4">
                            <p className="text-base text-[#343434]">Users Index</p>
                        </div>
                        <UserManagementList />
                        {/* use UserManagementForm on click update btn  */}
                        {/* <UserManagementForm /> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default UserManagement;