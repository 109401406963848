
import Loader from "../Loader/Loader"
import ReviewModerationItem from "./ReviewItem";
import ReviewFilters from "./ReviewFilters";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { GET_REVIEWS_DEFAULT_SIZE, REVIEW_PAGE_HEADERS } from "../../../utils/constants";
import { getReviews } from "../../../core/actionCreators/rmActionCreator";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import CommonPagination from "../CommonPagination/CommonPagination";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";

const ReviewList = () => {
    const dispatch = useDispatch()
    let { reviews, isLoading } = useSelector((s: any) => s.rmStore);

    const [searchCriteria, setSearchCriteria] = useState({
        pageNumber: 0,
        pageSize: GET_REVIEWS_DEFAULT_SIZE,
        id: "",
        productMsn: "",
        userId: "",
        reviewSubject: "",
        isApproved: "",
        isActive: "",
    } as any)
    const previousSearchCriteriaRef = useRef(searchCriteria);


    useEffect(() => {
        const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
        const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
        const previousCriteria = previousSearchCriteriaRef.current;
        const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

        if (searchFilterChanged) {
            const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
            for (let key in parsedSearchCriteria) {
                if (["reviewSubject", "userId", "productMsn", "id"].includes(key)) {
                    parsedSearchCriteria.pageNumber = 0
                    break
                }
            }
            dispatch(getReviews(queryString.stringify(parsedSearchCriteria), null));
            previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
        }
    }, [searchCriteria]);


    const onPageChange = (pageNumber: any) => {
        setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
    }

    return (
        <>
            <div className="pagelist-tbl">
                <table className="min-w-full divide-y divide-gray-300 mb-[54px]">
                    <thead>
                        <tr>
                            {
                                REVIEW_PAGE_HEADERS?.map((header: string) => {
                                    return (
                                        <th scope="col" className=" text-left "> {header} </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <ReviewFilters searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
                        {isLoading ? (
                            <Loader />
                        ) :
                            reviews?.data?.map((item: any, index: number) =>
                                <ReviewModerationItem key={index} index={index} review={item} />
                            )
                        }
                    </tbody>
                </table>
                <CommonPagination
                    data={reviews}
                    PAGE_SIZE={GET_REVIEWS_DEFAULT_SIZE}
                    onPageChange={onPageChange}
                />
            </div>
        </>
    )
}

export default ReviewList