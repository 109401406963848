import React, { useEffect, useState } from "react";
import '../Component/ParentComponent.scss';
import AddAttributePopup from "../Popup/AddAttributePopup";
import FormAttribute from "./FormAttribute";
import { useDispatch } from "react-redux";
import { FaSave } from "react-icons/fa";
import { IoCopySharp } from "react-icons/io5";
import { BsToggle2Off } from "react-icons/bs";
import { BsToggle2On } from "react-icons/bs";
import { RiDeleteBin4Fill } from "react-icons/ri"
import { GoPlus } from "react-icons/go";
import { deleteComponent, deleteElement, updateComponent, updateComponentStatus, updateElement, updateElementStatus } from "../../../core/actionCreators/lmsActionCreator";
import JsonViewPopup from "../Popup/JsonViewPopup";
import { useNavigate } from "react-router-dom";


const ParentComponent = (props: any) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { data, user, layoutId, componentId, callbackFn, page } = props

    const [addAttributePopup, setAttributepopup] = useState(false)
    const [attributes, setAttributes] = useState([])
    const [showCopyIcon, setShowCopyIcon] = useState(false)
    const [showAddIcon, setShowAddIcon] = useState(false)
    const [showSaveIcon, setShowSaveIcon] = useState(false)
    const [showActiveInactiveIcon, setShowActiveInactiveIcon] = useState(false)
    const [showDeleteIcon, setShowDeleteIcon] = useState(false)
    const [childAttributes, setChildAttributes] = useState([])
    const [jsonViewPopUp, setJsonViewPopUp] = useState(false)
    const [currentPage, setCurrentPage] = useState("")
    const [status, setStatus] = useState(false)
    const [showRedirectPageData, setShowRedirectPageData] = useState(false)
    const [redirectPageData, setRedirectPageData] = useState({
        "str": "",
        "type": "",
        "category": ""
    })

    const addAttributeHandler = () => {
        setAttributepopup(true)
    }

    useEffect(() => {
        if (data && data.parent && data.parent.attributes) {
            setAttributes(data.parent.attributes)
        }
        if (data && data.child && data.child.attributes) {
            setChildAttributes(data.child.attributes)
        }
    }, [data])

    useEffect(() => {
        if (page === "LAYOUT") {
            setCurrentPage("LAYOUT")
            setStatus(data?.parent?.component?.status)
            setShowAddIcon(true)
            setShowSaveIcon(true)
            setShowActiveInactiveIcon(true)
            setShowDeleteIcon(true)
        }
        if (page === "COMPONENT") {
            setCurrentPage("COMPONENT")
            setStatus(data?.parent?.element?.isDeleted)
            setShowAddIcon(true)
            setShowSaveIcon(true)
            setShowActiveInactiveIcon(true)
            setShowDeleteIcon(true)
            if (data.child) {
                setShowRedirectPageData(true)
                if (data.child.attributes && Array.isArray(data.child.attributes) && data.child.attributes.length > 0) {
                    setRedirectPageData({
                        "str": data.child.attributes.find((d: any) => d.attributeKeyId === 8)?.attributeValue,
                        "type": data.child.attributes.find((d: any) => d.attributeKeyId === 7)?.attributeValue,
                        "category": data.child.attributes.find((d: any) => d.attributeKeyId === 9)?.attributeValue
                    })
                }
            }
        }
    }, [props])

    const removeAttributeHandler = (id: any) => {
        let res = window.confirm("Are you sure want to delete?")
        if (res) {
            const filteredAttributes = attributes.filter((d: any) => d.id !== id)
            setAttributes([...filteredAttributes])
        }
    }

    const updateClickHandler = () => {
        if (currentPage === "LAYOUT") {
            if (data?.parent?.component?.id) {
                const copiedAttributes: any = [...attributes]
                for (let c of copiedAttributes) {
                    if (typeof (c.id) !== 'number') {
                        delete c.id
                    }
                }
                const payload = {
                    "componentId": data.parent.component.id,
                    "attributes": copiedAttributes,
                    "modifiedBy": user.id || -1
                }
                const callback = () => {
                    callbackFn(layoutId)
                }
                dispatch(updateComponent(payload, callback))
            }
            else {
                alert("Something went wrong.")
                return
            }
        }

        if (currentPage === "COMPONENT") {
            if (data?.parent?.element?.id) {
                const copiedAttributes: any = [...attributes]
                for (let c of copiedAttributes) {
                    if (typeof (c.id) !== 'number') {
                        delete c.id
                    }
                }
                const payload = {
                    "childElement": null,
                    "childAttributes": null,
                    "elementId": data.parent.element.id,
                    "attributes": copiedAttributes,
                    "modifiedBy": user.id || -1
                }
                if (data?.child?.element?.id) {
                    if (data.child.attributes && Array.isArray(data.child.attributes) && data.child.attributes.length > 0) {
                        for (let d of data.child.attributes) {
                            if (d.attributeKeyId === 7) {
                                d.attributeValue = redirectPageData.type
                            }
                            if (d.attributeKeyId === 8) {
                                d.attributeValue = redirectPageData.str
                            }
                            if (d.attributeKeyId === 9) {
                                d.attributeValue = redirectPageData.category
                            }
                        }
                    }
                    payload["childElement"] = data.child.element
                    payload["childAttributes"] = data.child.attributes
                }
                dispatch(updateElement(payload, callbackFn))
            }

            else {
                alert("Something went wrong.")
                return
            }
        }
    }

    const updateStatusClickHandler = (parentAttribute: any) => {
        if (currentPage === "LAYOUT") {
            const payload = {
                "componentId": parentAttribute.component.id,
                "status": !parentAttribute.component.status,
                "modifiedBy": user.id || -1
            }
            const callback = () => {
                callbackFn(layoutId)
            }
            dispatch(updateComponentStatus(payload, callback))
        }
        if (currentPage === "COMPONENT") {
            const payload = {
                "elementId": parentAttribute.element.id,
                "status": !parentAttribute.element.isDeleted,
                "modifiedBy": user.id || -1
            }
            dispatch(updateElementStatus(payload, callbackFn))
        }
    }

    const deleteClickHandler = (parentAttribute: any) => {
        let res = window.confirm("Are you sure want to delete?")
        if (!res) {
            return
        }

        if (currentPage === "LAYOUT") {
            const callback = () => {
                callbackFn(layoutId)
            }
            dispatch(deleteComponent(`${parentAttribute?.component?.id}?modifiedBy=${user.id || -1}`, callback))
        }

        if (currentPage === "COMPONENT") {
            dispatch(deleteElement(`${parentAttribute?.element?.id}?modifiedBy=${user.id || -1}`, callbackFn))
        }
    }

    const componentClickHandler = (componentName: number, componentId: number) => {
        let path = `/layout/${layoutId}/${componentName}/${componentId}`
        navigate(path)
    }

    return (<>
        {jsonViewPopUp && <JsonViewPopup jsonViewPopUp={jsonViewPopUp} setJsonViewPopUp={setJsonViewPopUp} data={data} />}
        {addAttributePopup && <AddAttributePopup
            addAttributePopup={addAttributePopup}
            setAttributepopup={setAttributepopup}
            attributes={attributes}
            setAttributes={setAttributes}
        />}

        <div className="Component-creation mb-[15px] bg-white m-[15px] rounded-lg">
            <div className="component-creation-header flex items-center justify-between p-[15px]">
                <h2 onClick={() => componentClickHandler(data?.parent?.component?.componentName, data?.parent?.component?.id)}>{data?.parent?.component?.componentName}</h2>
                <div className="header-right-sec flex items-center">
                    {showCopyIcon && <button className="mr-4 act-btn rounded text-sky-600  dark:text-sky-400"><IoCopySharp /> <p className="action-name px-[10px] py-[5px]">Copy</p></button>}
                    {showAddIcon && <button onClick={addAttributeHandler} className="mr-4 act-btn rounded text-sky-600  dark:text-sky-400"><GoPlus /> <p className="action-name px-[10px] py-[5px]">Add&nbsp;Attribute</p></button>}
                    {showSaveIcon && <button onClick={updateClickHandler} className="mr-4 act-btn rounded text-sky-600   dark:text-sky-400"><FaSave /> <p className="action-name px-[10px] py-[5px]">Save</p></button>}
                    {showActiveInactiveIcon && <button onClick={() => updateStatusClickHandler(data?.parent)}
                        className="mr-4 flex status-btn rounded">
                        <BsToggle2Off className={status == true ? "text-red-600" : "text-green-600"} />
                    </button>}
                    {showDeleteIcon && <button className="act-btn text-sky-600  dark:text-sky-400" onClick={() => deleteClickHandler(data?.parent)}><RiDeleteBin4Fill />
                        <p className="action-name px-[10px] py-[5px]">Delete</p>
                    </button>}
                </div>
            </div>


            <div className="flex flex-wrap">
                {attributes.length > 0 ? <>
                    {attributes.map((d: any, index: number) =>
                        <FormAttribute key={index}
                            removeAttributeHandler={removeAttributeHandler}
                            attributes={attributes}
                            setAttributes={setAttributes}
                            attribute={d} />
                    )}
                </> : null}

                {showRedirectPageData && <div className="border border-slate-200 w-full m-4 rounded-lg py-2.5 px-4">
                    <h5 className="font-semibold text-base mb-2">Redirect Page Data</h5>
                    <div className="flex justify-between">
                        <div className="text-sm">
                            <label>Str:&nbsp;</label>
                            <input type="text" className="border border-gray-500 outline-none ml-1.5 px-1.5 py-1 rounded" onChange={(e) => setRedirectPageData({ ...redirectPageData, str: e.target.value })}
                                value={redirectPageData.str}></input>
                        </div>
                        <div className="text-sm">
                            <label>Type:&nbsp;</label>
                            <input type="text" className="border border-gray-500 outline-none ml-1.5 px-1.5 py-1 rounded" onChange={(e) => setRedirectPageData({ ...redirectPageData, type: e.target.value })}
                                value={redirectPageData.type}></input>
                        </div>
                        <div className="text-sm">
                            <label>Category:&nbsp;</label>
                            <input type="text" className="border border-gray-500 outline-none ml-1.5 px-1.5 py-1 rounded" onChange={(e) => setRedirectPageData({ ...redirectPageData, category: e.target.value })}
                                value={redirectPageData.category}></input>
                        </div>
                    </div>
                </div>}
            </div>

            {/* {attributes.length > 0 ? <>
                {attributes.map((d: any) =>
                    <FormAttribute
                        removeAttributeHandler={removeAttributeHandler}
                        attributes={attributes}
                        setAttributes={setAttributes}
                        attribute={d} />
                )}
            </> : null} */}

        </div>
    </>)
}

export default ParentComponent