import { useNavigate } from "react-router-dom";

const ReviewModerationItem = (props: any) => {
    const navigate = useNavigate();
    const { review } = props;

    const editReviewHandler = () => {
        navigate(`/review/${review.id}`)
    }

    return (
        <>
            <tr>
                <td className="w-[10%]">
                    <span onClick={editReviewHandler} className="layout-name text-blue-500 cursor-pointer">{review.id}</span>
                </td>
                <td className="w-[15%]">{review.productMsn}</td>
                <td className="w-[15%]">{review.userName} {review.userId}</td>
                <td className="w-[5%]">{review.rating}</td>
                <td className="w-[10%]">{review.reviewSubject}</td>
                <td className="w-[10%]">{review.reviewText}</td>
                <td className="w-[5%]">{review.isActive?.toString()}</td>
                <td className="w-[5%]">{review.isApproved.toString()}</td>
                <td className="w-[15%]">{review.created}<br />{review.modified}</td>
                <td className="action flex mt-[10px] w-[10%]">
                    <div onClick={editReviewHandler} className="action-inner">
                        <p className="px-[10px] py-[5px] text-[#337ab7] font-semibold">Update</p>
                    </div>
                </td>
            </tr>
        </>)

}

export default ReviewModerationItem;