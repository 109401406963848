import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatTimestamp } from "../../../utils/helper";

const LayoutBlockListItem = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((s: any) => s.authStore);
    const { data } = props;


    const updateLayoutBlockNavigateHandler = (id: any) => {
        navigate(`/blocks/layout/${id}`);
    };

    // const deleteLayoutBlockHandler = (id: any) => {
    //     const isConfirmed = window.confirm('Are you sure want to delete?')
    //     if (isConfirmed) {
    //         dispatch(deleteGeneralBlock({ id: id, userId: user.id || 33 }, (resp: any) => {
    //             if (!resp.status) {
    //                 toast.error(resp.statusDescription)
    //                 return
    //             }
    //             toast.success("Deleted successfully")
    //         }))
    //     }
    //     return
    // };



    return (
        <>
            {data.map((_d: any) => (
                <tr className='[&:nth-child(odd)]:bg-[#f7f7f7]'>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[8%]">{_d.layoutId}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">{_d.layoutName}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[9%]">{_d.layoutCode}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[9%]">{_d.layoutTemplate}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[12%]">{_d.association}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[9%]">{_d.status ? "True" : "False"}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[9%]">{formatTimestamp(_d.createdAt)}</td>
                    <td onClick={() => updateLayoutBlockNavigateHandler(_d.layoutId)} className="px-4 py-3 text-left text-xs text-[#337ab7] w-[8%] font-semibold cursor-pointer">Update</td>
                    {/* <td className="px-4 py-3 text-left text-xs text-[#337ab7] w-[10%] font-semibold cursor-pointer">Generate Layout</td>
                    <td className="px-4 py-3 text-left text-xs text-[#337ab7] w-[6%] font-semibold cursor-pointer">ClearHtml</td> */}
                </tr>
            ))}
        </>
    )
}
export default LayoutBlockListItem;