import {
  SET_QNA_LIST, SET_QNA_LOADER
} from "../actionTypes/qnaActiontypes"

const initialState: any = {
  qnaList: {},
  qnaItem: {},
  isLoading: false,
}

const qnaReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_QNA_LIST:
      return {
        ...state,
        qnaList: action.payload
      }

    case SET_QNA_LOADER:
      return {
        ...state,
        isLoading: action.payload
      }
    default:
      return state

  }

}

export default qnaReducer 