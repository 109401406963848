import { useNavigate,useParams } from "react-router-dom";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import Header from "../../../components/Header/Header";
import { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import SideNavbar from "../../../components/Navbar/SideNavbar";
import StaticHtmlForm from "../../../components/StaticHtmlBlock/StaticHtmlForm";
import { setStaticHtmlBlock, getStaticHtmlBlock } from "../../../../core/actionCreators/blocksActionCreator";


const StaticHtml = () => {
    const params = useParams();
    const dispatch=useDispatch();
    const navigate = useNavigate();
    const backArrowClickHadler=()=>{
        navigate('/blocks/staticHtml');
    }
    const [pageMode, setPageMode] = useState("")
    const { staticHtmlBlock } = useSelector((s: any) => s.blocksStore)

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (currentPath === "/blocks/staticHtml/create") {
            setPageMode("CREATE")
        }
        else {
            if (params.id) {
                setPageMode("EDIT")
                dispatch(getStaticHtmlBlock(params.id, null))
            }
        }
        return () => {
            dispatch(setStaticHtmlBlock({}))
        }
    }, [params, window])

    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                            <div className="flex items-center w-full">
                                <ArrowLeftSLineIcon onClick={backArrowClickHadler} size={24} className="mr-4 cursor-pointer" />
                                <p className="text-xl font-bold text-[#43425D] flex items-center">{pageMode==="CREATE"? "Create" : "Update"} Static Html Block</p>

                            </div>
                        </div>
                        <StaticHtmlForm  pageMode={pageMode} staticHtmlBlock={staticHtmlBlock}/>
                    </div>
                </div>
            </div>
        </>
    )
}
export default StaticHtml;