import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux'
import toast from "react-hot-toast";
import { deleteStaticHtmlBlock } from "../../../core/actionCreators/blocksActionCreator";
import DeleteBin5FillIcon from "remixicon-react/DeleteBin5FillIcon";
import Edit2FillIcon from "remixicon-react/Edit2FillIcon";
const StaticHtmlListItem = (props: any) => {
  const { data } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { user } = useSelector((s: any) => s.authStore);



  const updateNavigateHandler = (id: any) => {
    navigate(`${id}`);
  };


  const deleteHtmlBlockNavigateHandler = (id: any) => {
    const isConfirmed = window.confirm('Are you sure want to delete?')
    if (isConfirmed) {
      dispatch(deleteStaticHtmlBlock({ id: id, userId: user.id || 33 }, (resp: any) => {
        if (!resp.status) {
          toast.error(resp.statusDescription)
          return
        }
        window.location.reload()
        toast.success("Deleted successfully")
      }))
    }
    return
  };


  return (
    <>
      {data.map((d: any) => (
        <tr className='[&:nth-child(odd)]:bg-[#f7f7f7]'>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[8%]">{d.id}</td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">{d.label}</td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[18%]">{d.name}</td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[40%]">{d.staticHtml}</td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[6%]">{d.status ? "True" : "False"}</td>

          <td onClick={() => updateNavigateHandler(d.id)} className="px-4 py-3 text-left text-xs text-[#337ab7] w-[8%] font-semibold cursor-pointer"> <Edit2FillIcon size={14} /></td>
          <td onClick={() => deleteHtmlBlockNavigateHandler(d.id)} className="px-4 py-3 text-left text-xs text-[#337ab7] w-[8%] font-semibold cursor-pointer"> <DeleteBin5FillIcon size={14} /></td>
        </tr>
      ))}
    </>
  )
}
export default StaticHtmlListItem;