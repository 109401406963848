import { useState } from "react";
import { clearCacheService } from "../../../core/services/lmsServices";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import queryString from "query-string";

const CacheDeletion = () => {
    const { user } = useSelector((s: any) => s.authStore);
    const [selectedDevice, setSelectedDevice] = useState('PWA');
    const [loading, setLoading] = useState(false);

    const clearHomePageCache = async () => {
        setLoading(true);
        const stringifiedQuery = queryString.stringify({
            user: user.id || 100,
            type: selectedDevice
        });
        try {
            await clearCacheService(stringifiedQuery)
            setLoading(false);
            toast.success("Cache cleared successfully!")
        }
        catch (err) {
            setLoading(false);
            toast.error("Something went wrong!")
        }
        return
    }

    return (
        <>
            <div className="flex justify-between items-center py-4 px-4 bg-white shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                <p className="text-xl font-bold text-[#43425D]">Cache Deletion</p>
            </div>

            <div className="p-4">

                <div className="mb-7">
                    <p className="text-sm text-[#343434] mb-2.5 font-medium">Clear cache by category code:</p>
                    <div className="flex">
                        <input
                            className="h-9 w-1/2 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                            type="text"
                            placeholder="Enter one category code"
                        />
                        <button className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ml-5">Delete</button>
                    </div>
                </div>

                <div className="mb-7">
                    <p className="text-sm text-[#343434] mb-2.5 font-medium">Clear cache by msn id:</p>
                    <div className="flex">
                        <input
                            className="h-9 w-1/2 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                            type="text"
                            placeholder="Enter one msn id "
                        />
                        <button className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ml-5">Delete</button>
                    </div>
                </div>

                <div>
                    <p className="text-sm text-[#343434] mb-2.5 font-medium">Clear HomePage cache by device:</p>
                    <div className="flex">
                        <select
                            className="h-9 w-1/2 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light bg-[#ffffff]"
                            value={selectedDevice}
                            onChange={(e) => setSelectedDevice(e.target.value)}
                        >
                            <option value="PWA">PWA</option>
                            <option value="APP">APP</option>
                            <option value="DESKTOP">DESKTOP</option>
                        </select>
                        <button
                            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ml-5"
                            onClick={clearHomePageCache}
                            disabled={loading}
                        >
                            {loading ? 'Loading...' : 'Delete'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default CacheDeletion;