import { useNavigate } from "react-router-dom";
import LayoutBlockListItem from "./LayoutBlockListItem";
import { GET_GENERAL_BLOCK_DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";
import queryString from "query-string";
import { getBlockLayouts } from "../../../core/actionCreators/blocksActionCreator";
import Loader from "../Loader/Loader";
import CommonPagination from "../CommonPagination/CommonPagination";
import { getBlockLayoutTemplates } from "../../../core/services/blocksServices";
import toast from "react-hot-toast";

const LayoutBlock = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { blockLayouts, isLoading } = useSelector((s: any) => s.blocksStore);

    const [searchCriteria, setSearchCriteria] = useState({
        id: "",
        layoutId: "",
        layoutName: "",
        layoutCode: "",
        layoutTemplate: "",
        association: "",
        htmlGenerationDate: "",
        pageNumber: 0,
        status: "",
        pageSize: GET_GENERAL_BLOCK_DEFAULT_PAGE_SIZE,
    } as any);


    const previousSearchCriteriaRef = useRef(searchCriteria);


    useEffect(() => {
        const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
        const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
        const previousCriteria = previousSearchCriteriaRef.current;
        const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

        if (searchFilterChanged) {
            const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
            for (let key in parsedSearchCriteria) {
                if (["id", "layoutId", "layoutName", "layoutCode", "layoutTemplate"].includes(key)) {
                    parsedSearchCriteria.pageNumber = 0
                    break
                }
            }
            dispatch(getBlockLayouts(queryString.stringify(parsedSearchCriteria), null));
            previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
        }
    }, [searchCriteria]);

    const onPageChange = (pageNumber: any) => {
        setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
    }

    const navigateToCreateLayoutBlockHandler = () => {
        navigate('/blocks/layout/create');
    }

    return (
        <>
            <div className="flex justify-between items-center pt-4 px-4">
                <p className="text-base text-[#343434]">Layouts</p>
                <button onClick={navigateToCreateLayoutBlockHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">Create Layout</button>
            </div>
            <div className="mt-4 campaign-list bg-white mb-[54px]">
                <table className="w-full" >
                    <thead className="bg-[#343434]">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[8%]">Id</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[22%]">Layout Name</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Layout Code</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Layout Template</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[12%]">Association</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[9%]">Status</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[9%]">Created At</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Action</th>
                            {/* <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[8%]">Generate Layout</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[6%]">Clear Html</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-4 py-3 text-left text-xs text-white w-[8%]">
                                <input value={searchCriteria.layoutId}
                                    onChange={(e) =>
                                        setSearchCriteria({ ...searchCriteria, layoutId: e.target.value })
                                    } className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[22%]">
                                <input value={searchCriteria.layoutName}
                                    onChange={(e) =>
                                        setSearchCriteria({ ...searchCriteria, layoutName: e.target.value })
                                    } className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[10%]">
                                <input value={searchCriteria.layoutCode}
                                    onChange={(e) =>
                                        setSearchCriteria({ ...searchCriteria, layoutCode: e.target.value })
                                    } className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[10%]">
                                <input value={searchCriteria.layoutTemplate}
                                    onChange={(e) =>
                                        setSearchCriteria({ ...searchCriteria, layoutTemplate: e.target.value })
                                    } className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[12%]">
                                <input value={searchCriteria.association}
                                    onChange={(e) =>
                                        setSearchCriteria({ ...searchCriteria, association: e.target.value })
                                    } className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[9%]">
                                <select
                                    value={searchCriteria.status}
                                    onChange={(e) =>
                                        setSearchCriteria({
                                            ...searchCriteria,
                                            status: e.target.value,
                                        })
                                    }
                                    className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs bg-white"
                                >
                                    <option>Select An Option</option>
                                    <option value={"true"}>True</option>
                                    <option value={"false"}>False</option>
                                </select>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[9%]">
                                {/* <input className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                /> */}
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[10%]">
                            </td>
                            {/* <td className="px-4 py-3 text-left text-xs text-white w-[8%]">
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[6%]">
                            </td> */}
                        </tr>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <LayoutBlockListItem data={blockLayouts?.data || []} />
                        )}
                    </tbody>
                </table>
            </div>
            {blockLayouts?.data && (
                <CommonPagination
                    data={blockLayouts}
                    PAGE_SIZE={GET_GENERAL_BLOCK_DEFAULT_PAGE_SIZE}
                    onPageChange={onPageChange}
                />
            )}
        </>
    )
}
export default LayoutBlock;