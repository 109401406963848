import axios from "axios";
import { apis } from "./params";

export function getPlaFeedsService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_LIST_OF_PLA_FEEDS + _param,
  });
}
// export function getPlaFeedService(_param: any) {
//     return axios({
//         "method": "Get",
//         "url": apis.GET_LIST_OF_PLA_FEED + _param,
//     })
// }

export function getPlaFeedByIdService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_PLA_FEED_BY_ID + _param,
  });
}

export function createPlaFeedService(_data: any) {
  return axios({
    method: "Post",
    url: apis.CREATE_PLA_FEED,
    data: _data,
    headers: {
      ..._data.headers,
    },
  });
}

export function updatePlaFeedService(_data: any) {
  return axios({
    method: "Post",
    url: apis.UPDATE_PLA_FEED,
    data: _data,
    headers: {
      ..._data.headers,
    },
  });
}

export function downloadPlaDumpService(_param: any) {
  return axios({
    method: "Get",
    url: apis.DOWNLOAD_PLA_DUMP + _param,
  });
}
export function bulkPlaService(_data: any) {
  return axios({
    method: "Post",
    url: apis.BULK_PLA_FILE + _data.param,
    data: _data.data,
  });
}
