import * as actions from '../actionTypes/aiPoweredActionTypes';


export const setAiLoader = (_data: any) => {
    return {
        type: actions.SET_AI_LOADER,
        payload: _data
    }
}

export const uploadProductDescription = (_data: any, _callback: any) => {
    return {
        type: actions.UPLOAD_PRODUCT_DESCRIPTION,
        payload: _data,
        callback: _callback
    }
}

export const trackProductDescription = (_data: any, _callback: any) => {
    return {
        type: actions.TRACK_PRODUCT_DESCRIPTION,
        payload: _data,
        callback: _callback
    }
}

export const setTrackProductDescription = (_data: any) => {
    return {
        type: actions.SET_TRACK_PRODUCT_DESCRIPTION,
        payload: _data,
    }
}