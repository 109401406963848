import Section from "./Section";
import MultiCategorySelection from "./MultiCategorySelection";
import Loader from "../Loader/Loader";


const CategoryBasedCampaign = (props: any) => {
    const { campaign, setcampaign, sections, pageMode, loader } = props

    return (
        <>
            {/* MID FORM SECTION */}
            <div className="mx-5 mb-2.5 bg-white p-5 rounded border border-[#EAEAEA]">
                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Applicable for All Category :</p>
                    <div className="w-2/5">
                        <div className="relative">
                            <div className="form-field full-width">
                                <select disabled={!["CREATE", "EDIT"].includes(pageMode)} onChange={(e) => setcampaign({ ...campaign, isUniversal: e.target.value })}>
                                    <option selected={campaign.isUniversal == false} value={"false"}>False</option>
                                    <option selected={campaign.isUniversal == true} value={"true"}>True</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex items-center w-full mb-7 last:mb-0">
                    <p className="text-xs font-semibold text-[#303030] w-48">Enable for Internal MSN :</p>
                    <div className="w-2/5">
                        <div className="relative">
                            <div className="form-field full-width">
                                <select disabled={!["CREATE", "EDIT"].includes(pageMode)} onChange={(e) => setcampaign({ ...campaign, isMsnChecked: e.target.value })}>
                                    <option selected={campaign.isMsnChecked == false} value={"false"}>False</option>
                                    <option selected={campaign.isMsnChecked == true} value={"true"}>True</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <MultiCategorySelection {...props} />
            </div >

            {/*SECTIONS */}
            {
                sections.map((d: any, index: number) =>
                    <Section key={index} currentSection={d} {...props} />
                )
            }
        </>
    )
};
export default CategoryBasedCampaign;