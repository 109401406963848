import { useState } from "react";
import * as XLSX from 'xlsx';
import { toast } from "react-hot-toast";
import FileUploadFillIcon from "remixicon-react/FileUploadFillIcon"
import { useDispatch } from "react-redux";
import { delayCommunicationViaWhatsapp } from "../../../../core/actionCreators/campaignActionCreator";
import { useSelector } from "react-redux";
import Loader from "../../Loader/Loader";

const EXPECTED_COLUMNS = ["phone", "expected_delay", "reason", "tat_correction"]

const DelayCommunication = () => {
    const dispatch = useDispatch()
    const { isLoading } = useSelector((s: any) => s.campaignStore)
    const [fileReadyForUpload, setFileReadyForUpload] = useState(false)
    const [file, setFile] = useState("")

    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];
        if (!file || !file.name.endsWith(".xlsx")) {
            toast.error('Please upload an XLSX file.')
            return;
        }
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const headers: any = jsonData[0]; // Assuming the first row contains column headers
            const hasExpectedColumns = EXPECTED_COLUMNS.every((column) => headers.includes(column));
            if (!hasExpectedColumns) {
                toast.error('Uploaded file does not contain all expected columns.');
                return;
            }
            let _dataArray = jsonData.slice(1);
            if (_dataArray.length === 0 || _dataArray.length > 1000) {
                toast.error('Please upload a file with 1 to 1000 records (excluding headers).')
                return;
            }

            setFileReadyForUpload(true)
            setFile(file)
            // const dataArray = jsonData.slice(1).map((row: any) => {
            //     return headers.reduce((obj: any, header: any, index: number) => {
            //         obj[header] = row[index] ? row[index].toString().trim().toUpperCase() : '';
            //         return obj;
            //     }, {});
            // });
        };
        reader.readAsArrayBuffer(file);
    };

    const uploadFileClickHandler = () => {
        if (!fileReadyForUpload) {
            toast.error("Invalid file.")
            return
        }
        const formData = new FormData();
        formData.append('file', file);
        dispatch(delayCommunicationViaWhatsapp(formData, () => {
            toast.success("Sent successfully")
            setFile("")
        }))
    }

    const downloadSampleFile = () => {
        const data = [
            { phone: '8750052553', expected_delay: '1', reason: 'Heavy Rainfall and Snowfall', tat_correction: '2-3' }
            // Add more data objects if needed
        ];

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "SampleFile.xlsx");
    }

    return (<>
        <div className="mx-5 mb-2.5 bg-white p-5 rounded border border-[#EAEAEA] mt-5">
            <div className="bg-white p-5 rounded border border-[#EAEAEA]">
                {/* <div className="flex items-center justify-between mb-2.5">
                        <p className="text-xs font-semibold text-[#3B86FF]">View File Format</p>
                    </div> */}
                <p className="text-xs font-semibold text-[#303030] mb-2.5">Upload File (xlsx)</p>
                <div className="flex w-full justify-between mb-px last:mb-0">
                    <div className="flex items-center w-[100%]">
                        <div className="w-full">
                            <div className="relative pr-4">
                                <input onChange={handleFileUpload} type="file" accept=".xlsx" className="w-full rounded-sm border border-gray-300 outline-none text-xs pl-7 px-2.5 py-1.5 text-[#303030] bg-[#E5EEFC]" />
                                <FileUploadFillIcon size={16} className="absolute left-2.5 top-1.5" />

                            </div>
                        </div>
                        {isLoading ? <Loader /> : <button onClick={uploadFileClickHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">Upload</button>}
                    </div>
                </div>
                <button onClick={downloadSampleFile} className="text-[10px] text-[#3c3c3c]">Download Sample File</button>
            </div>
        </div>
    </>)
}

export default DelayCommunication