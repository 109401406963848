import { put, takeLatest, all, call, takeEvery } from "redux-saga/effects";
import { toast } from "react-hot-toast";
import { setReviewModerationsLoader, setReviews, setReviewsDump, setUpdatedReview } from "../actionCreators/rmActionCreator";
import { downloadReviews, getReviewsService, reviewReplyService, updateReviewService, uploadReviewsFileService } from "../services/reviewsService";
import { GET_REVIEWS, GET_REVIEWS_DUMP, UPDATE_REVIEW, UPLOAD_REVIEWS_FILE } from "../actionTypes/reviewsModerationActionTypes";

function* _getReviews(_data: any) {
    try {
        yield put(setReviewModerationsLoader(true));
        const { data } = yield call(getReviewsService, _data.payload);
        yield put(setReviewModerationsLoader(false));
        if (data.status == true) {
            yield put(setReviews({
                totalCount: data?.data?.totalCount,
                data: data?.data?.reviewsList
            }))
            _data.callback && _data.callback(data.data)
        }
        return
    }
    catch (err: any) {
        yield put(setReviewModerationsLoader(false));
        toast.error("Something went wrong.")
    }
}

function* _updateReview(_data: any) {
    try {
        yield put(setReviewModerationsLoader(true));
        // if (_data.payload.merchandiseReplyText) {
        //     yield call(reviewReplyService, {
        //         "reply_text": _data.payload.merchandiseReplyText,
        //         "review_id": _data.payload.reviewId
        //     })
        // }
        const { data } = yield call(updateReviewService, _data.payload);
        yield put(setReviewModerationsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(data.data)
        }
        return
    }
    catch (err: any) {
        yield put(setReviewModerationsLoader(false));
        toast.error("Something went wrong.")
    }
}

function* _getReviewsDump(_data: any) {
    try {
        yield put(setReviewModerationsLoader(true));
        const { data } = yield call(downloadReviews, _data.payload);
        yield put(setReviewModerationsLoader(false));
        if (data.status == true) {
            yield put(setReviewsDump(data.data))
            _data.callback && _data.callback(data.data)
        }
    }
    catch (err: any) {
        yield put(setReviewModerationsLoader(false));
        toast.error("Something went wrong.")
    }
}

function* _uploadReviewsFile(_data: any) {
    try {
        yield put(setReviewModerationsLoader(true));
        const { data } = yield call(uploadReviewsFileService, _data.payload);
        yield put(setReviewModerationsLoader(false));
        if (data.status == true) {
            yield put(setReviews(data.data))
            _data.callback && _data.callback(data)
        }
        else {
            toast.error(data.data.errorMsg)
        }
    }
    catch (err: any) {
        yield put(setReviewModerationsLoader(false));
        console.log("_uploadReviewsFile: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

export default function* rmSaga() {
    yield all([
        takeLatest(GET_REVIEWS, _getReviews),
        takeLatest(UPDATE_REVIEW, _updateReview),
        takeLatest(GET_REVIEWS_DUMP, _getReviewsDump),
        takeLatest(UPLOAD_REVIEWS_FILE, _uploadReviewsFile)
    ])
}