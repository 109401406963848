import {
    SET_REVIEWS_LOADER, SET_REVIEWS, GET_REVIEWS, RESET, UPDATE_REVIEW, SET_UPDATE_REVIEW, GET_REVIEWS_DUMP, SET_REVIEWS_DUMP
} from '../actionTypes/reviewsModerationActionTypes';

const initialState: any = {
    isLoading: false,
    reviews: {},
    data: {},
    updateData: {},
    reviewsDump: ''
}

const rmReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_REVIEWS_LOADER:
            return {
                ...state,
                isLoading: action.payload
            }
        case SET_REVIEWS:
            return {
                ...state,
                reviews: action.payload
            }
        case GET_REVIEWS:
            return {
                ...state,
                reviews: action.payload
            }
        case UPDATE_REVIEW:
            return {
                ...state,
                data: action.payload
            }
        case SET_UPDATE_REVIEW:
            return {
                ...state,
                updateData: action.payload
            }
        case GET_REVIEWS_DUMP:
            return {
                ...state,
                // reviewsDump: action.payload
            }
        case SET_REVIEWS_DUMP:
            return {
                ...state,
                reviewsDump: action.payload
            }
        case RESET:
            return {
                ...state,
                updateData: {},
                reviewsDump: ''
            }
        default:
            return state;
    }
}

export default rmReducer;