import React from "react";
import { useNavigate } from "react-router-dom";


const FaqListItem = (props: any) => {
    const navigate = useNavigate()
    const { data } = props

    const updateFbtNavigateHandler = (id: any) => {
        navigate(`/faq/${id}`)
    }

    return (
        <>
            {data.map((_d: any) =>
                <tr className='[&:nth-child(odd)]:bg-[#f7f7f7]'>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[40%]">{_d.categoryCode}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[40%]">{_d.categoryName}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[10%]">{_d.number}</td>
                    <td onClick={() => updateFbtNavigateHandler(_d.categoryCode)} className="px-4 py-3 text-left text-xs text-[#3B86FF] font-semibold w-[10%]">Update</td>
                </tr>
            )}
        </>
    )
}
export default FaqListItem;