import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { formatTimestamp } from "../../../utils/helper";
import DeleteBin5FillIcon from "remixicon-react/DeleteBin5FillIcon";
import Edit2FillIcon from "remixicon-react/Edit2FillIcon";
import { deleteCategoryBlock } from "../../../core/actionCreators/blocksActionCreator";
// import { deleteCategoryBlock } from "../../../core/actionCreators/blocksActionCreator";

const CategoryListItem = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((s: any) => s.authStore);
  const { data } = props;

  const updateCategoryBlockNavigateHandler = (id: any) => {
    navigate(`/blocks/category/${id}`);
  };

  const deleteCategoryBlockHandler = (id: any) => {
    const isConfirmed = window.confirm('Are you sure want to delete?')
    if (isConfirmed) {
      dispatch(deleteCategoryBlock({ id: id, userId: user.id || 33 }, (resp: any) => {
        if (!resp.status) {
          toast.error(resp.statusDescription)
          return
        }
        toast.success("Deleted successfully")
        window.location.reload()
      }))
    }
    return
  };
  return (
    <>
      {data.map((_d: any) => (
        <tr className="[&:nth-child(odd)]:bg-[#f7f7f7]">
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d.id}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d.label}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d.name}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d.brand}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d.elementCount}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d.status ? "True" : "False"}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {formatTimestamp(_d.createdAt)}
          </td>
          <td
            className="px-4 py-3 text-left text-xs text-[#337ab7] font-semibold cursor-pointer"
            onClick={() => updateCategoryBlockNavigateHandler(_d.id)}
          >
            <Edit2FillIcon size={14} />
          </td>

          <td
            className="px-4 py-3 text-left text-xs text-[#337ab7] font-semibold cursor-pointer"

          >
            {" "}
            <DeleteBin5FillIcon onClick={() => deleteCategoryBlockHandler(_d.id)} size={14} />
          </td>
        </tr>
      ))}
    </>
  );
};
export default CategoryListItem;
