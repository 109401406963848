import React, { useEffect, useState } from "react"
import FileUploadFillIcon from "remixicon-react/FileUploadFillIcon"
import {
    CAMPAIGN_FILE_DIMENSION_CONFIG, CAMPAIGN_SECTION_TYPES, CAMPAIGN_TYPES, CREATE_CAMPAIGN_FORM_ERRORS,
    PLATFORMS
} from "../../../utils/constants"

const BannerItem = (props: any) => {
    const { deleteBannerbtnClickHandler, updateBannersHandler, data,
        sectionType, pageMode, campaign, platform } = props
    const [landingPageUrl, setLandingPageUrl] = useState("")
    const [file, setFile] = useState("" as any)
    const [bannerType, setBannerType] = useState("")
    const [categoryName, setCategoryName] = useState("")
    const [videoName, setVideoName] = useState("")
    const [externalVideoLink, setExternalVideoLink] = useState("")
    const [bannerTypes, setBannerTypes] = useState([] as any)
    const [formErrors, setFormErrors] = useState({} as any)

    useEffect(() => {
        if (platform) {
            let bannerTypes = Object.keys(CAMPAIGN_FILE_DIMENSION_CONFIG[platform]).filter((d: any) => d !== "PROMOTED_BRAND_UNIT")
            setBannerTypes(bannerTypes)
        }
    }, [platform, bannerType])

    useEffect(() => {
        if (file) {
            setFormErrors({ ...formErrors, file: "" })
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const dataURL = e.target.result;
                const img = new Image();
                img.onload = () => {
                    const fileSizeKB = file.size / 1024
                    const width = img.width;
                    const height = img.height;
                    const selectedFileDimension = `${width} * ${height}`
                    let _bannerType = bannerType
                    if (!platform) {
                        setFormErrors({ ...formErrors, file: "First please select platform." })
                        return
                    }
                    if (sectionType === CAMPAIGN_SECTION_TYPES.BANNER_AD_UNIT && !bannerType) {
                        setFormErrors({ ...formErrors, file: "First please select banner type." })
                        return
                    }
                    if (sectionType === CAMPAIGN_SECTION_TYPES.PROMOTED_BRAND_UNIT) {
                        _bannerType = CAMPAIGN_SECTION_TYPES.PROMOTED_BRAND_UNIT
                    }

                    //Get config
                    let config = CAMPAIGN_FILE_DIMENSION_CONFIG[platform]
                    if (!config) {
                        setFormErrors({ ...formErrors, file: "Something went wrong, selected platform is not configured." })
                        return
                    }
                    config = config[_bannerType]
                    console.log("SELECTED FILE DIMENSION", selectedFileDimension)
                    console.log("ALLOWED_DIMENSION", config.allowedDimensions)
                    console.log("SELECTED_FILE_SIZE_IN_KB", fileSizeKB)
                    console.log("ALLOWED_SIZE", config.maxFileSizeInKb)
                    if (!config) {
                        setFormErrors({ ...formErrors, file: "Something went wrong, selected banner type is not configured." })
                        return
                    }
                    if (!config.allowedDimensions.includes(selectedFileDimension)) {
                        setFormErrors({
                            ...formErrors, file: CREATE_CAMPAIGN_FORM_ERRORS.INVALID_FILE_DIMENSION + config.allowedDimensions.join(" or ")
                        })
                        return
                    }
                    if (fileSizeKB > config.maxFileSizeInKb) {
                        setFormErrors({ ...formErrors, file: CREATE_CAMPAIGN_FORM_ERRORS.INVALID_FILE_SIZE + config.maxFileSizeInKb + " kb" })
                        return
                    }
                };
                img.src = dataURL;
            };
            reader.readAsDataURL(file);
        }
    }, [file])

    useEffect(() => {
        if (props.data.landingPageUrl) {
            setLandingPageUrl(props.data.landingPageUrl)
        }
        if (props.data.bannerType) {
            setBannerType(props.data.bannerType)
        }
        if (props.data.categoryName) {
            setCategoryName(props.data.categoryName)
        }
        if (props.data.videoName) {
            setVideoName(props.data.videoName)
        }
        if (props.data.externalVideoLink) {
            setExternalVideoLink(props.data.externalVideoLink)
        }
    }, [data])

    const fileHandler = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            let file = e.target.files[0];
            setFile(file)
        }
    }

    useEffect(() => {
        if (landingPageUrl) {
            updateBannersHandler("LANDING_PAGE_URL", data.id, landingPageUrl)
            if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
                if (!landingPageUrl.startsWith("https://")) {
                    setFormErrors({ ...formErrors, landingPageUrl: CREATE_CAMPAIGN_FORM_ERRORS.INVALID_LANDING_PAGE_URL_PRODUCTION })
                }
                else {
                    setFormErrors({ ...formErrors, landingPageUrl: "" })
                }
            }
            else {
                if (!landingPageUrl.startsWith('https://') && !landingPageUrl.startsWith('https://')) {
                    setFormErrors({ ...formErrors, landingPageUrl: CREATE_CAMPAIGN_FORM_ERRORS.INVALID_LANDING_PAGE_URL_STAGING })
                }
                else {
                    setFormErrors({ ...formErrors, landingPageUrl: "" })
                }
            }
        }
        else {
            setFormErrors({ ...formErrors, landingPageUrl: "" })
        }
    }, [landingPageUrl])

    useEffect(() => {
        updateBannersHandler("FILE", data.id, file)
    }, [file])

    useEffect(() => {
        updateBannersHandler("BANNER_TYPE", data.id, bannerType)
    }, [bannerType])

    useEffect(() => {
        updateBannersHandler("CATEGORY_NAME", data.id, categoryName)
    }, [categoryName])

    useEffect(() => {
        updateBannersHandler("VIDEO_NAME", data.id, videoName)
    }, [videoName])

    useEffect(() => {
        if (Object.keys(formErrors).length) {
            updateBannersHandler("FORM_ERRORS", data.id, formErrors)
        }
    }, [formErrors])

    useEffect(() => {
        if (externalVideoLink) {
            updateBannersHandler("EXTERNAL_VIDEO_LINK", data.id, externalVideoLink)
            //Validate externalVideoLink
            const youtubeUrlPattern = /^(https?:\/\/)?(www\.)?(youtu\.be|youtube\.com)\b/;
            if (!youtubeUrlPattern.test(externalVideoLink)) {
                setFormErrors({ ...formErrors, externalVideoLink: CREATE_CAMPAIGN_FORM_ERRORS.INVALID_YOUTUBE_URL })
            }
            else {
                setFormErrors({ ...formErrors, externalVideoLink: "" })
            }
        }
    }, [externalVideoLink])


    const bannerTypeDisableHelper = (bannerType: string) => {
        let disable = false
        if (campaign?.bannerAdUnit?.banners?.find((d: any) => d.bannerType === bannerType)) {
            disable = true
        }
        return disable
    }

    return (<>
        <div className="flex w-full mb-5 last:mb-0">
            <div className="flex  w-[90%] pr-8">

                {/* Show Banner Type only in Banner Ad unit Case */}
                {["BANNER_AD_UNIT"].includes(sectionType) && <div className="w-2/6 min-w-[33.33%] pr-8">
                    <div className="relative">
                        <div className="form-field select_full-width">
                            <select disabled={!["CREATE", "EDIT"].includes(pageMode)} value={bannerType} onChange={(e) => setBannerType(e.target.value)}>
                                <option value={""}>Select</option>
                                {bannerTypes?.map((d: any, index: number) =>
                                    <option disabled={bannerTypeDisableHelper(d)} key={index} value={d}>{d}</option>
                                )}
                            </select>
                        </div>
                    </div>
                </div>}

                {["BANNER_AD_UNIT", "PROMOTED_BRAND_UNIT"].includes(sectionType) && <div className="pr-8 w-2/6 min-w-[33.33%]">
                    <div className="relative">
                        <input disabled={!["CREATE", "EDIT"].includes(pageMode)} accept=".jpg,.png,.jpeg" onChange={fileHandler} id='uploadFile' type="file" className="w-full rounded-sm border border-gray-300 outline-none text-xs pl-7 px-2.5 py-1.5 text-[#303030] bg-[#EFF6F8]" />
                        <FileUploadFillIcon size={16} className="absolute left-2.5 top-[9px]" />
                        {["EDIT", "VIEW"].includes(pageMode) && (data.pictureUrl || data.videoUrl) && < a className="text-[10px] text-[#3c3c3c]" href={data.pictureUrl || data.videoUrl} target="_blank">Previous File</a>}
                        <label className="text-[10px] text-[#d9232d]">{formErrors.file}</label>
                    </div>
                </div>}

                {["PROMOTED_BRAND_UNIT"].includes(sectionType) && <div className="pr-8 last:pr-0 w-2/6 min-w-[33.33%]">
                    <input disabled={!["CREATE", "EDIT"].includes(pageMode)} value={categoryName} onChange={(e) => setCategoryName(e.target.value)} type="text" placeholder="Enter Category Name" className="w-full rounded-sm border border-gray-300 outline-none text-xs px-2.5 py-2 text-[#303030]" />
                </div>}

                {["VIDEO_UNIT"].includes(sectionType) && <div className="pr-8 last:pr-0 w-1/2">
                    <input required disabled={!["CREATE", "EDIT"].includes(pageMode)} value={videoName} onChange={(e) => setVideoName(e.target.value)} type="text" placeholder="Enter Video Title" className="w-full rounded-sm border border-gray-300 outline-none text-xs px-2.5 py-2 text-[#303030]" />
                </div>}


                {["BANNER_AD_UNIT", "PROMOTED_BRAND_UNIT"].includes(sectionType) && <div className="w-1/2">
                    <input required disabled={!["CREATE", "EDIT"].includes(pageMode)} value={landingPageUrl} onChange={(e) => setLandingPageUrl(e.target.value)} type="text" placeholder="Enter Landing Page URL" className="w-full rounded-sm border border-gray-300 outline-none text-xs px-2.5 py-2 text-[#303030]" />
                    <label className="text-[10px] text-[#d9232d]">{formErrors.landingPageUrl}</label>
                </div>}

                {["VIDEO_UNIT"].includes(sectionType) && <div className="pr-8 last:pr-0 w-1/2">
                    <input required disabled={!["CREATE", "EDIT"].includes(pageMode)} value={externalVideoLink} onChange={(e) => setExternalVideoLink(e.target.value)} type="text" placeholder="Enter Video URL" className="w-full rounded-sm border border-gray-300 outline-none text-xs px-2.5 py-2 text-[#303030]" />
                    <label className="text-[10px] text-[#d9232d]">{formErrors.externalVideoLink}</label>
                </div>}

            </div>
            {["CREATE", "EDIT"].includes(pageMode) && <button onClick={() => deleteBannerbtnClickHandler(data.id)} className="text-[#D9322E] h-[34px] text-xs font-semibold bg-[#fae0e1] px-7 py-2 rounded ml-auto">Delete</button>}
        </div >
    </>)

}

export default BannerItem