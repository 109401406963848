import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import AttributeListingForm from "../../components/AttributeListing/AttributeListingForm";
import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getAttributeById,
  setAttribute,
} from "../../../core/actionCreators/attributeActionCreator";

const AttributeListing = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const backArrowClickHadler = () => {
    navigate("/attributeListing");
  };

  const [pageMode, setPageMode] = useState("");
  const { attribute } = useSelector((s: any) => s.attributeStore);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === "/attributeListing/create") {
      setPageMode("CREATE");
    } else {
      if (params.id) {
        setPageMode("EDIT");
        dispatch(getAttributeById(params.id, null));
      }
    }
    return () => {
      dispatch(setAttribute({}));
    };
  }, [params, window]);

  return (
    <>
      <div className="flex mainWrapper w-full">
        <div className="sidenav-wrapper">
          <SideNavbar />
        </div>
        <div className="right-container">
          <div className="header-container">
            <Header />
          </div>
          <div className="body-container">
            <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
              <div className="flex items-center w-full">
                <ArrowLeftSLineIcon
                  onClick={backArrowClickHadler}
                  size={24}
                  className="mr-4 cursor-pointer"
                />
                <p className="text-xl font-bold text-[#43425D] flex items-center">
                  {pageMode=== "CREATE" ? "Create": "Update"} Attribute Listing
                </p>
              </div>
            </div>
            <AttributeListingForm pageMode={pageMode} attribute={attribute} />
          </div>
        </div>
      </div>
    </>
  );
};
export default AttributeListing;
