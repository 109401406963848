import axios from "axios";
import { apis } from './params';

export function getFaqsService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_FAQS + _param,
    })
}

export function getFaqService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_FAQ + _param,
    })
}

export function downloadFaqDumpService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.DOWNLOAD_FAQ_DUMP
    })
}

export function createFaqService(_data: any) {
    return axios({
        "method": "Post",
        "url": apis.CREATE_FAQ,
        "data": _data
    })
}

export function deleteFaqSchemaService(_data: any) {
    return axios({
        "method": "Post",
        "url": apis.DELETE_FAQ_SCHEMA,
        "data": _data
    })
}
