import React from "react";
import UserProfile from "./UserProfile";


const WhatsappCampaignHeader = (props: any) => {
    const { whatsappCampaignTypes, setWhatsappCampaignType } = props

    return (<>
        <div className="campaign-header bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
            <div className="flex items-center justify-between w-full">
                <p className="text-xl font-bold text-[#43425D] w-1/5">Whatsapp Campaign</p>
                <select onChange={(e) => setWhatsappCampaignType(e.target.value)} className="bg-white text-xs border  border-gray-300 h-9 px-3 rounded">
                    <option value={""}>Select</option>
                    {
                        props.whatsappCampaignTypes.map((d: string) =>
                            <option value={d}>{d}</option>
                        )
                    }
                </select>
            </div>
        </div>
        {/* <div className="header">
            <div className="header-inner">
                <select onChange={(e) => setWhatsappCampaignType(e.target.value)}>
                    <option value={""}>Select</option>
                    {
                        props.whatsappCampaignTypes.map((d: string) =>
                            <option value={d}>{d}</option>
                        )
                    }
                </select>
            </div>
        </div> */}
    </>)
}

export default WhatsappCampaignHeader