import * as actions from '../actionTypes/qnaActiontypes';

export const setQnaLoader = (_data: any) => {
    return {
        type: actions.SET_QNA_LOADER,
        payload: _data
    }
}

export const getQnaList = (data: any, _callback: any) => {
    return {
        type: actions.GET_QNA_LIST,
        payload: data,
        callback: _callback
    }
}

export const setQnaList = (_data: any) => {
    return {
        type: actions.SET_QNA_LIST,
        payload: _data
    }
}


export const addUpdateAnswer = (_data: any, _callback: any) => {
    return {
        type: actions.ADD_UPDATE_ANSWER,
        payload: _data,
        callback: _callback
    }
}

export const publishQuestions = (_data: any, _callback: any) => {
    return {
        type: actions.PUBLISH_QUESTIONS,
        payload: _data,
        callback: _callback
    }
}

export const disableQuestions = (_data: any, _callback: any) => {
    return {
        type: actions.DISABLE_QUESTIONS,
        payload: _data,
        callback: _callback
    }
}

export const downloadQuestions = (_data: any, _callback: any) => {
    return {
        type: actions.DOWNLOAD_QUESTIONS,
        payload: _data,
        callback: _callback
    }
}

