import * as actions from '../actionTypes/lmsActionTypes';

export const setLmsLoader = (_data: any) => {
    return {
        type: actions.SET_LMS_LOADER,
        payload: _data
    }
}

export const getLayouts = (_data: any, _callback: any) => {
    return {
        type: actions.GET_LAYOUTS,
        payload: _data,
        callback: _callback
    }
}

export const setLayouts = (_data: any) => {
    return {
        type: actions.SET_LAYOUTS,
        payload: _data
    }
}

export const getComponents = (_data: any, _callback: any) => {
    return {
        type: actions.GET_COMPONENTS,
        payload: _data,
        callback: _callback
    }
}

export const setComponents = (_data: any,) => {
    return {
        type: actions.SET_COMPONENTS,
        payload: _data
    }
}


export const createLayout = (_data: any, _callback: any) => {
    return {
        type: actions.CREATE_LAYOUT,
        payload: _data,
        callback: _callback
    }
}

export const deleteLayout = (_data: any, _callback: any) => {
    return {
        type: actions.DELETE_LAYOUT,
        payload: _data,
        callback: _callback
    }
}

export const getLayout = (_data: any, _callback: any) => {
    return {
        type: actions.GET_LAYOUT,
        payload: _data,
        callback: _callback
    }
}

export const setLayout = (_data: any) => {
    return {
        type: actions.SET_LAYOUT,
        payload: _data
    }
}

export const getStaticFormFields = (_data: any, _callback: any) => {
    return {
        type: actions.GET_STATIC_FORM_FIELDS,
        payload: _data,
        callback: _callback
    }
}

export const setStaticFormFields = (_data: any) => {
    return {
        type: actions.SET_STATIC_FORM_FIELDS,
        payload: _data
    }
}

export const createComponent = (_data: any, _callback: any) => {
    return {
        type: actions.CREATE_COMPONENT,
        payload: _data,
        callback: _callback
    }
}

export const getComponent = (_data: any, _callback: any) => {
    return {
        type: actions.GET_COMPONENT,
        payload: _data,
        callback: _callback
    }
}

export const setComponent = (_data: any) => {
    return {
        type: actions.SET_COMPONENT,
        payload: _data
    }
}

export const getPopulatedLayout = (_data: any, _callback: any) => {
    return {
        type: actions.GET_POPULATED_LAYOUT,
        payload: _data,
        callback: _callback
    }
}

export const setPopulatedLayout = (_data: any) => {
    return {
        type: actions.SET_POPULATED_LAYOUT,
        payload: _data
    }
}

export const updateComponent = (_data: any, _callback: any) => {
    return {
        type: actions.UPDATE_COMPONENT,
        payload: _data,
        callback: _callback
    }
}

export const updateComponentStatus = (_data: any, _callback: any) => {
    return {
        type: actions.UPDATE_COMPONENT_STATUS,
        payload: _data,
        callback: _callback
    }
}


export const copyComponent = (_data: any, _callback: any) => {
    return {
        type: actions.COPY_COMPONENT,
        payload: _data,
        callback: _callback
    }
}

export const resetLmsState = () => {
    return {
        type: actions.RESET
    }
}

export const getLayoutJsonByLayoutCode = (_data: any, _callback: any) => {
    return {
        type: actions.GET_LAYOUT_JSON_BY_LAYOUT_CODE,
        payload: _data,
        callback: _callback
    }
}

export const setLayoutJsonByLayoutCode = (_data: any) => {
    return {
        type: actions.SET_LAYOUT_JSON_BY_LAYOUT_CODE,
        payload: _data
    }
}

export const setLayoutJsonApiLoader = (_data: any) => {
    return {
        type: actions.SET_LAYOUT_JSON_API_LOADER,
        payload: _data
    }
}

export const deleteComponent = (_data: any, _callback: any) => {
    return {
        type: actions.DELETE_COMPONENT,
        payload: _data,
        callback: _callback
    }
}

export const getPopulatedComponent = (_data: any, _callback: any) => {
    return {
        type: actions.GET_POPULATED_COMPONENT,
        payload: _data,
        callback: _callback
    }
}

export const setPopulatedComponent = (_data: any) => {
    return {
        type: actions.SET_POPULATED_COMPONENT,
        payload: _data
    }
}

export const createElement = (_data: any, _callback: any) => {
    return {
        type: actions.CREATE_ELEMENT,
        payload: _data,
        callback: _callback
    }
}

export const updateElementStatus = (_data: any, _callback: any) => {
    return {
        type: actions.UPDATE_ELEMENT_STATUS,
        payload: _data,
        callback: _callback
    }
}

export const updateElement = (_data: any, _callback: any) => {
    return {
        type: actions.UPDATE_ELEMENT,
        payload: _data,
        callback: _callback
    }
}

export const deleteElement = (_data: any, _callback: any) => {
    return {
        type: actions.DELETE_ELEMENT,
        payload: _data,
        callback: _callback
    }
}

export const clearCache = (_data: any, _callback: any) => {
    return {
        type: actions.CLEAR_CACHE,
        payload: _data,
        callback: _callback
    }
}

export const uploadImage = (_data: any, _callback: any) => {
    return {
        type: actions.UPLOAD_IMAGE,
        payload: _data,
        callback: _callback
    }
}
