import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-hot-toast";
import {
  setAttribute,
  setAttributeLoader,
  setAttributes,
  deleteAttribute,
  setDownloadDumpLoader,
} from "../actionCreators/attributeActionCreator";
import {
  createAttributeService,
  deleteAttributeService,
  updateAttributeService,
  downloadAttributeDumpService,
  getAttributeService,
  getAttributesService,
  getProductCountService,
  getAttributeByIdService,
  bulkAttributeService,
} from "../services/attributeServices";
import {
  CREATE_ATTRIBUTE,
  DELETE_ATTRIBUTE_SCHEMA,
  BULK_UPLOAD_ATTRIBUTE_FILE,
  DOWNLOAD_ATTRIBUTE_DUMP,
  GET_ATTRIBUTE,
  GET_ATTRIBUTES,
  GET_ATTRIBUTE_BY_ID,
  GET_PRODUCT_COUNT,
  UPDATE_ATTRIBUTE,
} from "../actionTypes/attributeActionTypes";

function* _getAttribute(_data: any) {
  try {
    yield put(setAttributeLoader(true));
    const { data } = yield call(getAttributeService, _data.payload);
    yield put(setAttributeLoader(false));
    if (data.status == true) {
      yield put(setAttribute(data.data));
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setAttributeLoader(false));
    console.log("_getAttribute: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getAttributes(_data: any) {
  try {
    yield put(setAttributeLoader(true));
    const { data } = yield call(getAttributesService, _data.payload);
    yield put(setAttributeLoader(false));
    if (data.status == true) {
      yield put(
        setAttributes({
          totalCount: data?.data?.totalCount,

          data: data?.data?.cmsAttributesListingResponses,
        })
      );
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setAttributeLoader(false));
    console.log("_getAttributes: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _createAttribute(_data: any) {
  try {
    yield put(setAttributeLoader(true));
    const { data } = yield call(createAttributeService, _data.payload);
    yield put(setAttributeLoader(false));
    if (data.status == true) {
      _data.callback && _data.callback(true);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setAttributeLoader(false));
    console.log("_createAttribute: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getProductCount(_data: any) {
  try {
    yield put(setAttributeLoader(true));
    const { data } = yield call(getProductCountService, _data.payload);
    yield put(setAttributeLoader(false));
    if (data.status == true) {
      _data.callback && _data.callback(true);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setAttributeLoader(false));
    console.log("_getProductCount: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _downloadAttributeDump(_data: any) {
  try {
    yield put(setDownloadDumpLoader(true));
    const { data } = yield call(downloadAttributeDumpService, _data.payload);
    yield put(setDownloadDumpLoader(false));
    if (data.status == true) {
      _data.callback && _data.callback(data);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setDownloadDumpLoader(false));
    console.log("_downloadAttributeDump: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getAttributeById(_data: any) {
  try {
    yield put(setAttributeLoader(true));
    const { data } = yield call(getAttributeByIdService, _data.payload);
    yield put(setAttributeLoader(false));
    if (data.status == true) {
      yield put(setAttribute(data.data));
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setAttributeLoader(false));
    console.log("_getAttributeById: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _deleteAttributeSchema(_data: any) {
  try {
    const { data } = yield call(deleteAttributeService, _data.payload);
    if (data.status == true) {
      yield put(deleteAttribute(_data.payload));
      _data.callback && _data.callback(true);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    console.log("_deleteAttributeSchema: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _updateAttribute(_data: any) {
  try {
    yield put(setAttributeLoader(true));
    const { data } = yield call(updateAttributeService, _data.payload);
    yield put(setAttributeLoader(false));
    if (data.status == true) {
      yield put(setAttribute(data.data));
      _data.callback && _data.callback(true);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setAttributeLoader(false));
    console.log("_updateAttribute: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _uploadAttributeFile(_data: any) {
  try {
    yield put(setAttributeLoader(true));
    const { data } = yield call(bulkAttributeService, _data.payload);
    yield put(setAttributeLoader(false));
    if (data.status == true) {
      yield put(setAttribute(data.data));
      _data.callback && _data.callback(data);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setAttributeLoader(false));
    console.log("_uploadAttributeFile: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

export default function* attributeSaga() {
  yield all([
    takeLatest(GET_ATTRIBUTES, _getAttributes),
    takeLatest(GET_ATTRIBUTE, _getAttribute),
    takeLatest(CREATE_ATTRIBUTE, _createAttribute),
    takeLatest(UPDATE_ATTRIBUTE, _updateAttribute),
    takeLatest(DELETE_ATTRIBUTE_SCHEMA, _deleteAttributeSchema),
    takeLatest(GET_ATTRIBUTE_BY_ID, _getAttributeById),
    takeLatest(DOWNLOAD_ATTRIBUTE_DUMP, _downloadAttributeDump),
    takeLatest(GET_PRODUCT_COUNT, _getProductCount),
    takeLatest(BULK_UPLOAD_ATTRIBUTE_FILE, _uploadAttributeFile),
  ]);
}
