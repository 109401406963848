import { call, put, takeLatest, all } from "redux-saga/effects";
import { toast } from "react-hot-toast";
import {createPageMappingService,updatePageMappingService} from  "../services/pageMappingServices";
import { CREATE_PAGEMAPPING,GET_PAGEMAPPING_BY_ID,GET_PAGEMAPPING,UPDATE_PAGEMAPPING,DELETE_PAGEMAPPING_SCHEMA, GET_PAGEMAPPINGS} from "../actionTypes/pageMappingActionTypes";
import {  deletePageMapping, setPageMapping, setPageMappingLoader, setPageMappings } from "../actionCreators/pageMappingActionCreator";
import {getPageMappingsService,getPageMappingService,getPageMappingByIdService,deletePageMappingService} from "../services/pageMappingServices";




function* _getPageMapping(_data: any) {
    try {
        yield put(setPageMappingLoader(true));
        const { data } = yield call(getPageMappingService, _data.payload);
        yield put(setPageMappingLoader(false));
        if (data.status == true) {
            yield put(setPageMapping(data.data))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setPageMappingLoader(false));
        console.log("_getPageMapping: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getPageMappings(_data: any) {
    try {
        yield put( setPageMappingLoader(true));
        const { data } = yield call(getPageMappingsService, _data.payload);
        yield put( setPageMappingLoader(false));

        if (data.status === true ) {
            yield put(setPageMappings({
                totalCount: data?.data?.totalCount,
                data: data?.data?.requestMappingResponse  
            }))
          
        }
        else {  
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put( setPageMappingLoader(false));
        console.log("_getPageMappings: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _createPageMapping(_data: any) {
    try {
        yield put(setPageMappingLoader(true));
        const { data } = yield call(createPageMappingService, _data.payload);
        yield put(setPageMappingLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(true)
        }
        else {
            toast.error(data.statusDescription)
        }
    }
    catch (err: any) {
        yield put(setPageMappingLoader(false));
        console.log("_createPageMapping: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}


function*_updatePageMapping(_data: any) {
    try {
        yield put(setPageMappingLoader(true));
        const { data } = yield call(updatePageMappingService, _data.payload);
        yield put(setPageMappingLoader(false));
        if (data.status == true) {
            yield put(setPageMapping(data.data))
            _data.callback && _data.callback(true)
        }
        else {
            toast.error(data.statusDescription)
        }
    }
    catch (err: any) {
        yield put(setPageMappingLoader(false));
        console.log("_updatePageMapping: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}


function* _getPageMappingById(_data: any) {
    try {
        yield put(setPageMappingLoader(true));
        const { data } = yield call(getPageMappingByIdService, _data.payload);
        yield put(setPageMappingLoader(false));
        if (data.status == true) {
            yield put(setPageMapping(data.data))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setPageMappingLoader(false));
        console.log("_getPageMappingById: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _deletePageMapping(_data: any) {
    try {
        const { data } = yield call(deletePageMappingService, _data.payload);
        if (data.status == true) {
            yield put(deletePageMapping( _data.payload))
            _data.callback && _data.callback(true)
        }
        else {
            toast.error(data.statusDescription)
        }
    }
    catch (err: any) {
        console.log("_deletePageMapping: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}


export default function* pageMappingSaga() {
    yield all([
        takeLatest(GET_PAGEMAPPINGS, _getPageMappings),
        takeLatest(GET_PAGEMAPPING, _getPageMapping),
        takeLatest(GET_PAGEMAPPING_BY_ID, _getPageMappingById),
        takeLatest(CREATE_PAGEMAPPING, _createPageMapping),
        takeLatest(UPDATE_PAGEMAPPING, _updatePageMapping),
        takeLatest(DELETE_PAGEMAPPING_SCHEMA, _deletePageMapping)
    
    ] )
}