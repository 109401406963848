import * as actions from "../actionTypes/plaFeedActionTypes";

export const setPlaLoader = (_data: any) => {
  return {
    type: actions.SET_PLA_LOADER,
    payload: _data,
  };
};

export const getPlaFeeds = (_data: any, _callback: any) => {
  return {
    type: actions.GET_PLA_FEEDS,
    payload: _data,
    callback: _callback,
  };
};

export const setPlaFeeds = (_data: any) => {
  return {
    type: actions.SET_PLA_FEEDS,
    payload: _data,
  };
};

export const getPlaFeed = (_data: any, _callback: any) => {
  return {
    type: actions.GET_PLA_FEED,
    payload: _data,
    callback: _callback,
  };
};

export const setPlaFeed = (_data: any) => {
  return {
    type: actions.SET_PLA_FEED,
    payload: _data,
  };
};

export const createPlaFeed = (_data: any, _callback: any) => {
  return {
    type: actions.CREATE_PLA_FEED,
    payload: _data,
    callback: _callback,
  };
};

export const updatePlaFeed = (_data: any, _callback: any) => {
  return {
    type: actions.UPDATE_PLA_FEED,
    payload: _data,
    callback: _callback,
  };
};

export const downloadPlaDump = (_data: any, _callback: any) => {
  return {
    type: actions.DOWNLOAD_PLA_DUMP,
    payload: _data,
    callback: _callback,
  };
};

export const setDownloadDumpLoader = (_data: any) => {
  return {
    type: actions.SET_DOWNLOAD_DUMP_LOADER,
    payload: _data,
  };
};

export const bulkPlaFile = (_data: any, _callback: any) => {
  return {
    type: actions.BULK_PLA_FILE,
    payload: _data,
    callback: _callback,
  };
};

export const getPlaById = (_data: any, _callback: any) => {
  return {
    type: actions.GET_PLA_BY_ID,
    payload: _data,
    callback: _callback,
  };
};
