const AiPoweredHeader = (props: any) => {
    const { aiPoweredType, setAiPoweredType } = props;
    return (<>
        <div className="campaign-header bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
            <div className="flex items-center justify-between w-full">
                <p className="text-xl font-bold text-[#43425D] w-1/5">AI Powered</p>
                <select onChange={(e) => setAiPoweredType(e.target.value)} className="bg-white text-xs border  border-gray-300 h-9 px-3 rounded">
                    <option value={""}>Select</option>
                    {
                        props.aiPoweredTypes.map((d: string) =>
                            <option value={d}>{d}</option>
                        )
                    }
                </select>
            </div>
        </div>
    </>)
}
export default AiPoweredHeader;
