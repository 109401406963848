import React from "react"
import BlocksSubHeader from "../../../components/Blocks/BlocksSubHeader";
import CategoryBlock from "../../../components/CategoryBlock/CategoryBlock";
import Header from "../../../components/Header/Header";
import SideNavbar from "../../../components/Navbar/SideNavbar";

const CategoryBlocks = () => {

    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <BlocksSubHeader />
                        <CategoryBlock />
                    </div>
                </div>
            </div>
        </>
    )
}
export default CategoryBlocks;