export const GET_CAMPAIGNS = "GET_CAMPAIGNS"
export const SET_CAMPAIGNS = "SET_CAMPAIGNS"
export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN"
export const GET_CAMPAIGN = "GET_CAMPAIGN"
export const SET_CAMPAIGN = "SET_CAMPAIGN"
export const GET_LOOKUPS = "GET_LOOKUPS"
export const SET_LOOKUPS = "SET_LOOKUPS"
export const GET_BRANDS = "GET_BRANDS"
export const SET_BRANDS = "SET_BRANDS"
export const GET_CATEGORIES = "GET_CATEGORIES"
export const SET_CATEGORIES = "SET_CATEGORIES"
export const GET_BRANDS_BY_CATEGORY = "GET_BRANDS_BY_CATEGORY"
export const SET_BRANDS_BY_CATEGORY = "SET_BRANDS_BY_CATEGORY"
export const SET_ADSENSE_LOADER = "SET_ADSENSE_LOADER"
export const RESET = "RESET"
export const UPDATE_CAMPAIGN_STATUS = "UPDATE_CAMPAIGN_STATUS"
export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN"