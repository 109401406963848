export const GET_ARTICLE_CATEGORY_ID = "GET_ARTICLE_CATEGORY_ID";
export const CREATE_ARTICLE = "CREATE_ARTICLE"
export const SET_ARTICLE_LISTING = "SET_ARTICLE_LISTING"
export const GET_ARTICLE_LISTING = "GET_ARTICLE_LISTING"
export const GET_ARTICLE_LISTING_BY_ID = "GET_ARTICLE_LISTING_BY_ID"
export const SET_ARTICLE_LISTING_BY_ID = "SET_ARTICLE_LISTING_BY_ID"
export const SET_ARTICLE = "SET_ARTICLE"
export const GET_ARTICLE = "GET_ARTICLE"
export const PUBLISH_ARTICLE = "PUBLISH_ARTICLE"
export const SET_ARTICLE_LOADER = "SET_ARTICLE_LOADER"
export const UPDATE_ARTICLE = "UPDATE_ARTICLE"
export const DELETE_ARTICLE_LIST = "DELETE_ARTICLE_LIST"
export const SET_DELETE_ARTICLE_LIST = "SET_DELETE_ARTICLE_LIST"
