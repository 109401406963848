import { SET_AI_LOADER, SET_TRACK_PRODUCT_DESCRIPTION } from "../actionTypes/aiPoweredActionTypes";

const initialState: any = {
    isLoading: false,
    productDescriptionStatus: {},
}

const aiPoweredReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_AI_LOADER:
            return {
                ...state,
                isLoading: action.payload
            }
        case SET_TRACK_PRODUCT_DESCRIPTION:
            return {
                ...state,
                productDescriptionStatus: action.payload
            }
        default:
            return state;
    }
}

export default aiPoweredReducer;