import React from 'react';
import Section from './Section';

const PageBasedCampaign = (props: any) => {
    const { sections } = props
    return (
        <>
            {/*SECTIONS */}
            {sections.map((d: any, index: number) =>
                <Section key={index} currentSection={d} {...props} />
            )}
        </>
    )
}

export default PageBasedCampaign