import * as actions from "../actionTypes/blocksActionTypes";

export const setBlocksLoader = (_data: any) => {
  return {
    type: actions.SET_BLOCKS_LOADER,
    payload: _data,
  };
};

export const getBrandBlocks = (_data: any, _callback: any) => {
  return {
    type: actions.GET_BRAND_BLOCKS,
    payload: _data,
    callback: _callback,
  };
};

export const setBrandBlocks = (_data: any) => {
  return {
    type: actions.SET_BRAND_BLOCKS,
    payload: _data,
  };
};

export const getBrandBlock = (_data: any, _callback: any) => {
  return {
    type: actions.GET_BRAND_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const deleteBrandBlock = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_BRAND_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const deleteBrandBlockElement = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_BRAND_BLOCK_ELEMENT,
    payload: _data,
    callback: _callback,
  };
};

export const setDeleteBrandBlock = (_data: any) => {
  return {
    type: actions.SET_DELETE_BRAND_BLOCK,
    payload: _data,
  };
};

export const setBrandBlock = (_data: any) => {
  return {
    type: actions.SET_BRAND_BLOCK,
    payload: _data,
  };
};

export const createBrandBlock = (_data: any, _callback: any) => {
  return {
    type: actions.CREATE_BRAND_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const updateBrandBlock = (_data: any, _callback: any) => {
  return {
    type: actions.UPDATE_BRAND_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const getCategoryBlocks = (_data: any, _callback: any) => {
  return {
    type: actions.GET_CATEGORY_BLOCKS,
    payload: _data,
    callback: _callback,
  };
};

export const setCategoryBlocks = (_data: any) => {
  return {
    type: actions.SET_CATEGORY_BLOCKS,
    payload: _data,
  };
};

export const getCategoryBlock = (_data: any, _callback: any) => {
  return {
    type: actions.GET_CATEGORY_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const setCategoryBlock = (_data: any) => {
  return {
    type: actions.SET_CATEGORY_BLOCK,
    payload: _data,
  };
};

export const deleteCategoryBlock = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_CATEGORY_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const setDeleteCategoryBlock = (_data: any) => {
  return {
    type: actions.SET_DELETE_CATEGORY_BLOCK,
    payload: _data,
  };
};

export const createCategoryBlock = (_data: any, _callback: any) => {
  return {
    type: actions.CREATE_CATEGORY_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const updateCategoryBlock = (_data: any, _callback: any) => {
  return {
    type: actions.UPDATE_CATEGORY_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const getImageBlocks = (_data: any, _callback: any) => {
  return {
    type: actions.GET_IMAGE_BLOCKS,
    payload: _data,
    callback: _callback
  }
}
export const getImageBlock = (_data: any, _callback: any) => {
  return {
    type: actions.GET_IMAGE_BLOCK,
    payload: _data,
    callback: _callback
  }
}
export const setImageBlocks = (_data: any) => {
  return {
    type: actions.SET_IMAGE_BLOCKS,
    payload: _data,

  }
}
export const setImageBlock = (_data: any) => {
  return {
    type: actions.SET_IMAGE_BLOCK,
    payload: _data,
  }
}

export const createImageBlock = (_data: any, _callback: any) => {
  return {
    type: actions.CREATE_IMAGE_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const updateImageBlock = (_data: any, _callback: any) => {
  return {
    type: actions.UPDATE_IMAGE_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const deleteImageBlock = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_IMAGE_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const deleteImageBlockElement = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_IMAGE_BLOCK_ELEMENT,
    payload: _data,
    callback: _callback,
  };
};

export const setDeleteImageBlock = (_data: any) => {
  return {
    type: actions.SET_DELETE_IMAGE_BLOCK,
    payload: _data,
  };
};
export const getProductBlocks = (_data: any, _callback: any) => {
  return {
    type: actions.GET_PRODUCT_BLOCKS,
    payload: _data,
    callback: _callback,
  };
};

export const setProductBlocks = (_data: any) => {
  return {
    type: actions.SET_PRODUCT_BLOCKS,
    payload: _data,
  };
};

export const createProductBlock = (_data: any, _callback: any) => {
  return {
    type: actions.CREATE_PRODUCT_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const getProductBlock = (_data: any, _callback: any) => {
  return {
    type: actions.GET_PRODUCT_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const setProductBlock = (_data: any) => {
  return {
    type: actions.SET_PRODUCT_BLOCK,
    payload: _data,
  };
};

export const updateProductBlock = (_data: any, _callback: any) => {
  return {
    type: actions.UPDATE_PRODUCT_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const deleteProductBlock = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_PRODUCT_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const deleteProductBlockElement = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_PRODUCT_BLOCK_ELEMENT,
    payload: _data,
    callback: _callback,
  };
};
export const setDeleteProductBlock = (_data: any) => {
  return {
    type: actions.SET_DELETE_PRODUCT_BLOCK,
    payload: _data,
  };
};

export const getStaticHtmlBlocks = (_data: any, _callback: any) => {
  return {
    type: actions.GET_STATIC_HTML_BLOCKS,
    payload: _data,
    callback: _callback
  }
}

export const setStaticHtmlBlocks = (_data: any) => {
  return {
    type: actions.SET_STATIC_HTML_BLOCKS,
    payload: _data,
  }
}

export const getStaticHtmlBlock = (_data: any, _callback: any) => {
  return {
    type: actions.GET_STATIC_HTML_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const setStaticHtmlBlock = (_data: any) => {
  return {
    type: actions.SET_STATIC_HTML_BLOCK,
    payload: _data,
  }
}

export const createStaticHtmlBlock = (_data: any, _callback: any) => {
  return {
    type: actions.CREATE_STATIC_HTML_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const updateStaticHtmlBlock = (_data: any, _callback: any) => {
  return {
    type: actions.UPDATE_STATIC_HTML_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const deleteStaticHtmlBlock = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_STATIC_HTML_BLOCK,
    payload: _data,
    callback: _callback,
  };
};

export const setDeleteStaticHtmlBlock = (_data: any) => {
  return {
    type: actions.SET_DELETE_STATIC_HTML_BLOCK,
    payload: _data,
  };
};

export const setGeneralBlocks = (_data: any) => {
  return {
    type: actions.SET_GENERAL_BLOCKS,
    payload: _data,
  };
};

export const getGeneralBlocks = (_data: any, _callback: any) => {
  return {
    type: actions.GET_GENERAL_BLOCKS,
    payload: _data,
    callback: _callback
  };
}

export const getGeneralBlock = (_data: any, _callback: any) => {
  return {
    type: actions.GET_GENERAL_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const setGeneralBlock = (_data: any) => {
  return {
    type: actions.SET_GENERAL_BLOCK,
    payload: _data,
  }
}

export const createGeneralBlock = (_data: any, _callback: any) => {
  return {
    type: actions.CREATE_GENERAL_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const updateGeneralBlock = (_data: any, _callback: any) => {
  return {
    type: actions.UPDATE_GENERAL_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const deleteGeneralBlock = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_GENERAL_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const setDeleteGenerelBlock = (_data: any) => {
  return {
    type: actions.SET_DELETE_GENERAL_BLOCK,
    payload: _data,
  }
}

export const deleteCategoryBlockElement = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_CATEGORY_BLOCK_ELEMENT,
    payload: _data,
    callback: _callback,
  };
};


export const setTemplateBlocks = (_data: any) => {
  return {
    type: actions.SET_TEMPLATE_BLOCKS,
    payload: _data,
  };
};

export const getTemplateBlocks = (_data: any, _callback: any) => {
  return {
    type: actions.GET_TEMPLATE_BLOCKS,
    payload: _data,
    callback: _callback
  };
}

export const getTemplateBlock = (_data: any, _callback: any) => {
  return {
    type: actions.GET_TEMPLATE_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const setTemplateBlock = (_data: any) => {
  return {
    type: actions.SET_TEMPLATE_BLOCK,
    payload: _data,
  }
}

export const createTemplateBlock = (_data: any, _callback: any) => {
  return {
    type: actions.CREATE_TEMPLATE_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const updateTemplateBlock = (_data: any, _callback: any) => {
  return {
    type: actions.UPDATE_TEMPLATE_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const deleteTemplateBlock = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_TEMPLATE_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const setDeleteTemplateBlock = (_data: any) => {
  return {
    type: actions.SET_DELETE_TEMPLATE_BLOCK,
    payload: _data,
  }
}


//BLOCK_TYPE
export const getBlockTypeBlock = (_data: any, _callback: any) => {
  return {
    type: actions.GET_BLOCK_TYPE_BLOCK,
    payload: _data,
    callback: _callback
  };
}

export const setBlockTypeBlock = (_data: any) => {
  return {
    type: actions.SET_BLOCK_TYPE_BLOCK,
    payload: _data,
  }
}

export const getBlockTypeBlocks = (_data: any, _callback: any) => {
  return {
    type: actions.GET_BLOCK_TYPES_BLOCKS,
    payload: _data,
    callback: _callback
  }
}

export const setBlockTypeBlocks = (_data: any) => {
  return {
    type: actions.SET_BLOCK_TYPE_BLOCKS,
    payload: _data,
  };
};

export const createBlockTypeBlock = (_data: any, _callback: any) => {
  return {
    type: actions.CREATE_BLOCK_TYPE_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const updateBlockTypeBlock = (_data: any, _callback: any) => {
  return {
    type: actions.UPDATE_BLOCK_TYPE_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const deleteBlockTypeBlock = (_data: any, _callback: any) => {
  return {
    type: actions.DELETE_BLOCK_TYPE_BLOCK,
    payload: _data,
    callback: _callback
  }
}

export const setDeleteBlockTypeBlock = (_data: any) => {
  return {
    type: actions.SET_DELETE_BLOCK_TYPE_BLOCK,
    payload: _data,
  }
}


//BLOCK_TYPE
export const getBlockLayout = (_data: any, _callback: any) => {
  return {
    type: actions.GET_BLOCK_LAYOUT,
    payload: _data,
    callback: _callback
  };
}

export const setBlockLayout = (_data: any) => {
  return {
    type: actions.SET_BLOCK_LAYOUT,
    payload: _data,
  }
}

export const getBlockLayouts = (_data: any, _callback: any) => {
  return {
    type: actions.GET_BLOCK_LAYOUTS,
    payload: _data,
    callback: _callback
  }
}

export const setBlockLayouts = (_data: any) => {
  return {
    type: actions.SET_BLOCK_LAYOUTS,
    payload: _data,
  };
};

export const createBlockLayout = (_data: any, _callback: any) => {
  return {
    type: actions.CREATE_BLOCK_LAYOUT,
    payload: _data,
    callback: _callback
  }
}

export const updateBlockLayout = (_data: any, _callback: any) => {
  return {
    type: actions.UPDATE_BLOCK_LAYOUT,
    payload: _data,
    callback: _callback
  }
}
