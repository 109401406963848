import React, { useEffect, useState } from "react"
import { RiDeleteBin4Fill } from "react-icons/ri"

const FormAttribute = (props: any) => {
    const { attribute, removeAttributeHandler, attributes, setAttributes } = props
    const [attributeKey, setAttributeKey] = useState("")
    const [attributeValue, setAttributeValue] = useState("")


    useEffect(() => {
        if (attribute) {
            if (attribute.attributeKey) {
                setAttributeKey(attribute.attributeKey)
            }
            if (attribute.attributeValue) {
                setAttributeValue(attribute.attributeValue)
            }
        }
    }, [props])

    useEffect(() => {
        const currentAttributeIndex = attributes.findIndex((d: any) => d.id === attribute.id)
        if (currentAttributeIndex !== -1) {
            attributes[currentAttributeIndex]["attributeValue"] = attributeValue
            setAttributes([...attributes])
        }
    }, [attributeValue])

    return (<>
        <div className="Component-creation-inner bg-white rounded  pb-[20px] mx-[15px]">
            <div className="form-field-x form-field">
                <label>{attributeKey}</label>
                <div className="flex items-center">
                    <input className="input-filled w-full" onChange={(e) => setAttributeValue(e.target.value)} value={attributeValue} type="text" />
                    <div onClick={() => removeAttributeHandler(attribute.id)} className="action-field cursor-pointer flex items-center justify-around ml-2.5"><RiDeleteBin4Fill /></div>
                </div>
            </div>
        </div>
    </>)
}
export default FormAttribute