import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import toast from "react-hot-toast";
import { formatTimestamp } from "../../../utils/helper";
import { deleteImageBlock } from "../../../core/actionCreators/blocksActionCreator";
import DeleteBin5FillIcon from "remixicon-react/DeleteBin5FillIcon";
import Edit2FillIcon from "remixicon-react/Edit2FillIcon";


const ImageBlockListItem = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { user } = useSelector((s: any) => s.authStore);
  const { data } = props;

  const updateImageBlockNavigateHandler = (id: any) => {
    navigate(`${id}`);
  };

  const deleteImageBlockNavigateHandler = (id: any) => {
    const isConfirmed = window.confirm('Are you sure want to delete?')
    if (isConfirmed) {
      dispatch(deleteImageBlock({ id: id, userId: user.id || 33 }, (resp: any) => {
        if (!resp.status) {
          toast.error(resp.statusDescription)
          return
        }
        toast.success("Deleted successfully")
        window.location.reload()
      }))
    }
    return
  };

  return (
    <>
      {data.map((data: any) => (
        <tr className="[&:nth-child(odd)]:bg-[#f7f7f7]">
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] ">
            {data.id}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] ">
            {data.label}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] ">
            {data.name}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {data.status ? "True" : "False"}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] ">
            {formatTimestamp(data.createdAt)}
          </td>
          <td
            onClick={() => updateImageBlockNavigateHandler(data.id)}
            className="px-4 py-3 text-left text-xs text-[#337ab7] font-semibold cursor-pointer"
          >
            <Edit2FillIcon size={14} />
          </td>
          <td
            onClick={() => deleteImageBlockNavigateHandler(data.id)}
            className="px-4 py-3 text-left text-xs text-[#337ab7] font-semibold cursor-pointer"
          >
            <DeleteBin5FillIcon size={14} />
          </td>
        </tr>
      ))}
    </>
  );
};
export default ImageBlockListItem;
