import * as actions from '../actionTypes/authActionTypes';

export const setUser = (_data: any) => {
    return {
        type: actions.SET_USER,
        payload: _data
    }
}

export const setUserFaiure = (_err: any) => {
    return {
        type: actions.SET_USER_FAILURE,
        payload: _err
    }
}

export const setLoader = (_data: Boolean) => {
    return {
        type: actions.SET_USER_LOADER,
        payload: _data
    }
}

export const userLogin = (_data: any) => {
    return {
        type: actions.USER_LOGIN,
        payload: _data
    }
}

export const loginWithMicrosoft = (_data: any, _cb: any) => {
    return {
        type: actions.LOGIN_WITH_MICROSOFT,
        payload: _data,
        callback: _cb
    }
}

export const loginWithMicrosoftFallback = (_err: any) => {
    return {
        type: actions.LOGIN_WITH_MICROSOFT_FALLBACK,
        payload: _err
    }
}

export const resetAuthState = () => {
    return {
        type: actions.RESET
    }
}