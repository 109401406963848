import AttributeList from "../../components/AttributeListing/AttributeListData";
import AttributeListingSubHeader from "../../components/AttributeListing/AttributeListingSubHeader";
import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";

const AttributeListings = () => {
  return (
    <>
      <div className="flex mainWrapper w-full">
        <div className="sidenav-wrapper">
          <SideNavbar />
        </div>
        <div className="right-container">
          <div className="header-container">
            <Header />
          </div>
          <div className="body-container">
            <AttributeList />
          </div>
        </div>
      </div>
    </>
  );
};
export default AttributeListings;
