import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deletePageMappingSchema } from "../../../core/actionCreators/pageMappingActionCreator";
import toast from "react-hot-toast";

const PageMappingListItem = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data } = props;

  const updateNavigateHandler = (id: any) => {
    navigate(`/pageMapping/${id}`);
  };

  const deletePageMappingHandler = (id: any) => {
    const shouldDelete = window.confirm("Are you sure you want to delete?");
    if (shouldDelete) {
      dispatch(
        deletePageMappingSchema(
          {
            id: id,
          },
          () => {
            toast.success("Deleted successfully");
          }
        )
      );
    }
  };

  return (
    <>
      {data.map((_d: any) => (
        <tr className="[&:nth-child(odd)]:bg-[#f7f7f7]">
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
            {_d.id}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[30%]">
            {_d.requestParam}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[25%]">
            {_d.layoutCodes}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
            {_d.isWebView ? "True" : "False"}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#337ab7] w-[10%] font-semibold cursor-pointer leading-5">
            <span onClick={() => updateNavigateHandler(_d.id)}>Update</span>
            <br></br>

            <span onClick={() => deletePageMappingHandler(_d.id)}>Delete</span>
          </td>
        </tr>
      ))}
    </>
  );
};
export default PageMappingListItem;
