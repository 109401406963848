import {
    SET_FAQ_LOADER, SET_FAQ, SET_FAQS,
} from '../actionTypes/faqActionTypes';

const initialState: any = {
    faqs: {},
    faq: {},
    isLoading: false
}

const faqReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_FAQ_LOADER:
            return {
                ...state,
                isLoading: action.payload
            }
        case SET_FAQ:
            return {
                ...state,
                faq: action.payload
            }
        case SET_FAQS:
            return {
                ...state,
                faqs: action.payload
            }
        default:
            return state;
    }
}

export default faqReducer