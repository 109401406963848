import { useEffect, useState } from "react";
import SearchLineIcon from "remixicon-react/SearchLineIcon";

const LeftSideContainer = (props: any) => {
  const { getTagId, productTags } = props;

  const getTag = (tagId: string) => {
    getTagId(tagId);
  }

  return (
    <>
      <div className="w-[30%] border border-[#E8E8E8] p-4 mr-2.5">
        <p className="text-base text-[#3c3c3c] pb-2.5">
          Product Tag Names ({productTags?.length})
        </p>
        <div className="relative w-full">
          <input
            type="search"
            placeholder="Search Here"
            className="w-full border border-gray-300 h-9 outline-none pl-2.5 text-xs"
          />
          <div className="absolute inset-y-0 right-0 flex items-center justify-center w-9 bg-blue-500 rounded-tr-sm rounded-br-sm">
            <SearchLineIcon size={16} className="text-white" />
          </div>
        </div>
        <ul className="h-[270px] border border-gray-300 mt-2.5">
          {productTags?.map((item: any, index: any) => (
            <li
              key={index}
              className="px-2 py-3 text-xs text-[#454545] bg-[#F8FAFF] hover:bg-[#F8FAFF] active:bg-[#F8FAFF]"
              onClick={() => getTag(item.tagId)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default LeftSideContainer;
