import { useNavigate } from "react-router-dom"
import Loader from "../Loader/Loader";
import { useEffect, useState, useRef } from "react";
import StaticHtmlListItem from "./StaticHtmlListItem";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { GET_STATIC_HTML_BLOCK_DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import CommonPagination from "../CommonPagination/CommonPagination";
import { getStaticHtmlBlocks } from "../../../core/actionCreators/blocksActionCreator";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";

const StaticHtmlBlock = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, staticHtmlBlocks } = useSelector((s: any) => s.blocksStore);
    const navigateToCreateStaticHtmlBlockHandler = () => {
        navigate('/blocks/staticHtml/create');
    }

    const [searchCriteria, setSearchCriteria] = useState({
        id: "",
        label: "",
        name: "",
        pageNumber: 0,
        pageSize: GET_STATIC_HTML_BLOCK_DEFAULT_PAGE_SIZE,
    } as any);

    const previousSearchCriteriaRef = useRef(searchCriteria);


    useEffect(() => {
        const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
        const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
        const previousCriteria = previousSearchCriteriaRef.current;
        const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

        if (searchFilterChanged) {
            const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
            for (let key in parsedSearchCriteria) {
                if (["id", "label", "name"].includes(key)) {
                    parsedSearchCriteria.pageNumber = 0
                    break
                }
            }
            dispatch(getStaticHtmlBlocks(queryString.stringify(parsedSearchCriteria), null));
            previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
        }
    }, [searchCriteria]);


    const onPageChange = (pageNumber: any) => {
        setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
    }

    return (
        <>
            <div className="flex justify-between items-center pt-4 px-4">
                <p className="text-base text-[#343434]">Static Htmls</p>
                <button onClick={navigateToCreateStaticHtmlBlockHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">Create Static Html</button>
            </div>
            <div className="mt-4 campaign-list bg-white mb-[54px]">
                <table className="w-full" >
                    <thead className="bg-[#343434]">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[8%]">ID</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]"> Label</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[18%]">Name</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[40%]">Static Html</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[6%]">Status</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[4%]">Update</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[4%]">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-4 py-3 text-left text-xs text-white w-[8%]">
                                <input className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                    value={searchCriteria.id}
                                    onChange={(e) => setSearchCriteria({ ...searchCriteria, id: e.target.value })}
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[20%]">
                                <input className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text" value={searchCriteria.label}
                                    onChange={(e) =>
                                        setSearchCriteria({
                                            ...searchCriteria,
                                            label: e.target.value,
                                        })
                                    }
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[18%]">
                                <input className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                    value={searchCriteria.name}
                                    onChange={(e) => setSearchCriteria({ ...searchCriteria, name: e.target.value })}
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[40%]">
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[6%]">
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[8%]">
                            </td>
                        </tr>
                        {isLoading ? (<Loader />) : (<StaticHtmlListItem data={staticHtmlBlocks?.data || []} />
                        )}
                    </tbody>
                </table>
            </div>

            {staticHtmlBlocks?.data && (<CommonPagination data={staticHtmlBlocks} PAGE_SIZE={GET_STATIC_HTML_BLOCK_DEFAULT_PAGE_SIZE} onPageChange={onPageChange}
            />
            )}
        </>
    )
}
export default StaticHtmlBlock