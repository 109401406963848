import React from 'react';
import FileUploadFillIcon from "remixicon-react/FileUploadFillIcon";
import Section from './Section';
import { apis } from '../../../core/services/params';

const MSNBasedCampaign = (props: any) => {
    const { campaign, setcampaign, sections, pageMode } = props
    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];
        campaign.file = file
        setcampaign({ ...campaign })
        const reader = new FileReader();
        reader.onload = (e: any) => {
            const csvData = e.target.result;
            const rows = csvData.split('\n');
            const selectedColumnIndex = 0;
            let dataArray = rows.map((row: any) => {
                const columns = row.split(',');
                return columns[selectedColumnIndex];
            });
            //sanitized data to array
            dataArray = dataArray.map((d: string) => d.trim().toUpperCase())
            dataArray = dataArray.filter((d: string) => d.startsWith('MSN') && d.length > 8);
            campaign.msns = dataArray
            setcampaign({ ...campaign })
        };
        reader.readAsText(file);
    }

    const handleFileDownload = (fileUrl: string) => {
        fetch(apis.DOWNLOAD_FILE + fileUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link: any = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'msns.csv');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            });
    }

    return (
        <>
            {/* <div className="mx-5 my-4">
                <input type="checkbox" />
                <span className="text-xs leading-3 font-semibold text-[#303030] ml-2">Enable PDP Banner</span>
            </div> */}
            <div className="mx-5 mb-2.5 bg-white p-5 rounded border border-[#EAEAEA]">
                <div className="bg-white p-5 rounded border border-[#EAEAEA]">
                    {/* <div className="flex items-center justify-between mb-2.5">
                        <p className="text-xs font-semibold text-[#3B86FF]">View File Format</p>
                    </div> */}
                    <p className="text-xs font-semibold text-[#303030] mb-2.5">Bulk Upload MSN ID</p>
                    <div className="flex w-full justify-between mb-5 last:mb-0">
                        <div className="flex items-center w-[90%]">
                            <div className="w-full">
                                <div className="relative">
                                    <input onChange={handleFileUpload} type="file" accept=".csv" className="w-full rounded-sm border border-gray-300 outline-none text-xs pl-7 px-2.5 py-1.5 text-[#303030] bg-[#E5EEFC]" />
                                    <FileUploadFillIcon size={16} className="absolute left-2.5 top-1.5" />
                                    {["VIEW", "EDIT"].includes(pageMode) && campaign.file && < button onClick={() => handleFileDownload(campaign.file)} className="text-[10px] text-[#3c3c3c]">Previous File</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*SECTIONS */}
            {sections.map((d: any, index: number) =>
                <Section key={index} currentSection={d} {...props} />
            )}
        </>
    )
}

export default MSNBasedCampaign