import toast from "react-hot-toast";
import { put, takeLatest, all, call, takeEvery } from "redux-saga/effects";

import {
    setServiceabilityLoader,
    setServiceabilityList
} from "../actionCreators/servicebilityActionCreators";
import {
    GET_SERVICEABILITY_LIST,
    UPLOAD_SERVICEABILITY_FILE
} from "../actionTypes/serviceabilityActionType";
import {
    getServiceabilityService, uploadServiceabilityFileService,
} from "../services/serviceabilityService";

function* _getServiceabilityList(_data: any) {
    try {
        yield put(setServiceabilityLoader(true));
        const { data } = yield call(getServiceabilityService, _data.payload);
        yield put(setServiceabilityList(false));
        if (data.status == true) {
            yield put(setServiceabilityList(data.data))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setServiceabilityList(false));
        console.log("_getServiceabilityList: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _uploadServiceabilityFile(_data: any) {
    try {
        yield put(setServiceabilityLoader(true));
        const { data } = yield call(uploadServiceabilityFileService, _data.payload);
        yield put(setServiceabilityLoader(false));
        if (data.status == true) {
            yield put(setServiceabilityList(data.data))
            _data.callback && _data.callback(data)
        }
        else {
            toast.error(data.statusDescription)
        }
    }
    catch (err: any) {
        yield put(setServiceabilityLoader(false));
        console.log("_uploadServiceabilityFile: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

export default function* serviceabilitySaga() {
    yield all([
        takeLatest(GET_SERVICEABILITY_LIST, _getServiceabilityList),
        takeLatest(UPLOAD_SERVICEABILITY_FILE, _uploadServiceabilityFile),

    ])
}