import * as actions from '../actionTypes/commonActionTypes';

export const blockCustomer = (_data: any, _callback: any) => {
    return {
        type: actions.BLOCK_CUSTOMER,
        payload: _data,
        callback: _callback
    }
}

export const setCommonLoader = (_data: any) => {
    return {
        type: actions.SET_COMMON_LOADER,
        payload: _data
    }
}
