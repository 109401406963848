import { call, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-hot-toast";
import { setAiLoader, setTrackProductDescription } from "../actionCreators/aiPoweredActionCreator";
import { uploadProductDescriptionService, getProductDescriptionService } from "../services/aiPoweredService";
import { UPLOAD_PRODUCT_DESCRIPTION, TRACK_PRODUCT_DESCRIPTION } from "../actionTypes/aiPoweredActionTypes";

function* _uploadProductDescriptionService(_data: any) {

    try {
        yield put(setAiLoader(true));
        const { data } = yield call(uploadProductDescriptionService, _data.payload);
        yield put(setAiLoader(false));
        _data.callback && _data.callback(null)
    }
    catch (err: any) {
        yield put(setAiLoader(false));
        console.log("_uploadProductDescriptionService: exceptionError: ", err);
        toast.error(`Something went wrong: ${err.message || 'Unknown error'}`);
    }

}

function* _trackProductDescriptionService(_data: any) {
    try {
        // yield put(setAiLoader(true));
        const { data } = yield call(getProductDescriptionService, _data.payload);
        // yield put(setAiLoader(false));
        if (data.status === "Success" && data.status_code === 200) {
            yield put(setTrackProductDescription({
                data: data.data

            }))
        } else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        // yield put(setAiLoader(false));
        console.log("_trackProductDescriptionService: exceptionError: ", err);
    }

}
export default function* aiPoweredSaga() {
    yield all([
        takeLatest(UPLOAD_PRODUCT_DESCRIPTION, _uploadProductDescriptionService),
        takeLatest(TRACK_PRODUCT_DESCRIPTION, _trackProductDescriptionService)
    ])
}