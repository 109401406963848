import * as actions from '../actionTypes/reviewsModerationActionTypes';

export const setReviewModerationsLoader = (data: any) => {
    return {
        type: actions.SET_REVIEWS_LOADER,
        payload: data
    }
}

export const getReviews = (_data: any, _callback: any) => {
    return {
        type: actions.GET_REVIEWS,
        payload: _data,
        callback: _callback
    }
}

export const setReviews = (_data: any) => {
    return {
        type: actions.SET_REVIEWS,
        payload: _data
    }
}

export const updateReview = (_data: any, _callback: any) => {
    return {
        type: actions.UPDATE_REVIEW,
        payload: _data,
        callback: _callback
    }
}

export const setUpdatedReview = (_data: any) => {
    return {
        type: actions.SET_UPDATE_REVIEW,
        payload: _data
    }
}

export const getReviewsDump = (param: string, _callback: any) => {
    return {
        type: actions.GET_REVIEWS_DUMP,
        payload: param,
        callback: _callback
    }
}

export const setReviewsDump = (param: string) => {
    return {
        type: actions.SET_REVIEWS_DUMP,
        payload: param
    }
}

export const uploadReviewsFile = (_data: any, _callback: any) => {
    return {
        type: actions.UPLOAD_REVIEWS_FILE,
        payload: _data,
        callback: _callback
    }
}

export const reset = () => {
    return {
        type: actions.RESET
    }
}