import {
  SET_ATTRIBUTE_LOADER,
  SET_ATTRIBUTES,
  SET_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  SET_DOWNLOAD_DUMP_LOADER,
} from "../actionTypes/attributeActionTypes";

const initialState: any = {
  attributes: {},
  attribute: {},
  isLoading: false,
  downloadDumpLoader: false,
};

const attributeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ATTRIBUTE_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_DOWNLOAD_DUMP_LOADER:
      return {
        ...state,
        downloadDumpLoader: action.payload,
      };
    case SET_ATTRIBUTES:
      return {
        ...state,
        attributes: action.payload,
      };
    case SET_ATTRIBUTE:
      return {
        ...state,
        attribute: action.payload,
      };
    case DELETE_ATTRIBUTE:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          data: state.attributes.data.filter(
            (obj: any) => obj.id !== action.payload.id
          ),
        },
      };
    default:
      return state;
  }
};

export default attributeReducer;
