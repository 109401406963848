import {
    SET_PAGEMAPPING_LOADER,
    SET_PAGEMAPPINGS,
    SET_PAGEMAPPING,DELETE_PAGEMAPPING
  } from "../actionTypes/pageMappingActionTypes";
  
  const initialState: any = {
    pageMappings: {},
    pageMaping: {},
    isLoading: false,
  };
  
  const pageMappingReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case SET_PAGEMAPPING_LOADER:
        return {
          ...state,
          isLoading: action.payload,
        };
      case SET_PAGEMAPPINGS:
        return {
          ...state,
          pageMappings: action.payload,
        };
      case SET_PAGEMAPPING:
        return {
          ...state,
          pageMaping: action.payload,
        };
      case DELETE_PAGEMAPPING:
        return {
          ...state,
          pageMappings:{...state.pageMappings, data: state.pageMappings.data.filter((obj:any)=>obj.id !== action.payload.id)}
        }
      default:
        return state;
    }
  };
  
  export default pageMappingReducer;
  