import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  createPlaFeed,
  updatePlaFeed,
} from "../../../core/actionCreators/plaFeedActionCreator";

const PlaFeedForm = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageMode, plaFeed } = props;
  const [showGoogleFeedStatus, setShowGoogleFeedStatus] = useState(true);

  const [plaFeedPayload, setPlaFeedPayload] = useState({
    uid:"",
    id: "",
    gtin: "",
    identifierExists: "",
    customLabel2: "",
    customLabel3: "",
    customLabel4: "",
    promotionId: "",
    countryCode: "",
    isGoogleActive: "",
    isFacebookActive: "",
    freeListing: "",
    displayAds: "",
    shoppingAds: "",
    localInventoryAds: "",
    freeLocalListings: "",
    buyOnGoogleListings: "",
  } as any);

  useEffect(() => {
    if (
      pageMode === "EDIT" &&
      Object.keys(plaFeed).length &&
      typeof plaFeed !== "string"
    ) {
      setPlaFeedPayload(plaFeed);
    } else {
      setPlaFeedPayload(plaFeedPayload);
    }
  }, [plaFeed]);

  const formSubmitHandler = () => {
    if (pageMode === "CREATE") {
      delete plaFeedPayload.uid;
      dispatch(
        createPlaFeed(plaFeedPayload, () => {
          toast.success("Saved successfully.");
          // navigate("/plaFeed/");
        })
      );
    } else {
      dispatch(
        updatePlaFeed(plaFeedPayload, () => {
          toast.success("Updated successfully.");
        })
      );
    }
  };

  const handleGoogleFeedStatusChange = (isActive: boolean) => {
    setShowGoogleFeedStatus(isActive);
 
    setPlaFeedPayload((prevPayload:any) => {
      const updatedPayload = {
        ...prevPayload,
        isGoogleActive: isActive,
      };

    if (!isActive) {
      return {
        ...updatedPayload,
        freeListing: false,
        displayAds: false,
        shoppingAds: false,
        localInventoryAds: false,
        freeLocalListings: false,
        buyOnGoogleListings: false,
      };
    }

    return updatedPayload;
  });
};

  return (
    <>
      <div className="p-4 pb-8">
        <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-4">
          <form action="">
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  MSN
                </label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                  type="text"
                  value={plaFeedPayload.id}
                  onChange={(e) =>
                    setPlaFeedPayload({
                      ...plaFeedPayload,
                      id: e.target.value,
                    })
                  }
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  GTIN
                </label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                  type="text"
                  value={plaFeedPayload.gtin}
                  onChange={(e) =>
                    setPlaFeedPayload({
                      ...plaFeedPayload,
                      gtin: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Identifier Exists
                </label>
                <select
                  className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light"
                  value={plaFeedPayload.identifierExists}
                  onChange={(e) =>
                    setPlaFeedPayload({
                      ...plaFeedPayload,
                      identifierExists: e.target.value,
                    })
                  }
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Custom Label 2
                </label>
                <select
                  className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light"
                  value={plaFeedPayload.customLabel2}
                  onChange={(e) =>
                    setPlaFeedPayload({
                      ...plaFeedPayload,
                      customLabel2: e.target.value,
                    })
                  }
                >
                  <option>activated</option>
                  <option>inactivated</option>
                </select>
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Custom Label 3
                </label>
                <select
                  className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light"
                  value={plaFeedPayload.customLabel3}
                  onChange={(e) =>
                    setPlaFeedPayload({
                      ...plaFeedPayload,
                      customLabel3: e.target.value,
                    })
                  }
                >
                  <option>high</option>
                  <option>medium</option>
                  <option>low</option>
                </select>
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Custom Label 4
                </label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                  type="text"
                  value={plaFeedPayload.customLabel4}
                  onChange={(e) =>
                    setPlaFeedPayload({
                      ...plaFeedPayload,
                      customLabel4: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Promotion ID
                </label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                  type="text"
                  value={plaFeedPayload.promotionId}
                  onChange={(e) =>
                    setPlaFeedPayload({
                      ...plaFeedPayload,
                      promotionId: e.target.value,
                    })
                  }
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Target Country
                </label>
                <select
                  className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light"
                  onChange={(e) =>
                    setPlaFeedPayload({
                      ...plaFeedPayload,
                      countryCode: e.target.value,
                    })
                  }
                >
                  <option value="356">India</option>
                  <option value="784">UAE</option>
                </select>
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Google Feed Status
                </label>
                <div className="flex items-end">
                  <div className="flex items-center">
                    <input
                      className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                      type="radio"
                      onChange={() => handleGoogleFeedStatusChange(true)}
                      checked={plaFeedPayload.isGoogleActive === true}
                    />
                    <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                      Active
                    </span>
                  </div>
                  <div className="flex items-center ml-4">
                    <input
                      className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                      type="radio"
                      onChange={() => handleGoogleFeedStatusChange(false)}
                      checked={plaFeedPayload.isGoogleActive === false}
                    />
                    <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                      Inactive
                    </span>
                  </div>
                </div>
                {showGoogleFeedStatus && (
                  <div className="mt-4">
                    <div className="mb-4 last:mb-0">
                      <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                        Google FreeListing
                      </label>
                      <div className="flex items-end">
                        <div className="flex items-center">
                          <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            onChange={(e) =>
                              setPlaFeedPayload({
                                ...plaFeedPayload,
                                freeListing: true,
                              })
                            }
                            checked={plaFeedPayload.freeListing === true}
                          />
                          <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                            Active
                          </span>
                        </div>
                        <div className="flex items-center ml-4">
                          <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            onChange={(e) =>
                              setPlaFeedPayload({
                                ...plaFeedPayload,
                                freeListing: false,
                              })
                            }
                            checked={plaFeedPayload.freeListing === false}
                          />
                          <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 last:mb-0">
                      <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                        Google DisplayAds
                      </label>
                      <div className="flex items-end">
                        <div className="flex items-center">
                          <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            onChange={(e) =>
                              setPlaFeedPayload({
                                ...plaFeedPayload,
                                displayAds: true,
                              })
                            }
                            checked={plaFeedPayload.displayAds === true}
                          />
                          <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                            Active
                          </span>
                        </div>
                        <div className="flex items-center ml-4">
                          <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            onChange={(e) =>
                              setPlaFeedPayload({
                                ...plaFeedPayload,
                                displayAds: false,
                              })
                            }
                            checked={plaFeedPayload.displayAds === false}
                          />
                          <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 last:mb-0">
                      <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                        Google ShoppingAds
                      </label>
                      <div className="flex items-end">
                        <div className="flex items-center">
                          <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            onChange={(e) =>
                              setPlaFeedPayload({
                                ...plaFeedPayload,
                                shoppingAds: true,
                              })
                            }
                            checked={plaFeedPayload.shoppingAds == true}
                          />
                          <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                            Active
                          </span>
                        </div>
                        <div className="flex items-center ml-4">
                          <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            onChange={(e) =>
                              setPlaFeedPayload({
                                ...plaFeedPayload,
                                shoppingAds: false,
                              })
                            }
                            checked={plaFeedPayload.shoppingAds === false}
                          />
                          <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 last:mb-0">
                      <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                        Google LocalInventoryAds
                      </label>
                      <div className="flex items-end">
                        <div className="flex items-center">
                          <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            onChange={(e) =>
                              setPlaFeedPayload({
                                ...plaFeedPayload,
                                localInventoryAds: true,
                              })
                            }
                            checked={plaFeedPayload.localInventoryAds === true}
                          />
                          <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                            Active
                          </span>
                        </div>
                        <div className="flex items-center ml-4">
                          <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            onChange={(e) =>
                              setPlaFeedPayload({
                                ...plaFeedPayload,
                                localInventoryAds: false,
                              })
                            }
                            checked={plaFeedPayload.localInventoryAds === false}
                          />
                          <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 last:mb-0">
                      <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                        Google FreeLocalListings
                      </label>
                      <div className="flex items-end">
                        <div className="flex items-center">
                          <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            onChange={(e) =>
                              setPlaFeedPayload({
                                ...plaFeedPayload,
                                freeLocalListings: true,
                              })
                            }
                            checked={plaFeedPayload.freeLocalListings === true}
                          />
                          <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                            Active
                          </span>
                        </div>
                        <div className="flex items-center ml-4">
                          <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            onChange={(e) =>
                              setPlaFeedPayload({
                                ...plaFeedPayload,
                                freeLocalListings: false,
                              })
                            }
                            checked={plaFeedPayload.freeLocalListings === false}
                          />
                          <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 last:mb-0">
                      <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                        Google BuyOnGoogleListings
                      </label>
                      <div className="flex items-end">
                        <div className="flex items-center">
                          <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            onChange={(e) =>
                              setPlaFeedPayload({
                                ...plaFeedPayload,
                                buyOnGoogleListings: true,
                              })
                            }
                            checked={
                              plaFeedPayload.buyOnGoogleListings === true
                            }
                          />
                          <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                            Active
                          </span>
                        </div>
                        <div className="flex items-center ml-4">
                          <input
                            className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                            type="radio"
                            onChange={(e) =>
                              setPlaFeedPayload({
                                ...plaFeedPayload,
                                buyOnGoogleListings: false,
                              })
                            }
                            checked={
                              plaFeedPayload.buyOnGoogleListings === false
                            }
                          />
                          <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                            Inactive
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Facebook Feed Status
                </label>
                <div className="flex items-end">
                  <div className="flex items-center">
                    <input
                      className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                      type="radio"
                      onChange={(e) =>
                        setPlaFeedPayload({
                          ...plaFeedPayload,
                          isFacebookActive: true,
                        })
                      }
                      checked={plaFeedPayload.isFacebookActive === true}
                    />
                    <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                      Active
                    </span>
                  </div>
                  <div className="flex items-center ml-4">
                    <input
                      className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                      type="radio"
                      onChange={(e) =>
                        setPlaFeedPayload({
                          ...plaFeedPayload,
                          isFacebookActive: false,
                        })
                      }
                      checked={plaFeedPayload.isFacebookActive === false}
                    />
                    <span className="ml-2 text-[#3c3c3c] text-sm font-light">
                      Inactive
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="mt-5">
          <button
            onClick={formSubmitHandler}
            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none "
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};
export default PlaFeedForm;
