import { useState } from "react";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import { useDispatch } from "react-redux";
import { getQnaList, addUpdateAnswer } from "../../../core/actionCreators/qnaActionCreator";
import { GET_QNA_DEFAULT_SIZE } from "../../../utils/constants";

const QAPopUp = (props: any) => {
    const { qAPopupData, setQAPopupData } = props
    const [answerText, setAnswerText] = useState("")
    const dispatch = useDispatch()

    const submitEventHandler = () => {
        if (!answerText) {
            alert("Fields are empty.")
            return;
        }
        let requestPayload = {
            "answerText": answerText,
            "cmsUserId": "101",
            "qId": qAPopupData.qid
        }

        let callback = (r: any) => {
            //Close popup
            setQAPopupData(false, "")
            alert("Answer Saved Succesfully.")

            //Handle if layour added successfully
            if (!r) {
                dispatch(getQnaList(`pageNumber=0&pageSize=${GET_QNA_DEFAULT_SIZE}`, null))
                return
            }
        }
        dispatch(addUpdateAnswer(requestPayload, callback))
    }


    return (
        <>
            <div className="popup-container">
                <div className="overlay">
                    <div className="popup-content w-[500px] rounded-md bg-[#f7f7f7] border border-[00000033] p-4">
                        <div className="flex justify-between">
                            <span className="text-xs font-semibold text-[#3c3c3c]">MSNVK13P3VRP91</span>
                            <CloseCircleFillIcon size={16} onClick={() => setQAPopupData(false, "")} className="text-[#3c3c3c]" />
                        </div>
                        <p className="text-sm text-[#3c3c3c] font-semibold mt-4">Text</p>
                        <div className="py-4">
                            <textarea value={answerText} onChange={(e) => setAnswerText(e.target.value)} className="w-full border border-[#ddd] focus:outline-none p-4 text-xs text-[#3c3c3c]" rows={5}></textarea>
                        </div>
                        <div className="flex justify-end">
                            <button onClick={() => setQAPopupData(false, "")} className="bg-transparent font-semibold text-slate-800  py-2 px-6">
                                Cancel
                            </button>
                            <button onClick={() => submitEventHandler()} className="bg-[#0000ff] text-white font-semibold rounded shadow-md  focus:outline-none  py-2 px-6">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default QAPopUp;