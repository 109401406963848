import LayoutListItem from "./LayoutListItem"
import Loader from "../Loader/Loader"
import ReviewModerationItem from "../ReviewModeraton/ReviewItem";
import ReviewFilters from "../ReviewModeraton/ReviewFilters";
const LayoutList = (props: any) => {
    const { headers, items, isLoading, moduleUsedIn, filterReviews } = props;
    return (
        <>
            <table className="min-w-full divide-y divide-gray-300 mb-[54px]">
                <thead>
                    <tr>
                        {
                            headers && headers.length && headers.map((header: string) => {
                                return (
                                    <th scope="col" className=" text-left "> {header} </th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>

                    {isLoading ? <Loader /> : <>
                        {moduleUsedIn === 'reviews' &&
                            <ReviewFilters filterUp={filterReviews} />
                        }
                        {items?.map((item: any, index: any) =>
                            <>
                                {
                                    moduleUsedIn == 'reviews' ?
                                        <ReviewModerationItem key={index} index={index} review={item} />
                                        : <LayoutListItem key={index} layout={item} />
                                }
                            </>
                        )}
                    </>}
                </tbody>
            </table>
        </>
    )
}

export default LayoutList