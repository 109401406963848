import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import SideNavbar from "../../components/Navbar/SideNavbar";
import Header from "../../components/Header/Header";
import FbtForm from "../../components/FBT/FbtForm";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFbtById, setFbt } from "../../../core/actionCreators/fbtActionCreator";

const Fbt = () => {
    const params = useParams();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [pageMode, setPageMode] = useState("")
    const { fbt } = useSelector((s: any) => s.fbtStore)

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (currentPath === "/fbt/create") {
            setPageMode("CREATE")
        }
        else {
            if (params.id) {
                setPageMode("EDIT")
                dispatch(getFbtById(params.id, null))
            }
        }
        return () => {
            dispatch(setFbt({}))
        }
    }, [params, window])

    const leftArrorClickHandler = () => {
        navigate("/fbt")
    }

    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                            <p className="text-xl font-bold text-[#43425D] flex items-center">
                                <ArrowLeftSLineIcon onClick={leftArrorClickHandler} size={24} className="mr-4" />
                                Create Fbt Listing
                            </p>
                        </div>
                        <FbtForm pageMode={pageMode} fbt={fbt} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Fbt;