import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { blockCustomer } from "../../../core/actionCreators/commonActionCreator";
import { useSelector } from "react-redux";

const BlockCustomerForm = () => {
    const [emailList, setEmailList] = useState("")
    const [phoneList, setPhoneList] = useState("")
    const [idList, setIdList] = useState("")

    const { isLoading } = useSelector((s: any) => s.commonStore)

    const dispatch = useDispatch()

    const blockCustomerHandler = () => {
        let payload: any = {}
        if (emailList) {
            payload.emailList = emailList.split(',').map(item => item.trim())
        }
        if (phoneList) {
            payload.phoneList = phoneList.split(',').map(item => item.trim())
        }
        if (idList) {
            payload.idList = idList.split(',').map(item => item.trim())
        }
        if (Object.keys(payload).length) {
            dispatch(blockCustomer(payload, (_data: any) => {
                setEmailList("")
                setPhoneList("")
                setIdList("")
                if (_data && _data.length) {
                    alert(JSON.stringify(_data))
                }
                else {
                    toast.success("Deactivated successfully.")
                }
            }))
        }
        else {
            toast.error("Required fields are missing.")
        }
        return
    }
    return (
        <>
            <div className="p-4">
                <div className="mb-5">
                    <label className="text-sm text-[#555] font-semibold">Enter Email Id</label>
                    <textarea value={emailList} onChange={(e) => setEmailList(e.target.value)} className="w-full h-20 rounded border text-[#555] p-2.5 border-[#EAEAEA] outline-none text-sm mt-3"></textarea>
                    <div className="pt-4">
                        <button onClick={blockCustomerHandler} className="h-9 py-2 px-4 bg-[#0c870c] text-white font-medium rounded shadow-md border border-[#3c3c3c] focus:outline-none">{isLoading && emailList ? "Loading..." : "Block Customer"}</button>
                        <button onClick={() => setEmailList("")} className="h-9 py-2 px-4 bg-[#d9232d] text-white font-medium rounded shadow-md border border-[#3c3c3c] focus:outline-none ml-8">Clear</button>
                    </div>
                </div>
                <div className="mb-5">
                    <label className="text-sm text-[#555] font-semibold">Enter Phone Number</label>
                    <textarea value={phoneList} onChange={(e) => setPhoneList(e.target.value)} className="w-full h-20 rounded border text-[#555] p-2.5 border-[#EAEAEA] outline-none text-sm mt-3"></textarea>
                    <div className="pt-4">
                        <button onClick={blockCustomerHandler} className="h-9 py-2 px-4 bg-[#0c870c] text-white font-medium rounded shadow-md border border-[#3c3c3c] focus:outline-none">{isLoading && phoneList ? "Loading..." : "Block Customer"}</button>
                        <button onClick={() => setPhoneList("")} className="h-9 py-2 px-4 bg-[#d9232d] text-white font-medium rounded shadow-md border border-[#3c3c3c] focus:outline-none ml-8">Clear</button>
                    </div>
                </div>
                <div className="mb-5">
                    <label className="text-sm text-[#555] font-semibold">Enter Customer Id</label>
                    <textarea value={idList} onChange={(e) => setIdList(e.target.value)} className="w-full h-20 rounded border text-[#555] p-2.5 border-[#EAEAEA] outline-none text-sm mt-3"></textarea>
                    <div className="pt-4">
                        <button onClick={blockCustomerHandler} className="h-9 py-2 px-4 bg-[#0c870c] text-white font-medium rounded shadow-md border border-[#3c3c3c] focus:outline-none">{isLoading && idList ? "Loading..." : "Block Customer"}</button>
                        <button onClick={() => setIdList("")} className="h-9 py-2 px-4 bg-[#d9232d] text-white font-medium rounded shadow-md border border-[#3c3c3c] focus:outline-none ml-8">Clear</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlockCustomerForm;