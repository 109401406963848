import UserManagementListItem from "./UserManagementListItem";

const UserManagementList = () => {
    return (
        <>
            <div className="mt-4 campaign-list bg-white mb-[54px]">
                <table className="w-full" >
                    <thead className="bg-[#343434]">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]">First Name</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]">Last Name</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]">Email ID</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]">Updated At</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Status</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-4 py-3 text-left text-xs text-white w-[20%]">
                                <input className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[20%]">
                                <input className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[20%]">
                            <input className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[20%]">
                                <input className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[10%]">
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[10%]">
                            </td>
                        </tr>
                        <UserManagementListItem />
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default UserManagementList;