
const ReviewFilters = (props: any) => {
    const { searchCriteria, setSearchCriteria } = props
    return (
        <tr>
            <td className="w-[10%]">
                <input value={searchCriteria.id} onChange={(e) => setSearchCriteria({ ...searchCriteria, id: e.target.value })} type="text" placeholder='Enter Id' className="border border-gray-300 h-7 w-full focus:outline-none px-1.5 rounded-sm"></input>
            </td>
            <td className="w-[15%]">
                <input value={searchCriteria.productMsn} onChange={(e) => setSearchCriteria({ ...searchCriteria, productMsn: e.target.value })} type="text" placeholder='Enter MSN' className="border border-gray-300 h-7 w-full focus:outline-none px-1.5 rounded-sm"></input>
            </td>
            <td className="w-[15%]">
                <input value={searchCriteria.userId} onChange={(e) => setSearchCriteria({ ...searchCriteria, userId: e.target.value })} type="text" placeholder="Enter User Id" className="border border-gray-300 h-7 w-full focus:outline-none px-1.5 rounded-sm"></input>
            </td>
            <td className="w-[5%]"></td>
            <td className="w-[10%]">
                <input value={searchCriteria.reviewSubject} onChange={(e) => setSearchCriteria({ ...searchCriteria, reviewSubject: e.target.value })} type="text" placeholder="Enter Subject" className="border border-gray-300 h-7 w-full focus:outline-none px-1.5 rounded-sm"></input>
            </td>
            <td className="w-[10%]"></td>
            <td className="w-[8%]">
                <select value={searchCriteria.isEnabled} onChange={(e) => setSearchCriteria({ ...searchCriteria, isEnabled: e.target.value })} className="bg-white border border-gray-300 h-7 w-full focus:outline-none px-1.5 rounded-sm">
                    <option value=''>All</option>
                    <option value='true'>True</option>
                    <option value='false'>False</option>
                </select>
            </td>
            <td className="w-[7%]">
                <select value={searchCriteria.isApproved} onChange={(e) => setSearchCriteria({ ...searchCriteria, isApproved: e.target.value })} className="bg-white border border-gray-300 h-7 w-full focus:outline-none px-1.5 rounded-sm">
                    <option value=''>All</option>
                    <option value='true'>True</option>
                    <option value='false'>False</option>
                </select>
            </td>
            <td className="w-[10%]"></td>
            <td className="action flex mt-[10px] w-[10%]">

            </td>
        </tr >
    )
}

export default ReviewFilters;