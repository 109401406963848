import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadPlaDump,
  bulkPlaFile,
} from "../../../core/actionCreators/plaFeedActionCreator";

const PlaFeedSubHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState("");
  const { user } = useSelector((s: any) => s.authStore);
  const { downloadDumpLoader } = useSelector((s: any) => s.plaFeedStore);

  const navigateToCreatePlaFeedHandler = () => {
    navigate("/plaFeed/create");
  };

  const fileHandler = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      setFile(file);
    }
  };

  const bulkUploadHandler = () => {
    if (!file) {
      toast.error("Please select file.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      bulkPlaFile(
        {
          param: `email=${user.email || "deepak.singh1@moglix.com"}`,
          data: formData,
        },
        (d: any) => {
          toast.success("Uploaded successfully.");
        }
      )
    );
  };

  const downloadDumpHandler = () => {
    dispatch(
      downloadPlaDump(
        `email=${user.email || "deepaksingh1@moglix.com"}`,
        (d: any) => {
          toast.success(d.statusDescription);
        }
      )
    );
  };
  return (
    <>
      <div className="campaign-header bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
        <div className="flex items-center justify-between w-full">
          <p className="text-xl font-bold text-[#43425D] w-1/5">PLA FEED</p>
          <div className="flex w-4/5 ml-auto justify-end">
            <input
              onChange={(e) => fileHandler(e)}
              type="file"
              className="border border-gray-300 py-2 rounded focus: outline-none px-1.5 text-xs w-1/4"
            />
            <button
              onClick={bulkUploadHandler}
              className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-5"
            >
              Bulk Upload
            </button>
            <button
              onClick={downloadDumpHandler}
              className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-1.5"
            >
              {" "}
              {downloadDumpLoader ? "Loading.." : "Download Dump"}
            </button>
            <button
              onClick={navigateToCreatePlaFeedHandler}
              className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-1.5"
            >
              Create Pla Feed
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default PlaFeedSubHeader;
