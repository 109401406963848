import axios from "axios";
import { apis } from './params';

export function getServiceabilityService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_SERVICEABILITY + _param
    })
}

export function uploadServiceabilityFileService(_data: any) {
    return axios({
        "method": "Post",
        "url": apis.UPLOAD_SERVICEABILITY_FILE + _data.param,
        "data": _data.data
    })
}