const UserManagementListItem = () => {
    return (
        <>
            <tr className='[&:nth-child(odd)]:bg-[#f7f7f7]'>
                <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">Pritam</td>
                <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">Singh</td>
                <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">pritam.singh@moglix.com</td>
                <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">2022-11-09 21:29:23</td>
                <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[10%]">1</td>
                <td className="px-4 py-3 text-left text-xs text-[#337ab7] w-[10%] font-semibold cursor-pointer">Update</td>
            </tr>
        </>
    )
}
export default UserManagementListItem;