import axios from "axios";
import { apis } from './params';

export function getFbtsService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_FBTS + _param,
    })
}

export function getFbtByIdService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_FBT_BY_ID + _param,
    })
}

export function downloadFbtDumpService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.DOWNLOAD_FBT_DUMP + _param
    })
}

export function createFbtService(_data: any) {
    return axios({
        "method": "Post",
        "url": apis.CREATE_FBT,
        "data": _data
    })
}

export function updateFbtService(_data: any) {
    return axios({
        "method": "Post",
        "url": apis.UPDATE_FBT,
        "data": _data
    })
}

export function uploadFbtFileService(_data: any) {
    return axios({
        "method": "Post",
        "url": apis.UPLOAD_FBT_FILE + _data.param,
        "data": _data.data
    })
}

