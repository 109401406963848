export const GET_PLA_FEEDS = "GET_PLA_FEEDS"
export const SET_PLA_FEEDS = "SET_PLA_FEEDS"
export const GET_PLA_FEED = "GET_PLA_FEED"
export const SET_PLA_FEED = "SET_PLA_FEED"
export const CREATE_PLA_FEED = "CREATE_PLA_FEED"
export const SET_PLA_LOADER = "SET_PLA_LOADER"
export const UPDATE_PLA_FEED = "UPDATE_PLA_FEED"
export const DOWNLOAD_PLA_DUMP = "DOWNLOAD_PLA_DUMP"
export const SET_DOWNLOAD_DUMP_LOADER = "SET_DOWNLOAD_DUMP_LOADER"
export const BULK_PLA_FILE = "BULK_PLA_FILE"
export const GET_PLA_BY_ID = "GET_PLA_BY_ID"


