import BlocksSubHeader from "../../../components/Blocks/BlocksSubHeader";
import Header from "../../../components/Header/Header";
import LayoutBlockList from "../../../components/LayoutBlock/LayoutBlock";
import SideNavbar from "../../../components/Navbar/SideNavbar";

const LayoutBlocks = () => {
    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <BlocksSubHeader />
                        <LayoutBlockList />
                    </div>
                </div>
            </div>
        </>
    )
}
export default LayoutBlocks;