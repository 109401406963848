import ProductBlockElementForm from "./ProductBlockElementForm";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  createProductBlock,
  updateProductBlock,
  deleteProductBlockElement,
} from "../../../core/actionCreators/blocksActionCreator";
import Loader from "../Loader/Loader";
import { uploadProductCsvFileService } from "../../../core/services/blocksServices";

const ProductBlockParentForm = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageMode, blockData, loaderFlag } = props;

  const { user } = useSelector((s: any) => s.authStore);

  const [csvFile, setCsvFile] = useState("")
  const [loader, setLoader] = useState(false)
  const [productBlockPayload, setProductBlockPayload] = useState({
    id: "",
    label: "",
    name: "",
    title: "",
    brand: "",
    category: "",
    elementCount: "",
    status: "true",
    associatedLayouts: [],
    productElementList: [
      {
        id: uuidv4(),
        elementId: "",
        label: "",
        name: "",
        url: "",
        image: "",
        elementOrder: "1",
      },
    ],
  } as any);

  useEffect(() => {
    if (pageMode === "EDIT" && Object.keys(blockData).length) {
      setProductBlockPayload({ ...blockData });
    }
  }, [blockData]);

  const createProductBlockHandler = () => {
    if (productBlockPayload.label.trim() === "") {
      toast.error("Label cannot be blank.");
      return;
    }
    if (productBlockPayload.name.trim() === "") {
      toast.error("Name cannot be blank.");
      return;
    }
    if (productBlockPayload.elementCount.trim() === "") {
      toast.error("ElementCount cannot be blank.");
      return;
    }
    if (productBlockPayload.category.trim() === "") {
      toast.error("Category cannot be blank.");
      return;
    }
    if (productBlockPayload.status === "") {
      toast.error("Status cannot be blank.");
      return;
    }
    // setButtonInfo(buttonStates.CREATE);
    const formData = new FormData();
    let data: any = { ...productBlockPayload };
    delete data.id;
    delete data.associatedLayouts
    let headers = {
      userId: user.id || 33,
    };

    data.productElementList.forEach((d: any) => {
      delete d.id
      d.elementOrder = parseInt(d.elementOrder)
      if (d.image) {
        let file = d.image;
        formData.append("files", file);
        delete d.image;
      }
    });

    formData.append("json", JSON.stringify(data));
    dispatch(
      createProductBlock({ data: formData, headers: headers }, () => {
        toast.success("Created successfully.");
        navigate("/blocks/product");
      })
    );
  };

  const updateProductBlockHandler = () => {
    const formData = new FormData();
    let data: any = { ...productBlockPayload, associatedLayouts: null };
    delete data.associatedLayouts;
    data.status = JSON.parse(data.status);

    let headers = {
      userId: user.id || 33,
    };
    let params = {
      id: data.id,
    };

    // Initialize an empty array to store image files
    let files: any = [];
    data.productElementList.forEach((d: any) => {
      d.elementOrder = parseInt(d.elementOrder)
      if (typeof d.id === 'string') {
        let file = d.image;
        delete d.id;
        delete d.image;
        files.push(file);
      }
      // If the image property exists, add the file to the files array
      if (d.image && typeof d.image === 'string') {
        files.push(null)
      }
      else {
        if (d.image) {
          let file = d.image;
          delete d.image;
          files.push(file);
        }
      }
    });

    files.forEach((file: any) => {
      formData.append("files", file);
    });

    formData.append("json", JSON.stringify(data));
    dispatch(
      updateProductBlock({ params, data: formData, headers }, () => {
        toast.success("Updated successfully.");
      })
    );
  };

  const addElementHandler = () => {
    let newElement = {
      id: uuidv4(),
      label: "",
      name: "",
      url: "",
      image: "",
      elementOrder: "1",
      elementId: ""
    };
    productBlockPayload.productElementList.push(newElement);
    setProductBlockPayload({ ...productBlockPayload });
  };

  const removeElementHandler = (elementId: any) => {
    const isConfirmed = window.confirm('Are you sure want to delete?')
    if (!isConfirmed) {
      return
    }

    if (productBlockPayload.productElementList.length === 1) {
      toast.error("Please rethink about it.");
      return;
    }

    const elementIndex = productBlockPayload.productElementList.findIndex(
      (d: any) => d.id === elementId
    );
    if (elementIndex === -1) {
      toast.error("Something went wrong.");
      return;
    }

    if (pageMode === "EDIT" && typeof elementId !== 'string') {
      dispatch(
        deleteProductBlockElement(
          {
            blockId: productBlockPayload.id,
            blockElementId: elementId,
            userId: user.id || 33,
          },
          (resp: any) => {
            if (!resp.status) {
              toast.error(resp.statusDescription);
              return;
            }
            productBlockPayload.productElementList.splice(elementIndex, 1);
            setProductBlockPayload({ ...productBlockPayload });
            return;
          }
        )
      );
    }
    else {
      productBlockPayload.productElementList.splice(elementIndex, 1);
      setProductBlockPayload({ ...productBlockPayload });
    }
  };

  const onGeneralBlockChangeHandler = (type: string, e: any) => {
    if (type === "CSV") {
      if (e.target.files && e.target.files[0]) {
        let file = e.target.files[0];
        setCsvFile(file)
      }
    }
  };

  const uploadCsvFileHandler = async () => {
    if (csvFile) {
      try {
        let headers = {
          userId: user.id || 33,
        };
        let params = {
          id: productBlockPayload.id,
        };
        const formData = new FormData();
        formData.append("file", csvFile);
        setLoader(true)
        const { data } = await uploadProductCsvFileService({ params, data: formData, headers })
        if (data.status && data.statusCode === 200) {
          window.location.reload()
        }
        setLoader(false)
      }
      catch (err) {
        setLoader(false)
        console.log("method: uploadCsvFileHandler: error", err)
      }
    }
  }


  const navigateToLayoutPageHandler = (layoutId: number) => {
    navigate(`/blocks/layout/${layoutId}`);
  }

  return (
    <>
      <div className="p-4 pb-8">
        <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5">
          <form action="">
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Label*
                </label>
                <input
                  value={productBlockPayload.label}
                  onChange={(e) =>
                    setProductBlockPayload({
                      ...productBlockPayload,
                      label: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Name*
                </label>
                <input
                  value={productBlockPayload.name}
                  onChange={(e) =>
                    setProductBlockPayload({
                      ...productBlockPayload,
                      name: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                  type="text"
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Title
                </label>
                <input
                  value={productBlockPayload.title}
                  onChange={(e) =>
                    setProductBlockPayload({
                      ...productBlockPayload,
                      title: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Brand
                </label>
                <input
                  value={productBlockPayload.brand}
                  onChange={(e) =>
                    setProductBlockPayload({
                      ...productBlockPayload,
                      brand: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                  type="text"
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Category*
                </label>
                <input
                  value={productBlockPayload.category}
                  onChange={(e) =>
                    setProductBlockPayload({
                      ...productBlockPayload,
                      category: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Element Count*
                </label>
                <input
                  value={productBlockPayload.elementCount}
                  onChange={(e) =>
                    setProductBlockPayload({
                      ...productBlockPayload,
                      elementCount: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                  type="text"
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Status*
                </label>
                <select
                  value={productBlockPayload.status}
                  onChange={(e) =>
                    setProductBlockPayload({
                      ...productBlockPayload,
                      status: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none bg-white font-light"
                >
                  <option value={"true"}>Active</option>
                  <option value={"false"}>Inactive</option>
                </select>
              </div>

              {/* Render only if its update mode */}
              {pageMode === "EDIT" && <div className="w-1/2 px-2">
                {productBlockPayload.s3FileName && (
                  <div className="thumbnail-container">
                    <img
                      className="thumbnail"
                      src={productBlockPayload.s3FileName}
                      alt="Preview"
                    />
                  </div>
                )}
                {productBlockPayload.s3FileName && (
                  <a
                    className="text-[10px] text-[#3c3c3c]"
                    href={productBlockPayload.s3FileName}
                    target="_blank"
                  >
                    File
                  </a>
                )}
                <div className="flex items-end">
                  <div className="w-full">
                    <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                      Upload Csv File
                    </label>
                    <input
                      onChange={(e) => onGeneralBlockChangeHandler("CSV", e)}
                      className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 py-1 outline-none text-sm font-light"
                      type="file"
                    />
                  </div>
                  <button className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ml-5" type="button" onClick={uploadCsvFileHandler}>{loader ? "Uploading.." : "Upload"}</button>
                </div>
              </div>}
            </div>

            {/* Show Associated Layout if edit mode */}
            {pageMode === "EDIT" && productBlockPayload.associatedLayouts && Array.isArray(productBlockPayload.associatedLayouts) && productBlockPayload.associatedLayouts.length > 0 && <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Associated Layouts</label>
                <ul className="list-none p-0">
                  {productBlockPayload.associatedLayouts.map((d: any, index: number) =>
                    <li key={index} className="mb-1 last:mb-0">
                      <p onClick={() => navigateToLayoutPageHandler(d.layout_id)} className="text-blue-500 hover:underline">{d.layoutName}</p>
                    </li>
                  )}
                </ul>
              </div>
            </div>}
          </form>
        </div>
        {productBlockPayload.productElementList.map((d: any, index: number) => (
          <ProductBlockElementForm
            key={index}
            data={d}
            productBlockPayload={productBlockPayload}
            setProductBlockPayload={setProductBlockPayload}
            removeElementHandler={removeElementHandler}
          />
        ))}
        <div className="mt-5">
          <button
            onClick={addElementHandler}
            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none "
          >
            Add Element
          </button>
          {loaderFlag ? <Loader /> : <button
            onClick={
              pageMode === "EDIT"
                ? updateProductBlockHandler
                : createProductBlockHandler
            }
            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ml-5"
          >
            {pageMode === "EDIT" ? "Update" : "Create"}
          </button>}
        </div>
      </div>
    </>
  );
};

export default ProductBlockParentForm;
