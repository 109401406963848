import BlocksSubHeader from "../../../components/Blocks/BlocksSubHeader";
import Header from "../../../components/Header/Header";
import SideNavbar from "../../../components/Navbar/SideNavbar";
import ProductBlockList from "../../../components/ProductBlock/ProductBlockList";

const ProductBlocks =()=>{
    return(
        <>
           <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <BlocksSubHeader />
                        <ProductBlockList />
                    </div>
                </div>
            </div> 
        </>
    )
}
export default ProductBlocks;