import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { createStaticHtmlBlock, updateStaticHtmlBlock } from "../../../core/actionCreators/blocksActionCreator";

const StaticHtmlForm = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageMode, staticHtmlBlock } = props;
  const { user } = useSelector((s: any) => s.authStore);


  const [staticHtmlPayload, setStaticHtmlPayload] = useState({
    id: "",
    label: "",
    name: "",
    title: "",
    staticHtml: "",
    associatedLayouts: [],
    status: "true"
  } as any);

  useEffect(() => {
    if (
      pageMode === "EDIT" &&
      Object.keys(staticHtmlBlock).length
      // typeof staticHtmlBlock !== "string"
    ) {
      setStaticHtmlPayload(staticHtmlBlock);
    } else {
      setStaticHtmlPayload(staticHtmlPayload);
    }

  }, [staticHtmlBlock])

  const createBlockHandler = () => {
    // const formData = new FormData();
    if (staticHtmlPayload.name.trim() === "") {
      toast.error("Name cannot be blank.");
      return;
    }
    if (staticHtmlPayload.staticHtml.trim() === "") {
      toast.error("Static Html cannot be blank.");
      return;
    }

    if (staticHtmlPayload.status === "") {
      toast.error("Status is Required.");
      return;
    }
    let data: any = { ...staticHtmlPayload };
    delete data.id;
    delete data.associatedLayouts
    let headers = {
      userId: user.id || 33,
    };

    dispatch(

      createStaticHtmlBlock({ data, headers: headers }, () => {
        toast.success("Created successfully.");
        navigate("/blocks/staticHtml");
      })
    );
  }

  const updateBlockHandler = () => {
    let data: any = { ...staticHtmlPayload };
    let headers = {
      userId: user.id || 33,
    };
    let params = {
      id: data.id,
    };
    delete data.associatedLayouts
    dispatch(
      updateStaticHtmlBlock({ params, data, headers }, () => {
        toast.success("Updated successfully.");
      })
    );
  }

  const navigateToLayoutPageHandler = (layoutId: number) => {
    navigate(`/blocks/layout/${layoutId}`);
  }

  return (
    <>
      <div className="p-4 pb-8">
        <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5">
          <form action="">
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Label</label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text" value={staticHtmlPayload.label}
                  onChange={(e) =>
                    setStaticHtmlPayload({
                      ...staticHtmlPayload,
                      label: e.target.value
                    })
                  }
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Name*</label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text" value={staticHtmlPayload.name}
                  onChange={(e) =>
                    setStaticHtmlPayload({
                      ...staticHtmlPayload,
                      name: e.target.value
                    })
                  }
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Title</label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text" value={staticHtmlPayload.title}
                  onChange={(e) =>
                    setStaticHtmlPayload({
                      ...staticHtmlPayload,
                      title: e.target.value
                    })
                  }
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Static Html*</label>
                <textarea value={staticHtmlPayload.staticHtml}
                  onChange={(e) => setStaticHtmlPayload({
                    ...staticHtmlPayload, staticHtml: e.target.value
                  })
                  }
                  rows={3} className="w-full rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">

                </textarea>
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Status*</label>
                <select value={staticHtmlPayload.status}
                  onChange={(e) => setStaticHtmlPayload({ ...staticHtmlPayload, status: e.target.value })
                  }
                  className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">

                  <option value={"true"}>Active</option>
                  <option value={"false"}>Inactive</option>
                </select>
              </div>
            </div>

            {/* Show Associated Layout if edit mode */}
            {pageMode === "EDIT" && staticHtmlPayload.associatedLayouts && Array.isArray(staticHtmlPayload.associatedLayouts) && staticHtmlPayload.associatedLayouts.length > 0 && <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Associated Layouts</label>
                <ul className="list-none p-0">
                  {staticHtmlPayload.associatedLayouts.map((d: any, index: number) =>
                    <li key={index} className="mb-1 last:mb-0">
                      <p onClick={() => navigateToLayoutPageHandler(d.layout_id)} className="text-blue-500 hover:underline">{d.layoutName}</p>
                    </li>
                  )}
                </ul>
              </div>
            </div>}
          </form>
        </div>

        <div className="mt-5">

          <button onClick={
            pageMode === "EDIT"
              ? updateBlockHandler
              : createBlockHandler}



            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none"> {pageMode === "CREATE" ? "Create" : "Update"}</button>
        </div>
      </div>
    </>
  )
}
export default StaticHtmlForm;