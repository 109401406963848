import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import Header from "../../../components/Header/Header";
import LayoutTypeForm from "../../../components/LayoutTypeBlock/LayoutTypeForm";
import SideNavbar from "../../../components/Navbar/SideNavbar";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getBlockTypeBlock, setBlockTypeBlock } from "../../../../core/actionCreators/blocksActionCreator";

const LayoutType = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [pageMode, setPageMode] = useState("");
    const navigate = useNavigate();

    const { blockTypeBlock, isLoading } = useSelector((s: any) => s.blocksStore);

    const backArrowClickHadler = () => {
        navigate('/blocks/layoutType');
    }

    useEffect(() => {
        const currentPath = window.location.pathname;
        if (currentPath === "/blocks/layoutType/create") {
            setPageMode("CREATE");
        } else {
            if (params.id) {
                setPageMode("EDIT");
                dispatch(getBlockTypeBlock(params.id, null));
            }
        }
        return () => {
            dispatch(setBlockTypeBlock({}));
        };
    }, [params, window]);

    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                            <div className="flex items-center w-full">
                                <ArrowLeftSLineIcon onClick={backArrowClickHadler} size={24} className="mr-4" />
                                <p className="text-xl font-bold text-[#43425D] flex items-center">{pageMode === "EDIT" ? "Update" : "Create"} Block Type</p>
                            </div>
                        </div>
                        <LayoutTypeForm pageMode={pageMode}
                            blockData={blockTypeBlock} loaderFlag={isLoading} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default LayoutType;