import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { FiSearch } from "react-icons/fi";

import "./LayoutListPage.scss";

import SideNavbar from "../../components/Navbar/SideNavbar";
import Header from "../../components/Header/Header";
import Pagination from "../../components/Pagination/Pagination";
import LayoutList from "../../components/LayoutList/LayoutList";
import { getLayouts } from "../../../core/actionCreators/lmsActionCreator";
import { LAYOUT_PAGE_HEADERS, PAGE_SIZE, PLATFORMS } from "../../../utils/constants";
import AddPagePopup from "../../components/Popup/AddPagePopup";


const LayoutListPage = () => {
  const dispatch = useDispatch();
  const [showActionTooltip, setShowActionTooltip] = useState(false)
  let { layouts, isLoading } = useSelector((s: any) => s.lmsStore);
  const [addPagePopup, setAddPagePopup] = useState(false)
  const [searchString, setSearchString] = useState("")
  const [createdBy, setCreatedBy] = useState("")
  const [status, setStatus] = useState("")
  const [platform, setPlatform] = useState("")


  const fetchLayouts = (_param: string, _callback: any) => {
    dispatch(getLayouts(_param, _callback));
  }

  //Component did mount
  useEffect(() => {
    fetchLayouts(`pageNumber=0&pageSize=${PAGE_SIZE}&searchString=${"app"}`, null)
  }, []);

  //Search by searchString
  useEffect(() => {
    if (searchString) {
      setCreatedBy("")
      fetchLayouts(`searchString=${searchString}`, null)
    }
  }, [searchString])

  //Search by Platform
  useEffect(() => {
    if (platform) {
      setSearchString("")
      setCreatedBy("")
      fetchLayouts(`searchString=${platform}`, null)
    }
  }, [platform])

  //Search by createdBy
  useEffect(() => {
    if (createdBy) {
      setSearchString("")
      fetchLayouts(`createdby=${createdBy}`, null)
    }
  }, [createdBy])

  //Search by status
  useEffect(() => {
    if (status) {
      setSearchString("")
      setCreatedBy("")
      fetchLayouts(`status=${status}`, null)
    }
  }, [status])

  return (
    <>
      {addPagePopup && <AddPagePopup addPagePopup={addPagePopup} setAddPagePopup={setAddPagePopup} />}

      <div className="flex mainWrapper w-full">
        <div className="sidenav-wrapper">
          <SideNavbar />
        </div>
        <div className="right-container">
          <div className="header-container">
            <Header />
          </div>
          <div className="body-container bg-white" >
            <div className="pagelist-filter items-center flex p-[15px]">
              <div className="form-field">
                <label>Search</label>
                <div className="input-field">
                  <input
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    type="text"
                    placeholder="Search by ID, Layout Name"
                  />
                  <div className="search-icon flex justify-around items-center">
                    <FiSearch size={16} />
                  </div>
                </div>
              </div>
              <div className="form-field">
                <label>Created By</label>
                <input value={createdBy} onChange={(e) => setCreatedBy(e.target.value)} type="text" placeholder="Enter Email ID" />
              </div>
              <div className="form-field">
                <label>Status</label>
                <select onChange={(e) => setStatus(e.target.value)}>
                  <option>Select</option>
                  <option value="true" >Active</option>
                  <option value="false" >Inactive</option>
                </select>
              </div>
              <div className="form-field">
                <label>Platform</label>
                <select onChange={(e) => setPlatform(e.target.value)}>
                  {PLATFORMS.map((p, index) =>
                    <option key={index} value={p.value} >{p.title}</option>
                  )}
                </select>
              </div>
              <div className="right-create-btn">
                <button onClick={() => setAddPagePopup(true)} className=' py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75'>Create Page</button>
              </div>
            </div>
            <div className="pagelist-tbl">
              <LayoutList isLoading={isLoading}
                headers = {LAYOUT_PAGE_HEADERS}
                showActionTooltip={showActionTooltip}
                setShowActionTooltip={setShowActionTooltip}
                moduleUsedIn = {'layout'}
                items={layouts?.data} />
            </div>
            <Pagination />
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutListPage;
