import Article from "../../components/Article/Article";
import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import { useEffect, useState } from "react";




const ArticleForms = () => {
  return (
    <>
      <div className="flex mainWrapper w-full">
        <div className="sidenav-wrapper">
          <SideNavbar />
        </div>
        <div className="right-container">
          <div className="header-container">
            <Header />
          </div>
          <div className="body-container">
            <Article />
          </div>
        </div>
      </div>
    </>
  )
}

export default ArticleForms;