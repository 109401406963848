import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { getPopulatedLayout, getStaticFormFields, setLayout, setPopulatedLayout } from "../../../core/actionCreators/lmsActionCreator"
import ParentComponent from "../../components/Component/ParentComponent"
import SideNavbar from "../../components/Navbar/SideNavbar"
import ComponentListHeader from "../../components/Header/ComponentListHeader"
import AddComponentPopup from "../../components/Popup/AddComponentPopup"
import Loader from "../../components/Loader/Loader"
import "./LayoutPage.scss"
import JsonViewPopup from "../../components/Popup/JsonViewPopup"

const Layout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams();
    const [layoutId, setLayoutId] = useState("")


    const [jsonViewPopup, setJsonViewPopup] = useState(false)
    const [addComponentPopup, setAddComponentPopup] = useState(false)
    let { populatedLayout, isLoading, layoutJson } = useSelector((s: any) => s.lmsStore);
    let { user } = useSelector((s: any) => s.authStore);


    const fetchPopulatedLayout = (_layoutId: any) => {
        let callback = (r: any) => {
            if (r && r.data && r.data.attributes && r.data.attributes.length == 0) {
                // navigate(`/layout/${_layoutId}/component`)
            }
        }
        dispatch(getPopulatedLayout(_layoutId, callback))
    }

    useEffect(() => {
        if (params.layoutId) {
            setLayoutId(params.layoutId)
            fetchPopulatedLayout(params.layoutId)
            dispatch(getStaticFormFields(null, null))
        }
        return () => {
            dispatch(setLayout({}))
            dispatch(setPopulatedLayout({}))
        }
    }, [params]);


    return (<>

        {jsonViewPopup && <JsonViewPopup data={layoutJson} jsonViewPopup={jsonViewPopup} setJsonViewPopup={setJsonViewPopup} />}
        {addComponentPopup && <AddComponentPopup page={"LAYOUT"} layoutId={layoutId} setAddComponentPopup={setAddComponentPopup} />}
        <div className="flex mainWrapper w-full">
            <div className="sidenav-wrapper">
                <SideNavbar />
            </div>
            <div className="right-container">
                <div className="header-container">
                    <ComponentListHeader
                        setJsonViewPopup={setJsonViewPopup}
                        layoutId={layoutId}
                        setAddComponentPopup={setAddComponentPopup}
                        layoutName={populatedLayout?.cmsLayoutResponse?.layoutName}
                        device={populatedLayout?.cmsLayoutResponse?.device}
                        layoutCode={populatedLayout?.cmsLayoutResponse?.layoutCode} />
                </div>

                {isLoading ? <Loader /> : <>
                    {populatedLayout?.populatedComponents ? <div className="body-container pt-[75px] pb-[15px] bg-sky-50">
                        {
                            populatedLayout.populatedComponents.map((c: any, index: number) =>
                                <ParentComponent
                                    key={index}
                                    data={c}
                                    user={user}
                                    layoutId={layoutId}
                                    callbackFn={fetchPopulatedLayout}
                                    page="LAYOUT"
                                />
                            )
                        }
                    </div> : null}
                </>}
            </div>
        </div>
    </>)

}

export default Layout