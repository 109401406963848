import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { downloadQuestions } from "../../../core/actionCreators/qnaActionCreator";

const QASubHeader = (props: any) => {
    const { searchCriteria } = props
    const dispatch = useDispatch()
    const { user } = useSelector((s: any) => s.authStore)
    const email = user.email || 'vishal.malik@moglix.com'

    const downloadReportClickHandler = () => {
        for (const key in searchCriteria) {
            if (searchCriteria.hasOwnProperty(key) && searchCriteria[key] === "") {
                delete searchCriteria[key];
            }
        }
        if (Object.keys(searchCriteria).length) {
            delete searchCriteria["pgNumber"]
            delete searchCriteria["pgSize"]
            const stringifiedSearchCriteria = queryString.stringify(searchCriteria);
            dispatch(downloadQuestions("email=" + email + "&" + stringifiedSearchCriteria, null))
        }
    }

    return (
        <>
            <div className="campaign-header bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                <div className="flex items-center justify-between w-full">
                    <p className="text-xl font-bold text-[#43425D] w-1/5">Q & A</p>
                    <button onClick={downloadReportClickHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">Download</button>
                </div>
            </div>
        </>
    )
}
export default QASubHeader;
