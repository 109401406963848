import React, { useEffect, useState } from "react";
import "../Popup/AddComponentPopup.scss";
import { useDispatch } from "react-redux";
import { createComponent, createElement, getPopulatedComponent, getPopulatedLayout, uploadImage } from "../../../core/actionCreators/lmsActionCreator";
import AddAttributeForm from "../Form/AddAttributeForm";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";

const AddComponentPopup = (props: any) => {
    const dispatch = useDispatch()
    const { setAddComponentPopup, layoutId, componentId, page } = props
    let { user } = useSelector((s: any) => s.authStore);
    let { staticFormFields } = useSelector((s: any) => s.lmsStore);
    const [order, setOrder] = useState(0)
    const [componentName, setComponentName] = useState("")
    const [componentType, setComponentType] = useState("")
    const [msns, setMsns] = useState("")
    const [formAttributes, setFormAttributes] = useState([{
        "id": uuidv4(),
        "attributeKeyId": "",
        "attributeValue": ""
    }])
    const [currentPage, setCurrentPage] = useState("")


    useEffect(() => {
        setCurrentPage(page)
    }, [props, page])

    const addFieldHandler = () => {
        setFormAttributes([...formAttributes, {
            "id": uuidv4(),
            "attributeKeyId": "",
            "attributeValue": ""
        }])
    }

    const removeFieldHandler = (id: string) => {
        const filteredFields = formAttributes.filter((d) => d.id !== id)
        setFormAttributes([...filteredFields])
    }


    const addClickHandler = () => {
        const attributes = formAttributes.map((d) => {
            return {
                "attributeKeyId": d["attributeKeyId"],
                "attributeValue": d["attributeValue"]
            }
        })

        if (page === "LAYOUT") {
            const imageUploadActions = attributes.filter((d: any) => [27, 28].includes(d["attributeKeyId"]))
            if (imageUploadActions.length > 0) {
                if (imageUploadActions.length == 2) {
                    let index = attributes.findIndex((d: any) => d.attributeKeyId === imageUploadActions[0]["attributeKeyId"])
                    let formData = new FormData();
                    formData.append("file", imageUploadActions[0]["attributeValue"])
                    dispatch(uploadImage(formData, (res: any) => {
                        attributes[index]["attributeValue"] = res.s3ImageUrl
                        index = attributes.findIndex((d: any) => d.attributeKeyId === imageUploadActions[1]["attributeKeyId"])
                        formData.append("file", imageUploadActions[1]["attributeValue"])
                        dispatch(uploadImage(formData, (res1: any) => {
                            attributes[index]["attributeValue"] = res1.s3ImageUrl
                            let requestPayload = {
                                "component": {
                                    "componentName": componentName,
                                    "componentType": componentType,
                                    "isParentStructure": false,
                                    "childComponentId": "",
                                    "status": true
                                },
                                "msns": msns,
                                "attributes": (componentType === "product") ? null : attributes,
                                "modifiedBy": user.id || -1,
                                "order": order,
                                "layoutId": layoutId
                            }
                            setAddComponentPopup(false)
                            let callback = () => {
                                dispatch(getPopulatedLayout(layoutId, null))
                            }
                            dispatch(createComponent(requestPayload, callback))
                        }))
                    }))
                }
                if (imageUploadActions.length == 1) {
                    const formData = new FormData();
                    formData.append("file", imageUploadActions[0]["attributeValue"]);
                    dispatch(uploadImage(formData, (res: any) => {
                        let index = attributes.findIndex((d: any) => d.attributeKeyId === imageUploadActions[0]["attributeKeyId"])
                        attributes[index]["attributeValue"] = res.s3ImageUrl
                        let requestPayload = {
                            "component": {
                                "componentName": componentName,
                                "componentType": componentType,
                                "isParentStructure": false,
                                "childComponentId": "",
                                "status": true
                            },
                            "msns": msns,
                            "attributes": (componentType === "product") ? null : attributes,
                            "modifiedBy": user.id || -1,
                            "order": order,
                            "layoutId": layoutId
                        }
                        setAddComponentPopup(false)
                        let callback = () => {
                            dispatch(getPopulatedLayout(layoutId, null))
                        }
                        dispatch(createComponent(requestPayload, callback))
                    }))
                }
            }
            let requestPayload = {
                "component": {
                    "componentName": componentName,
                    "componentType": componentType,
                    "isParentStructure": false,
                    "childComponentId": "",
                    "status": true
                },
                "msns": msns,
                "attributes": (componentType === "product") ? null : attributes,
                "modifiedBy": user.id || -1,
                "order": order,
                "layoutId": layoutId
            }
            setAddComponentPopup(false)
            let callback = () => {
                dispatch(getPopulatedLayout(layoutId, null))
            }
            dispatch(createComponent(requestPayload, callback))
        }
        if (page === "COMPONENT") {
            let requestPayload = {
                "element": {
                    "componentId": componentId,
                    "parentId": null,
                    "isDeleted": false,
                    "order": order
                },
                "attributes": attributes,
                "modifiedBy": user.id || -1
            }
            setAddComponentPopup(false)
            let callback = () => {
                dispatch(getPopulatedComponent(componentId, null))
            }
            dispatch(createElement(requestPayload, callback))
        }
    }

    return (
        <>
            <div className="popup-container">
                <div className="overlay" >
                    <div className="right-popup-content">
                        <div className="popup-content-inner">
                            <h2>{currentPage === "LAYOUT" ? "Add Component" : "Add Element"}</h2>
                            <p className="text-xs">Fill in the below details..</p>

                            <div className="right-form-container ">
                                <div className="form-field mt-4 pr-5">
                                    <label>{currentPage === "LAYOUT" ? "Component Name" : "Element Name"}</label>
                                    <input required value={componentName} onChange={(e) => setComponentName(e.target.value)} type="text" placeholder="Enter Component Name" />
                                </div>
                                <div className="form-field">
                                    <label>{currentPage === "LAYOUT" ? "Component Type" : "Element Type"}</label>
                                    <select onChange={(e) => setComponentType(e.target.value)}>
                                        <option value={""} >Non Product</option>
                                        <option value={"product"} >Product</option>
                                    </select>
                                </div>
                                <div className="form-field mt-4 pr-5">
                                    <label>Order</label>
                                    <input value={order} onChange={(e) => setOrder(Number(e.target.value))} type="number"></input>
                                </div>

                                {componentType === "" && formAttributes.map((d, index) =>
                                    <>
                                        <AddAttributeForm
                                            key={index}
                                            formAttribute={d}
                                            formAtrributes={formAttributes}
                                            setFormAttributes={setFormAttributes}
                                            staticFormFields={staticFormFields}
                                            removeFieldHandler={removeFieldHandler} />
                                        <button onClick={addFieldHandler} className="add-field py-1 mt-[20px] font-semibold px-2">Add Field</button>
                                    </>
                                )
                                }
                                {componentType === "product" && <div>
                                    <div className="form-field">
                                        <label>MSN's</label>
                                        <textarea onChange={(e) => setMsns(e.target.value)} value={msns} rows={12} cols={50}>
                                        </textarea>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>

                        <div className="fix-bottom-btn-cta fixed pr-[20px] flex mt-6 justify-end items-center">
                            <button onClick={() => setAddComponentPopup(false)} className="bg-transparent font-semibold text-slate-800  py-2 px-6">Cancel</button>
                            <button onClick={addClickHandler} className="bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 py-2 px-6">{currentPage === "LAYOUT" ? "Add Component" : "Add Element"}</button>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
};

export default AddComponentPopup;

