import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../LayoutListPage/LayoutListPage.scss";
import SideNavbar from "../../components/Navbar/SideNavbar";
import Header from "../../components/Header/Header";
import { GET_REVIEWS_DEFAULT_SIZE } from "../../../utils/constants";
import { getReviews, getReviewsDump, uploadReviewsFile } from "../../../core/actionCreators/rmActionCreator";
import { downloadFile } from "../../../core/services/utilService";
import toast from "react-hot-toast";
import ReviewList from "../../components/ReviewModeraton/ReviewList";
import queryString from "query-string";


const Reviews= () => {
    const dispatch = useDispatch();
    let { user } = useSelector((s: any) => s.authStore);

    const [dateFilter, setDates] = useState({
        fromDate: '',
        toDate: ''
    });
    const [file, setFile] = useState("");

    const downloadDump = () => {
        dispatch(getReviewsDump(user['email'] || 'anil.payasia@moglix.com', (reviewsDump: any) => {
            downloadFile(reviewsDump, 'reviewDump');
        }))
    }

    const fileHandler = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            let file = e.target.files[0];
            setFile(file)
        }
    }

    const updateTimeStamp = ((e: any, variable: string) => {
        const date = e.target.value;
        const timeStamp = new Date(date).getTime();
        const obj: any = dateFilter;
        obj[variable] = timeStamp;
        setDates(obj);
    });

    const bulkUploadHandler = () => {
        if (!file) {
            toast.error("Please select file.")
            return
        }
        const formData = new FormData()
        formData.append("file", file)
        dispatch(uploadReviewsFile({
            param: `email=${user.email || "anil.payasia@moglix.com"}`,
            data: formData
        }, (d: any) => {
            toast.success("Uploaded successfully.")
        }))
    }

    const applyDateFilter = () => {
        if (dateFilter && dateFilter['fromDate'] && dateFilter['toDate']) {
            const stringifiedSearchCriteria = queryString.stringify({
                pageNumber: 0,
                pageSize: GET_REVIEWS_DEFAULT_SIZE,
                fromDate: dateFilter['fromDate'],
                toDate: dateFilter['toDate']
            })
            dispatch(getReviews(stringifiedSearchCriteria, null))
        } else {
            alert('please select dates');
        }
        return
    }

    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container bg-white" >
                        <div className="campaign-header bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                            <div className="flex items-center justify-between w-full">
                                <p className="text-xl font-bold text-[#43425D] w-1/5">REVIEWS & RATINGS</p>
                                <div className="flex w-4/5 ml-auto justify-end">
                                    <input type="file" onChange={(e) => fileHandler(e)} className="border border-gray-300 py-2 rounded focus: outline-none px-1.5 text-xs w-1/4" />
                                    <button onClick={bulkUploadHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400Download focus:ring-opacity-75 ml-5">Bulk Upload</button>
                                    <button className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-1.5" onClick={() => downloadDump()}>Download Dump</button>
                                </div>
                            </div>
                        </div>
                        <p className="text-xs mb-1.5 text-[#5E5D5D] font-medium pt-4 px-4">Select Date Range</p>
                        <div className="flex w-full pb-4">
                            <div className="px-4">
                                <input
                                    className="w-full h-7 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                    type="date"
                                    onChange={(e) => updateTimeStamp(e, 'fromDate')}
                                />
                            </div>
                            <div className="px-4 pl-0">
                                <input
                                    className="w-full h-7 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                    type="date"
                                    onChange={(e) => updateTimeStamp(e, 'toDate')}
                                />
                            </div>
                            <button onClick={applyDateFilter} className="h-7 px-6 text-xs bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-1.5">Go</button>
                        </div>
                        <ReviewList />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Reviews;
