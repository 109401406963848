import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import CreateNewTag from "../ProductTags/CreateNewTag";

const CreateTagingPage = () => {
    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <div className="bg-white">
                            <CreateNewTag productTagDetails={null} pageMode="create"/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default CreateTagingPage;