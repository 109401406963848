import {
  SET_PLA_FEED,
  SET_PLA_FEEDS,
  SET_PLA_LOADER,
  UPDATE_PLA_FEED,
  SET_DOWNLOAD_DUMP_LOADER,
} from "../actionTypes/plaFeedActionTypes";

const initialState: any = {
  plaFeeds: {},
  plaFeed: {},
  isLoading: false,
  downloadDumpLoader: false,
};

const blocksReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_PLA_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_PLA_FEEDS:
      return {
        ...state,
        plaFeeds: action.payload,
      };
    case SET_PLA_FEED:
      return {
        ...state,
        plaFeed: action.payload,
      };

    case SET_DOWNLOAD_DUMP_LOADER:
      return {
        ...state,
        downloadDumpLoader: action.payload,
      };
    // case UPDATE_PLA_FEED:
    //     return {
    //         ...state,
    //         plaFeed: {
    //             ...state.plaFeeds,
    //             plaFeed: state.plaFeeds?.map((d: any) => d.tagId === action.payload.tagId ? action.payload : d)
    //         }
    //     };
    default:
      return state;
  }
};

export default blocksReducer;
