import axios from "axios";
import { apis } from './params';

export function getArticleCategoryIdService(id: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_ARTICLE_CATEGORY_ID + id,
  })
}

export function createArticleService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.CREATE_ARTICLE,
    "data": _data
  })

}

export function getArticleListingService(_param: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_ARTICLE_LISTING + _param

  })

}

export function getArticleByIdService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_ARTICLE_LISTING_BY_ID + _param,
  });
}

export function publishArticleService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.PUBLISH_ARTICLE + _data
  })

}

export function updateArticleService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.UPDATE_ARTICLE,
    "data": _data
  })
}

export function deleteArticleService(_param: any) {
  return axios({
    "method": "Delete",
    "url": `${apis.DELETE_ARTICLE_LIST}${_param.id}?userEmail=${_param.email}`,
    "data": _param
  });
}