import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import Header from "../../../components/Header/Header";
import ImageBlockParentForm from "../../../components/Image/ImageBlockParentForm";
import SideNavbar from "../../../components/Navbar/SideNavbar";
import {
  setImageBlock,
  getImageBlock,
} from "../../../../core/actionCreators/blocksActionCreator";

const Image = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [pageMode, setPageMode] = useState("");
  const { imageBlock, isLoading } = useSelector((s: any) => s.blocksStore);


  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === "/blocks/image/create") {
      setPageMode("CREATE");
    } else {
      if (params.id) {
        setPageMode("EDIT");
        dispatch(getImageBlock(params.id, null));
      }
    }
    return () => {
      dispatch(setImageBlock({}));
    };
  }, [params, window]);

  const backArrowClickHadler = () => {
    Navigate("/blocks/image");
  };

  return (
    <>
      <div className="flex mainWrapper w-full">
        <div className="sidenav-wrapper">
          <SideNavbar />
        </div>
        <div className="right-container">
          <div className="header-container">
            <Header />
          </div>
          <div className="body-container">
            <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
              <div className="flex items-center w-full">
                <ArrowLeftSLineIcon
                  onClick={backArrowClickHadler}
                  size={24}
                  className="mr-4 cursor-pointer"
                />
                <p className="text-xl font-bold text-[#43425D] flex items-center">
                  {pageMode === "CREATE" ? "Create" : "Update"} Image Block
                </p>
              </div>
            </div>
            <ImageBlockParentForm loaderFlag={isLoading} pageMode={pageMode} imageBlock={imageBlock} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Image;
