import CreateNewTag from "./CreateNewTag";

interface CreateNewTagProps {
    productTagDetails: any;
    pageMode: string;
}

const RightSideContainer: React.FC<CreateNewTagProps> = ({ productTagDetails, pageMode })=> {
    return (
        <>
            <div className="w-[calc(100%-30%)] ">
                <CreateNewTag productTagDetails={productTagDetails} pageMode={pageMode}/>
            </div>
        </>
    )
}
export default RightSideContainer;