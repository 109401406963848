import { useNavigate } from "react-router-dom";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";

const CreateBrandBlockSubHeader = (props: any) => {
  const { pageMode } = props;
  const navigate = useNavigate();

  const backArrowClickHadler = () => {
    navigate("/blocks/brand");
  };
  return (
    <>
      <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
        <div className="flex items-center w-full">
          <ArrowLeftSLineIcon
            onClick={backArrowClickHadler}
            size={24}
            className="mr-4 cursor-pointer"
          />
          <p className="text-xl font-bold text-[#43425D] flex items-center">
            {pageMode === "CREATE" ? "Create" : "Update"} Brand Block
          </p>
        </div>
      </div>
    </>
  );
};
export default CreateBrandBlockSubHeader;
