import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import CommonPagination from "../CommonPagination/CommonPagination";
import { GET_AI_DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import FileUploadFillIcon from "remixicon-react/FileUploadFillIcon"
import { useDispatch } from "react-redux";
import { uploadProductDescription, trackProductDescription } from "../../../core/actionCreators/aiPoweredActionCreator";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { convertJsonToCsv } from "../../../utils/helper";
import { RiLoader2Fill } from "react-icons/ri";
import GenerateProductList from "./GenerateProductList";
import queryString from "query-string";


const GenerateProductDescription = () => {
    const dispatch = useDispatch()
    const { user } = useSelector((s: any) => s.authStore);
    const { isLoading, productDescriptionStatus } = useSelector((s: any) => s.aiStore)
    const [file, setFile] = useState("")
    const EXPECTED_COLUMNS = ["msns"]
    const [requestPayload, setRequestPayload] = useState({});

    useEffect(() => {
        dispatch(trackProductDescription(`${user.email || "dev@moglix.com"}`, () => {
            // toast.success("Product description fetched successfully");
        }));
    }, [])

    const handleFileUpload = (event: any) => {
        const file = event.target.files[0];
        if (!file || !file.name.endsWith(".csv")) {
            toast.error('Please upload an csv file.')
            return;
        }

        const reader = new FileReader();
        reader.onload = (e: any) => {
            const data = new Uint8Array(e.target.result);
            const textDecoder = new TextDecoder('utf-8');
            const csvText = textDecoder.decode(data);

            // Parse CSV content
            const rows = csvText.split('\n')
            const headers = rows[0].split(',').map((h) => h.trim().replace("\r", ""));
            
            const missingColumns = EXPECTED_COLUMNS.filter(col => !headers.includes(col.trim()));

            if (missingColumns.length > 0) {
                toast.error(`Missing columns: ${missingColumns.join(', ')}`);
                return;
            }

            const jsonData = rows.slice(1).map(row => {
                if (row.trim() === "") {
                    // Skip empty rows
                    return null;
                }

                const values = row.split(',');
                const rowObject: { [key: string]: string } = {};
                headers.forEach((header, index) => {
                    rowObject[header] = values[index];
                });
                return rowObject;
            }).filter(row => row !== null);

            const msns = jsonData.map(d => d?.msns?.toUpperCase())

            if (msns.length === 0) {
                toast.error('No "msn" values found.')
                return
            }

            setRequestPayload({
                "msns": msns,
                "username": user.email || "dev@moglix.com"
            })

        };
        reader.readAsArrayBuffer(file);
    };

    const uploadFileClickHandler = () => {
        if (Object.keys(requestPayload).length === 0) {
            toast.error("Data set is empty.")
            return
        }

        dispatch(uploadProductDescription(requestPayload, () => {
            toast.success("Uploaded successfully")
            setFile("")
            dispatch(trackProductDescription(`${user.email || "dev@moglix.com"}`, null));
        }))

    }

    const downloadSampleFile = () => {
        const data = [
            { msns: 'MSN2VZF4Q9C5QV' }
            // Add more data objects if needed
        ];
        convertJsonToCsv(data, 'sample.csv')
    }

    return (<>
        <div className="mx-5 mb-2.5 bg-white p-5 rounded border border-[#EAEAEA] mt-5">
            <div className="bg-white p-5 rounded border border-[#EAEAEA]">
                <p className="text-xs font-semibold text-[#303030] mb-2.5">Upload File (csv)</p>
                <div className="flex w-full justify-between mb-px last:mb-0">
                    <div className="flex items-center w-[100%]">
                        <div className="w-full">
                            <div className="relative pr-4">
                                <input onChange={handleFileUpload} type="file" accept=".csv" className="w-full rounded-sm border border-gray-300 outline-none text-xs pl-7 px-2.5 py-1.5 text-[#303030] bg-[#E5EEFC]" />
                                <FileUploadFillIcon size={16} className="absolute left-2.5 top-1.5" />
                            </div>
                        </div>
                        <button onClick={uploadFileClickHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">{isLoading ? "Uploading" : "Upload"}</button>
                    </div>
                </div>
                <button onClick={downloadSampleFile} className="text-[10px] text-[#3c3c3c]">Download Sample File</button>
            </div>
        </div>



        {/* Track Product Description Table */}
        <div className="mx-5 mb-2.5 bg-white p-5 rounded border border-[#EAEAEA] mt-10">
            <table className="w-full">
                <thead className="bg-[#343434]">
                    <tr>
                        <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[25%]">
                            Uploaded By
                        </th>

                        <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[25%]">
                            Uploaded At
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[25%]">
                            Status
                        </th>
                        <th className="px-4 py-3 text-bottom text-xs font-semibold text-white w-[25%]">
                            Action
                        </th>
                    </tr>
                </thead>
                <GenerateProductList data={productDescriptionStatus?.data || []} />
                {/* {isLoading ? (<Loader />) : (<GenerateProductList data={productDescriptionStatus?.data || []} />)} */}
            </table>
        </div>
    </>)
}

export default GenerateProductDescription;