import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import QAPopUp from "../Popup/QAPopUp";

const QAListItem = (props: any) => {
    const store: any = useSelector(s => s)
    const { user } = store.authStore
    const { data,selectedQuestionIds ,setSelectedQuestionIds } = props
    const [qAPopupData, setQAPopupData] = useState({
        open: false,
        qid: ""
    });

    const handleClickEvent = (openPoupup: boolean, currentqid: any) => {
        setQAPopupData((qAPopupData) => ({
            ...qAPopupData,
            qid: currentqid,
            open: openPoupup
        }));
    };

    // useEffect(() => {
    // }, [qAPopupData]);

    function formatDate(date: string): string {
        const originalDate = new Date(date);
        const year = originalDate.getUTCFullYear();
        const month = String(originalDate.getUTCMonth() + 1).padStart(2, '0');
        const day = String(originalDate.getUTCDate()).padStart(2, '0');
        const hours = String(originalDate.getUTCHours()).padStart(2, '0');
        const minutes = String(originalDate.getUTCMinutes()).padStart(2, '0');
        const seconds = String(originalDate.getUTCSeconds()).padStart(2, '0');
        const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
    }

    const handleQuestionSelect = (questionId : any, published :any) => {
        const existingIndex = selectedQuestionIds.findIndex((item: { questionId: any; }) => item.questionId === questionId);
        if (existingIndex !== -1) {
          // If the questionId is already selected, remove it from the array
          setSelectedQuestionIds((prevSelectedQuestionIds: any[]) =>
            prevSelectedQuestionIds.filter(item => item.questionId !== questionId)
          );
        } else {
          // If the questionId is not selected, add it to the array
          setSelectedQuestionIds((prevSelectedQuestionIds: any) =>
            [...prevSelectedQuestionIds, { questionId, published }]
          );
        }
      };
    return (
        <>
            {
                qAPopupData.open &&
                <QAPopUp qAPopupData={qAPopupData} setQAPopupData={setQAPopupData} user={user} />
            }
            {data.map((_d: any) =>
                <tr className='[&:nth-child(even)]:bg-[#f7f7f7]'>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
                        <input type="checkbox" name="selectedQuestion"
                            onChange={() => handleQuestionSelect(_d.id,_d.status)} className="h-4 w-4" />
                    </td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{_d.id}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{_d.productMsn}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{_d.customerId}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{_d.categoryName}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{_d.questionText}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{_d.status}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{formatDate(_d.created)}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{formatDate(_d.modified)}</td>
                    <td onClick={() => handleClickEvent(true, _d.id)} className="px-4 py-3 text-left text-xs text-[#3B86FF] font-bold cursor-pointer">{_d.status === "ANSWERED" ? "UPDATE" : "ADD"}</td>
                </tr>)}

        </>
    )
}

export default QAListItem