import React, { useEffect, useState } from "react";
import "./ComponentList.scss";
import { useDispatch } from "react-redux";
import SideNavbar from "../../components/Navbar/SideNavbar";
import { useNavigate, useParams } from "react-router-dom";
import { copyComponent, getComponents, getLayout } from "../../../core/actionCreators/lmsActionCreator";
import { useSelector } from "react-redux";
import ComponentHeader from "../../components/Header/ComponentHeader";
import Loader from "../../components/Loader/Loader";

const ComponentList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [layoutId, setLayoutId] = useState("")
  let { layout, components, isLoading } = useSelector((s: any) => s.lmsStore);
  const { user } = useSelector((s: any) => s.authStore);

  const [selectedComponents, setSelectedComponents] = useState(Array())

  const selectComponentHandler = (e: any) => {
    const isChecked = e.target.checked
    const component = JSON.parse(e.target.value)
    if (isChecked) {
      selectedComponents.push(component)
      setSelectedComponents([...selectedComponents])
    }
    else {
      const filteredData = selectedComponents.filter((d) => d.id !== component.id)
      setSelectedComponents([...filteredData])
    }
  }

  const addComponentHandler = () => {
    if (selectedComponents.length == 0) {
      alert("Please select component.")
      return
    }
    const componentIds = selectedComponents.map((d) => {
      return {
        "componentId": d.id
      }
    })

    let requestPayload = {
      "layoutId": layoutId,
      "componentIds": componentIds,
      "modifiedBy": user.id || -1
    }

    let callback = (r: any) => {
      if (!r) {
        navigate(`/layout/${layoutId}`)
        return
      }
    }
    dispatch(copyComponent(requestPayload, callback))
  }


  useEffect(() => {
    if (params.layoutId) {
      setLayoutId(params.layoutId)
      dispatch(getComponents("", null))
      dispatch(getLayout(params.layoutId, null));
    }
  }, [params]);

  return (
    <>
      <div className="flex mainWrapper w-full">
        <div className="sidenav-wrapper">
          <SideNavbar />
        </div>
        <div className="right-container">
          <div className="header-container">
            <ComponentHeader layoutId={layoutId}
              layoutName={layout?.cmsLayoutResponse?.layoutName}
              device={layout?.cmsLayoutResponse?.device}
              layoutCode={layout?.cmsLayoutResponse?.layoutCode}

            />
          </div>

          <div className="search-component p-[15px] mt-[130px] pb-[90px]">
            <div className="search-component-inner">
              <h4 className="search-txt">Search result for “Keyword”</h4>

              {isLoading ? <Loader /> : <>
                {components?.data?.map((l: any, index: number) =>
                  <>
                    <div key={index} className="keyword-name flex mt-[20px]">
                      <input value={JSON.stringify(l)} onChange={(e) => selectComponentHandler(e)} className="mr-[10px]" type="checkbox" />
                      <div className="Keyword">
                        <h4 >{l.componentName} </h4>
                      </div>
                    </div>
                  </>
                )}
              </>}

              <div className="btm-fixed-strip ml-[200px]">
                <div className="btm-fixed-strip-inner bg-white flex justify-between items-center p-[15px]">
                  <p>{selectedComponents.length} component selected</p>
                  <button onClick={addComponentHandler} className="py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">
                    Select and Copy Component
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComponentList;
