import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteAttributeSchema } from "../../../core/actionCreators/attributeActionCreator";
import toast from "react-hot-toast";

const AttributeListItem = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data } = props;

  const updateNavigateHandler = (id: any) => {
    navigate(`/attributeListing/${id}`);
  };

  const deleteAttributeHandler = (id: any) => {
    const shouldDelete = window.confirm("Are you sure you want to delete?");
    if (shouldDelete) {
      dispatch(
        deleteAttributeSchema(
          {
            id: id,
          },
          () => {
            toast.success("Deleted successfully");
          }
        )
      );
    }
  };

  return (
    <>
      {data.map((_d: any, index: any) => (
        <tr key={index} className="[&:nth-child(odd)]:bg-[#f7f7f7]">
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[8%]">
            {_d.id}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[27%]">
            {_d.title}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">
            {_d.category}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
            {_d.productCount}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">
            {_d.isActive.toString()}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#337ab7] w-[10%] font-semibold cursor-pointer leading-5">
            <span onClick={() => updateNavigateHandler(_d.id)}>Update</span>
            <br></br>
            <span onClick={() => deleteAttributeHandler(_d.id)}>Delete</span>
          </td>
        </tr>
      ))}
    </>
  );
};
export default AttributeListItem;
