import { call, put, takeLatest, all } from "redux-saga/effects";
import { GET_QNA_LIST, ADD_UPDATE_ANSWER, PUBLISH_QUESTIONS, DISABLE_QUESTIONS, DOWNLOAD_QUESTIONS } from "../actionTypes/qnaActiontypes"
import { setQnaList, setQnaLoader } from "../actionCreators/qnaActionCreator";
import { getQnaList, addUpdateAnswerCall, publishQuestionsCall, disableQuestionsCall, downloadQuestionsCall } from "../services/qnaService";
import { toast } from "react-hot-toast";


function* _getQnaList(_data: any) {
    try {
        yield put(setQnaLoader(true));
        const { data } = yield call(getQnaList, _data.payload);
        yield put(setQnaLoader(false));
        if (data.status == true) {
            yield put(setQnaList(data.data))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setQnaLoader(false));
        console.log("_getQnaList: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _addUpdateAnswer(_data: any) {
    try {
        yield put(setQnaLoader(true));
        const { data } = yield call(addUpdateAnswerCall, _data.payload);
        yield put(setQnaLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setQnaLoader(false));
        console.log("_addUpdateAnswer: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _publishQuestions(_data: any) {
    try {
        yield put(setQnaLoader(true));
        const { data } = yield call(publishQuestionsCall, _data.payload);
        yield put(setQnaLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setQnaLoader(false));
        console.log("_publishQuestions: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _disableQuestions(_data: any) {
    try {
        yield put(setQnaLoader(true));
        const { data } = yield call(disableQuestionsCall, _data.payload);
        yield put(setQnaLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setQnaLoader(false));
        console.log("_disableQuestions: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _downloadQuestions(_data: any) {
    try {
        yield put(setQnaLoader(true));
        const { data } = yield call(downloadQuestionsCall, _data.payload);
        yield put(setQnaLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
            toast.success(data.message)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setQnaLoader(false));
        console.log("_downloadQuestions: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

export default function* qnaSaga() {
    yield all([
        takeLatest(GET_QNA_LIST, _getQnaList),
        takeLatest(ADD_UPDATE_ANSWER, _addUpdateAnswer),
        takeLatest(PUBLISH_QUESTIONS, _publishQuestions),
        takeLatest(DISABLE_QUESTIONS, _disableQuestions),
        takeLatest(DOWNLOAD_QUESTIONS, _downloadQuestions)

    ])
}