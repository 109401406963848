import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { toast } from "react-hot-toast";
import {
  downloadAttributeDump,
  bulkUploadAttributeFile,
} from "../../../core/actionCreators/attributeActionCreator";

const AttributeListingSubHeader = (props: any) => {
  const { searchCriteria } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((s: any) => s.authStore);
  const { downloadDumpLoader } = useSelector((s: any) => s.attributeStore);
  const [file, setFile] = useState("");
  const navigateToCreateAttributeListingHandler = () => {
    navigate("/attributeListing/create");
  };
  const fileHandler = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      setFile(file);
    }
  };
  const bulkUploadHandler = () => {
    if (!file) {
      toast.error("Please select file.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    dispatch(
      bulkUploadAttributeFile(
        {
          param: `email=${user.email || "deepak.singh1@moglix.com"}`,
          data: formData,
        },
        (d: any) => {
          toast.success("Uploaded successfully.");
        }
      )
    );
  };

  const downloadDumpHandler = () => {
    if (!searchCriteria.category) {
      toast.error("Please fill category code");
      return;
    }

    dispatch(
      downloadAttributeDump(
        `email=${user.email || "deepaksingh1@moglix.com"}&category=${
          searchCriteria.category
        }`,
        (d: any) => {
          toast.success(d.statusDescription);
        }
      )
    );
  };
  return (
    <>
      <div className="campaign-header bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
        <div className="flex items-center justify-between w-full">
          <p className="text-xl font-bold text-[#43425D] w-1/5">
            ATTRIBUTE LISTING
          </p>
          <div className="flex w-4/5 ml-auto justify-end">
            <input
              onChange={(e) => fileHandler(e)}
              type="file"
              className="border border-gray-300 py-2 rounded focus: outline-none px-1.5 text-xs w-1/4"
            />
            <button
              onClick={bulkUploadHandler}
              className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-5"
            >
              Bulk Upload
            </button>
            <button
              onClick={downloadDumpHandler}
              className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-1.5"
            >
              {" "}
              {downloadDumpLoader ? "Loading.." : "Download Dump"}
            </button>
            <button
              onClick={navigateToCreateAttributeListingHandler}
              className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-1.5"
            >
              Create Attribute listing
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default AttributeListingSubHeader;
