import * as actions from '../actionTypes/fbtActionTypes';

export const setFbtLoader = (_data: any) => {
    return {
        type: actions.SET_FBT_LOADER,
        payload: _data
    }
}

export const setDownloadDumpLoader = (_data: any) => {
    return {
        type: actions.SET_DOWNLOAD_DUMP_LOADER,
        payload: _data
    }
}

export const getFbts = (_data: any, _callback: any) => {
    return {
        type: actions.GET_FBTS,
        payload: _data,
        callback: _callback
    }
}

export const setFbts = (_data: any) => {
    return {
        type: actions.SET_FBTS,
        payload: _data,
    }
}

export const getFbtById = (_data: any, _callback: any) => {
    return {
        type: actions.GET_FBT_BY_ID,
        payload: _data,
        callback: _callback
    }
}

export const setFbt = (_data: any) => {
    return {
        type: actions.SET_FBT,
        payload: _data
    }
}

export const downloadFbtDump = (_data: any, _callback: any) => {
    return {
        type: actions.DOWNLOAD_FBT_DUMP,
        payload: _data,
        callback: _callback
    }
}

export const createFbt = (_data: any, _callback: any) => {
    return {
        type: actions.CREATE_FBT,
        payload: _data,
        callback: _callback
    }
}

export const updateFbt = (_data: any, _callback: any) => {
    return {
        type: actions.UPDATE_FBT,
        payload: _data,
        callback: _callback
    }
}

export const uploadFbtFile = (_data: any, _callback: any) => {
    return {
        type: actions.UPLOAD_FBT_FILE,
        payload: _data,
        callback: _callback
    }
}
