import * as actions from '../actionTypes/serviceabilityActionType';

export const setServiceabilityLoader = (_data: any) => {
    return {
        type: actions.SET_SERVICEABILITY_LOADER,
        payload: _data
    }
}

export const getServiceabilityList = (_data: any, _callback: any) => {
    return {
        type: actions.GET_SERVICEABILITY_LIST,
        payload: _data,
        callback: _callback
    }
}

export const setServiceabilityList = (_data: any) => {
    return {
        type: actions.SET_SERVICEABILITY_LIST,
        payload: _data
    }
}

export const uploadServiceabilityFile = (_data: any, _callback: any) => {
    return {
        type: actions.UPLOAD_SERVICEABILITY_FILE,
        payload: _data,
        callback: _callback
    }
}
