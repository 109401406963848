export const GET_BRAND_BLOCKS = "GET_BRAND_BLOCKS";
export const SET_BRAND_BLOCKS = "SET_BRAND_BLOCKS";
export const GET_BRAND_BLOCK = "GET_BRAND_BLOCK";
export const DELETE_BRAND_BLOCK = "DELETE_BRAND_BLOCK";
export const DELETE_BRAND_BLOCK_ELEMENT = "DELETE_BRAND_BLOCK_ELEMENT";
export const SET_DELETE_BRAND_BLOCK = "SET_DELETE_BRAND_BLOCK";
export const SET_BRAND_BLOCK = "SET_BRAND_BLOCK";
export const CREATE_BRAND_BLOCK = "CREATE_BRAND_BLOCK";
export const SET_BLOCKS_LOADER = "SET_BLOCKS_LOADER";
export const UPDATE_BRAND_BLOCK = "UPDATE_BRAND_BLOCK";

export const GET_CATEGORY_BLOCKS = "GET_CATEGORY_BLOCKS"
export const SET_CATEGORY_BLOCKS = "SET_CATEGORY_BLOCKS"
export const GET_CATEGORY_BLOCK = "GET_CATEGORY_BLOCK"
export const SET_CATEGORY_BLOCK = "SET_CATEGORY_BLOCK"
export const CREATE_CATEGORY_BLOCK = "CREATE_CATEGORY_BLOCK"
export const UPDATE_CATEGORY_BLOCK = "UPDATE_CATEGORY_BLOCK";
export const DELETE_CATEGORY_BLOCK = "DELETE_CATEGORY_BLOCK";
export const DELETE_CATEGORY_BLOCK_ELEMENT = "DELETE_CATEGORY_BLOCK_ELEMENT";
export const SET_DELETE_CATEGORY_BLOCK = "SET_DELETE_CATEGORY_BLOCK";

export const GET_STATIC_HTML_BLOCKS = "GET_STATIC_HTML_BLOCKS"
export const SET_STATIC_HTML_BLOCKS = "SET_STATIC_HTML_BLOCKS"
export const GET_STATIC_HTML_BLOCK = "GET_STATIC_HTML_BLOCK"
export const SET_STATIC_HTML_BLOCK = "SET_STATIC_HTML_BLOCK"
export const CREATE_STATIC_HTML_BLOCK = "CREATE_STATIC_HTML_BLOCK"
export const UPDATE_STATIC_HTML_BLOCK = "UPDATE_STATIC_HTML_BLOCK"
export const DELETE_STATIC_HTML_BLOCK = "DELETE_STATIC_HTML_BLOCK"
export const SET_DELETE_STATIC_HTML_BLOCK = "SET_DELETE_STATIC_HTML_BLOCK";

export const GET_IMAGE_BLOCKS = "GET_IMAGE_BLOCKS"
export const SET_IMAGE_BLOCKS = "SET_IMAGE_BLOCKS"
export const GET_IMAGE_BLOCK = "GET_IMAGE_BLOCK"
export const SET_IMAGE_BLOCK = "SET_IMAGE_BLOCK"
export const CREATE_IMAGE_BLOCK = "CREATE_IMAGE_BLOCK"
export const UPDATE_IMAGE_BLOCK = "UPDATE_IMAGE_BLOCK"
export const DELETE_IMAGE_BLOCK = "DELETE_IMAGE_BLOCK";
export const SET_DELETE_IMAGE_BLOCK = "SET_DELETE_IMAGE_BLOCK";
export const DELETE_IMAGE_BLOCK_ELEMENT = "SET_DELETE_IMAGE_BLOCK_ELEMENT";

export const GET_PRODUCT_BLOCKS = "GET_PRODUCT_BLOCKS"
export const SET_PRODUCT_BLOCKS = "SET_PRODUCT_BLOCKS"
export const GET_PRODUCT_BLOCK = "GET_PRODUCT_BLOCK"
export const SET_PRODUCT_BLOCK = "SET_PRODUCT_BLOCK"
export const CREATE_PRODUCT_BLOCK = "CREATE_PRODUCT_BLOCK"
export const UPDATE_PRODUCT_BLOCK = "UPDATE_PRODUCT_BLOCK"
export const DELETE_PRODUCT_BLOCK = "DELETE_PRODUCT_BLOCK";
export const SET_DELETE_PRODUCT_BLOCK = "SET_DELETE_PRODUCT_BLOCK";
export const DELETE_PRODUCT_BLOCK_ELEMENT = "SET_DELETE_PRODUCT_BLOCK_ELEMENT";

export const GET_GENERAL_BLOCKS = "GET_GENERAL_BLOCKS"
export const SET_GENERAL_BLOCKS = "SET_GENERAL_BLOCKS"
export const GET_GENERAL_BLOCK = "GET_GENERAL_BLOCK"
export const SET_GENERAL_BLOCK = "SET_GENERAL_BLOCK"
export const CREATE_GENERAL_BLOCK = "CREATE_GENERAL_BLOCK"
export const UPDATE_GENERAL_BLOCK = "UPDATE_GENERAL_BLOCK"
export const DELETE_GENERAL_BLOCK = "DELETE_GENERAL_BLOCK";
export const DELETE_GENERAL_BLOCK_ELEMENT = "DELETE_GENERAL_BLOCK_ELEMENT";
export const SET_DELETE_GENERAL_BLOCK = "SET_DELETE_GENERAL_BLOCK";

export const GET_TEMPLATE_BLOCKS = "GET_TEMPLATE_BLOCKS"
export const SET_TEMPLATE_BLOCKS = "SET_TEMPLATE_BLOCKS"
export const GET_TEMPLATE_BLOCK = "GET_TEMPLATE_BLOCK"
export const SET_TEMPLATE_BLOCK = "SET_TEMPLATE_BLOCK"
export const CREATE_TEMPLATE_BLOCK = "CREATE_TEMPLATE_BLOCK"
export const UPDATE_TEMPLATE_BLOCK = "UPDATE_TEMPLATE_BLOCK"
export const DELETE_TEMPLATE_BLOCK = "DELETE_TEMPLATE_BLOCK";
export const DELETE_TEMPLATE_BLOCK_ELEMENT = "DELETE_TEMPLATE_BLOCK_ELEMENT";
export const SET_DELETE_TEMPLATE_BLOCK = "SET_DELETE_TEMPLATE_BLOCK"

export const GET_BLOCK_TYPES_BLOCKS = "GET_BLOCK_TYPES_BLOCKS"
export const SET_BLOCK_TYPE_BLOCKS = "SET_BLOCK_TYPE_BLOCKS"
export const GET_BLOCK_TYPE_BLOCK = "GET_BLOCK_TYPE_BLOCK"
export const SET_BLOCK_TYPE_BLOCK = "SET_BLOCK_TYPE_BLOCK"
export const CREATE_BLOCK_TYPE_BLOCK = "CREATE_BLOCK_TYPE_BLOCK"
export const UPDATE_BLOCK_TYPE_BLOCK = "UPDATE_BLOCK_TYPE_BLOCK"
export const DELETE_BLOCK_TYPE_BLOCK = "DELETE_BLOCK_TYPE_BLOCK";
export const SET_DELETE_BLOCK_TYPE_BLOCK = "SET_DELETE_BLOCK_TYPE_BLOCK"


export const GET_BLOCK_LAYOUTS = "GET_BLOCK_LAYOUTS"
export const SET_BLOCK_LAYOUTS = "SET_BLOCK_LAYOUTS"
export const GET_BLOCK_LAYOUT = "GET_BLOCK_LAYOUT"
export const SET_BLOCK_LAYOUT = "SET_BLOCK_LAYOUT"
export const CREATE_BLOCK_LAYOUT = "CREATE_BLOCK_LAYOUT"
export const UPDATE_BLOCK_LAYOUT = "UPDATE_BLOCK_LAYOUT"
