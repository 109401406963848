import {
    SET_LMS_LOADER, SET_COMPONENT, SET_COMPONENTS,
    SET_LAYOUT, SET_LAYOUTS, SET_STATIC_FORM_FIELDS, SET_POPULATED_LAYOUT, RESET,
    SET_LAYOUT_JSON_BY_LAYOUT_CODE, SET_POPULATED_COMPONENT, SET_LAYOUT_JSON_API_LOADER
} from '../actionTypes/lmsActionTypes';

const initialState: any = {
    components: [],
    component: {},
    isLoading: false,
    layouts: {},
    layout: {},
    staticFormFields: [],
    populatedLayout: {},
    layoutJson: {},
    populatedComponent: [],
    layoutJsonApiLoader: false
}

const lmsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_LMS_LOADER:
            return {
                ...state,
                isLoading: action.payload
            }
        case SET_COMPONENT:
            return {
                ...state,
                component: action.payload
            }
        case SET_COMPONENTS:
            return {
                ...state,
                components: action.payload
            }
        case SET_LAYOUT:
            return {
                ...state,
                layout: action.payload
            }
        case SET_LAYOUTS:
            return {
                ...state,
                layouts: action.payload
            }
        case SET_STATIC_FORM_FIELDS:
            return {
                ...state,
                staticFormFields: action.payload
            }
        case SET_POPULATED_LAYOUT:
            return {
                ...state,
                populatedLayout: action.payload
            }
        case SET_LAYOUT_JSON_BY_LAYOUT_CODE:
            return {
                ...state,
                layoutJson: action.payload
            }
        case SET_LAYOUT_JSON_API_LOADER:
            return {
                ...state,
                layoutJsonApiLoader: action.payload
            }
        case SET_POPULATED_COMPONENT:
            return {
                ...state,
                populatedComponent: action.payload
            }
        case RESET:
            return initialState;
        default:
            return state;
    }
}

export default lmsReducer