import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import queryString from 'query-string';
import { getFbts } from "../../../core/actionCreators/fbtActionCreator";
import { GET_FBTS_DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import { useSelector } from "react-redux";
import FbtListItem from "./FbtListItem";
import Loader from "../Loader/Loader";
import CommonPagination from "../CommonPagination/CommonPagination";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";

const FbtListData = () => {
    const dispatch = useDispatch()
    const { fbts, isLoading } = useSelector((s: any) => s.fbtStore)

    const [searchCriteria, setSearchCriteria] = useState({
        pageNumber: 0,
        pageSize: GET_FBTS_DEFAULT_PAGE_SIZE,
        msn: "",
        valueMsn: "",
        isActive: ""
    } as any)

    const previousSearchCriteriaRef = useRef(searchCriteria);


    useEffect(() => {
        const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
        const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
        const previousCriteria = previousSearchCriteriaRef.current;
        const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

        if (searchFilterChanged) {
            const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
            for (let key in parsedSearchCriteria) {
                if (["msn", "valueMsn"].includes(key)) {
                    parsedSearchCriteria.pageNumber = 0
                    break
                }
            }
            dispatch(getFbts(queryString.stringify(parsedSearchCriteria), null));
            previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
        }
    }, [searchCriteria]);


    const onPageChange = (pageNumber: any) => {
        setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
    }

    return (
        <>
            <div className="mt-2.5 campaign-list bg-white mb-[54px]">
                <table className="w-full">
                    <thead className="bg-[#343434]">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[6%]">Id</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]"> Msn</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[24%]">FBT Msns</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">Created At</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]"> Modified At</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[12%]">Status</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[6%]">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-white">
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[6%]">
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">
                                <input value={searchCriteria.msn} onChange={(e) => setSearchCriteria({ ...searchCriteria, msn: e.target.value })} type="text" className="border border-gray-300 h-7 rounded-sm focus: outline-none px-1.5 text-xs w-full"></input>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[24%]">
                                <input value={searchCriteria.valueMsn} onChange={(e) => setSearchCriteria({ ...searchCriteria, valueMsn: e.target.value })} type="text" className="border border-gray-300 h-7 rounded-sm focus:outline-none px-1.5 text-xs w-full"></input>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[12%]">
                                <select value={searchCriteria.isActive} onChange={(e) => setSearchCriteria({ ...searchCriteria, isActive: e.target.value })} className="bg-white border border-gray-300 h-7 rounded-sm focus: outline-none px-1.5 text-xs w-full">
                                    <option>Select</option>
                                    <option value={"true"}>Active</option>
                                    <option value={"false"}>Inactive</option>
                                </select>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[6%]"></td>
                        </tr>
                        {isLoading ? <Loader /> : <FbtListItem data={fbts?.data || []} />}
                    </tbody>
                </table>
            </div>
            {fbts.data && <CommonPagination data={fbts} PAGE_SIZE={GET_FBTS_DEFAULT_PAGE_SIZE} onPageChange={onPageChange} />}
        </>
    )
}
export default FbtListData;