import { useNavigate } from "react-router-dom";
import LayoutTypeListItem from "./LayoutTypeListItem";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { GET_GENERAL_BLOCK_DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import { useEffect, useRef, useState } from "react";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";
import queryString from "query-string";
import { getBlockTypeBlocks } from "../../../core/actionCreators/blocksActionCreator";
import Loader from "../Loader/Loader";
import CommonPagination from "../CommonPagination/CommonPagination";

const LayoutTypeList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { blockTypeBlocks, isLoading } = useSelector((s: any) => s.blocksStore);

    const [searchCriteria, setSearchCriteria] = useState({
        id: "",
        name: "",
        status: "",
        pageNumber: 0,
        pageSize: GET_GENERAL_BLOCK_DEFAULT_PAGE_SIZE,
    } as any);

    const previousSearchCriteriaRef = useRef(searchCriteria);

    useEffect(() => {
        const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
        const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
        const previousCriteria = previousSearchCriteriaRef.current;
        const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

        if (searchFilterChanged) {
            const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
            for (let key in parsedSearchCriteria) {
                if (["id", "name", "status"].includes(key)) {
                    parsedSearchCriteria.pageNumber = 0
                    break
                }
            }
            dispatch(getBlockTypeBlocks(queryString.stringify(parsedSearchCriteria), null));
            previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
        }
    }, [searchCriteria]);

    const onPageChange = (pageNumber: any) => {
        setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
    }

    const navigateToCreateLayoutListBlockHandler = () => {
        navigate('/blocks/layoutType/create');
    }
    return (
        <>
            <div className="flex justify-between items-center pt-4 px-4">
                <p className="text-base text-[#343434]">Block Types</p>
                <button onClick={navigateToCreateLayoutListBlockHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">Create Block Types</button>
            </div>
            <div className="mt-4 campaign-list bg-white mb-[54px]">
                <table className="w-full" >
                    <thead className="bg-[#343434]">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">ID</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[30%]"> Name</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]">Created At</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Updated At</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Status</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Update</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <LayoutTypeListItem data={blockTypeBlocks?.data || []} />
                        )}
                    </tbody>
                </table>
            </div>
            {blockTypeBlocks?.data && (
                <CommonPagination
                    data={blockTypeBlocks}
                    PAGE_SIZE={GET_GENERAL_BLOCK_DEFAULT_PAGE_SIZE}
                    onPageChange={onPageChange}
                />
            )}
        </>
    )
}
export default LayoutTypeList;