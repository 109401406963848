import React from "react";
import { useNavigate } from "react-router-dom";


const FbtListItem = (props: any) => {
    const navigate = useNavigate()
    const { data } = props

    const updateFbtNavigateHandler = (id: any) => {
        navigate(`/fbt/${id}`)
    }

    return (
        <>
            {data.map((_d: any) =>
                <tr className='[&:nth-child(odd)]:bg-[#f7f7f7] bg-white'>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[6%]">{_d.id}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">{_d.msn}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[24%]">{_d.fbtMsns.slice(0, 50)}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">{_d.createdAt}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">{_d.modifiedAt}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[12%]">{_d.isActive ? "Active" : "Inactive"}</td>
                    <td onClick={() => updateFbtNavigateHandler(_d.id)} className="px-4 py-3 text-left text-xs text-[#337ab7] font-semibold w-[6%]">Update</td>
                </tr>)}
        </>
    )
}
export default FbtListItem;