import { useNavigate } from "react-router-dom";
import { formatTimestamp } from "../../../utils/helper";

const PlaFeedListItem = (props: any) => {
  const navigate = useNavigate();
  const { data } = props;

  const updatePlaFeedNavigateHandler = (uid: any) => {
    navigate(`/plaFeed/${uid}`);
  };

  return (
    <>
      {data.map((item: any, index: number) => (
        <tr className="[&:nth-child(odd)]:bg-[#f7f7f7]" key={index}>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[14%]">
            {item?.id}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">
            {item?.customLabel0}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
            {item?.productType}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
            {item.countryCode == "356" ? "India" : "UAE"}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[10%]">
            {item?.availability}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[8%]">
            {item.shoppingAds ? "True" : "False"}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[10%]">
            {formatTimestamp(item?.modifiedAt)}
          </td>
          <td
            onClick={() => updatePlaFeedNavigateHandler(item.uid)}
            className="px-4 py-3 text-left text-xs text-[#337ab7] w-[8%] font-semibold cursor-pointer"
          >
            Update
          </td>
        </tr>
      ))}
    </>
  );
};
export default PlaFeedListItem;
