import { useEffect, useState } from "react";
import { getBlockBlockTypes, getBlockBlocks, getBlockLayoutGroups } from "../../../core/services/blocksServices";
import toast from "react-hot-toast";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import { useNavigate } from "react-router-dom";

const LayoutBlockElementForm = (props: any) => {
    const {
        data,
        layoutBlockPayload,
        setLayoutBlockPayload,
        removeElementHandler,
    } = props;

    const [blockTypes, setBlockTypes] = useState([])
    const [selectedBlockTypeId, setSelectedBlockTypeId] = useState("")
    const [selectedBlockId, setSelectedBlockId] = useState("")
    const [blocks, setBlocks] = useState([])
    const navigate = useNavigate()


    useEffect(() => {
        getBlockBlockTypes().then(((resp) => {
            const { data } = resp
            if (data.status && data.data && Array.isArray(data.data)) {
                setBlockTypes(data.data)
                if (props.pageMode) {
                    // Edit Page Mode
                    if (props.pageMode === "EDIT") {
                        setSelectedBlockTypeId(props.data.type)
                    }
                    else {
                        setSelectedBlockTypeId(data.data[0].id)
                        formHandlerV2("BLOCK_TYPE", data.data[0].id, data.data)
                    }
                }
            }
        })).catch((err) => {
            console.log(err)
            toast.error("Something went wrong!")
        })
    }, [data])

    useEffect(() => {
        if (selectedBlockTypeId) {
            getBlockBlocks(selectedBlockTypeId).then((resp) => {
                const { data } = resp
                if (data.status && data.data && Array.isArray(data.data)) {
                    let blocks = data.data
                    setBlocks(blocks)
                    if (props.pageMode) {
                        //Edit Page Mode
                        if (props.pageMode === "EDIT") {
                            setSelectedBlockId(props.data.blockId)
                            blocks = [{
                                "id": props.data.blockId,
                                "name": props.data.blockLabel
                            }, ...blocks]
                            setBlocks(blocks)
                            formHandlerV2("BLOCK", blocks[0].id, blocks)
                        }
                        else {
                            setSelectedBlockId(data.data[0].id)
                            formHandlerV2("BLOCK", data.data[0].id, blocks)
                        }
                    }
                }
            }).catch((err) => {
                console.log(err)
                toast.error("Something went wrong!")
            })
        }
    }, [selectedBlockTypeId])



    const formHandlerV2 = (formType: any, value: any, dataset: any) => {
        //Only for CREATE pageMode
        if (layoutBlockPayload.layoutBlockList && Array.isArray(layoutBlockPayload.layoutBlockList)) {
            const elementIndex = layoutBlockPayload.layoutBlockList.findIndex(
                (d: any) => d.id === data.id
            );
            if (elementIndex === -1) {
                toast.error("Something went wrong.");
                return;
            }
            const element = layoutBlockPayload.layoutBlockList[elementIndex];


            if (formType === "BLOCK_TYPE") {
                element.type = Number(value)
            }
            else if (formType === "BLOCK") {
                const block: any = dataset.find((d: any) => d.id === Number(value))
                if (!block) {
                    toast.error("Something went wrong with the block!")
                    return
                }
                element.blockId = block.id
                element.blockLabel = block.name
            }
            else {
                toast.error("Something went wrong!")
            }

            layoutBlockPayload.layoutBlockList[elementIndex] = element;
            setLayoutBlockPayload({ ...layoutBlockPayload })
        }
    };
    const navigateToAssicatedBlock = (data: any) => {
        const { typeName, blockId } = data;
        const typeToPathMap: { [key: string]: string } = {
            "general": "general",
            "product": "product",
            "image": "image",
            "static html": "staticHtml",
            "category": "category",
            "brand": "brand",
        };
        const path = typeToPathMap[typeName];
        if (path) {
            navigate(`/blocks/${path}/${blockId}`);
        }
    }

    const formHandler = (formType: any, e: any) => {
        if (layoutBlockPayload.layoutBlockList && Array.isArray(layoutBlockPayload.layoutBlockList)) {
            const elementIndex = layoutBlockPayload.layoutBlockList.findIndex(
                (d: any) => d.id === data.id
            );
            if (elementIndex === -1) {
                toast.error("Something went wrong.");
                return;
            }
            const element = layoutBlockPayload.layoutBlockList[elementIndex];

            if (formType === "VISIBLE") {
                element.visible = e.target.value
            }
            else if (formType === "BLOCK_ORDER") {
                element.blockOrder = e.target.value
            }
            else if (formType === "BLOCK_TYPE") {
                setSelectedBlockTypeId(e.target.value)
                element.type = e.target.value
            }
            else if (formType === "BLOCK") {
                setSelectedBlockId(e.target.value)
                const block: any = blocks.find((d: any) => d.id === Number(e.target.value))
                if (!block) {
                    toast.error("Something went wrong with the block!")
                    return
                }
                element.blockId = block.id
                element.blockLabel = block.name
            }
            else {
                toast.error("Something went wrong!")
            }
            layoutBlockPayload.layoutBlockList[elementIndex] = element;
            setLayoutBlockPayload({ ...layoutBlockPayload })
        }
    };

    return (
        <>
            <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-4">
                <div>
                    <CloseCircleFillIcon onClick={() => removeElementHandler(data.id)} className="ml-auto cursor-pointer" size={22} />
                </div>
                <form action="">
                    <div className="flex w-full mb-6 last:mb-0">
                        <div className="w-1/2 px-2">
                            <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Block Type</label>
                            <select value={data.type} onChange={(e) => formHandler("BLOCK_TYPE", e)} className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                {blockTypes?.map((d: any) =>
                                    <option value={d.id}>{d.name}</option>
                                )}
                            </select>
                        </div>
                        <div className="w-1/2 px-2">
                            <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Block</label>
                            <select value={data.blockId} onChange={(e) => formHandler("BLOCK", e)} className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                {blocks?.map((d: any) =>
                                    <option value={d.id}>{d.name}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="flex w-full mb-6 last:mb-0">
                        <div className="w-1/2 px-2">
                            <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Visible</label>
                            <select value={data.visible.toString()} onChange={(e) => formHandler("VISIBLE", e)} className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                <option value={"true"}>Active</option>
                                <option value={"false"}>Inactive</option>
                            </select>
                        </div>
                        <div className="w-1/2 px-2">
                            <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Block Order</label>
                            <input value={data.blockOrder} required onChange={(e) => formHandler("BLOCK_ORDER", e)}
                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                type="text"
                            />
                        </div>
                    </div>
                    {props.pageMode === "EDIT" && <h6 onClick={() => navigateToAssicatedBlock(data)} className="text-blue-500 hover:text-blue-700 hover:underline cursor-pointer">
                        Associate Block
                    </h6>}
                </form>
            </div>
        </>
    )
}
export default LayoutBlockElementForm;