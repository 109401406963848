import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import SideNavbar from "../../components/Navbar/SideNavbar"
import ComponentPageHeader from "../../components/Header/ComponentPageHeader"
import { useDispatch } from "react-redux";
import { getPopulatedComponent, getStaticFormFields } from "../../../core/actionCreators/lmsActionCreator";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import ParentComponent from "../../components/Component/ParentComponent";
import AddComponentPopup from "../../components/Popup/AddComponentPopup";

const ComponentPage = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const [layoutId, setLayoutId] = useState("")
    const [componentId, setComponentId] = useState("")
    const [componentName, setComponentName] = useState("")
    const [addComponentPopup, setAddComponentPopup] = useState(false)
    let { populatedComponent, isLoading } = useSelector((s: any) => s.lmsStore);
    let { user } = useSelector((s: any) => s.authStore);

    useEffect(() => {
        dispatch(getStaticFormFields(null, null))
        if (params.layoutId) {
            setLayoutId(params.layoutId)
        }
        if (params.componentId) {
            setComponentId(params.componentId)
        }
        if (params.componentName) {
            setComponentName(params.componentName)
        }
    }, [params]);


    const fetchPopulatedComponent = () => {


        let callback = null
        dispatch(getPopulatedComponent(componentId, callback))
    }

    useEffect(() => {
        if (componentId) {
            fetchPopulatedComponent()
        }
    }, [componentId])

    return (<>
        {addComponentPopup && <AddComponentPopup page={"COMPONENT"}
            componentId={componentId} setAddComponentPopup={setAddComponentPopup} />}
        <div className="flex mainWrapper w-full">
            <div className="sidenav-wrapper">
                <SideNavbar />
            </div>
            <div className="right-container">
                <div className="header-container">
                    <ComponentPageHeader
                        setAddComponentPopup={setAddComponentPopup}
                        layoutId={layoutId}
                        componentName={componentName} />
                </div>

                {isLoading ? <Loader /> : <>
                    {populatedComponent ? <div className="body-container pt-[75px] pb-[15px] bg-sky-50">
                        {
                            populatedComponent.map((c: any, index: number) =>
                                <ParentComponent
                                    key={index}
                                    data={c}
                                    user={user}
                                    layoutId={layoutId}
                                    componentId={componentId}
                                    callbackFn={fetchPopulatedComponent}
                                    page="COMPONENT"
                                />
                            )
                        }
                    </div> : null}
                </>}
            </div>
        </div>
    </>)
}
export default ComponentPage