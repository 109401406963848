import jwt_decode from 'jwt-decode';
import { put, takeLatest, all } from "redux-saga/effects";

import { setUser, setLoader, loginWithMicrosoftFallback } from '../actionCreators/authActionCreators';
import { LOGIN_WITH_MICROSOFT } from "../actionTypes/authActionTypes";

function* loginWithMicrosoftSaga(_data: any) {
    try {
        const decoded: any = jwt_decode(_data.payload);
        window.localStorage.setItem("CMS_USER_AUTH_TOKEN", _data.payload)
        yield put(setUser(decoded))
        if (_data.callback) {
            _data.callback()
        }
    }
    catch (err: any) {
        yield put(setLoader(false));
        yield put(loginWithMicrosoftFallback(err?.response?._data));
    }
}


export default function* authSaga() {
    yield all([takeLatest(LOGIN_WITH_MICROSOFT, loginWithMicrosoftSaga)])
}
