import axios from "axios";
import { apis } from './params';

export function getReviewsService(_param: any) {
    return axios({
        "method": "Get",
        "url": apis.GET_REVIEWS + _param
    })
}

export function updateReviewService(_payload: any) {
    return axios({
        "method": "POST",
        "url": apis.UPDATE_REVIEW,
        "data": _payload
    })
}

export function reviewReplyService(_payload: any) {
    return axios({
        "method": "POST",
        "url": apis.REVIEW_REPLY,
        "data": _payload
    })
}

export function uploadReviewsFileService(_data: any) {
    return axios({
        "method": "Post",
        "url": apis.UPLOAD_REVIEWS,
        "data": _data.data
    })
}

export function downloadReviews(param: any) {
    return axios({
        method: 'GET',
        url: apis.DOWNlOAD_REVIEWS + param
    })
}