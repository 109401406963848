import { useState } from "react";
import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import QAListData from "../../components/QuestionAnswer/QAListData";
import QASubHeader from "../../components/QuestionAnswer/QASubHeader";
import { GET_QNA_DEFAULT_SIZE } from "../../../utils/constants";



const QuestionAnswer = () => {
    const [searchCriteria, setSearchCriteria] = useState({
        id: "",
        productMsn: "",
        customerId: "",
        categoryName: "",
        status: "",
        pageNumber: 0,
        pageSize: GET_QNA_DEFAULT_SIZE
    } as any)

    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <QASubHeader searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
                        <QAListData searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default QuestionAnswer