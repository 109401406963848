import React, { useEffect, useState } from 'react';

import "../Pagination/Pagination.scss";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { AiOutlineDoubleLeft } from "react-icons/ai";


const CommonPagination = (props: any) => {
    const { PAGE_SIZE, data, onPageChange } = props
    // console.log("++++++++++++++++++++++++++++++")
    // console.log({
    //     PAGE_SIZE: PAGE_SIZE,
    //     data: data,
    //     onPageChange: onPageChange
    // })
    // console.log("++++++++++++++++++++++++++++++")

    let [pageNumber, setPageNumber] = useState(1)
    let [currentPageBatchIndex, setCurrentPageBatchIndex] = useState(1)
    const [pages, setPages] = useState([])
    const [lowerBound, setLowerBound] = useState(1)
    const [upperBound, setUpperBound] = useState(PAGE_SIZE)

    useEffect(() => {
        let pageSize = PAGE_SIZE
        if (data.totalCount) {
            let totalCount = data.totalPage ? data.totalPage : (((data.totalCount % PAGE_SIZE == 0) ? data.totalCount / PAGE_SIZE : (Math.floor(data.totalCount / PAGE_SIZE) + 1)));
            let totalPages = []
            let counter = 1
            while (totalCount >= counter) {
                totalPages.push(counter)
                counter = counter + 1
            }

            let firstIndex = 0
            let secondIndex = pageSize
            if (currentPageBatchIndex > 1) {
                firstIndex = pageSize * (currentPageBatchIndex - 1)
                secondIndex = ((pageSize * currentPageBatchIndex) > data.totalPage ? data.totalPage : pageSize * currentPageBatchIndex)
            }
            const pages: any = totalPages.slice(firstIndex, secondIndex);
            setPages(pages)
        }
    }, [data, currentPageBatchIndex])

    //Left arrow click handler
    const rightClickNavigationHandler = () => {
        currentPageBatchIndex = currentPageBatchIndex + 1
        setCurrentPageBatchIndex(currentPageBatchIndex)
        currentPageHandler(pages[PAGE_SIZE - 1] + 1)
        return;
    }

    //Right arrow click handler
    const leftClickNavigationHandler = () => {
        if (currentPageBatchIndex == 1) {
            return;
        }
        else {
            currentPageBatchIndex = currentPageBatchIndex - 1
            setCurrentPageBatchIndex(currentPageBatchIndex)
            currentPageHandler(pages[0] - PAGE_SIZE)
        }
    }

    const currentPageHandler = (_pageNumber: number) => {
        setPageNumber(_pageNumber)
        onPageChange(_pageNumber)
    }

    useEffect(() => {
        if (pageNumber == 1) {
            setLowerBound(1)
            setUpperBound(PAGE_SIZE)
        }
        else {
            setLowerBound(PAGE_SIZE * (pageNumber - 1))
            setUpperBound(PAGE_SIZE * pageNumber)
        }
    }, [data, pageNumber])


    return (
        <>
            {Object.keys(data).length > 0 && <div className="pagelist-pagination p-[15px] fixed bg-sky-50">
                <div className="pagination-inner flex justify-between items-center">
                    <p className="display-txt">Displaying {`${lowerBound} - ${upperBound}`} of {data?.totalCount} results.</p>
                    <div className="pagination-list flex">
                        <div className="page-number">
                            <p>
                                <AiOutlineDoubleLeft onClick={leftClickNavigationHandler} />
                            </p>
                        </div>
                        {pages.map((d: any, index: number) =>
                            <div onClick={() => currentPageHandler(d)} key={index} className={`${(d == pageNumber) && "active"} page-number`} >
                                <p >{d}</p>
                            </div>
                        )}
                        <div className="page-number">
                            <p>
                                <AiOutlineDoubleRight onClick={rightClickNavigationHandler} />
                            </p>
                        </div>
                    </div>
                </div>
            </div >}

        </>
    );
};

export default CommonPagination;