import { useNavigate } from 'react-router-dom';
import ArrowLeftSLineIcon from 'remixicon-react/ArrowLeftSLineIcon';

const SAVE_CAMPAIGN = "Save Campaign"
const LOADING = "Loading..."

const CampaignHeader = (props: any) => {
    const navigate = useNavigate()
    const { pageMode, isLoading, viewBannerDimension, setViewBannerDimension, saveCampaignHandler } = props
    const leftArrowClickHandler = () => {
        navigate("/campaign")
    }

    const headerContentHandler = (pageMode: String) => {
        if (pageMode === "CREATE") {
            return "Create Campaign"
        }
        else if (pageMode === "VIEW") {
            return "View Campaign"
        }
        else if (pageMode === "EDIT") {
            return "Edit Campaign"
        }
        else {
            return ""
        }
    }

    return (
        <>
            <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                <div className="flex items-center justify-between w-full">
                    <p className="text-xl font-bold text-[#43425D] flex items-center">
                        <ArrowLeftSLineIcon onClick={leftArrowClickHandler} size={24} className="mr-4" />
                        {headerContentHandler(pageMode)}
                    </p>
                    {["CREATE", "EDIT"].includes(pageMode) && <div className="flex item-center  justify-end ">
                        <button onClick={() => setViewBannerDimension(!viewBannerDimension)} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">View Banner Dimension</button>
                        <div onClick={saveCampaignHandler} className='pl-2.5'>
                            <button disabled={isLoading} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">{isLoading ? LOADING : SAVE_CAMPAIGN}</button>
                        </div>
                    </div>}
                </div>
            </div>
        </>
    )
};
export default CampaignHeader;