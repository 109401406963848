import Loader from "../Loader/Loader";
import QAListItem from "./QAListItem";
import { useEffect, useRef, useState } from "react";
import queryString from 'query-string';
import { useDispatch, useSelector } from "react-redux";
import { disableQuestions, getQnaList } from "../../../core/actionCreators/qnaActionCreator";
import { GET_QNA_DEFAULT_SIZE } from "../../../utils/constants";
import CommonPagination from "../../components/CommonPagination/CommonPagination";
import { publishQuestions } from "../../../core/actionCreators/qnaActionCreator";
import { toast } from "react-hot-toast";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";


const QAListData = (props: any) => {
    const dispatch = useDispatch();
    const { qnaList, isLoading } = useSelector((s: any) => s.qnaStore)
    const { user } = useSelector((s: any) => s.authStore)
    const [selectedQuestionIds, setSelectedQuestionIds] = useState([] as any);
    const { searchCriteria, setSearchCriteria } = props

    const previousSearchCriteriaRef = useRef(searchCriteria);


    useEffect(() => {
        const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
        const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
        const previousCriteria = previousSearchCriteriaRef.current;
        const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

        if (searchFilterChanged) {
            const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
            for (let key in parsedSearchCriteria) {
                if (["id", "productMsn", "customerId", "categoryName"].includes(key)) {
                    parsedSearchCriteria.pageNumber = 0
                    break
                }
            }
            dispatch(getQnaList(queryString.stringify(parsedSearchCriteria), null));
            previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
        }
    }, [searchCriteria]);


    const onPageChange = (pageNumber: any) => {
        setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
    }

    const publishEventHandler = () => {
        if (selectedQuestionIds.length === 0) {
            toast.error("Fields are empty.");
            return;
        }

        const hasPublishedQuestions = selectedQuestionIds.some((item: { published: string; }) => item.published === 'PUBLISHED');

        if (hasPublishedQuestions) {
            setSelectedQuestionIds([]);
            toast.error("Already published question.")
            // dispatch(getQnaList(`pageNumber=0&pageSize=${GET_QNA_DEFAULT_SIZE}`, null));
            return;
        }

        const requestPayload = {
            cmsUserId: user.id || 33,
            questionIdList: selectedQuestionIds.map((item: { questionId: any; }) => item.questionId)
        };

        const callback = (r: any) => {
            setSelectedQuestionIds([]);
            alert("Questions Published Successfully");
            if (!r) {
                dispatch(getQnaList(`pageNumber=0&pageSize=${GET_QNA_DEFAULT_SIZE}`, null));
            }
        };
        dispatch(publishQuestions(requestPayload, callback));
    };


    const disableEventHandler = () => {
        if (selectedQuestionIds.length === 0) {
            toast.error("Fields are empty.");
            return;
        }

        const requestPayload = {
            cmsUserId: user.id || 33,
            questionIdList: selectedQuestionIds.map((item: { questionId: any; }) => item.questionId)
        };

        const callback = (r: any) => {
            setSelectedQuestionIds([]);
            alert("Questions Disabled Successfully");
            if (!r) {
                dispatch(getQnaList(`pageNumber=0&pageSize=${GET_QNA_DEFAULT_SIZE}`, null));
            }
        };
        dispatch(disableQuestions(requestPayload, callback));
    };



    return (
        <>
            <div className="mt-2.5 campaign-list bg-white mb-[54px]">
                <table className="w-full">
                    <thead className="bg-[#343434]">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white"></th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Q ID</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">MSN</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white"> Customer ID</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Category</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Question</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Status</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Add On Date</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Modified Date</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-4 py-3 text-left text-xs text-white w-[6%]">

                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[6%]">
                                <input value={searchCriteria.id} onChange={(e) => setSearchCriteria({ ...searchCriteria, id: e.target.value })} className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[24%]">
                                <input value={searchCriteria.productMsn} onChange={(e) => setSearchCriteria({ ...searchCriteria, productMsn: e.target.value })} className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[24%]">
                                <input value={searchCriteria.customerId} onChange={(e) => setSearchCriteria({ ...searchCriteria, customerId: e.target.value })} className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[15%]">
                                <input value={searchCriteria.categoryName} onChange={(e) => setSearchCriteria({ ...searchCriteria, categoryName: e.target.value })} className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />

                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[6%]">

                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[8%]">

                                <select onChange={(e) => setSearchCriteria({ ...searchCriteria, status: e.target.value })} className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs bg-white">
                                    <option>UNANSWERED</option>
                                    <option>PUBLISHED</option>
                                    <option>ANSWERED</option>
                                </select>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[8%]"></td>
                        </tr>

                        {isLoading ? <Loader /> : <QAListItem selectedQuestionIds={selectedQuestionIds} setSelectedQuestionIds={setSelectedQuestionIds} data={qnaList?.qlist || []}
                        />}


                    </tbody>
                </table>
                <div className="mt-5 px-4 mb-4">
                    <button onClick={publishEventHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 mb-5">Publish</button>
                    <button onClick={disableEventHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-5 mb-5">Disable</button>
                </div>
            </div>
            {qnaList && <CommonPagination data={qnaList} PAGE_SIZE={GET_QNA_DEFAULT_SIZE} onPageChange={onPageChange} />}
        </>
    )
}
export default QAListData;