import { useState } from "react";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin4Fill } from "react-icons/ri";
import { SlOptions } from "react-icons/sl";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteLayout, getLayouts } from "../../../core/actionCreators/lmsActionCreator";
import { PAGE_SIZE } from "../../../utils/constants";

const LayoutListItem = (props: any) => {
    let { user } = useSelector((s: any) => s.authStore);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { layout } = props

    const editLayoutHandler = () => {
        alert("Soon it will be functional.")
        return
    }

    const deleteLayoutHandler = () => {
        let res = window.confirm("Are you sure want to delete?")
        if (res === true) {
            let param = `${layout.id}?modifiedBy=${user.id || -1}`
            let callback = (r: any) => {
                if (!r) {
                    dispatch(getLayouts(`pageNumber=0&pageSize=${PAGE_SIZE}`, null))
                    return
                }
            }
            dispatch(deleteLayout(param, callback))
        }
        return
    }

    return (<>
        <tr>
            <td className="">
                <span onClick={() => navigate(`/layout/${layout.id}`)} className="layout-name text-blue-500 cursor-pointer">{layout.layoutName}</span>
                <span className="block layout-id">ID: {layout.layoutCode}</span>
            </td>
            <td className="">{layout.device}</td>
            <td className="">{layout.createdBy}</td>
            <td className="">2016-03-04 . 16:40:51</td>
            <td className="">{layout.status ? "Active" : "Inactive"}</td>
            <td className="action flex mt-[10px]">
                <div onClick={editLayoutHandler} className="action-inner">
                    <MdModeEdit />
                    <p className="action-name px-[10px] py-[5px]">Edit&nbsp;Page</p>
                </div>
                <div onClick={deleteLayoutHandler} className="action-inner">
                    <RiDeleteBin4Fill />
                    <p className="action-name px-[10px] py-[5px]">Delete</p>
                </div>
            </td>
        </tr>
    </>)

}

export default LayoutListItem