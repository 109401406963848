import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import UploadCloudFillIcon from "remixicon-react/UploadCloudFillIcon";
import {
  createTag,
  updateTag,
} from "../../../core/actionCreators/productTagsActionCreator";
import { uploadProductTagImage } from "../../../core/services/productTagsService";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

const tagFormSchema = Yup.object().shape({
  tagName: Yup.string()
    .min(2, "Tag name is too Short!")
    .max(15, "Tag name is too Long!")
    .required("Tag name is Required"),
  priority: Yup.number()
    .max(50, "Priority cannot be greater than 50")
    .required(),
  description: Yup.string()
    .min(5, "description is  too Short!")
    .max(30, "description is  too Long!")
    .required("description is Required"),
  type: Yup.string().required("Type is required"),
  status: Yup.string().required("Status is required"),
  addes_msn: Yup.string(),
  delete_msn: Yup.string(),
});

interface CreateNewTagProps {
  productTagDetails: any;
  pageMode: string;
}
const CreateNewTag: React.FC<CreateNewTagProps> = ({
  productTagDetails,
  pageMode,
}) => {
  const [editMode, setEditMode] = useState(true as boolean);
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState("");
  const [tagMsns, setTagMsns] = useState([]);
  const [file, setFile] = useState("");
  const [showImage, setShowImage] = useState("");

  useEffect(() => {
    if (productTagDetails && pageMode === "edit") {
      setImageUrl(productTagDetails.imageUrl);
      setShowImage(productTagDetails.imageUrl);
      setTagMsns(productTagDetails.products);
    } else {
      setEditMode(false);
    }
  }, [productTagDetails, pageMode]);

  const editTagInfo = () => {
    setEditMode(false);
  };

  const fileHandler = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      let file = e.target.files[0];
      setFile(file);
      setImageUrl("");
      setShowImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const imageUploadHandler = async () => {
    if (!file) {
      return null;
    }
    const formData = new FormData();
    formData.append("file", file);
    const data = await uploadProductTagImage(formData);
    return data?.data?.data;
  };

  const getPostBody = async (data: any, imageUrl: any) => {
    return {
      name: data.tagName,
      type: data.type,
      description: data.description,
      priority: data.priority.toString(),
      status: data.status,
      imageUrl: imageUrl,
      addProducts: data.addes_msn.length > 0 ? data.addes_msn.split(",") : [],
      removeProducts:
        data.delete_msn.length > 0 ? data.delete_msn.split(",") : [],
    };
  };

  const checkIsDataUpdated = (fields: any, productTagDetails: any)=>{
    if(
      productTagDetails != null &&
      fields['tagName'] == productTagDetails['name'] &&
      fields['description'] == productTagDetails['description'] && 
      fields['type'] == productTagDetails['type'] && 
      fields['priority'].toString() == productTagDetails['priority'] && 
      fields['status'] == productTagDetails['status'] 
    ){ return null }else{
      return "done";
    }
  }

  const onSubmit = async (fields: any, { setStatus, setSubmitting }: any) => {
    const isDone =  checkIsDataUpdated(fields, productTagDetails);
    if(isDone == null){ 
      toast.error("Please make some changes in data.");
      return; 
    }
    setSubmitting(true);
    if (pageMode === "create") {
      const response = await imageUploadHandler();
      if (response === null) {
        toast.error("Please select image.");
        return;
      }
      const resquestBody = await getPostBody(fields, response);
      dispatch(
        createTag(resquestBody, (response: any) => {
          if (response.status) {
            toast.success("Created successfully.");
          } else {
            toast.error("Something went wrong.");
          }
        })
      );
    } else if (pageMode === "edit") {
      if (imageUrl?.length === 0 || imageUrl === null) {
        const response = await imageUploadHandler();
        if (response === null) {
          toast.error("Please select image.");
          return;
        }
        const resquestBody = await getPostBody(fields, response);
        dispatch(
          updateTag(
            { ...resquestBody, tagId: productTagDetails.tagId },
            (response: any) => {
              if (response.status) {
                toast.success("Updated successfully.");
              } else {
                toast.error("Something went wrong.");
              }
            }
          )
        );
      } else {
        const resquestBody = await getPostBody(fields, imageUrl);
        dispatch(
          updateTag(
            { ...resquestBody, tagId: productTagDetails.tagId },
            (response: any) => {
              if (response.status) {
                toast.success("Updated successfully.");
              } else {
                toast.error("Something went wrong.");
              }
            }
          )
        );
      }
    }
  };

  // form data with initial values
  let initialValues = {};

  if (pageMode === "edit" && Object.entries(productTagDetails).length > 0) {
    initialValues = Object.assign(
      {},
      {
        tagName: productTagDetails.name,
        type: productTagDetails.type,
        description: productTagDetails.description,
        priority: productTagDetails.priority,
        status: productTagDetails.status,
        addes_msn: "",
        delete_msn: "",
      }
    );
  } else {
    initialValues = Object.assign(
      {},
      {
        tagName: "",
        type: "text",
        description: "",
        priority: "",
        status: "active",
        addes_msn: "",
        delete_msn: "",
      }
    );
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={tagFormSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting, setFieldValue }) => {
        return (
          <Form>
            <div className="border border-[#E8E8E8] p-4 mr-2.5 w-full">
              <div className="flex items-center justify-between pb-2.5 border-b border-gray-300">
                <p className="text-base text-[#343434]">
                  {pageMode === "create"
                    ? "Create New Tag"
                    : "Same Day Dispatch Tag"}
                </p>
                {editMode === false ? (
                  <button
                    type="submit"
                    className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                  >
                    save
                  </button>
                ) : (
                  <span onClick={() => editTagInfo()}>
                    <button
                      type="button"
                      className="border border-[#4688F1] text-[#4688F1] px-2 py-1"
                    >
                      Edit
                    </button>
                  </span>
                )}
              </div>
              <div className="pt-4">
                <div className="flex">
                  <div className="w-1/5">
                    <div className="w-[116px] p-2.5 h-11 border border-gray-300">
                      <img src={showImage} alt="" width="100" height="20" />
                    </div>
                    <button className="w-[116px] flex items-center bg-[#5D5D5D] text-[white] p-2.5 text-xs rounded mt-4">
                      <input
                        onChange={fileHandler}
                        type="file"
                        disabled={editMode}
                      />
                      <UploadCloudFillIcon size={16} className="" />
                      <span className="ml-1">Upload Image</span>
                    </button>
                  </div>
                  <div className="w-4/5">
                    <div className="flex w-full mb-5">
                      <div className="w-1/2 px-2">
                        <label className="block text-xs mb-1.5 text-[#303030]">
                          Tag Name*
                        </label>
                        <Field
                          className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm"
                          type="text"
                          name="tagName"
                          disabled={editMode}
                        />
                        <ErrorMessage
                          name="tagName"
                          component="div"
                          className="text-xs text-[red]"
                        />
                      </div>
                      <div className="w-1/2 px-2">
                        <label className="block text-xs mb-1.5 text-[#303030]">
                          Select Tag Type*
                        </label>
                        <Field
                          name="type"
                          as="select"
                          disabled={editMode}
                          className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white"
                        >
                          <option value="text">Text</option>
                          <option value="image">Image</option>
                        </Field>
                        <ErrorMessage
                          name="type"
                          component="div"
                          className="text-xs text-[red]"
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-5">
                      <div className="w-1/2 px-2">
                        <label className="block text-xs mb-1.5 text-[#303030]">
                          Tag Description*
                        </label>
                        <Field
                          className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm"
                          name="description"
                          disabled={editMode}
                          type="text"
                        />
                        <ErrorMessage
                          name="description"
                          component="div"
                          className="text-xs text-[red]"
                        />
                      </div>
                      <div className="w-1/2 px-2">
                        <label className="block text-xs mb-1.5 text-[#303030]">
                          Priority*
                        </label>
                        <Field
                          className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm"
                          name="priority"
                          disabled={editMode}
                          type="number"
                        />
                        <ErrorMessage
                          name="priority"
                          component="div"
                          className="text-xs text-[red]"
                        />
                      </div>
                    </div>
                    <div className="flex w-full mb-5">
                      <div className="w-1/2 px-2">
                        <label className="block text-xs mb-1.5 text-[#303030]">
                          Status*
                        </label>
                        <Field
                          name="status"
                          as="select"
                          disabled={editMode}
                          className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white"
                        >
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </Field>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="text-xs text-[red]"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border border-[#EAEAEA] p-4 bg-[#f8faff] mb-5 last:mb-0">
                <p className="text-sm text-[#303030]">Addes MSN (0)</p>
                <div className=" mt-4">
                  <Field
                    type="text"
                    name="addes_msn"
                    placeholder="e.g. MSN9876543HG, MSN9876543HG"
                    className="w-full h-9 px-2.5 outline-none text-sm text-[#555]"
                  />
                  <div className="mt-4">
                    <div className="flex flex-wrap">
                      {tagMsns?.map((item: any, index: any) => (
                        <span
                          className="bg-[#CEE1FD] text-xs px-3 py-2 rounded-3xl text-center mr-2.5 mb-2.5 text-[#3A3A3A]"
                          key={index}
                        >
                          {item}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="border border-[#EAEAEA] p-4 bg-[#f8faff] mb-5 last:mb-0">
                <p className="text-sm text-[#303030]">Delete MSN</p>
                <div className=" mt-4">
                  <Field
                    type="text"
                    name="delete_msn"
                    placeholder="e.g. MSN9876543HG, MSN9876543HG"
                    className="w-full h-9 px-2.5 outline-none text-sm text-[#555]"
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default CreateNewTag;
