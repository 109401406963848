import { toast } from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import AddCircleLineIcon from "remixicon-react/AddCircleLineIcon";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import CloseCircleLineIcon from "remixicon-react/CloseCircleLineIcon";
import { formatDate, getFileNameWithoutExtension } from "../../../utils/helper";

const ImageBlockElementForm = (props: any) => {
  const {
    data,
    imageBlockPayload,
    setImageBlockPayload,
    removeElementHandler,
  } = props;

  const removeGeneralBlockHandler = (parentId: any, blockId: any) => {
    const isConfirmed = window.confirm('Are you sure want to delete?')
    if (!isConfirmed) {
      return
    }
    // if (data.imgeGeneralList.length === 1) {
    //   toast.error("Please rethink about it.");
    //   return;
    // }
    const parentImageBlockIndx = imageBlockPayload.imageList.findIndex(
      (d: any) => d.id === parentId
    );
    if (parentImageBlockIndx === -1) {
      toast.error("Somethig went wrong with the page, please refresh");
      return;
    }
    const subBlockIndx = imageBlockPayload.imageList[
      parentImageBlockIndx
    ].imgeGeneralList.findIndex((d: any) => d.id === blockId);
    if (subBlockIndx === -1) {
      toast.error("Somethig went wrong with the page, please refresh");
      return;
    }
    imageBlockPayload.imageList[parentImageBlockIndx].imgeGeneralList.splice(
      subBlockIndx,
      1
    );
    setImageBlockPayload({ ...imageBlockPayload });
  };

  const subFormHandler = (
    formType: any,
    e: any,
    parentBlockId: any,
    subBlockId: any
  ) => {
    const parentIndx = imageBlockPayload.imageList.findIndex(
      (d: any) => d.id == parentBlockId
    );

    if (parentIndx === -1) {
      toast.error("Something went wrong phase 1.");
      return;
    }
    const subIndx = imageBlockPayload.imageList[
      parentIndx
    ].imgeGeneralList.findIndex((d: any) => d.id === subBlockId);
    if (subIndx === -1) {
      toast.error("Something went wrong phase 2.");
      return;
    }
    const element =
      imageBlockPayload.imageList[parentIndx].imgeGeneralList[subIndx];

    if (formType === "TEXT-BLOCK") {
      element.generalText = e.target.value;
    } else if (formType === "URL-BLOCK") {
      element.generalUrl = e.target.value;
    } else {
      toast.error("Something went wrong.");
    }
    imageBlockPayload.imageList[parentIndx].imgeGeneralList[subIndx] = element;
    setImageBlockPayload({ ...imageBlockPayload });
  };

  function checkImageOverlap(data: any, imageOrder: any, startDate: any, endDate: any) {
    // Convert dates to Date objects for comparison
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(endDate);

    // Check if the image list contains any images that meet the criteria
    return data.imageList.some((image: any) => {
      if (image.imageOrder === imageOrder) {
        const imageStartDate = new Date(image.startDate);
        const imageEndDate = new Date(image.endDate);

        // Check if dates overlap
        return (newStartDate <= imageEndDate && newStartDate >= imageStartDate) ||
          (newEndDate <= imageEndDate && newEndDate >= imageStartDate) ||
          (newStartDate <= imageStartDate && newEndDate >= imageEndDate);
      }
      return false;
    });

  }

  const formHandler = (formType: any, e: any) => {
    const elementIndex = imageBlockPayload.imageList.findIndex(
      (d: any) => d.id === data.id
    );

    if (elementIndex === -1) {
      toast.error("Something went wrong.");
      return;
    }
    const element = imageBlockPayload.imageList[elementIndex];
    element.containErrors = false;
    if (formType === "IMAGE") {
      if (e.target.files && e.target.files[0]) {
        let file = e.target.files[0];
        element.image = file;
        element.fileName = getFileNameWithoutExtension(e.target.files[0].name)
      }
    } else if (formType === "LINK") {
      element.link = e.target.value;
    } else if (formType === "TITLE") {
      element.title = e.target.value;
    } else if (formType === "IMAGE-ORDER") {
      if (element.startDate && element.endDate) {
        const isOverlap = checkImageOverlap(imageBlockPayload, parseInt(e.target.value || 1), element.startDate, element.endDate)
        if (isOverlap) {
          toast.error("Image overlap!")
          element.imageOrder = parseInt(e.target.value || 1)
        }
        else {
          element.imageOrder = parseInt(e.target.value || 1)
        }
      }
      else {
        element.imageOrder = parseInt(e.target.value || 1);
      }
    }
    else if (formType === "START_DATE") {
      if (element.endDate) {
        const startDate = new Date(e.target.value)
        const endDate = new Date(element.endDate)
        if (startDate >= endDate) {
          element.startDate = ""
          element.containErrors = true
          toast.error("Invalid combination of start_date and end_date")
        }
        else {
          element.startDate = e.target.value;
        }
      }
      else {
        element.startDate = e.target.value;
      }
    }
    else if (formType === "END_DATE") {
      const startDate = new Date(element.startDate)
      const endDate = new Date(e.target.value)
      if (!element.startDate) {
        element.endDate = ""
        element.containErrors = true
        toast.error("Please select start date first!")
      }
      else if (startDate >= endDate) {
        element.endDate = ""
        element.containErrors = true
        toast.error("Invalid combination of start_date and end_date!")
      }
      else {
        element.endDate = e.target.value;
        element.containErrors = false
      }
    }
    else {
      toast.error("Something went wrong.");
    }
    imageBlockPayload.imageList[elementIndex] = element;
    setImageBlockPayload({ ...imageBlockPayload });
  };

  const addGeneralBlockHandler = (imageBlockId: string) => {
    let newBlockElement = {
      id: uuidv4(),
      generalText: "",
      generalUrl: "",
    };
    let imageBlockIndex = imageBlockPayload.imageList.findIndex(
      (d: any) => d.id === imageBlockId
    );
    if (imageBlockIndex === -1) {
      toast.error("Something went wrong.");
      return;
    }
    imageBlockPayload.imageList[imageBlockIndex].imgeGeneralList.push(
      newBlockElement
    );
    setImageBlockPayload({ ...imageBlockPayload });
  };

  return (
    <>
      <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-4">
        <div className="cursor-pointer">
          <CloseCircleFillIcon onClick={() => removeElementHandler(data.id)} className="ml-auto" size={22} />
        </div>
        <form action="">
          <div className="flex w-full mb-6 last:mb-0">
            <div className="w-1/2 px-2">
              {data.s3ImageUrl && (
                <div className="thumbnail-container">
                  <img
                    className="thumbnail"
                    src={data.s3ImageUrl}
                    alt="Preview"
                  />
                </div>
              )}
              {data.s3ImageUrl && (
                <a
                  className="text-[10px] text-[#3c3c3c]"
                  href={data.s3ImageUrl}
                  target="_blank"
                >
                  File
                </a>
              )}

              <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                Image
              </label>
              <input
                onChange={(e) => formHandler("IMAGE", e)}
                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                type="file"
              />
            </div>
            <div className="w-1/2 px-2">
              <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                Link
              </label>
              <input
                value={data.link}
                onChange={(e) => formHandler("LINK", e)}
                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                type="text"
              />
            </div>
          </div>

          <div className="flex w-full mb-6 last:mb-0">
            <div className="w-1/2 px-2">
              <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                Start Date
              </label>
              <input
                value={formatDate(data.startDate)}
                onChange={(e) => formHandler("START_DATE", e)}
                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                type="date"
              />
            </div>
            <div className="w-1/2 px-2">
              <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                End Date
              </label>
              <input
                value={formatDate(data.endDate)}
                onChange={(e) => formHandler("END_DATE", e)}
                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                type="date"
              />
            </div>
          </div>

          <div className="flex w-full mb-6 last:mb-0">
            <div className="w-1/2 px-2">
              <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                Title
              </label>
              <input
                value={data.title}
                onChange={(e) => formHandler("TITLE", e)}
                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                type="text"
              />
            </div>
            <div className="w-1/2 px-2">
              <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                Image_order
              </label>
              <input
                value={data.imageOrder}
                onChange={(e) => formHandler("IMAGE-ORDER", e)}
                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                type="text"
              />
            </div>
          </div>


          <button type="button" className="h-9 py-2 px-5 mb-5 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none " onClick={() => addGeneralBlockHandler(data.id)}>ADD GENERAL BLOCK</button>

          {data.imgeGeneralList.map((d: any, index: number) => (
            <div key={index} className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Text Block
                </label>
                <input
                  value={d.generalText}
                  onChange={(e) =>
                    subFormHandler("TEXT-BLOCK", e, data.id, d.id)
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Url Block
                </label>
                <input
                  value={d.generalUrl}
                  onChange={(e) =>
                    subFormHandler("URL-BLOCK", e, data.id, d.id)
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div
                className="cursor-pointer mt-7"
                onClick={() => removeGeneralBlockHandler(data.id, d.id)}
              > <CloseCircleLineIcon size={22} /></div>

              {/* <div
                className="cursor-pointer mt-7"
                onClick={() => addGeneralBlockHandler(data.id)}
              ><AddCircleLineIcon size={22} /></div> */}
            </div>
          ))}
        </form>
      </div>
    </>
  );
};
export default ImageBlockElementForm;
