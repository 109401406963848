import { SET_COMMON_LOADER } from "../actionTypes/commonActionTypes";

const initialState: any = {
    isLoading: false,
}
const commonReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_COMMON_LOADER:
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state;
    }
}

export default commonReducer