import axios from "axios";
import { apis } from "./params";

export function getBrandBlocksService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_LIST_OF_BRAND_BLOCKS + _param,
  });
}

export function deleteBrandBlockService(_param: any) {
  return axios({
    method: "Post",
    url: apis.DELETE_BRAND_BLOCK + _param.id,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

export function deleteBrandBlockElementService(_param: any) {
  return axios({
    method: "Post",
    url:
      apis.DELETE_BRAND_BLOCK_ELEMENT +
      `${_param.blockId}/element/${_param.blockElementId}`,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

export function getBrandBlockByIdService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_BRAND_BLOCK_BY_ID + _param,
  });
}

export function createBrandBlockService(_data: any) {
  return axios({
    method: "Post",
    url: apis.CREATE_BRAND_BLOCK,
    data: _data.data,
    headers: {
      'Content-Type': 'multipart/form-data',
      ..._data.headers,
    },
  });
}

export function updateBrandBlockService(_data: any) {
  return axios({
    method: "Post",
    url: apis.UPDATE_BRAND_BLOCK + _data.params.id,
    data: _data.data,
    headers: {
      ..._data.headers,
    },
  });
}

export function getCategoryBlocksService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_LIST_OF_CATEGORY_BLOCKS + _param,
  });
}

export function getCategoryBlockByIdService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_CATEGORY_BLOCK_BY_ID + _param,
  });
}

export function createCategoryBlockService(_data: any) {
  return axios({
    method: "Post",
    url: apis.CREATE_CATEGORY_BLOCK,
    data: _data.data,
    headers: {
      ..._data.headers,
    },
  });
}

export function updateCategoryBlockService(_data: any) {
  return axios({
    method: "Post",
    url: apis.UPDATE_CATEGORY_BLOCK + _data.params.id,
    data: _data.data,
    headers: {
      ..._data.headers,
    },
  });
}


export function deleteCategoryBlockService(_param: any) {
  return axios({
    method: "Post",
    url: apis.DELETE_CATEGORY_BLOCK + _param.id,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

export function deleteCategoryBlockElementService(_param: any) {
  return axios({
    method: "Post",
    url:
      apis.DELETE_CATEGORY_BLOCK_ELEMENT +
      `${_param.blockId}/element/${_param.blockElementId}`,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

// export function getStaticHtmlBlocksService(_param: any) {
//     return axios({
//         "method": "Get",
//         "url": apis.GET_STATIC_HTML_BLOCKS + _param,
//     })
// }


// export function deleteStaticHtmlBlockService(_data: any) {
//     return axios({
//         "method": "Delete",
//         "url": apis. DELETE_STATIC_HTML_BLOCK + _data.params.id,
//         "data": _data.data,
//         "headers": {
//             ..._data.headers
//         }
//     })
// }


export function getImageBlocksService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_LIST_OF_IMAGE_BLOCKS + _param,
  });
}

export function getImageBlockByIdService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_IMAGE_BLOCK_BY_ID + _param,
  });
}

export function createImageBlockService(_data: any) {
  return axios({
    method: "Post",
    url: apis.CREATE_IMAGE_BLOCK,
    data: _data.data,
    headers: {
      ..._data.headers,
    },
  });
}

export function updateImageBlockService(_data: any) {
  return axios({
    method: "Post",
    url: apis.UPDATE_IMAGE_BLOCK + _data.params.id,
    data: _data.data,
    headers: {
      ..._data.headers,
    },
  });
}

export function deleteImageBlockService(_param: any) {
  return axios({
    method: "Post",
    url: apis.DELETE_IMAGE_BLOCK + _param.id,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

export function deleteImageBlockElementService(_param: any) {
  return axios({
    method: "Post",
    url:
      apis.DELETE_IMAGE_BLOCK_ELEMENT +
      `${_param.blockId}/element/${_param.blockElementId}`,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

export function deleteImageGeneralBlockElementService(_param: any) {
  return axios({
    method: "Post",
    url:
      apis.DELETE_IMAGE_BLOCK_ELEMENT,
    data: _param,
    headers: {
      userId: _param.userId,
    },
  });
}

export function getProductBlocksService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_PRODUCT_BLOCKS + _param,
  });
}

export function getProductBlockByIdService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_PRODUCT_BLOCK_BY_ID + _param,
  });
}

export function createProductBlockService(_data: any) {
  return axios({
    method: "Post",
    url: apis.CREATE_PRODUCT_BLOCK,
    data: _data.data,
    headers: {
      ..._data.headers,
    },
  });
}

export function updateProductBlockService(_data: any) {
  return axios({
    method: "Post",
    url: apis.UPDATE_PRODUCT_BLOCK + _data.params.id,
    data: _data.data,
    headers: {
      ..._data.headers,
    },
  });
}

export function deleteProductBlockService(_param: any) {
  return axios({
    method: "Post",
    url: apis.DELETE_PRODUCT_BLOCK + _param.id,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

export function uploadProductCsvFileService(_data: any) {
  return axios({
    method: "Post",
    url: apis.PRODUCT_BLOCK_UPLOAD_CSV_FILE + _data.params.id,
    data: _data.data,
    headers: {
      ..._data.headers,
    },
  });
}

export function deleteProductBlockElementService(_param: any) {
  return axios({
    method: "Post",
    url:
      apis.DELETE_PRODUCT_BLOCK_ELEMENT +
      `${_param.blockId}/element/${_param.blockElementId}`,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

export function getStaticHtmlBlocksService(_param: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_STATIC_HTML_BLOCKS + _param,
  })
}

export function getStaticHtmlBlockByIdService(_param: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_STATIC_HTML_BLOCK_BY_ID + _param
  })
}

export function createStaticHtmlBlockService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.CREATE_STATIC_HTML_BLOCK,
    "data": _data.data,
    "headers": {
      ..._data.headers
    }
  })
}

export function updateStaticHtmlBlockService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.UPDATE_STATIC_HTML_BLOCK + _data.params.id,
    "data": _data.data,
    "headers": {
      ..._data.headers
    }
  })
}

export function deleteStaticHtmlBlockService(_param: any) {
  return axios({
    method: "Post",
    url: apis.DELETE_STATIC_HTML_BLOCK + _param.id,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

export function getGeneralBlocksService(_param: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_GENERAL_BLOCKS + _param,
  })
}

export function getGeneralBlockByIdService(_param: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_GENERAL_BLOCK_BY_ID + _param
  })
}

export function createGeneralBlockService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.CREATE_GENERAL_BLOCK,
    "data": _data.data,
    "headers": {
      ..._data.headers
    }
  })
}

export function updateGeneralBlockService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.UPDATE_GENERAL_BLOCK + _data.params.id,
    "data": _data.data,
    "headers": {
      ..._data.headers
    }
  })
}

export function deleteGeneralBlockService(_param: any) {
  return axios({
    method: "Post",
    url: apis.DELETE_GENERAL_BLOCK + _param.id,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

export function deleteGeneralBlockElementService(_param: any) {
  return axios({
    method: "Post",
    url: apis.DELETE_GENERAL_BLOCK_ELEMENT + `${_param.blockId}/element/${_param.blockElementId}`,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

export function getTemplateBlocksService(_param: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_TEMPLATE_BLOCKS + _param,
  })
}

export function getTemplateBlockByIdService(_param: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_TEMPLATE_BLOCK_BY_ID + _param
  })
}

export function createTemplateBlockService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.CREATE_TEMPLATE_BLOCK,
    "data": _data.data,
    "headers": {
      ..._data.headers
    }
  })
}

export function updateTemplateBlockService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.UPDATE_TEMPLATE_BLOCK + _data.params.id,
    "data": _data.data,
    "headers": {
      ..._data.headers
    }
  })
}

export function deleteTemplateBlockService(_param: any) {
  return axios({
    method: "Post",
    url: apis.DELETE_TEMPLATE_BLOCK + _param.id,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

export function deleteTemplateBlockElementService(_param: any) {
  return axios({
    method: "Post",
    url: apis.DELETE_TEMPLATE_BLOCK_ELEMENT + `${_param.blockId}/element/${_param.blockElementId}`,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

//LAYOUT_TYPE
export function getBlockTypeBlocksService(_param: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_BLOCK_TYPE_BLOCKS + _param,
  })
}

export function getBlockTypeBlockByIdService(_param: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_BLOCK_TYPE_BLOCK_BY_ID + _param
  })
}

export function createBlockTypeBlockService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.CREATE_BLOCK_TYPE_BLOCK,
    "data": _data.data,
    "headers": {
      ..._data.headers
    }
  })
}

export function updateBlockTypeBlockService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.UPDATE_BLOCK_TYPE_BLOCK + _data.params.id,
    "data": _data.data,
    "headers": {
      ..._data.headers
    }
  })
}

export function deleteBlockTypeBlockService(_param: any) {
  return axios({
    method: "Post",
    url: apis.DELETE_BLOCK_TYPE_BLOCK + _param.id,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

//LAYOUTS
export function getBlockLayoutsService(_param: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_BLOCK_LAYOUTS + _param,
  })
}

export function getBlockLayoutService(_param: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_BLOCK_LAYOUT_BY_ID + _param
  })
}

export function createBlockLayoutService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.CREATE_BLOCK_LAYOUT,
    "data": _data.data,
    "headers": {
      ..._data.headers
    }
  })
}

export function updateBlockLayoutService(_data: any) {
  return axios({
    "method": "Post",
    "url": apis.UPDATE_BLOCK_LAYOUT + _data.params.id,
    "data": _data.data,
    "headers": {
      ..._data.headers
    }
  })
}

export function deleteBlockLayoutElementService(_param: any) {
  return axios({
    method: "Post",
    url:
      apis.DELETE_BLOCK_LAYOUT_ELEMENT +
      `${_param.blockId}/element/${_param.blockElementId}`,
    data: {},
    headers: {
      userId: _param.userId,
    },
  });
}

export function getBlockLayoutTemplates() {
  return axios({
    "method": "Get",
    "url": apis.GET_BLOCK_LAYOUT_TEMPLATES
  })
}

export function getBlockLayoutGroups() {
  return axios({
    "method": "Get",
    "url": apis.GET_BLOCK_LAYOUT_GROUP
  })
}

export function getBlockBlockTypes() {
  return axios({
    "method": "Get",
    "url": apis.GET_BLOCK_BLOCK_TYPES
  })
}

export function getBlockBlocks(id: any) {
  return axios({
    "method": "Get",
    "url": apis.GET_BLOCK_BLOCKS + id
  })
}