import { put, takeLatest, all, call, takeEvery } from "redux-saga/effects";
import {
    setComponent,
    setComponents,
    setLayout, setLayoutJsonApiLoader, setLayoutJsonByLayoutCode, setLayouts, setLmsLoader,
    setPopulatedComponent,
    setPopulatedLayout,
    setStaticFormFields
} from "../actionCreators/lmsActionCreator";
import {
    CLEAR_CACHE,
    COPY_COMPONENT,
    CREATE_COMPONENT,
    CREATE_ELEMENT,
    CREATE_LAYOUT, DELETE_COMPONENT, DELETE_ELEMENT, DELETE_LAYOUT, GET_COMPONENT, GET_COMPONENTS, GET_LAYOUT,
    GET_LAYOUTS, GET_LAYOUT_JSON_BY_LAYOUT_CODE, GET_POPULATED_COMPONENT, GET_POPULATED_LAYOUT, GET_STATIC_FORM_FIELDS, UPDATE_COMPONENT, UPDATE_COMPONENT_STATUS, UPDATE_ELEMENT, UPDATE_ELEMENT_STATUS, UPLOAD_IMAGE
} from "../actionTypes/lmsActionTypes";
import {
    clearCacheService,
    copyComponentService,
    createComponentService,
    createElementService,
    createLayoutService, deleteComponentService, deleteElementService, deleteLayoutService, getComponentService, getComponentsService, getLayoutJsonByLayoutCode, getLayoutService,
    getLayoutsService, getPopulatedComponentService, getPopulatedLayoutService, getStaticFormFieldsService, updateComponentService, updateComponentStatusService, updateElementService, updateElementStatusService
} from "../services/lmsServices";
import { toast } from "react-hot-toast";
import { uploadImageService } from "../services/utilService";


function* _getLayouts(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(getLayoutsService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            yield put(setLayouts(data.data))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_getLayouts: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _createLayout(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(createLayoutService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_createLayout: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _deleteLayout(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(deleteLayoutService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_deleteLayout: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getLayout(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(getLayoutService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            yield put(setLayout(data.data))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_getLayout: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getStaticFormFields(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(getStaticFormFieldsService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            yield put(setStaticFormFields(data.data))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_getStaticFormFields: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _createComponent(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(createComponentService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_createComponent: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getComponent(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(getComponentService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            yield put(setComponent(data.data))
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_getComponent: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getPopulatedLayout(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(getPopulatedLayoutService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true && data.data && data.data.components && data.data.components.length > 0) {
            yield put(setPopulatedLayout(data.data))
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_getPopulatedLayout: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _updateComponent(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(updateComponentService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_updateComponent: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _updateComponentStatus(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(updateComponentStatusService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_updateComponentStatus: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getComponents(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(getComponentsService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            yield put(setComponents(data.data))
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_getComponents: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _copyComponent(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(copyComponentService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_copyComponent: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}


function* _getLayoutJsonByLayoutCode(_data: any) {
    try {
        yield put(setLayoutJsonApiLoader(true));
        const { data } = yield call(getLayoutJsonByLayoutCode, _data.payload);
        yield put(setLayoutJsonApiLoader(false));
        if (data.status == true) {
            yield put(setLayoutJsonByLayoutCode(data.data))
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLayoutJsonApiLoader(false));
        console.log("_getLayoutJsonByLayoutCode: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _deleteComponent(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(deleteComponentService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_deleteComponent: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}


function* _getPopulatedComponent(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(getPopulatedComponentService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            yield put(setPopulatedComponent(data.data))
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_deleteComponent: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}


function* _createElement(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(createElementService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_createElement: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}


function* _updateElementStatus(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(updateElementStatusService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_updateElementStatus: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}


function* _updateElement(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(updateElementService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_updateElement: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _deleteElement(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(deleteElementService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_deleteElement: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _clearCache(_data: any) {
    try {
        yield put(setLmsLoader(true));
        const { data } = yield call(clearCacheService, _data.payload);
        yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(null)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setLmsLoader(false));
        console.log("_clearCache: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _uploadImage(_data: any) {
    try {
        // yield put(setLmsLoader(true));
        const { data } = yield call(uploadImageService, _data.payload);
        // yield put(setLmsLoader(false));
        if (data.status == true) {
            _data.callback && _data.callback(data.data)
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        // yield put(setLmsLoader(false));
        console.log("_uploadImage: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

export default function* lmsSaga() {
    yield all([
        takeLatest(GET_LAYOUT, _getLayout),
        takeLatest(GET_LAYOUTS, _getLayouts),
        takeLatest(CREATE_LAYOUT, _createLayout),
        takeLatest(DELETE_LAYOUT, _deleteLayout),
        takeLatest(GET_STATIC_FORM_FIELDS, _getStaticFormFields),
        takeLatest(CREATE_COMPONENT, _createComponent),
        takeLatest(GET_COMPONENT, _getComponent),
        takeLatest(GET_POPULATED_LAYOUT, _getPopulatedLayout),
        takeLatest(UPDATE_COMPONENT, _updateComponent),
        takeLatest(UPDATE_COMPONENT_STATUS, _updateComponentStatus),
        takeLatest(GET_COMPONENTS, _getComponents),
        takeLatest(COPY_COMPONENT, _copyComponent),
        takeLatest(GET_LAYOUT_JSON_BY_LAYOUT_CODE, _getLayoutJsonByLayoutCode),
        takeLatest(DELETE_COMPONENT, _deleteComponent),
        takeLatest(GET_POPULATED_COMPONENT, _getPopulatedComponent),
        takeLatest(CREATE_ELEMENT, _createElement),
        takeLatest(UPDATE_ELEMENT_STATUS, _updateElementStatus),
        takeLatest(UPDATE_ELEMENT, _updateElement),
        takeLatest(DELETE_ELEMENT, _deleteElement),
        takeLatest(CLEAR_CACHE, _clearCache),
        takeEvery(UPLOAD_IMAGE, _uploadImage)
    ])
}