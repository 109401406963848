const IMAGES ={
    LOGO:{
        darklogo:require('./moglix-logo.svg').default
    },
    WNIMG:{
        wnimg:require('./windows.svg').default
    },
    GIMG:{
        gimg:require('./google.png').default
    }
}
export default IMAGES;