import { useDispatch } from "react-redux";
import CategoryBlocksElementForm from "./CategoryBlocksElementForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  createCategoryBlock,
  deleteCategoryBlockElement,
  updateCategoryBlock,
  // deleteCategoryBlockElement,
} from "../../../core/actionCreators/blocksActionCreator";
import { toast } from "react-hot-toast";
import Loader from "../Loader/Loader";


const CategoryBlocksParentForm = (props: any) => {
  const { pageMode, blockData, loaderFlag } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((s: any) => s.authStore);

  const [categoryBlockPayload, setCategoryBlockPayload] = useState({
    id: "",
    label: "",
    name: "",
    title: "",
    brand: "",
    category: "",
    elementCount: "",
    status: "true",
    associatedLayouts: [],
    categoryElementList: [{
      id: uuidv4(),
      name: "",
      label: "",
      elementOrder: "1",
      url: "",
      file: ""
    },]
  } as any);


  useEffect(() => {
    if (pageMode === "EDIT" && Object.keys(blockData).length) {
      setCategoryBlockPayload(blockData);
    }
  }, [blockData]);

  const createCategoryBlockHandler = () => {
    if (categoryBlockPayload.label.trim() === "") {
      toast.error("Label cannot be blank.");
      return;
    }
    if (categoryBlockPayload.name.trim() === "") {
      toast.error("Name cannot be blank.");
      return;
    }
    const formData = new FormData();
    let data: any = { ...categoryBlockPayload };
    delete data.associatedLayouts

    //TypeCast to number
    if (data.elementCount) {
      data.elementCount = parseInt(data.elementCount)
    }
    data.status = JSON.parse(data.status)

    delete data.id;
    let headers = {
      userId: user.id || 33,
    };

    data.categoryElementList.forEach((d: any) => {
      if (d.localFile) {
        let file = d.localFile;
        formData.append("files", file);
        delete d.file
        delete d.id;
        delete d.localFile
      }
      if (d.elementOrder) {
        d.elementOrder = parseInt(d.elementOrder)
      }
    });

    formData.append("json", JSON.stringify(data));
    dispatch(
      createCategoryBlock({ data: formData, headers: headers }, (resp: any) => {
        if (resp.status == true) {
          toast.success("Created successfully.");
          navigate("/blocks/category");
        } else {
          toast.error(resp.statusDescription);
          setCategoryBlockPayload({ ...categoryBlockPayload, categoryElementList: [] })
        }
      })
    );
  };

  const updateCategoryBlockHandler = () => {
    const formData = new FormData();
    let data: any = { ...categoryBlockPayload };

    //TypeCast to number
    if (data.elementCount) {
      data.elementCount = parseInt(data.elementCount)
    }

    data.status = JSON.parse(data.status)
    delete data.associatedLayouts
    let headers = {
      userId: user.id || 33,
    };
    let params = {
      id: data.id,
    };

    data.categoryElementList.forEach((d: any) => {
      if (d.localFile) {
        let file = d.localFile;
        formData.append("files", file);
        delete d.localFile
      }
      if (typeof d.id === 'string') {
        delete d.id
        delete d.file
      }
      if (d.elementOrder) {
        d.elementOrder = parseInt(d.elementOrder)
      }
    });
    formData.append("json", JSON.stringify(data));
    dispatch(
      updateCategoryBlock({ params, data: formData, headers }, (resp: any) => {
        if (resp.status) {
          toast.success("Updated successfully.");
          window.location.reload();
        } else {
          setCategoryBlockPayload(blockData);
          toast.error(resp.statusDescription);
        }
      })
    );
  };


  const addElementHandler = () => {
    let newElement = {
      id: uuidv4(),
      name: "",
      label: "",
      elementOrder: "1",
      url: "",
      image: "",
      file: "",
    };
    categoryBlockPayload.categoryElementList.push(newElement);
    setCategoryBlockPayload({ ...categoryBlockPayload });
  };

  const removeCategoryBlockElementHandler = (blockElementId: any) => {
    const isConfirmed = window.confirm('Are you sure want to delete?')
    if (!isConfirmed) {
      return
    }
    const elementIndex = categoryBlockPayload.categoryElementList.findIndex(
      (d: any) => d.id === blockElementId
    );
    if (elementIndex === -1) {
      toast.error("Something went wrong.");
      return;
    }

    if (pageMode === "EDIT" && typeof blockElementId !== 'string') {
      //Call api to delete from db
      dispatch(deleteCategoryBlockElement({
        blockId: categoryBlockPayload.id,
        blockElementId: blockElementId,
        userId: user.id || 33
      }, (resp: any) => {
        if (!resp.status) {
          toast.error(resp.statusDescription)
          return
        }
        categoryBlockPayload.categoryElementList.splice(elementIndex, 1);
        setCategoryBlockPayload({ ...categoryBlockPayload });
        return
      }))
    }
    else {
      categoryBlockPayload.categoryElementList.splice(elementIndex, 1);
      setCategoryBlockPayload({ ...categoryBlockPayload });
    }
  };

  const navigateToLayoutPageHandler = (layoutId: number) => {
    navigate(`/blocks/layout/${layoutId}`);
  }


  return (
    <>
      <div className="p-4 pb-8">
        <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5">
          <form action="">
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Label*
                </label>
                <input
                  required
                  value={categoryBlockPayload.label}
                  onChange={(e) =>
                    setCategoryBlockPayload({
                      ...categoryBlockPayload,
                      label: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Name*
                </label>
                <input
                  required
                  value={categoryBlockPayload.name}
                  onChange={(e) =>
                    setCategoryBlockPayload({
                      ...categoryBlockPayload,
                      name: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Title
                </label>
                <input
                  required
                  value={categoryBlockPayload.title}
                  onChange={(e) =>
                    setCategoryBlockPayload({
                      ...categoryBlockPayload,
                      title: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Select*
                </label>
                <select className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                  <option value={"true"}>Brand</option>
                  <option value={"false"}>Category</option>
                </select>
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  List*
                </label>
                <input
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Element Count*
                </label>
                <input
                  required
                  value={categoryBlockPayload.elementCount}
                  onChange={(e) =>
                    setCategoryBlockPayload({
                      ...categoryBlockPayload,
                      elementCount: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Status*
                </label>
                <select
                  value={categoryBlockPayload.status}
                  onChange={(e) =>
                    setCategoryBlockPayload({
                      ...categoryBlockPayload,
                      status: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light"
                >
                  <option value={"true"}>Active</option>
                  <option value={"false"}>Inactive</option>
                </select>
              </div>
            </div>

            {/* Show Associated Layout if edit mode */}
            {pageMode === "EDIT" && categoryBlockPayload.associatedLayouts && Array.isArray(categoryBlockPayload.associatedLayouts) && categoryBlockPayload.associatedLayouts.length > 0 && <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Associated Layouts</label>
                <ul className="list-none p-0">
                  {categoryBlockPayload.associatedLayouts.map((d: any, index: number) =>
                    <li key={index} className="mb-1 last:mb-0">
                      <p onClick={() => navigateToLayoutPageHandler(d.layout_id)} className="text-blue-500 hover:underline">{d.layoutName}</p>
                    </li>
                  )}
                </ul>
              </div>
            </div>}
          </form>
        </div>
        {categoryBlockPayload?.categoryElementList?.map(
          (d: any, index: number) => (
            <CategoryBlocksElementForm
              key={index}
              data={d}
              categoryBlockPayload={categoryBlockPayload}
              setCategoryBlockPayload={setCategoryBlockPayload}
              removeCategoryBlockElementHandler={removeCategoryBlockElementHandler}
            />
          )
        )}

        <div className="mt-5">
          <button
            onClick={addElementHandler}
            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none "
          >
            Add Element
          </button>
          {loaderFlag ? <Loader /> :
            <button
              onClick={
                pageMode === "EDIT"
                  ? updateCategoryBlockHandler
                  : createCategoryBlockHandler
              }
              className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ml-5"
            >
              {pageMode === "EDIT" ? "Update" : "Create"}
            </button>}
        </div>
      </div>
    </>
  );
};
export default CategoryBlocksParentForm;
