import React from "react";
import JSONPretty from "react-json-pretty";
import './JsonViewPopup.scss';
import { IoCloseCircleSharp } from "react-icons/io5";
import { toast } from "react-hot-toast";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import { IoCopySharp } from "react-icons/io5";

const JsonViewPopup = (props: any) => {
    let { layoutJsonApiLoader } = useSelector((s: any) => s.lmsStore);
    const { data, setJsonViewPopup } = props


    const copyToClipBoardClickHandler = () => {
        const jsonString = JSON.stringify(data);
        navigator.clipboard.writeText(jsonString)
            .then(() => toast.success("Copied."))
            .catch((error) => console.error('method: copyToClipBoardClickHandler: exceptionError ', error));
    }
    return (<>
        <div className="popup-container ">
            <div className="overlay">
                <div className="popup-content json-popup">
                    <div className="popup-content-inner mt-[15px]">
                        <div className="popup">
                            {
                                layoutJsonApiLoader ? <Loader /> :
                                    <>
                                        <button className="flex text-blue-500 absolute top-4 right-[60px]" onClick={copyToClipBoardClickHandler}><IoCopySharp />&nbsp; Copy To Clibboard</button>
                                        <button className="absolute top-4 right-5" onClick={() => setJsonViewPopup(false)}><IoCloseCircleSharp /></button>
                                        <JSONPretty data={data} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default JsonViewPopup