import * as actions from '../actionTypes/adsenseActionTypes';

export const getCampaigns = (_data: any, _callback: any) => {
    return {
        type: actions.GET_CAMPAIGNS,
        payload: _data,
        callback: _callback
    }
}

export const setCampaigns = (_data: any,) => {
    return {
        type: actions.SET_CAMPAIGNS,
        payload: _data
    }
}

export const getBrands = (_data: any, _callback: any) => {
    return {
        type: actions.GET_BRANDS,
        payload: _data,
        callback: _callback
    }
}

export const setBrands = (_data: any,) => {
    return {
        type: actions.SET_BRANDS,
        payload: _data
    }
}

export const getCategories = (_data: any, _callback: any) => {
    return {
        type: actions.GET_CATEGORIES,
        payload: _data,
        callback: _callback
    }
}

export const setCategories = (_data: any,) => {
    return {
        type: actions.SET_CATEGORIES,
        payload: _data
    }
}

export const getBrandsByCategory = (_data: any, _callback: any) => {
    return {
        type: actions.GET_BRANDS_BY_CATEGORY,
        payload: _data,
        callback: _callback
    }
}

export const setBrandsByCategory = (_data: any,) => {
    return {
        type: actions.SET_BRANDS_BY_CATEGORY,
        payload: _data
    }
}

export const getLookups = (_data: any, _callback: any) => {
    return {
        type: actions.GET_LOOKUPS,
        payload: _data,
        callback: _callback
    }
}

export const setLookups = (_data: any,) => {
    return {
        type: actions.SET_LOOKUPS,
        payload: _data
    }
}

export const createCampaign = (_data: any, _callback: any) => {
    return {
        type: actions.CREATE_CAMPAIGN,
        payload: _data,
        callback: _callback
    }
}

export const getCampaign = (_data: any, _callback: any) => {
    return {
        type: actions.GET_CAMPAIGN,
        payload: _data
    }
}

export const setCampaign = (_data: any) => {
    return {
        type: actions.SET_CAMPAIGN,
        payload: _data
    }
}

export const setAdsenseLoader = (_data: any) => {
    return {
        type: actions.SET_ADSENSE_LOADER,
        payload: _data
    }
}

export const updateCampaignStatus = (_data: any, _callback: any) => {
    return {
        type: actions.UPDATE_CAMPAIGN_STATUS,
        payload: _data,
        callback: _callback
    }
}

export const updateCampaign = (_data: any) => {
    return {
        type: actions.UPDATE_CAMPAIGN,
        payload: _data
    }
}