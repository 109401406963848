import { useDispatch } from "react-redux";
import { GET_FAQ_DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import { getFaqs } from "../../../core/actionCreators/faqActionCreator";
import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import queryString from 'query-string';
import CommonPagination from "../CommonPagination/CommonPagination";
import Loader from "../Loader/Loader";
import FaqListItem from "./faqListItem";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";

const FaqSchemaList = () => {
    const dispatch = useDispatch()
    const { faqs, isLoading } = useSelector((s: any) => s.faqStore)

    const [searchCriteria, setSearchCriteria] = useState({
        pageNumber: 0,
        pageSize: GET_FAQ_DEFAULT_PAGE_SIZE,
        categoryCode: "",
        categoryName: "",
    } as any)

    const previousSearchCriteriaRef = useRef(searchCriteria);


    useEffect(() => {
        const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
        const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
        const previousCriteria = previousSearchCriteriaRef.current;
        const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

        if (searchFilterChanged) {
            const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
            for (let key in parsedSearchCriteria) {
                if (["categoryCode", "categoryName"].includes(key)) {
                    parsedSearchCriteria.pageNumber = 0
                    break
                }
            }
            dispatch(getFaqs(queryString.stringify(parsedSearchCriteria), null));
            previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
        }
    }, [searchCriteria]);


    const onPageChange = (pageNumber: any) => {
        setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
    }

    return (
        <>
            <div className="mt-2.5 campaign-list bg-white mb-[54px]">
                <table className="w-full">
                    <thead className="bg-[#343434]">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[40%]">Category Code</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[40%]"> Category Name</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Count</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[40%]">
                                <input value={searchCriteria.categoryCode} onChange={(e) => setSearchCriteria({ ...searchCriteria, categoryCode: e.target.value })} type="text" className="border border-gray-300 h-7 rounded-sm focus:outline-none px-1.5 text-xs w-full"></input>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[40%]">
                                <input value={searchCriteria.categoryName} onChange={(e) => setSearchCriteria({ ...searchCriteria, categoryName: e.target.value })} type="text" className="border border-gray-300 h-7 rounded-sm focus: outline-none px-1.5 text-xs w-full"></input>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[10%]">
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[10%]">
                            </td>
                        </tr>
                        {isLoading ? <Loader /> : <FaqListItem data={faqs?.data || []} />}
                    </tbody>
                </table>
            </div>
            {faqs.data && <CommonPagination data={faqs} PAGE_SIZE={GET_FAQ_DEFAULT_PAGE_SIZE} onPageChange={onPageChange} />}
        </>
    )
}
export default FaqSchemaList;