import MoreFillIcon from "remixicon-react/MoreFillIcon"
import PauseCircleFillIcon from "remixicon-react/PauseCircleFillIcon"
import StopCircleFillIcon from "remixicon-react/StopCircleFillIcon"
import PencilFillIcon from 'remixicon-react/PencilFillIcon';
import { useSelector, useDispatch } from "react-redux"
import { updateCampaignStatus } from "../../../core/actionCreators/adsenseActionCreator"
import { toast } from "react-hot-toast"
import { Link, useNavigate } from "react-router-dom";

const CampaignItem = (props: any) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const store: any = useSelector(s => s)
    const { user } = store.authStore
    const { editSectionToggle, setEditSectionToggle, campaign, editSectionId, setEditSectionId } = props

    const editIconClickHandler = (campaignId: String) => {
        setEditSectionToggle(!editSectionToggle)
        setEditSectionId(campaignId)
    }

    const campaignActionHandler = (status: any) => {
        if (status === "RUNNING") {
            return "Pause Camapign"
        }
        if (status === "PAUSED") {
            return "Resume Campaign"
        }
        if (["ACTIVE", "DRAFT"].includes(status)) {
            return "Start Campaign"
        }
        return ""
    }

    const updateStatusOfCampaignHandler = (campaignId: String, status: String, forceEnd: Boolean) => {
        setEditSectionToggle(!editSectionToggle)
        let _status;
        if (status === "RUNNING") {
            _status = "PAUSE"
        }
        if (status === "PAUSED") {
            _status = "RESUME"
        }
        if (status === "ACTIVE") {
            _status = "START"
        }
        if (status === "DRAFT") {
            _status = "START"
        }
        if (status === "RUNNING" && forceEnd) {
            _status = "FORCE_END"
        }
        dispatch(updateCampaignStatus(`id=${campaignId}&status=${_status}&updatedBy=${user.id || -1}`, () => {
            toast.success("Updated successfully.")
        }))
    }

    const navigateToEditOrViewCampaignHandler = (campaignId: String) => {
        navigate(`/campaign/${campaign.id}`)
    }

    return (<>
        <tr className='[&:nth-child(even)]:bg-[#f7f7f7]'>
            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
                {campaign?.campaignName}
                {/* <Link to={`/campaign/${campaign.id}`}>{campaign?.campaignName}</Link> */}
            </td>
            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{campaign.createdByEmail || "user"}</td>
            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{campaign?.startDate?.split("T")[0]}</td>
            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{campaign?.endDate?.split("T")[0]}</td>
            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{campaign?.campaignType}</td>
            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{campaign?.platform}</td>
            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">{campaign?.status}</td>
            {!["FORCE_ENDED", "EXPIRED"].includes(campaign.status) && <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
                <MoreFillIcon size={20} onClick={() => editIconClickHandler(campaign.id)} className="text-black relative " />
                {editSectionToggle && editSectionId === campaign.id && <div className='absolute bg-white w-44 z-10 shadow-[0_0_10px_2px_rgba(0,0,0,.1)] rounded right-16'>

                    {/* Edit Section CTA */}
                    {["DRAFT", "PAUSED"].includes(campaign?.status) && <div onClick={() => navigateToEditOrViewCampaignHandler(campaign?.id)} className='flex items-center py-2.5 px-3  hover:bg-sky-50 active:bg-sky-50'>
                        <PencilFillIcon size={16} className="text-black " />
                        <span className='pl-2'>Edit Campaign</span>
                    </div>}

                    {["RUNNING", "PAUSED", "EXPIRED", "FORCE_ENDED"].includes(campaign?.status) && <div
                        onClick={() => navigateToEditOrViewCampaignHandler(campaign?.id)}
                        className='flex items-center py-2.5 px-3  hover:bg-sky-50 active:bg-sky-50'>
                        <span className='pl-2'>View Campaign</span>
                    </div>}

                    <div onClick={() => updateStatusOfCampaignHandler(campaign.id, campaign.status, false)} className='flex items-center py-2.5 px-3 hover:bg-sky-50 active:bg-sky-50'>
                        <PauseCircleFillIcon size={16} className="text-black " />
                        <span className='pl-2'>{campaignActionHandler(campaign.status)}</span>
                    </div>

                    {campaign?.status === "RUNNING" && <div onClick={() => updateStatusOfCampaignHandler(campaign.id, campaign.status, true)} className='flex items-center py-2.5 px-3 hover:bg-sky-50 active:bg-sky-50'>
                        <StopCircleFillIcon size={16} className="text-black " />
                        <span className='pl-2'>Force End Campaign</span>
                    </div>}


                </div>}
            </td>}
        </tr>
    </>)
}

export default CampaignItem