import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import ProductTagSubHeader from "../../components/ProductTags/ProductTagSubHeader";
import LeftSideContainer from "./LeftSideContainer";
import RightSideContainer from "./RightSideContainer";
import {
  getProductTags,
  getProductTagDetails,
} from "../../../core/actionCreators/productTagsActionCreator";
import Loader from "../../components/Loader/Loader";

const ProductTagging = () => {
  const dispatch = useDispatch();
  const { productTags, isLoading, productTagDetails } = useSelector(
    (s: any) => s.productTagsStore
  );
  const [pageMode, setPageMode] = useState("edit" as string);

  useEffect(() => {
    if (productTags.length === 0) {
      dispatch(getProductTags(null, null));
    }
  }, [productTags, productTagDetails]);

  const getTagId = (id: string) => {
    setPageMode("edit");
    dispatch(getProductTagDetails(id, null));
  };

  return (
    <>
      <div className="flex mainWrapper w-full">
        <div className="sidenav-wrapper">
          <SideNavbar />
        </div>
        <div className="right-container">
          <div className="header-container">
            <Header />
          </div>
          <div className="body-container">
            <ProductTagSubHeader />
            <div className="bg-white p-4 mt-2.5 flex h-[81vh]">
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  <LeftSideContainer
                    productTags={productTags || []}
                    getTagId={getTagId}
                  />
                  <RightSideContainer
                    productTagDetails={productTagDetails}
                    pageMode={pageMode}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductTagging;
