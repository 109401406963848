import toast from "react-hot-toast"
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon"

const GeneralBlockElementForm = (props: any) => {
    const { data, generalBlockPayload, setGeneralBlockPayload, removeElementHandler } = props

    const formHandler = (formType: any, e: any) => {
        const elementIndex = generalBlockPayload.generalElementList.findIndex((d: any) => d.id === data.id)
        if (elementIndex === -1) {
            toast.error("Something went wrong.")
            return
        }
        const element = generalBlockPayload.generalElementList[elementIndex]
        if (formType === "NAME") {
            element.name = e.target.value
        }
        else if (formType === "LABEL") {
            element.label = e.target.value
        }
        else {
            toast.error("Something went wrong.")
            return
        }
        generalBlockPayload.generalElementList[elementIndex] = element
        setGeneralBlockPayload({ ...generalBlockPayload })
    }


    return (
        <>
            <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-4">
                <div>
                    <CloseCircleFillIcon onClick={() => removeElementHandler(data.id)} className="ml-auto cursor-pointer" size={22} />
                </div>
                <form action="">
                    <div className="flex w-full mb-6 last:mb-0">
                        <div className="w-1/2 px-2">
                            <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Name</label>
                            <input value={data.name} onChange={(e) => formHandler("NAME", e)}
                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                                type="text"
                            />
                        </div>
                        <div className="w-1/2 px-2">
                            <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Link</label>
                            <input value={data.label} onChange={(e) => formHandler("LABEL", e)}
                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                type="text"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export default GeneralBlockElementForm;