import axios from "axios";
import { apis } from './params';

export function getQnaList(_param: any) {
    return axios({
        "method": "GET",
        "url": apis.GET_QNA_LIST + _param
    })
}

export function addUpdateAnswerCall(_param: any) {
    return axios.post(apis.ADD_UPDATE_ANSWER + _param.qId, _param)
}

export function publishQuestionsCall(_param: any) {
    return axios.post(apis.PUBLISH_QUESTIONS, _param)
}

export function disableQuestionsCall(_param: any) {
    return axios.post(apis.DISABLE_QUESTIONS + _param)
}

export function downloadQuestionsCall(_param: any) {
    return axios.get(apis.DOWNLOAD_QUESTIONS + _param)
}