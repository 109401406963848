import axios from "axios";
import { apis } from "./params";

export function getAttributesService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_ATTRIBUTES + _param,
  });
}

export function getAttributeService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_ATTRIBUTE + _param,
  });
}

export function downloadAttributeDumpService(_param: any) {
  return axios({
    method: "Get",
    url: apis.DOWNLOAD_ATTRIBUTE_DUMP + _param,
  });
}
export function bulkAttributeService(_data: any) {
  return axios({
    method: "Post",
    url: apis.BULK_UPLOAD_ATTRIBUTE + _data.param,
    data: _data.data,
  });
}

export function createAttributeService(_data: any) {
  return axios({
    method: "Post",
    url: apis.CREATE_ATTRIBUTE,
    data: _data
  });
}

export function updateAttributeService(_data: any) {
  return axios({
    method: "Post",
    url: apis.UPDATE_ATTRIBUTE,
    data: _data,
  });
}

export function getProductCountService(_data: any) {
  return axios({
    method: "Post",
    url: apis.GET_PRODUCT_COUNT,
    data: _data,
  });
}

export function deleteAttributeService(_data: any) {
  return axios({
    method: "Get",
    url: apis.DELETE_ATTRIBUTE_SCHEMA + _data.id,
  });
}

export function getAttributeByIdService(_param: any) {
  return axios({
    method: "Get",
    url: apis.GET_ATTRIBUTE_BY_ID + _param,
  });
}
