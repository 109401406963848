import BlockCustomerForm from "../../components/BlockCustomer/BlockCustomerForm";
import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";

const BlockCustomer =()=>{
    return(
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <BlockCustomerForm />
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlockCustomer;