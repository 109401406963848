import axios from 'axios';
import { apis } from './params';

export function uploadProductDescriptionService(_body: any) {
  return axios({
    "method": "Post",
    "url": apis.UPLOAD_PRODUCT_DESCRIPTION,
    "data": _body,
  })
}

export function downloadProductDescriptionService(id: any) {
  return axios({
    "method": "Get",
    "url": apis.DOWNLOAD_PRODUCT_DESCRIPTION + id,
  })
}

export function getProductDescriptionService(_email: any) {
  return axios({
    "method": "Get",
    "url": apis.TRACK_PRODUCT_DESCRIPTION + _email, 
  })

}