import * as actions from "../actionTypes/articleActionTypes";

export const setArticleLoader = (_data: any) => {
    return {
        type: actions.SET_ARTICLE_LOADER,
        payload: _data
    }
}

export const getArticleCategoryById = (_data: any, _callback: any) => {
    return {
        type: actions.GET_ARTICLE_CATEGORY_ID,
        payload: _data,
        callback: _callback
    }
}

export const createArticle = (_data: any, _callback: any) => {
    return {
        type: actions.CREATE_ARTICLE,
        payload: _data,
        callback: _callback
    }
}

export const getArticle = (_data: any, _callback: any) => {
    return {
        type: actions.GET_ARTICLE,
        payload: _data,
        callback: _callback
    }
}
export const setArticle = (_data: any) => {
    return {
        type: actions.SET_ARTICLE,
        payload: _data,

    }
}
export const getArticleListing = (_data: any, _callback: any) => {
    return {
        type: actions.GET_ARTICLE_LISTING,
        payload: _data,
        callback: _callback
    }
}
export const setArticleListing = (_data: any) => {
    return {
        type: actions.SET_ARTICLE_LISTING,
        payload: _data,

    }
}

export const getArticleListingById = (_data: any, _callback: any) => {
    return {
        type: actions.GET_ARTICLE_LISTING_BY_ID,
        payload: _data,
        callback: _callback
    }
}

export const publishArticle = (_data: any, _callback: any) => {
    return {
        type: actions.PUBLISH_ARTICLE,
        payload: _data,
        callback: _callback
    }
}

export const updateArticle = (_data: any, _callback: any) => {
    return {
        type: actions.UPDATE_ARTICLE,
        payload: _data,
        callback: _callback
    }
}

export const deleteArticleList = (_data: any, _callback: any) => {
    return {
        type: actions.DELETE_ARTICLE_LIST,
        payload: _data,
    };
};

export const setDeleteArticleList = (_data: any) => {
    return {
        type: actions.SET_DELETE_ARTICLE_LIST,
        payload: _data,
    };
};