import { call, put, takeLatest, all, take } from "redux-saga/effects";

import {
  CREATE_BRAND_BLOCK,
  CREATE_CATEGORY_BLOCK,
  GET_BRAND_BLOCK,
  GET_IMAGE_BLOCK,
  GET_BRAND_BLOCKS,
  GET_PRODUCT_BLOCKS,
  GET_PRODUCT_BLOCK,
  CREATE_PRODUCT_BLOCK,
  GET_CATEGORY_BLOCK,
  GET_CATEGORY_BLOCKS,
  GET_IMAGE_BLOCKS,
  UPDATE_BRAND_BLOCK,
  UPDATE_CATEGORY_BLOCK,
  UPDATE_IMAGE_BLOCK,
  CREATE_IMAGE_BLOCK,
  UPDATE_PRODUCT_BLOCK,
  DELETE_BRAND_BLOCK,
  DELETE_BRAND_BLOCK_ELEMENT,
  DELETE_IMAGE_BLOCK,
  DELETE_PRODUCT_BLOCK,
  DELETE_IMAGE_BLOCK_ELEMENT,
  DELETE_PRODUCT_BLOCK_ELEMENT,
  DELETE_STATIC_HTML_BLOCK,
  GET_STATIC_HTML_BLOCKS, GET_STATIC_HTML_BLOCK, UPDATE_STATIC_HTML_BLOCK, CREATE_STATIC_HTML_BLOCK,
  GET_GENERAL_BLOCKS,
  GET_GENERAL_BLOCK,
  DELETE_GENERAL_BLOCK_ELEMENT,
  GET_TEMPLATE_BLOCKS,
  GET_TEMPLATE_BLOCK,
  DELETE_TEMPLATE_BLOCK,
  DELETE_TEMPLATE_BLOCK_ELEMENT,
  CREATE_TEMPLATE_BLOCK,
  UPDATE_TEMPLATE_BLOCK,
  CREATE_GENERAL_BLOCK,
  DELETE_GENERAL_BLOCK,
  UPDATE_GENERAL_BLOCK,
  GET_BLOCK_TYPES_BLOCKS,
  GET_BLOCK_TYPE_BLOCK,
  DELETE_BLOCK_TYPE_BLOCK,
  CREATE_BLOCK_TYPE_BLOCK,
  UPDATE_BLOCK_TYPE_BLOCK,
  GET_BLOCK_LAYOUTS,
  GET_BLOCK_LAYOUT,
  CREATE_BLOCK_LAYOUT,
  UPDATE_BLOCK_LAYOUT,
  DELETE_CATEGORY_BLOCK,
  DELETE_CATEGORY_BLOCK_ELEMENT
} from "../actionTypes/blocksActionTypes";

import {
  setBlocksLoader,
  setBrandBlock,
  setBrandBlocks,
  setCategoryBlock,
  setCategoryBlocks,
  setImageBlocks,
  setImageBlock,
  setProductBlocks,
  setProductBlock,
  setDeleteBrandBlock,
  setStaticHtmlBlocks, setStaticHtmlBlock, setDeleteStaticHtmlBlock,
  setGeneralBlocks,
  setGeneralBlock,
  setTemplateBlocks,
  setTemplateBlock,
  setDeleteTemplateBlock,
  setDeleteGenerelBlock,
  setBlockTypeBlocks,
  setBlockTypeBlock,
  setDeleteBlockTypeBlock,
  setBlockLayouts,
  setBlockLayout,
  setDeleteCategoryBlock,
  setDeleteImageBlock
} from "../actionCreators/blocksActionCreator";

import {
  createBrandBlockService,
  createCategoryBlockService,
  getBrandBlockByIdService,
  getImageBlockByIdService,
  getProductBlocksService,
  getBrandBlocksService,
  getCategoryBlockByIdService,
  getCategoryBlocksService,
  getImageBlocksService,
  updateBrandBlockService,
  updateCategoryBlockService,
  updateImageBlockService,
  createImageBlockService,
  createProductBlockService,
  getProductBlockByIdService,
  updateProductBlockService,
  deleteBrandBlockService,
  deleteBrandBlockElementService,
  deleteProductBlockService,
  deleteImageBlockService,
  deleteCategoryBlockService,
  deleteCategoryBlockElementService,
  deleteImageBlockElementService,
  deleteProductBlockElementService,
  deleteStaticHtmlBlockService,
  getStaticHtmlBlocksService,
  getStaticHtmlBlockByIdService,
  updateStaticHtmlBlockService,
  createStaticHtmlBlockService,
  getGeneralBlocksService,
  getGeneralBlockByIdService,
  deleteGeneralBlockElementService,
  getTemplateBlocksService,
  getTemplateBlockByIdService,
  deleteTemplateBlockService,
  deleteTemplateBlockElementService,
  updateTemplateBlockService,
  createTemplateBlockService,
  createGeneralBlockService,
  deleteGeneralBlockService,
  updateGeneralBlockService,
  getBlockTypeBlocksService,
  deleteBlockTypeBlockService,
  updateBlockTypeBlockService,
  createBlockTypeBlockService,
  getBlockTypeBlockByIdService,
  getBlockLayoutsService,
  updateBlockLayoutService,
  createBlockLayoutService,
  getBlockLayoutService
} from "../services/blocksServices";
import { toast } from "react-hot-toast";

function* _getBrandBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getBrandBlockByIdService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setBrandBlock(data.data));
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getBrandBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _deleteBrandBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(deleteBrandBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status) {
      yield put(setDeleteBrandBlock(_data.payload.id));
    }
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteBrandBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}


function* _deleteBrandBlockElement(_data: any) {
  try {
    // yield put(setBlocksLoader(true));
    const { data } = yield call(deleteBrandBlockElementService, _data.payload);
    // yield put(setBlocksLoader(false));
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteBrandBlockElement: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}
//.............................................................

function* _getBrandBlocks(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getBrandBlocksService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(
        setBrandBlocks({
          totalCount: data.totalCount,
          pageSize: data.pageSize,
          currentPage: data.currentPage,
          totalPage: data.totalPage,
          data: data.data,
        })
      );
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getBrandBlocks: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _createBrandBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(createBrandBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_createBrandBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _updateBrandBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(updateBrandBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_updateBrandBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getCategoryBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getCategoryBlockByIdService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setCategoryBlock(data.data));
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getCategoryBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getCategoryBlocks(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getCategoryBlocksService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(
        setCategoryBlocks({
          totalCount: data.totalCount,
          pageSize: data.pageSize,
          currentPage: data.currentPage,
          totalPage: data.totalPage,
          data: data.data,
        })
      );
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getCategoryBlocks: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _createCategoryBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(createCategoryBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_createCategoryBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _updateCategoryBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(updateCategoryBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_updateCategoryBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _deleteCategoryBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(deleteCategoryBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status) {
      yield put(setDeleteCategoryBlock(_data.payload.id));
    }
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteCategoryBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _deleteCategoryBlockElement(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(
      deleteCategoryBlockElementService,
      _data.payload
    );
    yield put(setBlocksLoader(false));
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteCategoryBlockElement: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}


function* _getStaticHtmlBlocks(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getStaticHtmlBlocksService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setStaticHtmlBlocks({
        totalCount: data.totalCount,
        pageSize: data.pageSize,
        currentPage: data.currentPage,
        totalPage: data.totalPage,
        data: data.data
      }))
    }
    else {
      _data.callback && _data.callback(data)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getStaticHtmlBlocks: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

function* _getStaticHtmlBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getStaticHtmlBlockByIdService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setStaticHtmlBlock(data.data))
    }
    else {
      _data.callback && _data.callback(data)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getStaticHtmlBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}


function* _getImageBlocks(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getImageBlocksService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(
        setImageBlocks({
          totalCount: data.totalCount,
          pageSize: data.pageSize,
          currentPage: data.currentPage,
          totalPage: data.totalPage,
          data: data.data,
        })
      );
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("__getImageBlocks: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getImageBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getImageBlockByIdService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setImageBlock(data.data));
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getImageBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _createImageBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(createImageBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      _data.callback && _data.callback(true);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_createImageBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _updateImageBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(updateImageBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setImageBlock(data.data));
      _data.callback && _data.callback(true);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_updateImageBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}
function* _deleteImageBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(deleteImageBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status) {
      yield put(setDeleteImageBlock(_data.payload.id));
    }
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteImageBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _deleteImageBlockElement(_data: any) {
  try {
    // yield put(setBlocksLoader(true));
    const { data } = yield call(deleteImageBlockElementService, _data.payload);
    // yield put(setBlocksLoader(false));
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteImageBlockElement: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getProductBlocks(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getProductBlocksService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(
        setProductBlocks({
          totalCount: data.totalCount,
          pageSize: data.pageSize,
          currentPage: data.currentPage,
          totalPage: data.totalPage,
          data: data.data,
        })
      );
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getProductBlocks: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getProductBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getProductBlockByIdService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setProductBlock(data.data));
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getProductBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _createProductBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(createProductBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      _data.callback && _data.callback(true);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_createProductBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _updateProductBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(updateProductBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setProductBlock(data.data));
      _data.callback && _data.callback(true);
    } else {
      toast.error(data.statusDescription);
    }
  } catch (err: any) {
    window.location.reload()
    yield put(setBlocksLoader(false));
    console.log("_updateProductBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _deleteProductBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(deleteProductBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status) {
      yield put(setDeleteBrandBlock(_data.payload.id));
    }
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteProductBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _deleteProductBlockElement(_data: any) {
  try {
    // yield put(setBlocksLoader(true));
    const { data } = yield call(
      deleteProductBlockElementService,
      _data.payload
    );
    // yield put(setBlocksLoader(false));
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteProductBlockElement: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}



function* _getStaticHtmlBlockById(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getStaticHtmlBlockByIdService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setStaticHtmlBlock(data.data))
    }
    else {
      _data.callback && _data.callback(data)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getStaticHtmlBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}


function* _createStaticHtmlBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(createStaticHtmlBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      _data.callback && _data.callback(true)
    }
    else {
      toast.error(data.statusDescription)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_createStaticHtmlBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }

}


function* _updateStaticHtmlBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(updateStaticHtmlBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setStaticHtmlBlock(data.data))
      _data.callback && _data.callback(true)
    }
    else {
      toast.error(data.statusDescription)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_updateStaticHtmlBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

function* _deleteStaticHtmlBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(deleteStaticHtmlBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status) {
      yield put(setDeleteStaticHtmlBlock(_data.payload.id));
    }
    _data.callback && _data.callback(data);
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteStaticHtmlBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getGeneralBlocks(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getGeneralBlocksService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(
        setGeneralBlocks({
          totalCount: data.totalCount,
          pageSize: data.pageSize,
          currentPage: data.currentPage,
          totalPage: data.totalPage,
          data: data.data,
        })
      );
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getGeneralBlocks: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _createGeneralBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(createGeneralBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    _data.callback && _data.callback(data)
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_createGeneralBlock: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getGeneralBlockById(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getGeneralBlockByIdService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setGeneralBlock(data.data))
    }
    else {
      _data.callback && _data.callback(data)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getGeneralBlockById: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

function* _deleteGeneralBlockElement(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(deleteGeneralBlockElementService, _data.payload);
    yield put(setBlocksLoader(false));
    _data.callback && _data.callback(data)
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteGeneralBlockElement: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

function* _deleteGeneralBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(deleteGeneralBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setDeleteGenerelBlock(_data.payload.id))
    }
    _data.callback && _data.callback(data)
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteGeneralBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

function* _updateGeneralBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(updateGeneralBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setGeneralBlock(data.data))
    }
    _data.callback && _data.callback(data)
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_updateGeneralBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}


function* _getTemplateBlocks(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getTemplateBlocksService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(
        setTemplateBlocks({
          totalCount: data.totalCount,
          pageSize: data.pageSize,
          currentPage: data.currentPage,
          totalPage: data.totalPage,
          data: data.data,
        })
      );
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getTemplateBlocks: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getTemplateBlockById(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getTemplateBlockByIdService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setTemplateBlock(data.data))
    }
    else {
      _data.callback && _data.callback(data)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getTemplateBlockById: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

function* _deleteTemplateBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(deleteTemplateBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setDeleteTemplateBlock(_data.payload.id))
    }
    else {
      _data.callback && _data.callback(data)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteTemplateBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

function* _deleteTemplateBlockElement(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(deleteTemplateBlockElementService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setDeleteTemplateBlock(_data.payload.id))
    }
    else {
      _data.callback && _data.callback(data)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteTemplateBlockElement: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

function* _updateTemplateBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(updateTemplateBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setTemplateBlock(data.data))
      _data.callback && _data.callback(true)
    }
    else {
      toast.error(data.statusDescription)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_updateTemplateBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}


function* _createTemplateBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(createTemplateBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setTemplateBlock(data.data))
      _data.callback && _data.callback(true)
    }
    else {
      toast.error(data.statusDescription)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_updateTemplateBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

//BLOCK_TYPE
function* _getBlockTypeBlocks(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getBlockTypeBlocksService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(
        setBlockTypeBlocks({
          totalCount: data.totalCount,
          pageSize: data.pageSize,
          currentPage: data.currentPage,
          totalPage: data.totalPage,
          data: data.data,
        })
      );
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getBlockTypeBlocks: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getBlockTypeBlockById(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getBlockTypeBlockByIdService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setBlockTypeBlock(data.data))
    }
    else {
      _data.callback && _data.callback(data)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getBlockTypeBlockById: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

function* _deleteBlockTypeBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(deleteBlockTypeBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setDeleteBlockTypeBlock(_data.payload.id))
    }
    _data.callback && _data.callback(data)
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_deleteBlockTypeBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

function* _updateBlockTypeBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(updateBlockTypeBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    yield put(setBlockTypeBlock(data.data))
    _data.callback && _data.callback(data)
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_updateBlockTypeBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

function* _createBlockTypeBlock(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(createBlockTypeBlockService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setBlockTypeBlock(data.data))
      _data.callback && _data.callback(true)
    }
    else {
      toast.error(data.statusDescription)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_createBlockTypeBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

// LAYOUT
function* _getBlockLayouts(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getBlockLayoutsService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(
        setBlockLayouts({
          totalCount: data.totalCount,
          pageSize: data.pageSize,
          currentPage: data.currentPage,
          totalPage: data.totalPage,
          data: data.data,
        })
      );
    } else {
      _data.callback && _data.callback(data);
    }
  } catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getBlockLayouts: exceptionError: ", err);
    toast.error("Something went wrong.");
  }
}

function* _getBlockLayout(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(getBlockLayoutService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setBlockLayout(data.data))
    }
    else {
      _data.callback && _data.callback(data)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_getBlockLayout: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}


function* _updateBlockLayout(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(updateBlockLayoutService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setBlockLayout(data.data))
      _data.callback && _data.callback(true)
    }
    else {
      toast.error(data.statusDescription)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_updateBlockTypeBlock: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}

function* _createBlockLayout(_data: any) {
  try {
    yield put(setBlocksLoader(true));
    const { data } = yield call(createBlockLayoutService, _data.payload);
    yield put(setBlocksLoader(false));
    if (data.status == true) {
      yield put(setBlockLayout(data.data))
      _data.callback && _data.callback(true)
    }
    else {
      toast.error(data.statusDescription)
    }
  }
  catch (err: any) {
    yield put(setBlocksLoader(false));
    console.log("_createBlockLayout: exceptionError: ", err)
    toast.error("Something went wrong.")
  }
}
export default function* blocksSaga() {
  yield all([
    takeLatest(GET_BRAND_BLOCKS, _getBrandBlocks),
    takeLatest(GET_BRAND_BLOCK, _getBrandBlock),
    takeLatest(DELETE_BRAND_BLOCK, _deleteBrandBlock),
    takeLatest(DELETE_BRAND_BLOCK_ELEMENT, _deleteBrandBlockElement),
    takeLatest(CREATE_BRAND_BLOCK, _createBrandBlock),
    takeLatest(UPDATE_BRAND_BLOCK, _updateBrandBlock),
    takeLatest(GET_CATEGORY_BLOCKS, _getCategoryBlocks),
    takeLatest(GET_CATEGORY_BLOCK, _getCategoryBlock),
    takeLatest(DELETE_CATEGORY_BLOCK, _deleteCategoryBlock),
    takeLatest(CREATE_CATEGORY_BLOCK, _createCategoryBlock),
    takeLatest(UPDATE_CATEGORY_BLOCK, _updateCategoryBlock),
    takeLatest(DELETE_CATEGORY_BLOCK_ELEMENT, _deleteCategoryBlockElement),
    takeLatest(GET_IMAGE_BLOCKS, _getImageBlocks),
    takeLatest(GET_IMAGE_BLOCK, _getImageBlock),
    takeLatest(DELETE_IMAGE_BLOCK, _deleteImageBlock),
    takeLatest(CREATE_IMAGE_BLOCK, _createImageBlock),
    takeLatest(UPDATE_IMAGE_BLOCK, _updateImageBlock),
    takeLatest(DELETE_IMAGE_BLOCK_ELEMENT, _deleteImageBlockElement),
    takeLatest(GET_PRODUCT_BLOCKS, _getProductBlocks),
    takeLatest(GET_PRODUCT_BLOCK, _getProductBlock),
    takeLatest(DELETE_PRODUCT_BLOCK, _deleteProductBlock),
    takeLatest(CREATE_PRODUCT_BLOCK, _createProductBlock),
    takeLatest(UPDATE_PRODUCT_BLOCK, _updateProductBlock),
    takeLatest(DELETE_PRODUCT_BLOCK_ELEMENT, _deleteProductBlockElement),

    takeLatest(GET_STATIC_HTML_BLOCKS, _getStaticHtmlBlocks),
    takeLatest(GET_STATIC_HTML_BLOCK, _getStaticHtmlBlockById),
    takeLatest(CREATE_STATIC_HTML_BLOCK, _createStaticHtmlBlock),
    takeLatest(UPDATE_STATIC_HTML_BLOCK, _updateStaticHtmlBlock),
    takeLatest(DELETE_STATIC_HTML_BLOCK, _deleteStaticHtmlBlock),

    takeLatest(GET_GENERAL_BLOCKS, _getGeneralBlocks),
    takeLatest(GET_GENERAL_BLOCK, _getGeneralBlockById),
    takeLatest(DELETE_GENERAL_BLOCK_ELEMENT, _deleteGeneralBlockElement),
    takeLatest(CREATE_GENERAL_BLOCK, _createGeneralBlock),
    takeLatest(DELETE_GENERAL_BLOCK, _deleteGeneralBlock),
    takeLatest(UPDATE_GENERAL_BLOCK, _updateGeneralBlock),

    takeLatest(GET_TEMPLATE_BLOCKS, _getTemplateBlocks),
    takeLatest(GET_TEMPLATE_BLOCK, _getTemplateBlockById),
    takeLatest(DELETE_TEMPLATE_BLOCK, _deleteTemplateBlock),
    takeLatest(DELETE_TEMPLATE_BLOCK_ELEMENT, _deleteTemplateBlockElement),
    takeLatest(CREATE_TEMPLATE_BLOCK, _createTemplateBlock),
    takeLatest(UPDATE_TEMPLATE_BLOCK, _updateTemplateBlock),

    takeLatest(GET_BLOCK_TYPES_BLOCKS, _getBlockTypeBlocks),
    takeLatest(GET_BLOCK_TYPE_BLOCK, _getBlockTypeBlockById),
    takeLatest(DELETE_BLOCK_TYPE_BLOCK, _deleteBlockTypeBlock),
    takeLatest(CREATE_BLOCK_TYPE_BLOCK, _createBlockTypeBlock),
    takeLatest(UPDATE_BLOCK_TYPE_BLOCK, _updateBlockTypeBlock),

    takeLatest(GET_BLOCK_LAYOUTS, _getBlockLayouts),
    takeLatest(GET_BLOCK_LAYOUT, _getBlockLayout),
    takeLatest(CREATE_BLOCK_LAYOUT, _createBlockLayout),
    takeLatest(UPDATE_BLOCK_LAYOUT, _updateBlockLayout)

  ]);
}
