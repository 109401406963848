import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import "../../components/Article/ArticleStyle.scss";
import { createArticle, publishArticle, updateArticle } from '../../../core/actionCreators/articleActionCreator';
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import queryString from "query-string";


const ArticleFormSubHeader = (props: any) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { articlePayload, setArticlePayload, pageMode, articleForm } = props;
  const { user } = useSelector((s: any) => s.authStore);
  const [saveArticleLoader, setSaveArticleLoader] = useState(false)
  const [publishArticleLoader, setPublishArticleLoader] = useState(false)
  const [unPublishArticleLoader, setUnPublishArticleLoader] = useState(false)

  useEffect(() => {
    if (
      pageMode === "EDIT" &&
      Object.keys(articleForm).length &&
      typeof articleForm !== "string"
    ) {
      setArticlePayload(articleForm);
    } else {
      setArticlePayload(articlePayload);
    }
  }, [articleForm]);

  const onSave = () => {
    if (articlePayload.articleTitle.trim() === "") {
      toast.error("Article Title is required")
      return;
    }
    if (articlePayload.categoryCode.trim() === "") {
      toast.error("Category Code is required")
      return;
    }

    if (articlePayload.friendlyUrl.trim() === "") {
      toast.error("Friendly Url is required")
      return;
    }

    if (pageMode === "CREATE") {
      articlePayload["userId"] = user.id || 100
      setSaveArticleLoader(true)
      dispatch(
        createArticle(articlePayload, (status: boolean) => {
          setSaveArticleLoader(false)
          if (status) {
            toast.success("Saved successfully.");
            navigate("/article")
          }
        })
      );
    } else {
      setSaveArticleLoader(true)
      dispatch(updateArticle(articlePayload, (status: boolean) => {
        setSaveArticleLoader(true)
        if (status) {
          toast.success("Updated successfully.")
          navigate("/article")
        }
      }))
    }

  }

  const onPublish = () => {
    const params = queryString.stringify({
      userId: user.id || 100,
      status: !articlePayload.status,
      id: articlePayload.id
    });
    setPublishArticleLoader(true)

    if (articlePayload.status == false) {
      dispatch(publishArticle(params, (status: boolean) => {
        setPublishArticleLoader(false)
        if (status) {
          toast.success("Published successfully.");
        }
      }))
    } else {
      setPublishArticleLoader(false)
      toast.error("Invalid Request")
    }

  }

  const onUnPublish = () => {
    const params = queryString.stringify({
      userId: user.id || 100,
      status: !articlePayload.status,
      id: articlePayload.id
    });
    setPublishArticleLoader(true)

    if (articlePayload.status == true) {
      dispatch(publishArticle(params, (status: boolean) => {
        setPublishArticleLoader(false)
        if (status) {
          toast.success("UnPublished successfully.");
        }
      }))
    } else {
      setPublishArticleLoader(false)
      toast.error("Invalid Request")
    }

  }

  const leftArrowClickHandler = () => {
    navigate("/article");
  };

  return (
    <>
      <div className="campaign-header bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
        <div className="flex items-center justify-between w-full">
          <div className=" bg-white p-[15px] ">
            <p className="text-xl font-bold text-[#43425D] flex items-center">
              <ArrowLeftSLineIcon onClick={leftArrowClickHandler} size={24} className="mr-4 cursor-pointer" />
              {pageMode === "CREATE" ? "Create Article" : `Update Article ${params.id}`}
            </p>
          </div>
          <button onClick={onSave} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml">
            {saveArticleLoader ? "Loading..." : "SAVE"}
          </button>
          {!articlePayload?.status && <button onClick={onPublish} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-1.5">
            {publishArticleLoader && articlePayload.status == true ? "Loading..." : "PUBLISH"}
          </button>}
          {articlePayload?.status && <button onClick={onUnPublish} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-1.5">
            {unPublishArticleLoader && articlePayload.status == true ? "Loading..." : "UNPUBLISH"}
          </button>}
        </div>
      </div>
    </>
  );
}
export default ArticleFormSubHeader;
