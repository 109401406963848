import queryString from "query-string";
import { useEffect, useState } from "react";
import { getServiceabilityList, uploadServiceabilityFile } from "../../../core/actionCreators/servicebilityActionCreators";
import { GET_SERVICEABILITY_DEFAULT_SIZE } from "../../../utils/constants";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";


const ServiceabilityList = (props: any) => {
    const { isLoading, items, headers, dispatch } = props;
    const [file, setFile] = useState("")
    const { user } = useSelector((s: any) => s.authStore)
    const [searchCriteria, setSearchCriteria] = useState({
        pageNumber: 0,
        pageSize: GET_SERVICEABILITY_DEFAULT_SIZE,
        productMsn: "",
        zipCode: "",
    } as any)

    useEffect(() => {
        for (const key in searchCriteria) {
            if (searchCriteria.hasOwnProperty(key) && searchCriteria[key] === "") {
                delete searchCriteria[key];
            }
        }
        if (Object.keys(searchCriteria).length) {
            const stringifiedSearchCriteria = queryString.stringify(searchCriteria);
            dispatch(getServiceabilityList(stringifiedSearchCriteria, null))
        }
    }, [searchCriteria])

    const fileHandler = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            let file = e.target.files[0];
            setFile(file)
        }
    }

    const bulkUploadHandler = () => {
        if (!file) {
            toast.error("Please select file.")
            return
        }
        const formData = new FormData()
        formData.append("file", file)
        dispatch(uploadServiceabilityFile({
            param: `email=${user.email || "deepak.singh1@moglix.com"}`,
            data: formData
        }, (d: any) => {
            toast.success("Uploaded successfully.")
        }))
    }

    return (
        <>
            <div className="p-4 flex">
                <p className="text-xl font-bold text-[#43425D] w-1/5">SERVICEABILITY</p>
                <div className="flex w-4/5 ml-auto justify-end">
                    <input onChange={(e) => fileHandler(e)} type="file" className="border border-gray-300 py-2 rounded focus: outline-none px-1.5 text-xs w-1/4" />
                    <button onClick={bulkUploadHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-5">Bulk Upload</button>
                </div>
            </div>
            <div className="mt-2.5 campaign-list bg-white mb-[54px]">
                <table className="w-full">
                    <thead className="bg-[#343434]">
                        <tr>
                            {
                                headers && headers.length && headers.map((header: string) => {
                                    return (
                                        <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[40%]">{header}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[40%]">
                                <input type="text" value={searchCriteria.productMsn} onChange={(e) => setSearchCriteria({ ...searchCriteria, productMsn: e.target.value })} className="border border-gray-300 h-7 rounded-sm focus:outline-none px-1.5 text-xs w-full"></input>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">
                                <input type="text" value={searchCriteria.zipCode} onChange={(e) => setSearchCriteria({ ...searchCriteria, zipCode: e.target.value })} className="border border-gray-300 h-7 rounded-sm focus: outline-none px-1.5 text-xs w-full"></input>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">
                            </td>
                        </tr>

                        {items?.map((item: { msn: string, zipcode: string, isCod: any, isServiceable: any }, index: any) =>
                            <>
                                <tr className='[&:nth-child(odd)]:bg-[#f7f7f7]'>
                                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[40%]">{item.msn}</td>
                                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">{item.zipcode}</td>
                                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">{JSON.stringify(item.isServiceable)}</td>
                                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[15%]">{JSON.stringify(item.isCod)}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    )
}
export default ServiceabilityList;