import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createBlockTypeBlock, updateBlockTypeBlock } from "../../../core/actionCreators/blocksActionCreator";
import toast from "react-hot-toast";
import Loader from "../Loader/Loader";

const LayoutTypeForm = (props: any) => {
    const { pageMode, blockData, loaderFlag } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((s: any) => s.authStore);

    const [blockTypeBlockPayload, setBlockTypeBlockPayload] = useState({
        id: "",
        name: "",
        status: "true",
    } as any);

    useEffect(() => {
        if (pageMode === "EDIT" && Object.keys(blockData).length) {
            setBlockTypeBlockPayload(blockData);
        }
    }, [blockData]);

    const createBlockTypeBlockHandler = () => {
        let data: any = { ...blockTypeBlockPayload };
        delete data.id;
        let headers = {
            userId: user.id || 33,
        };
        dispatch(
            createBlockTypeBlock({ data: data, headers: headers }, (resp: any) => {
                toast.success("Created successfully.");
                navigate("/blocks/layoutType");
            })
        );
    };

    const updateBlockTypeBlockHandler = () => {
        let data: any = { ...blockTypeBlockPayload };
        let headers = {
            userId: user.id || 33,
        };
        let params = {
            id: data.id,
        };
        dispatch(
            updateBlockTypeBlock({ params, data: data, headers }, (resp: any) => {
                if (!resp.status) {
                    toast.error(resp.statusDescription)
                    return
                }
                toast.success("Updated successfully.");
            })
        );
    };

    return (
        <>
            <div className="p-4 pb-8">
                <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-4">
                    <form action="">
                        <div className="flex w-full mb-6 last:mb-0">
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Name*</label>
                                <input value={blockTypeBlockPayload.name}
                                    onChange={(e) =>
                                        setBlockTypeBlockPayload({
                                            ...blockTypeBlockPayload,
                                            name: e.target.value,
                                        })
                                    }
                                    className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                                    type="text"
                                />
                            </div>
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Status*</label>
                                <select value={blockTypeBlockPayload.status}
                                    onChange={(e) =>
                                        setBlockTypeBlockPayload({
                                            ...blockTypeBlockPayload,
                                            status: e.target.value,
                                        })
                                    } className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                    <option value={"true"}>Active</option>
                                    <option value={"false"}>Inactive</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="mt-5">
                    {loaderFlag ? <Loader /> : <button onClick={pageMode === "EDIT" ? updateBlockTypeBlockHandler : createBlockTypeBlockHandler} className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ">{pageMode === "EDIT" ? "Update" : "Create"}</button>}
                </div>
            </div>
        </>
    )
}
export default LayoutTypeForm;