import { call, put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-hot-toast";
import { BLOCK_CUSTOMER } from "../actionTypes/commonActionTypes";
import { blockCustomerService } from "../services/commonServices";
import { setCommonLoader } from "../actionCreators/commonActionCreator";

function* _blockCustomer(_data: any) {
    try {
        yield put(setCommonLoader(true));
        const { data } = yield call(blockCustomerService, _data.payload);
        yield put(setCommonLoader(false));
        _data.callback && _data.callback(data.data)
    }
    catch (err: any) {
        yield put(setCommonLoader(false));
        console.log("_blockCustomer: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

export default function* commonSaga() {
    yield all([takeLatest(BLOCK_CUSTOMER, _blockCustomer)])
}