import { apis } from "../../../core/services/params";


export const loginHelper = {

    //Login With microsoft handler
    loginWithMicrosoftHandler: () => {
        window.open(apis.LOGIN_WITH_MICROSOFT, "_self");
    }

}