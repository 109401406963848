import { call, put, all, takeLatest } from "redux-saga/effects";
import { getCategories, setAdsenseLoader, setBrands, setBrandsByCategory, setCampaign, setCampaigns, setCategories, setLookups, updateCampaign } from "../actionCreators/adsenseActionCreator";
import { createCampaignService, getBrandsByCategoryService, getBrandsService, getCampaignService, getCampaignsService, getCategoriesService, getLookupsService, updateCampaignStatusService } from "../services/adsenseServices";
import { toast } from "react-hot-toast";
import { CREATE_CAMPAIGN, GET_BRANDS, GET_BRANDS_BY_CATEGORY, GET_CAMPAIGN, GET_CAMPAIGNS, GET_CATEGORIES, GET_LOOKUPS, UPDATE_CAMPAIGN_STATUS, } from "../actionTypes/adsenseActionTypes";
import { CAMPAIGN_TYPES } from "../../utils/constants";
import { convertToWebp, deleteFileService, downloadFileService, parseCSVService, uploadFileService, uploadImageService } from "../services/utilService";

import { replaceExtensionFromJpegOrPngToWebp } from "../../utils/helper";

function* _getCampaign(_data: any) {
    try {
        yield put(setAdsenseLoader(true));
        const { data } = yield call(getCampaignService, _data.payload);
        yield put(setAdsenseLoader(false));
        if (data.status == true) {
            yield put(setCampaign(data.data))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setAdsenseLoader(false));
        console.log("_getCampaign: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getCampaigns(_data: any) {
    try {
        yield put(setAdsenseLoader(true));
        const { data } = yield call(getCampaignsService, _data.payload);
        yield put(setAdsenseLoader(false));
        if (data.status == true) {
            yield put(setCampaigns(data.data))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        yield put(setAdsenseLoader(false));
        console.log("_getCampaigns: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getLookups(_data: any) {
    try {
        // yield put(setAdsenseLoader(true));
        const { data } = yield call(getLookupsService, _data.payload);
        // yield put(setAdsenseLoader(false));
        if (data.status == true) {
            yield put(setLookups(data.data.lookups))
        }
        else {
            _data.callback && _data.callback(data)
        }
    }
    catch (err: any) {
        // yield put(setAdsenseLoader(false));
        console.log("_getLookups: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getBrands(_data: any) {
    try {
        // yield put(setAdsenseLoader(true));
        const { data } = yield call(getBrandsService);
        // yield put(setAdsenseLoader(false));
        if (data.brands && Array.isArray(data.brands) && data.brands.length) {
            //sort brands by ascending order
            data.brands.sort((a: any, b: any) => {
                // Convert both names to lowercase to ensure case-insensitive comparison
                let nameA = a.name.toLowerCase();
                let nameB = b.name.toLowerCase();

                // Compare the two names
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // Names are equal
                return 0;
            });
            yield put(setBrands(data.brands))
        }
        else {
            throw Error('Brand list is empty')
        }
    }
    catch (err: any) {
        // yield put(setAdsenseLoader(false));
        console.log("_getBrands: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _createCampaign(_data: any): Generator<any, any, any> {
    try {
        yield put(setAdsenseLoader(true));
        //Handle file upload
        const requestPayload: any = _data.payload

        //HANDLE BANNER_AD_UNIT
        if (requestPayload.bannerAdUnit && requestPayload.bannerAdUnit.banners.length) {
            for (let b of requestPayload.bannerAdUnit.banners) {
                if (b.file) {
                    const formData = new FormData();
                    formData.append("file", b.file);
                    formData.append("bucketName", "adsense/campaign")
                    const { data } = yield call(uploadImageService, formData);
                    if (data.status && data.data && Object.keys(data.data).length) {
                        b.pictureUrl = data.data.s3ImageUrl
                        delete b.file
                        if (requestPayload.isApplicableForApp === true || requestPayload.platform === "APP") {
                            //Convert image to webp
                            const webResp = yield call(convertToWebp, {
                                "sourceBucket": "img.moglix.com",
                                "sourceFile": data.data.s3FileName,
                                "destBucket": "img.moglix.com",
                                "destFile": replaceExtensionFromJpegOrPngToWebp(data.data.s3FileName)
                            })
                            b.pictureUrl = replaceExtensionFromJpegOrPngToWebp(b.pictureUrl)
                        }
                    }
                }
            }
        }

        //HANDLE PROMOTED_BRAND_UNIT
        if (requestPayload.promotedBrandUnit && requestPayload.promotedBrandUnit.banners.length) {
            for (let b of requestPayload.promotedBrandUnit.banners) {
                if (b.file) {
                    const formData = new FormData();
                    formData.append("file", b.file);
                    formData.append("bucketName", "adsense/campaign")
                    const { data } = yield call(uploadImageService, formData);
                    if (data.status && data.data && Object.keys(data.data).length) {
                        b.pictureUrl = data.data.s3ImageUrl
                        delete b.file
                        if (requestPayload.isApplicableForApp === true || requestPayload.platform === "APP") {
                            //Convert image to webp
                            const webResp = yield call(convertToWebp, {
                                "sourceBucket": "img.moglix.com",
                                "sourceFile": data.data.s3FileName,
                                "destBucket": "img.moglix.com",
                                "destFile": replaceExtensionFromJpegOrPngToWebp(data.data.s3FileName)
                            })
                            b.pictureUrl = replaceExtensionFromJpegOrPngToWebp(b.pictureUrl)
                        }
                    }
                }
            }
        }

        //HANDLE VIDEO_UNIT
        if (requestPayload.videoUnit && requestPayload.videoUnit.banners.length) {
            for (let b of requestPayload.videoUnit.banners) {
                if (b.file) {
                    const formData = new FormData();
                    formData.append("file", b.file);
                    formData.append("bucketName", "adsense/campaign")
                    const { data } = yield call(uploadImageService, formData);
                    if (data.status && data.data && Object.keys(data.data).length) {
                        b.videoUrl = data.data.s3ImageUrl
                        delete b.file
                    }
                }
            }
        }

        //HANDLE MSN_BASED_CAMPAIGN
        if (requestPayload.campaignType === CAMPAIGN_TYPES.MSNS_BASED_CAMPAIGN) {
            if (requestPayload.file === "") {
                toast.error("CSV file is missing.")
                yield put(setAdsenseLoader(false));
                return
            }
            if (requestPayload.msns.length === 0) {
                if (typeof requestPayload.file == "object") {
                    toast.error("Invalid msns in uploaded file.")
                    yield put(setAdsenseLoader(false));
                    return
                }
                if (typeof requestPayload.file == "string" && requestPayload.file.startsWith("https://s3")) {
                    const { data } = yield call(downloadFileService, requestPayload.file)
                    if (!data) {
                        toast.error("An error occured while downloading data.")
                        yield put(setAdsenseLoader(false));
                        return
                    }
                    const jsonArray = yield call(parseCSVService, data)
                    const firstColumn = jsonArray.map((row: any) => Object.values(row)[0]);
                    requestPayload.msns = firstColumn
                }
            }
            if (typeof requestPayload.file == "object") {
                if (requestPayload._prevFile) {
                    yield call(deleteFileService, requestPayload._prevFile)
                    delete requestPayload._prevFile
                }
                const formData = new FormData();
                formData.append("file", requestPayload.file);
                const { data } = yield call(uploadFileService, formData);
                if (data.status && data.data && Object.keys(data.data).length) {
                    requestPayload.file = data.data.s3FileUrl
                }
            }
        }
        const { data } = yield call(createCampaignService, requestPayload);
        yield put(setAdsenseLoader(false));
        if (data.status == true) {
            yield put(setCampaigns(data.data))
            _data.callback && _data.callback(null)
        }
        else {
            toast.error(data.description)
        }
    }
    catch (err: any) {
        yield put(setAdsenseLoader(false));
        console.log("_createCampaign: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _updateCampaignStatus(_data: any) {
    try {
        yield put(setAdsenseLoader(true));
        const { data } = yield call(updateCampaignStatusService, _data.payload);
        yield put(setAdsenseLoader(false));
        if (data.status == true) {
            yield put(updateCampaign(data.data))
            _data.callback && _data.callback(null)
        }
        else {
            toast.error(data.description)
        }
    }
    catch (err: any) {
        yield put(setAdsenseLoader(false));
        console.log("_updateCampaignStatus: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getCategories(_data: any) {
    try {
        // yield put(setAdsenseLoader(true));
        const { data } = yield call(getCategoriesService);
        // yield put(setAdsenseLoader(false));
        if (data.statusMsg == true && data.categoryList && Array.isArray(data.categoryList) && data.categoryList.length) {
            //sort brands by ascending order
            data.categoryList.sort((a: any, b: any) => {
                // Convert both names to lowercase to ensure case-insensitive comparison
                let nameA = a.categoryName.toLowerCase();
                let nameB = b.categoryName.toLowerCase();

                // Compare the two names
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // Names are equal
                return 0;
            });
            yield put(setCategories(data.categoryList))
        }
        else {
            throw Error('Category list is empty')
        }
    }
    catch (err: any) {
        // yield put(setAdsenseLoader(false));
        console.log("_getCategories: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}

function* _getBrandsByCategory(_data: any) {
    try {
        // yield put(setAdsenseLoader(true));
        const { data } = yield call(getBrandsByCategoryService, _data.payload);
        // yield put(setAdsenseLoader(false));
        if (data.brands && Array.isArray(data.brands) && data.brands.length) {
            yield put(setBrandsByCategory(data.brands))
        }
        else {
            throw Error('Brand list is empty')
        }
    }
    catch (err: any) {
        // yield put(setAdsenseLoader(false));
        console.log("_getBrands: exceptionError: ", err)
        toast.error("Something went wrong.")
    }
}


export default function* adsenseSaga() {
    yield all([
        takeLatest(GET_CAMPAIGN, _getCampaign),
        takeLatest(GET_CAMPAIGNS, _getCampaigns),
        takeLatest(GET_BRANDS, _getBrands),
        takeLatest(GET_LOOKUPS, _getLookups),
        takeLatest(CREATE_CAMPAIGN, _createCampaign),
        takeLatest(UPDATE_CAMPAIGN_STATUS, _updateCampaignStatus),
        takeLatest(GET_CATEGORIES, _getCategories),
        takeLatest(GET_BRANDS_BY_CATEGORY, _getBrandsByCategory)
    ])
}