import { useNavigate } from "react-router-dom";

const PageMappingSubHeader = () => {
  const navigate = useNavigate();
  const navigateToCreateParamHandler = () => {
    navigate("/pageMapping/create");
  };

  return (
    <>
      <div className="flex justify-between items-center pt-4 px-4">
        <p className="text-base text-[#343434]">PAGE MAPPING</p>
        <button
          onClick={navigateToCreateParamHandler}
          className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
        >
          Create Param
        </button>
      </div>
    </>
  );
};
export default PageMappingSubHeader;
