import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServiceabilityList } from "../../../core/actionCreators/servicebilityActionCreators";
import { GET_SERVICEABILITY_DEFAULT_SIZE, PAGE_SIZE, SERVICEABLITY_PAGE_HEADERS } from "../../../utils/constants";
import CommonPagination from "../../components/CommonPagination/CommonPagination";
import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import Pagination from "../../components/Pagination/Pagination";
import ServiceabilityList from "../../components/Serviceability/ServiceabilityList";

const Serviceability = () => {

    const dispatch = useDispatch();
    let { serviceabilityData, isLoading } = useSelector((s: any) => s.serviceabilityStore);
    
    const fetchServiceAbilityList = (_param: string, _callback: any) => {
        dispatch(getServiceabilityList(_param, _callback));
    }

    useEffect(() => {
        fetchServiceAbilityList(`pageNumber=0&pageSize=${GET_SERVICEABILITY_DEFAULT_SIZE}`, null)
    }, []);

    const onPageChange = (_pageNumber: number) => {
        dispatch(getServiceabilityList(`pageNumber=${_pageNumber}&pageSize=${GET_SERVICEABILITY_DEFAULT_SIZE}`, null))
    }

    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <ServiceabilityList  isLoading={isLoading} 
                        items={serviceabilityData?.serviceList} 
                        headers = {SERVICEABLITY_PAGE_HEADERS}
                        dispatch={dispatch}/>
                    </div>
                    {serviceabilityData.serviceList && <CommonPagination data={serviceabilityData} PAGE_SIZE={GET_SERVICEABILITY_DEFAULT_SIZE} onPageChange={onPageChange} />}
                </div>
            </div>
        </>
    )
}
export default Serviceability;