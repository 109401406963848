import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { createFbt, updateFbt } from "../../../core/actionCreators/fbtActionCreator"
import { toast } from "react-hot-toast"


const FbtForm = (props: any) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { pageMode, fbt } = props

    const { user } = useSelector((s: any) => s.authStore);

    const [fbtPayload, setFbtPayload] = useState({
        id: "",
        isActive: "",
        msn: "",
        fbtMsns: [],
    } as any)

    useEffect(() => {
        if (pageMode === "EDIT" && Object.keys(fbt).length) {
            setFbtPayload(fbt)
        }
    }, [fbt])

    const formSubmitHandler = () => {
        if (pageMode === "CREATE") {
            let payload = { ...fbtPayload }
            delete payload.id
            payload.fbtMsns = payload.fbtMsns.split(',').map((item: string) => item.trim())
            payload.isActive = Boolean(payload.isActive)
            dispatch(createFbt(payload, () => {
                toast.success("Saved succsssfully.")
                navigate("/fbt")
            }))
        }
        else {
            let payload = { ...fbtPayload }
            payload.fbtMsns = payload.fbtMsns.split(',').map((item: string) => item.trim())
            payload.isActive = Boolean(payload.isActive)
            dispatch(updateFbt(payload, () => {
                toast.success("Updated succsssfully.")
            }))
        }
    }

    return (<>
        <div className="p-4">
            <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5">
                <form action="">
                    <div className="flex w-full mb-6 last:mb-0">
                        <div className="w-1/2 px-2">
                            <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Msn</label>
                            <input value={fbtPayload.msn} onChange={(e) => setFbtPayload({ ...fbtPayload, msn: e.target.value })}
                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                type="text"
                            />
                        </div>
                        <div className="w-1/2 px-2">
                            <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">FBT Msns (comma seperated)</label>
                            <input value={fbtPayload.fbtMsns} onChange={(e) => setFbtPayload({ ...fbtPayload, fbtMsns: e.target.value })}
                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-XS font-light"
                                type="text"
                            />
                        </div>
                    </div>

                    <div className="flex w-full mb-6 last:mb-0">
                        <div className="w-1/2 px-2">
                            <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Title</label>
                            <div className="flex items-end">
                                <div className="flex items-center">

                                    <input value={"true"} onChange={(e) => setFbtPayload({ ...fbtPayload, isActive: e.target.value })} checked={fbtPayload.isActive == "true"}
                                        className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                                        type="radio"
                                    />
                                    <span className="ml-2 text-[#3c3c3c] text-sm font-light">Active</span>
                                </div>
                                <div className="flex items-center ml-4">
                                    <input value={"false"} onChange={(e) => setFbtPayload({ ...fbtPayload, isActive: e.target.value })} checked={fbtPayload.isActive == "false"}
                                        className="w-4 h-4 rounded border  border-[#3c3c3c] px-2.5 outline-none "
                                        type="radio"
                                    />
                                    <span className="ml-2 text-[#3c3c3c] text-sm font-light">Inactive</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="mt-5">
                <button onClick={formSubmitHandler} className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none">{pageMode === "CREATE" ? "Create" : "Update"}</button>
            </div>
        </div>
    </>)
}

export default FbtForm