import axios from 'axios';
import { apis } from './params';

export async function getProductTags() {
    return axios({
        "method": "Get",
        "url": apis.GET_ALL_TAGS
    })
}
export async function getProductTagsDetails(params: string) {
    return axios({
        "method": "Get",
        "url": apis.GET_TAG_DETAILS + params
    })
}
export async function uploadProductTagImage(_body: any) {
    return axios({
        "method": "Post",
        "url": apis.UPLOAD_TAG_IMAGE,
        "data": _body
    })
}
export async function createProductTag(_body: object) {
    return axios({
        "method": "Post",
        "url": apis.CREATE_PRODUCT_TAG,
        "data": _body
    })
}
export async function updateProductTag(_body: object) {
    return axios({
        "method": "Post",
        "url": apis.UPDATE_PRODUCT_TAG,
        "data": _body
    })
}