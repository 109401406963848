import { useNavigate } from "react-router-dom";
import CategoryListItem from "./CategoryListItem";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import queryString from "query-string";
import { getCategoryBlocks } from "../../../core/actionCreators/blocksActionCreator";
import { GET_CATEGORY_BLOCK_DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import Loader from "../Loader/Loader";
import CommonPagination from "../CommonPagination/CommonPagination";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";

const CategoryBlock = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryBlocks, isLoading } = useSelector((s: any) => s.blocksStore);
  const navigateToCreateCategoryBlockHandler = () => {
    navigate("/blocks/category/create");
  };

  const [searchCriteria, setSearchCriteria] = useState({
    id: "",
    label: "",
    name: "",
    brand: "",
    elementCount: "",
    status: "",
    pageNumber: 0,
    pageSize: GET_CATEGORY_BLOCK_DEFAULT_PAGE_SIZE,
  } as any);

  const previousSearchCriteriaRef = useRef(searchCriteria);


  useEffect(() => {
    const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
    const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
    const previousCriteria = previousSearchCriteriaRef.current;
    const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

    if (searchFilterChanged) {
      const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
      for (let key in parsedSearchCriteria) {
        if (["id", "label", "name", "brand", "elementCount"].includes(key)) {
          parsedSearchCriteria.pageNumber = 0
          break
        }
      }
      dispatch(getCategoryBlocks(queryString.stringify(parsedSearchCriteria), null));
      previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
    }
  }, [searchCriteria]);


  const onPageChange = (pageNumber: any) => {
    setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
  }

  return (
    <>
      <div className="flex justify-between items-center pt-4 px-4">
        <p className="text-base text-[#343434]">Category Blocks</p>
        <button
          onClick={navigateToCreateCategoryBlockHandler}
          className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
        >
          Create Block
        </button>
      </div>
      <div className="mt-4 campaign-list bg-white mb-[54px]">
        <table className="w-full">
          <thead className="bg-[#343434]">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[8%]">
                ID
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]">
                {" "}
                Label
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[24%]">
                Name
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">
                Brand
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[8%]">
                Elements Count
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[8%]">
                Status
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">
                Created At
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[8%]">
                Update
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[8%]">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-3 text-left text-xs text-white w-[8%]">
                <input
                  value={searchCriteria.id}
                  onChange={(e) =>
                    setSearchCriteria({ ...searchCriteria, id: e.target.value })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                  type="text"
                />
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[20%]">
                <input
                  value={searchCriteria.label}
                  onChange={(e) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      label: e.target.value,
                    })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                  type="text"
                />
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[24%]">
                <input
                  value={searchCriteria.name}
                  onChange={(e) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      name: e.target.value,
                    })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                  type="text"
                />
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[15%]">
                <input
                  value={searchCriteria.brand}
                  onChange={(e) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      brand: e.target.value,
                    })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                  type="text"
                />
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[8%]">
                <input
                  value={searchCriteria.elementCount}
                  onChange={(e) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      elementCount: e.target.value,
                    })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                  type="text"
                />
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[15%]">
                <select
                  value={searchCriteria.status}
                  onChange={(e) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      status: e.target.value,
                    })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs bg-white"
                >
                  <option>Select An Option</option>
                  <option value={"true"}>True</option>
                  <option value={"false"}>False</option>
                </select>
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[7%]"></td>
            </tr>
            {isLoading ? (
              <Loader />
            ) : (
              <CategoryListItem data={categoryBlocks?.data || []} />
            )}
          </tbody>
        </table>
      </div>
      {categoryBlocks?.data && (
        <CommonPagination
          data={categoryBlocks}
          PAGE_SIZE={GET_CATEGORY_BLOCK_DEFAULT_PAGE_SIZE}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};
export default CategoryBlock;
