import {
    SET_ADSENSE_LOADER, SET_BRANDS, SET_BRANDS_BY_CATEGORY, SET_CAMPAIGN, SET_CAMPAIGNS, SET_CATEGORIES, SET_LOOKUPS, UPDATE_CAMPAIGN, UPDATE_CAMPAIGN_STATUS
} from '../actionTypes/adsenseActionTypes';
import { RESET } from '../actionTypes/authActionTypes';

const initialState: any = {
    campaigns: {},
    campaign: {},
    isLoading: false,
    brands: [],
    lookups: {},
    categories: [],
    brandsByCategory: []
}

const adsenseReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_ADSENSE_LOADER:
            return {
                ...state,
                isLoading: action.payload
            }
        case SET_BRANDS:
            return {
                ...state,
                brands: action.payload
            }
        case SET_CAMPAIGNS:
            return {
                ...state,
                campaigns: action.payload
            }
        case SET_CAMPAIGN:
            return {
                ...state,
                campaign: action.payload
            }
        case SET_LOOKUPS:
            return {
                ...state,
                lookups: action.payload
            }
        case UPDATE_CAMPAIGN:
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    data: state.campaigns.data.map((d: any) => d.id === action.payload.id ? action.payload : d)
                }
            };
        case SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case SET_BRANDS_BY_CATEGORY:
            return {
                ...state,
                brandsByCategory: action.payload
            }
        case RESET:
            return initialState;
        default:
            return state;
    }
}

export default adsenseReducer