import React, { useEffect, useState } from "react";
import "../Pagination/Pagination.scss";
import { AiOutlineDoubleRight } from "react-icons/ai";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import { getLayouts } from "../../../core/actionCreators/lmsActionCreator";
import { useDispatch } from "react-redux";
import { PAGE_SIZE } from "../../../utils/constants";


const Pagination = () => {
  const dispatch = useDispatch()
  const { layouts } = useSelector((s: any) => s.lmsStore);

  let [pageNumber, setPageNumber] = useState(1)
  let [currentPageBatchIndex, setCurrentPageBatchIndex] = useState(1)
  const [pages, setPages] = useState([])
  const [lowerBound, setLowerBound] = useState(1)
  const [upperBound, setUpperBound] = useState(PAGE_SIZE)

  useEffect(() => {
    let pageSize = PAGE_SIZE
    if (layouts.totalCount) {
      let totalCount = layouts.totalCount
      let totalPages = []
      let counter = 1
      while (totalCount >= counter) {
        totalPages.push(counter)
        counter = counter + 1
      }

      let firstIndex = 0
      let secondIndex = pageSize
      if (currentPageBatchIndex > 1) {
        firstIndex = pageSize * (currentPageBatchIndex - 1)
        secondIndex = ((pageSize * currentPageBatchIndex) > layouts.totalPage ? layouts.totalPage : pageSize * currentPageBatchIndex)
      }
      const pages: any = totalPages.slice(firstIndex, secondIndex);
      setPages(pages)
    }
  }, [layouts, currentPageBatchIndex])

  //Left arrow click handler
  const rightClickNavigationHandler = () => {
    currentPageBatchIndex = currentPageBatchIndex + 1
    setCurrentPageBatchIndex(currentPageBatchIndex)
    currentPageHandler(pages[PAGE_SIZE - 1] + 1)
    return;
  }

  //Right arrow click handler
  const leftClickNavigationHandler = () => {
    if (currentPageBatchIndex == 1) {
      return;
    }
    else {
      currentPageBatchIndex = currentPageBatchIndex - 1
      setCurrentPageBatchIndex(currentPageBatchIndex)
      currentPageHandler(pages[0] - PAGE_SIZE)
    }
  }

  const getDataOfCurrentPage = (_pageNumber: number) => {
    dispatch(getLayouts(`pageNumber=${_pageNumber - 1}&pageSize=${PAGE_SIZE}`, null));
    return
  }

  const currentPageHandler = (_pageNumber: number) => {
    setPageNumber(_pageNumber)
    getDataOfCurrentPage(_pageNumber)
  }

  useEffect(() => {
    if (pageNumber == 1) {
      setLowerBound(1)
      setUpperBound(PAGE_SIZE)
    }
    else {
      setLowerBound(PAGE_SIZE * (pageNumber - 1))
      setUpperBound(PAGE_SIZE * pageNumber)
    }
  }, [layouts, pageNumber])


  return (
    <>
      {Object.keys(layouts).length > 0 && <div className="pagelist-pagination p-[15px] fixed bg-sky-50">
        <div className="pagination-inner flex justify-between items-center">
          <p className="display-txt">Displaying {`${lowerBound} - ${upperBound}`} of {layouts?.totalCount} results.</p>
          <div className="pagination-list flex">
            <div className="page-number">
              <p>
                <AiOutlineDoubleLeft onClick={leftClickNavigationHandler} />
              </p>
            </div>
            {pages.map((d: any, index: number) =>
              <div onClick={() => currentPageHandler(d)} key={index} className={`${(d == pageNumber) && "active"} page-number`} >
                <p >{d}</p>
              </div>
            )}
            <div className="page-number">
              <p>
                <AiOutlineDoubleRight onClick={rightClickNavigationHandler} />
              </p>
            </div>
          </div>
        </div>
      </div >}

    </>
  );
};

export default Pagination;
