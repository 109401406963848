import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteBlockTypeBlock } from "../../../core/actionCreators/blocksActionCreator";
import toast from "react-hot-toast";
import { formatTimestamp } from "../../../utils/helper";
import Edit2FillIcon from "remixicon-react/Edit2FillIcon";
import DeleteBin5FillIcon from "remixicon-react/DeleteBin5FillIcon";

const LayoutTypeListItem = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((s: any) => s.authStore);
    const { data } = props;

    const updateLayoutTypeNavigateHandler = (id: any) => {
        navigate(`/blocks/layoutType/${id}`);
    };

    const deleteLayoutTypeBlockHandler = (id: any) => {
        const isConfirmed = window.confirm('Are you sure want to delete?')
        if (isConfirmed) {
            dispatch(deleteBlockTypeBlock({ id: id, userId: user.id || 33 }, (resp: any) => {
                if (!resp.status) {
                    toast.error(resp.statusDescription)
                    return
                }
                toast.success("Deleted successfully")
                window.location.reload()
            }))
        }
        return
    };

    return (
        <>
            {data.map((_d: any) => (
                <tr className='[&:nth-child(even)]:bg-[#f7f7f7]'>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[10%]">1</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[30%]">{_d.name}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[20%]">{formatTimestamp(_d.createdAt)}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[10%]">{formatTimestamp(_d.updatedAt)}</td>
                    <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[10%]">{_d.status ? "True" : "False"}</td>
                    <td onClick={() => updateLayoutTypeNavigateHandler(_d.id)} className="px-4 py-3 text-left text-xs text-[#337ab7] w-[10%] font-semibold cursor-pointer"><Edit2FillIcon size={14} /></td>
                    <td onClick={() => deleteLayoutTypeBlockHandler(_d.id)} className="px-4 py-3 text-left text-xs text-[#337ab7] w-[10%] font-semibold cursor-pointer">{" "} <DeleteBin5FillIcon size={14} /></td>
                </tr>
            ))}
        </>
    )
}
export default LayoutTypeListItem;