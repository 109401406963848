import { useNavigate } from "react-router-dom";
import TemplateBlockListItem from "./TemplateBlockListItem";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { GET_TEMPLATE_BLOCK_DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";
import queryString from "query-string";
import { getTemplateBlocks } from "../../../core/actionCreators/blocksActionCreator";
import Loader from "../Loader/Loader";
import CommonPagination from "../CommonPagination/CommonPagination";

const TemplateBlock = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { templateBlocks, isLoading } = useSelector((s: any) => s.blocksStore);

    const [searchCriteria, setSearchCriteria] = useState({
        id: "",
        name: "",
        status: "",
        pageNumber: 0,
        pageSize: GET_TEMPLATE_BLOCK_DEFAULT_PAGE_SIZE,
    } as any);

    const previousSearchCriteriaRef = useRef(searchCriteria);


    useEffect(() => {
        const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
        const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
        const previousCriteria = previousSearchCriteriaRef.current;
        const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

        if (searchFilterChanged) {
            const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
            for (let key in parsedSearchCriteria) {
                if (["id", "name", "status"].includes(key)) {
                    parsedSearchCriteria.pageNumber = 0
                    break
                }
            }
            dispatch(getTemplateBlocks(queryString.stringify(parsedSearchCriteria), null));
            previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
        }
    }, [searchCriteria]);


    const onPageChange = (pageNumber: any) => {
        setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
    }
    const navigateToCreateTemplateBlockHandler = () => {
        navigate('/blocks/template/create');
    }
    return (
        <>
            <div className="flex justify-between items-center pt-4 px-4">
                <p className="text-base text-[#343434]">Templates</p>
                <button onClick={navigateToCreateTemplateBlockHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">Create Templates</button>
            </div>
            <div className="mt-4 campaign-list bg-white mb-[54px]">
                <table className="w-full" >
                    <thead className="bg-[#343434]">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">ID</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[40%]"> Name</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Status</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Created At</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Updated At</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Update</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="px-4 py-3 text-left text-xs text-white w-[10%]">
                                <input value={searchCriteria.id}
                                    onChange={(e) =>
                                        setSearchCriteria({ ...searchCriteria, id: e.target.value })
                                    } className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[40%]">
                                <input value={searchCriteria.name}
                                    onChange={(e) =>
                                        setSearchCriteria({ ...searchCriteria, name: e.target.value })
                                    } className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                                    type="text"
                                />
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[10%]">
                                <select
                                    value={searchCriteria.status}
                                    onChange={(e) =>
                                        setSearchCriteria({
                                            ...searchCriteria,
                                            status: e.target.value,
                                        })
                                    }
                                    className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs bg-white"
                                >
                                    <option>Select An Option</option>
                                    <option value={"true"}>True</option>
                                    <option value={"false"}>False</option>
                                </select>
                            </td>
                            <td className="px-4 py-3 text-left text-xs text-white w-[10%]">

                            </td>
                        </tr>
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <TemplateBlockListItem data={templateBlocks?.data || []} />
                        )}
                    </tbody>
                </table>
            </div>
            {templateBlocks?.data && (
                <CommonPagination
                    data={templateBlocks}
                    PAGE_SIZE={GET_TEMPLATE_BLOCK_DEFAULT_PAGE_SIZE}
                    onPageChange={onPageChange}
                />
            )}
        </>
    )
}
export default TemplateBlock;