import { useNavigate, useParams } from "react-router-dom";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import CategoryBlocksParentForm from "../../../components/CategoryBlock/CategoryBlocksParentForm";
import Header from "../../../components/Header/Header";
import SideNavbar from "../../../components/Navbar/SideNavbar";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CreateCategoryBlockSubHeader from "../../../components/CategoryBlock/CreateCategoryBlockSubHeader";
import {
  getCategoryBlock,
  setCategoryBlock,
} from "../../../../core/actionCreators/blocksActionCreator";

const CategoryBlock = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageMode, setPageMode] = useState("");
  const { categoryBlock, isLoading } = useSelector((s: any) => s.blocksStore);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === "/blocks/category/create") {
      setPageMode("CREATE");
    } else {
      if (params.id) {
        setPageMode("EDIT");
        dispatch(getCategoryBlock(params.id, null));
      }
    }
    return () => {
      dispatch(setCategoryBlock({}));
    };
  }, [params, window]);

  return (
    <>
      <div className="flex mainWrapper w-full">
        <div className="sidenav-wrapper">
          <SideNavbar />
        </div>
        <div className="right-container">
          <div className="header-container">
            <Header />
          </div>
          <div className="body-container">
            <CreateCategoryBlockSubHeader pageMode={pageMode} />
            <CategoryBlocksParentForm
              pageMode={pageMode}
              blockData={categoryBlock}
              loaderFlag={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default CategoryBlock;
