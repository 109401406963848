import {
    SET_FBT_LOADER, SET_FBT, SET_FBTS, SET_DOWNLOAD_DUMP_LOADER
} from '../actionTypes/fbtActionTypes';

const initialState: any = {
    fbts: {},
    fbt: {},
    isLoading: false,
    downloadDumpLoader: false
}

const fbtReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_FBT_LOADER:
            return {
                ...state,
                isLoading: action.payload
            }
        case SET_DOWNLOAD_DUMP_LOADER:
            return {
                ...state,
                downloadDumpLoader: action.payload
            }
        case SET_FBTS:
            return {
                ...state,
                fbts: action.payload
            }
        case SET_FBT:
            return {
                ...state,
                fbt: action.payload
            }
        default:
            return state;
    }
}

export default fbtReducer