import { useState } from "react";

const SideImage = (props: any) => {
    const { articlePayload, setArticlePayload } = props;
    const [pwaImage, setPwaImage] = useState("")
    const [desktopImage, setDesktopImage] = useState("")

    const handleDesktopImageChange = (e: any) => {
        const file = e.target.files[0];
        setArticlePayload({ ...articlePayload, desktopImageUrl: file })
        if (file) {
            const reader: any = new FileReader();
            reader.onloadend = () => {
                setDesktopImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleMobileImageChange = (e: any) => {
        const file = e.target.files[0];
        setArticlePayload({ ...articlePayload, pwaImageUrl: file })
        if (file) {
            const reader: any = new FileReader();
            reader.onloadend = () => {
                setPwaImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <>
            <div className="image-container rounded shadow-[0_1px_4px_rgba(0,0,0,.2)]">
                <div className="desktop-view w-1/2 px-2 mt-4">
                    {/* LOCAL RENDER */}
                    {desktopImage && (
                        <div>
                            <img
                                src={desktopImage}
                            // alt="Image Preview"
                            />
                        </div>
                    )}

                    {articlePayload.desktopImageUrl && typeof articlePayload.desktopImageUrl === "string" && (
                        <div>
                            <img
                                src={"https://cdn.moglix.com/" + articlePayload.desktopImageUrl}
                            // alt="Image Preview"
                            />
                        </div>
                    )}
                    {articlePayload.desktopImageUrl && typeof articlePayload.desktopImageUrl === "string" && (
                        <a
                            className="text-[10px] text-[#3c3c3c]"
                            href={"https://cdn.moglix.com/" + articlePayload.desktopImageUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Preview Image
                        </a>
                    )}

                    <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                        Desktop Image
                    </label>
                    <input
                        onChange={handleDesktopImageChange}
                        className="file w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                        type="file"
                    />

                </div>

                <div className="mobile-view w-1/2 px-2 mt-8">
                    {/* LOCAL RENDER */}
                    {pwaImage && (
                        <div>
                            <img
                                src={pwaImage}
                            // alt="Image Preview"
                            />
                        </div>
                    )}

                    {articlePayload.pwaImageUrl && typeof articlePayload.pwaImageUrl === "string" && (
                        <div className="thumbnail-container">
                            <img
                                className="thumbnail"
                                src={"https://cdn.moglix.com/" + articlePayload.pwaImageUrl}
                            // alt="Image Preview"
                            />
                        </div>
                    )}
                    {articlePayload.pwaImageUrl && typeof articlePayload.pwaImageUrl === "string" && (
                        <a
                            className="text-[10px] text-[#3c3c3c]"
                            href={"https://cdn.moglix.com/" + articlePayload.pwaImageUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Preview Image
                        </a>
                    )}

                    <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                        Mobile Image
                    </label>
                    <input
                        onChange={handleMobileImageChange}
                        className="file w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                        type="file"
                    />
                </div>
            </div>
        </>
    );
};

export default SideImage;
