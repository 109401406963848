import { useNavigate } from "react-router-dom";
import ArrowLeftSLineIcon from "remixicon-react/ArrowLeftSLineIcon";
import Header from "../../components/Header/Header"
import SideNavbar from "../../components/Navbar/SideNavbar"
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getReviews, updateReview } from "../../../core/actionCreators/rmActionCreator";
import toast from "react-hot-toast";

const Review = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const [loader, setLoader] = useState(false)

    const [review, setReview] = useState({
        productMsn: "",
        isApproved: "",
        isActive: "",
        reviewId: "",
        rating: "",
        reviewSubject: "",
        reviewText: "",
        userId: "",
        userName: "",
        images: "",
        videos: "",
        isEnabled: "",
        merchandiseReplyText: ""
    })

    useEffect(() => {
        if (params.id) {
            dispatch(getReviews(`id=${params.id}`, (reviews: any) => {
                if (reviews.reviewsList && Array.isArray(reviews.reviewsList) && reviews.reviewsList.length === 1) {
                    setReview({ ...reviews.reviewsList[0] })
                }
                else {
                    toast.error('Review not found.')
                }
            }))
        }
    }, [params])


    const backArrowClickHadler = () => {
        navigate('/review')
    }

    const updateReviewClickHandler = () => {
        //Review
        review["isEnabled"] = review["isActive"]
        setLoader(true)
        dispatch(updateReview(review, (updateReviewApiRes: string) => {
            setLoader(false)
            toast.success(updateReviewApiRes)
        }))
    }


    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container bg-white" >
                        <div className="Subheader bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                            <div className="flex items-center w-full">
                                <ArrowLeftSLineIcon onClick={backArrowClickHadler} size={24} className="mr-4" />
                                <p className="text-xl font-bold text-[#43425D] flex items-center"> Review Update #{params.id}</p>
                            </div>
                        </div>
                        <div className="p-4 pb-8">
                            <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5">
                                <form>
                                    <div className="flex w-full mb-6 last:mb-0">
                                        <div className="w-1/2 px-2">
                                            <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium" htmlFor="msn">MSN*</label>
                                            <input
                                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                                type="text"
                                                value={review.productMsn} onChange={(e) => setReview({ ...review, productMsn: e.target.value })}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-full mb-6 last:mb-0">
                                        <div className="w-1/2 px-2">
                                            <label htmlFor='isEnabled' className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Enable</label>
                                            <select
                                                value={JSON.stringify(review.isActive)} onChange={(e) => setReview({ ...review, isActive: e.target.value })}
                                                id="isEnabled"
                                                className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                                <option value='true'>True</option>
                                                <option value='false'>False</option>
                                            </select>
                                        </div>
                                        <div className="w-1/2 px-2">
                                            <label htmlFor='isApproved' className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Approve</label>
                                            <select
                                                id="isApproved"
                                                value={review.isApproved} onChange={(e) => setReview({ ...review, isApproved: e.target.value })}
                                                className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                                <option value='true'>True</option>
                                                <option value='false'>False</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="flex w-full mb-6 last:mb-0">
                                        <div className="w-1/2 px-2">
                                            <label htmlFor='reviewId' className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">ReviewId</label>
                                            <input
                                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                                type="text"
                                                disabled
                                                value={review.reviewId} onChange={(e) => setReview({ ...review, reviewId: e.target.value })}
                                                id="reviewId"
                                            />
                                        </div>
                                        <div className="w-1/2 px-2">
                                            <label htmlFor='rating' className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Rating</label>
                                            <input
                                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                                type="text"
                                                id="rating"
                                                value={review.rating} onChange={(e) => setReview({ ...review, rating: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-full mb-6 last:mb-0">
                                        <div className="w-1/2 px-2">
                                            <label htmlFor='reviewSubject' className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Review Subject</label>
                                            <input
                                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                                type="text"
                                                id="reviewSubject"
                                                value={review.reviewSubject} onChange={(e) => setReview({ ...review, reviewSubject: e.target.value })}
                                            />
                                        </div>
                                        <div className="w-1/2 px-2">
                                            <label htmlFor='reviewText' className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Review text</label>
                                            <input
                                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                                type="text"
                                                id="reviewText"
                                                value={review.reviewText} onChange={(e) => setReview({ ...review, reviewText: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-full mb-6 last:mb-0">
                                        <div className="w-1/2 px-2">
                                            <label htmlFor='userId' className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">User Id</label>
                                            <input
                                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                                type="text"
                                                disabled
                                                id="userId"
                                                value={review.userId} onChange={(e) => setReview({ ...review, userId: e.target.value })}
                                            />
                                        </div>
                                        <div className="w-1/2 px-2">
                                            <label htmlFor='userName' className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">User name</label>
                                            <input
                                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                                type="text"
                                                disabled
                                                id="userName"
                                                value={review.userName} onChange={(e) => setReview({ ...review, userName: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-full mb-6 last:mb-0">
                                        <div className="w-1/2 px-2">
                                            <label htmlFor='imagesUrls' className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Images</label>
                                            <input
                                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                                                type="text"
                                                id="images"
                                                value={review.images} onChange={(e) => setReview({ ...review, images: e.target.value })}
                                            />
                                        </div>
                                        <div className="w-1/2 px-2">
                                            <label htmlFor='videoUrls' className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Videos</label>
                                            <input
                                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                                                type="text"
                                                value={review.videos} onChange={(e) => setReview({ ...review, videos: e.target.value })}
                                                id="videos"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex w-full mb-6 last:mb-0">
                                        <div className="w-1/2 px-2">
                                            <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Reply</label>
                                            <input
                                                className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] p-2 outline-none text-sm font-light"
                                                type="text"
                                                value={review.merchandiseReplyText} onChange={(e) => setReview({ ...review, merchandiseReplyText: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="mt-5">
                                <button disabled={loader} type='submit' onClick={updateReviewClickHandler} className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ">{loader ? "Loading..." : "Save"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Review;