import { useNavigate } from "react-router-dom";

const ProductTagSubHeader = () => {
    const navigate = useNavigate();
    const createNewPageClickHandler = () => {
        navigate("/productTagging/create")
    }
    return (
        <>
            <div className="campaign-header bg-white p-[15px] shadow-[0_2px_6px_rgba(0,0,0,.1)]">
                <div className="flex items-center justify-between w-full">
                    <p className="text-xl font-bold text-[#43425D] w-1/5">Product Tags</p>
                    <div className='pl-12'>
                        <button onClick={createNewPageClickHandler} className="h-9 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">Create New Tag</button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ProductTagSubHeader;