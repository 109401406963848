import { SET_USER_LOADER, SET_USER, SET_USER_FAILURE, RESET } from '../actionTypes/authActionTypes';

const initialState: any = {
    user: {},
    isAuthenticated: false,
    isLoading: false,
    userFailure: {},
}

const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true
            }
        case SET_USER_FAILURE:
            return {
                ...state,
                userFailure: action.payload
            }
        case SET_USER_LOADER:
            return {
                ...state,
                isLoading: action.payload
            }
        case RESET:
            return initialState
        default:
            return state;
    }
}

export default authReducer