import ImageBlockElementForm from "./ImageBlockElementForm";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  createImageBlock,
  updateImageBlock,
  deleteImageBlockElement,
} from "../../../core/actionCreators/blocksActionCreator";
import { toast } from "react-hot-toast";
import Loader from "../Loader/Loader";


const ImageBlockParentForm = (props: any) => {
  const { pageMode, imageBlock, loaderFlag } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((s: any) => s.authStore);

  const [imageBlockPayload, setImageBlockPayload] = useState({
    id: "",
    image: "",
    label: "",
    name: "",
    status: "true",
    title: "",
    url: "",
    associatedLayouts: [],
    imageList: [
      {
        id: uuidv4(),
        image: "",
        link: "",
        title: "",
        imageOrder: 0,
        fileName: "",
        imgeGeneralList: [],
        // imgeGeneralList: [
        //   {
        //     id: uuidv4(),
        //     generalText: "",
        //     generalUrl: "",
        //   },
        // ],
      },
    ]
  } as any);

  useEffect(() => {
    if (pageMode === "EDIT" && Object.keys(imageBlock).length) {
      setImageBlockPayload({ ...imageBlock });
    }
  }, [imageBlock]);

  const createImageBlockHandler = () => {
    if (imageBlockPayload.label.trim() === "") {
      toast.error("Label is Required");
      return;
    }
    if (imageBlockPayload.name.trim() === "") {
      toast.error("Name is Required");
      return;
    }
    if (imageBlockPayload.status === "") {
      toast.error("Status is Required");
      return;
    }
    // setButtonInfo(buttonStates.CREATE);
    const formData = new FormData();
    let data: any = { ...imageBlockPayload };
    data.status = JSON.parse(data.status)
    delete data.associatedLayouts
    delete data.id;

    let headers = {
      userId: user.id || 33,
    };
    data.imageList.forEach((d: any) => {
      delete d.id;
      d.imageOrder = parseInt(d.imageOrder)
      if (d.image) {
        let file = d.image;
        formData.append("files", file);
        delete d.image;
      }
      if (d.hasOwnProperty("containErrors")) {
        delete d.containErrors
      }
      d.imgeGeneralList.forEach((element: any) => {
        delete element.id
      });
    });


    formData.append("json", JSON.stringify(data));
    dispatch(
      createImageBlock({ data: formData, headers: headers }, () => {
        toast.success("Created successfully.");
        navigate("/blocks/image");
      })
    );
  };

  const updateImageBlockHandler = () => {
    // Create a new FormData object to hold the form data
    const formData = new FormData();

    // Create a copy of the imageBlockPayload to manipulate data
    let data: any = { ...imageBlockPayload };

    // Remove associatedLayouts property from the data
    delete data.associatedLayouts;

    // Parse the status property from string to JSON
    data.status = JSON.parse(data.status);

    // Define headers object with userId
    let headers = {
      userId: user.id || 33,
    };

    // Define params object with the id property
    let params = {
      id: data.id,
    };

    // Initialize an empty array to store image files
    let files: any = [];

    // Iterate over each item in the imageList array
    data.imageList.forEach((d: any) => {
      // Parse imageOrder property to an integer
      d.imageOrder = parseInt(d.imageOrder);

      // Check if the id is a string, indicating a new image
      if (typeof d.id === 'string') {
        // Get the image file and remove id and image properties from the item
        let file = d.image;
        delete d.id;
        delete d.image;

        // Add the image file to the files array
        files.push(file);
      }

      // Remove containErrors property if it exists
      if (d.hasOwnProperty("containErrors")) {
        delete d.containErrors;
      }

      // If the image property exists, add the file to the files array
      if (d.image && typeof d.image === 'string') {
        files.push(null)
      }
      else {
        if (d.image) {
          let file = d.image;
          delete d.image;
          files.push(file);
        }
      }

      // Iterate over each item in the imgeGeneralList array
      d.imgeGeneralList.forEach((_d: any) => {
        // Remove id property if it is a string
        if (typeof _d.id === 'string') {
          delete _d.id;
        }
      });
    });


    files.forEach((file: any) => {
      formData.append("files", file);
    });


    // Append the JSON stringified data to the formData
    formData.append("json", JSON.stringify(data));

    // Dispatch the updateImageBlock action with params, formData, and headers
    dispatch(
      updateImageBlock({ params, data: formData, headers }, () => {
        // Show a success toast message upon successful update
        toast.success("Updated successfully.");
        window.location.reload()
      })
    );
  };


  const addElementHandler = () => {
    let newElement = {
      id: uuidv4(),
      image: "",
      link: "",
      title: "",
      imageOrder: 0,
      fileName: "",
      imgeGeneralList: [
        {
          id: uuidv4(),
          generalText: "",
          generalUrl: "",
        },
      ],
    };
    imageBlockPayload.imageList.push(newElement);
    setImageBlockPayload({ ...imageBlockPayload });
  };

  const removeElementHandler = (elementId: any) => {
    const isConfirmed = window.confirm('Are you sure want to delete?')
    if (!isConfirmed) {
      return
    }

    if (imageBlockPayload.imageList.length === 1) {
      toast.error("Please rethink about it.");
      return;
    }
    const elementIndex = imageBlockPayload.imageList.findIndex(
      (d: any) => d.id === elementId
    );
    if (elementIndex === -1) {
      toast.error("Something went wrong.");
      return;
    }
    if (pageMode === "EDIT") {
      dispatch(
        deleteImageBlockElement(
          {
            blockId: imageBlockPayload.id,
            blockElementId: elementId,
            userId: user.id || 33,
          },
          (resp: any) => {
            if (!resp.status) {
              toast.error(resp.statusDescription);
              return;
            }
            imageBlockPayload.imageList.splice(elementIndex, 1);
            setImageBlockPayload({ ...imageBlockPayload });
            return;
          }
        )
      );
    }

    imageBlockPayload.imageList.splice(elementIndex, 1);
    setImageBlockPayload({ ...imageBlockPayload });
  };

  const navigateToLayoutPageHandler = (layoutId: number) => {
    navigate(`/blocks/layout/${layoutId}`);
  }

  return (
    <>
      {/* First Level */}
      <div className="p-4 pb-8">
        <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5">
          <form action="">
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Label*
                </label>
                <input
                  value={imageBlockPayload.label}
                  onChange={(e) =>
                    setImageBlockPayload({
                      ...imageBlockPayload,
                      label: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                  required
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Name*
                </label>
                <input
                  value={imageBlockPayload.name}
                  onChange={(e) =>
                    setImageBlockPayload({
                      ...imageBlockPayload,
                      name: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                  required
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Title
                </label>
                <input
                  value={imageBlockPayload.title}
                  onChange={(e) =>
                    setImageBlockPayload({
                      ...imageBlockPayload,
                      title: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Url
                </label>
                <input
                  value={imageBlockPayload.url}
                  onChange={(e) =>
                    setImageBlockPayload({
                      ...imageBlockPayload,
                      url: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                  type="text"
                />
              </div>
            </div>
            <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">
                  Status*
                </label>
                <select
                  required
                  value={imageBlockPayload.status}
                  onChange={(e) =>
                    setImageBlockPayload({
                      ...imageBlockPayload,
                      status: e.target.value,
                    })
                  }
                  className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light"
                >
                  <option value={"true"}>Active</option>
                  <option value={"false"}>Inactive</option>
                </select>
              </div>
            </div>

            {/* Show Associated Layout if edit mode */}
            {pageMode === "EDIT" && imageBlockPayload.associatedLayouts && Array.isArray(imageBlockPayload.associatedLayouts) && imageBlockPayload.associatedLayouts.length > 0 && <div className="flex w-full mb-6 last:mb-0">
              <div className="w-1/2 px-2">
                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Associated Layouts</label>
                <ul className="list-none p-0">
                  {imageBlockPayload.associatedLayouts.map((d: any, index: number) =>
                    <li key={index} className="mb-1 last:mb-0">
                      <p onClick={() => navigateToLayoutPageHandler(d.layout_id)} className="text-blue-500 hover:underline">{d.layoutName}</p>
                    </li>
                  )}
                </ul>
              </div>
            </div>}
          </form>
        </div>

        {imageBlockPayload.imageList.map((d: any, index: number) => (
          <ImageBlockElementForm
            key={index}
            data={d}
            imageBlockPayload={imageBlockPayload}
            setImageBlockPayload={setImageBlockPayload}
            removeElementHandler={removeElementHandler}
          />
        ))}

        <div className="mt-5">
          <button
            type="button"
            onClick={addElementHandler}
            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none "
          >
            Add Image
          </button>

          {loaderFlag ? <Loader /> : <button
            onClick={
              pageMode === "EDIT"
                ? updateImageBlockHandler
                : createImageBlockHandler
            }
            className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ml-5"
          >
            {pageMode === "EDIT" ? "Update" : "Create"}
          </button>}
        </div>
      </div>
    </>
  );
};
export default ImageBlockParentForm;
