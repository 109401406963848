export const SET_LMS_LOADER = "SET_LMS_LOADER";
export const SET_LAYOUT = "SET_LAYOUT";
export const GET_LAYOUT = "GET_LAYOUT";
export const GET_LAYOUTS = "GET_LAYOUTS";
export const SET_LAYOUTS = "SET_LAYOUTS";
export const SET_COMPONENT = "SET_COMPONENT";
export const SET_COMPONENTS = "SET_COMPONENTS";
export const CREATE_LAYOUT = "CREATE_LAYOUT";
export const DELETE_LAYOUT = "DELETE_LAYOUT";
export const GET_STATIC_FORM_FIELDS = "GET_STATIC_FORM_FIELDS";
export const SET_STATIC_FORM_FIELDS = "SET_STATIC_FORM_FIELDS";
export const CREATE_COMPONENT = "CREATE_COMPONENT";
export const GET_COMPONENT = "GET_COMPONENT";
export const GET_POPULATED_LAYOUT = "GET_POPULATED_LAYOUT";
export const SET_POPULATED_LAYOUT = "SET_POPULATED_LAYOUT";
export const UPDATE_COMPONENT = "UPDATE_COMPONENT";
export const UPDATE_COMPONENT_STATUS = "UPDATE_COMPONENT_STATUS";
export const GET_COMPONENTS = "GET_COMPONENTS";
export const COPY_COMPONENT = "COPY_COMPONENT";
export const RESET = "RESET";
export const GET_LAYOUT_JSON_BY_LAYOUT_CODE = "GET_LAYOUT_JSON_BY_LAYOUT_CODE";
export const SET_LAYOUT_JSON_BY_LAYOUT_CODE = "SET_LAYOUT_JSON_BY_LAYOUT_CODE";
export const SET_LAYOUT_JSON_API_LOADER = "SET_LAYOUT_JSON_API_LOADER"
export const DELETE_COMPONENT = "DELETE_COMPONENT";
export const GET_POPULATED_COMPONENT = "GET_POPULATED_COMPONENT";
export const SET_POPULATED_COMPONENT = "SET_POPULATED_COMPONENT";
export const CREATE_ELEMENT = "CREATE_ELEMENT";
export const UPDATE_ELEMENT_STATUS = "UPDATE_ELEMENT_STATUS";
export const UPDATE_ELEMENT = "UPDATE_ELEMENT";
export const DELETE_ELEMENT = "DELETE_ELEMENT";
export const CLEAR_CACHE = "CLEAR_CACHE";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";

