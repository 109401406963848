import {
  DELETE_ARTICLE_LIST,
  SET_ARTICLE, SET_ARTICLE_LISTING, SET_ARTICLE_LOADER
} from "../actionTypes/articleActionTypes";

const initialState: any = {
  articleForm: {},
  articleListing: {},
  isLoading: false,
};

const articleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ARTICLE_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      }

    case SET_ARTICLE:
      return {
        ...state,
        articleForm: action.payload,
      };

    case SET_ARTICLE_LISTING:
      return {
        ...state,
        articleListing: action.payload,
      };
    case DELETE_ARTICLE_LIST:
      return {
        ...state,
        articleListing: { ...state.articleListing, data: state.articleListing.data.filter((obj: any) => obj.id !== action.payload.id) }
      }
    default:
      return state;
  }
};

export default articleReducer;
