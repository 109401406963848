import React, { useState } from "react"
import { downloadProductDescriptionService } from "../../../core/services/aiPoweredService"
import toast from "react-hot-toast"
import { convertTextToCSV } from "../../../utils/helper";

const GenerateProductListItem = (props: any) => {
    const { _data } = props;
    const [loader, setLoader] = useState(false)

    const downloadFileClickHandler = (id: string) => {
        setLoader(true)
        downloadProductDescriptionService(id).then(({ data }) => {
            setLoader(false)
            convertTextToCSV(data)
            toast.success("Downloaded successfully")
        }).catch((err) => {
            setLoader(false)
            toast.error('Something went wrong')
        })
    }

    return (<>
        <tr className="[&:nth-child(odd)]:bg-[#f7f7f7] h-12">
            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[33%]"> {_data?.uploaded_by}</td>
            <td className="px-4 py-3 text-left text-xs text-[#3F3F3F] w-[34%]">{_data?.uploaded_at} </td>
            <td className="px-4 py-3 text-bottom text-xs text-[#3F3F3F] w-[33%]">{_data?.status === 1 ? "PROCESSED" : "IN_PROGRESS"}</td>
            {_data?.status === 1 ? (<button onClick={() => downloadFileClickHandler(_data?.uuid)} className="h-9 mt-1 mb-1 py-2 px-4 bg-blue-500 text-white font-semibold rounded shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 ml-4">
                {loader ? "Downloading" : "Download"} </button>) :
                (
                    <button className="h-9 py-2 px-4 bg-gray-300 text-white font-semibold rounded ml-4 mt-1 mb-1" disabled> Download </button>
                )}
        </tr>
    </>)
}

export default React.memo(GenerateProductListItem);