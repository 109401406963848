import { useState } from 'react';
import Loader from '../Loader/Loader';
import CampaignItem from './CampaignItem';


const CampaignList = (props: any) => {
    const { campaigns, isLoading } = props
    const [editSectionId, setEditSectionId] = useState("")
    const [editSectionToggle, setEditSectionToggle] = useState(false)

    return (
        <>
            <div className="mt-2.5 campaign-list bg-white mb-[54px]">
                <table className="w-full">
                    <thead className="bg-[#343434]">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Campaign Name</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Created By</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Start Date</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">End Date</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Campaign Type</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Device</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Status</th>
                            <th className="px-4 py-3 text-left text-xs font-semibold text-white">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? <Loader /> : <>
                            {campaigns.length > 0 ? campaigns.map((d: any, index: number) =>
                                <CampaignItem editSectionId={editSectionId} setEditSectionId={setEditSectionId}
                                    editSectionToggle={editSectionToggle} setEditSectionToggle={setEditSectionToggle} key={index} campaign={d} />
                            ) : null}
                        </>}
                    </tbody>
                </table>
            </div>
        </>
    );
};
export default CampaignList;