import Header from "../../components/Header/Header";
import SideNavbar from "../../components/Navbar/SideNavbar";
import PlaFeedList from "../../components/PlaFeed/PlaFeedListData";
import PlaFeedSubHeader from "../../components/PlaFeed/PlaFeedSubHeader";

const PlaFeeds = () => {
    return (
        <>
            <div className="flex mainWrapper w-full">
                <div className="sidenav-wrapper">
                    <SideNavbar />
                </div>
                <div className="right-container">
                    <div className="header-container">
                        <Header />
                    </div>
                    <div className="body-container">
                        <PlaFeedSubHeader />
                        <PlaFeedList />
                    </div>
                </div>
            </div>
        </>
    )
}
export default PlaFeeds;