import React, { useEffect, useState } from "react"
import Header from "../../components/Header/Header"
import CampaignList from "../../components/CampaignList/CampaignList"
import SideNavbar from "../../components/Navbar/SideNavbar"
import CampaignListPageHeader from "../../components/CampaignList/CampaignListPageHeader"
import { useDispatch } from "react-redux"
import { getCampaigns, getLookups } from "../../../core/actionCreators/adsenseActionCreator"
import { useSelector } from "react-redux"
import CommonPagination from "../../components/CommonPagination/CommonPagination"
import { GET_CAMPAIGN_DEFAULT_PAGE_SIZE } from "../../../utils/constants"

const CampaignListPage = () => {
    const dispatch = useDispatch()
    let store = useSelector((s: any) => s);
    const { campaigns, isLoading, lookups } = store.adsenseStore

    useEffect(() => {
        dispatch(getLookups(`module=CAMPAIGN`, null))
    }, [])

    const onPageChange = (_pageNumber: number) => {
        dispatch(getCampaigns({
            "where": {},
            "page": _pageNumber - 1,
            "limit": GET_CAMPAIGN_DEFAULT_PAGE_SIZE,
            "sortCriteria": {
                "field": "_id",
                "direction": "DESC"
            }
        }, null))
    }

    return (
        <div className="flex mainWrapper w-full">
            <div className="sidenav-wrapper">
                <SideNavbar />
            </div>
            <div className="right-container">
                <div className="header-container">
                    <Header />
                </div>
                <div className="body-container">
                    <CampaignListPageHeader lookups={lookups} />
                    {campaigns.data && <CampaignList isLoading={isLoading} campaigns={campaigns.data} />}
                    {campaigns.data && <CommonPagination data={campaigns} PAGE_SIZE={GET_CAMPAIGN_DEFAULT_PAGE_SIZE} onPageChange={onPageChange} />}
                </div>
            </div>
        </div>
    )
}

export default CampaignListPage