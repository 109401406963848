import { useDispatch, useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";
import Loader from "../Loader/Loader";
import queryString from "query-string";
import CommonPagination from "../CommonPagination/CommonPagination";
import { GET_PAGEMAPPING_DEFAULT_PAGE_SIZE } from "../../../utils/constants";
import PageMappingSubHeader from "./PageMappingSubHeader";
import {
  getPageMappings
} from "../../../core/actionCreators/pageMappingActionCreator";
import PageMappingListItem from "./PageMappingListItem";
import { isEqual, removeEmptyStringKeys } from "../../../utils/helper";

const PageMappingList = () => {
  const dispatch = useDispatch();

  const { pageMappings, isLoading } = useSelector(
    (s: any) => s.pageMappingStore
  );

  const [searchCriteria, setSearchCriteria] = useState({
    pageNumber: 0,
    pageSize: GET_PAGEMAPPING_DEFAULT_PAGE_SIZE,
    id: "",
    requestParam: "",
    layoutCodes: "",
    isWebView: "",
  } as any);


  const previousSearchCriteriaRef = useRef(searchCriteria);


  useEffect(() => {
    const updatedSearchCriteria = removeEmptyStringKeys(searchCriteria);
    const stringifiedSearchCriteria = queryString.stringify(updatedSearchCriteria);
    const previousCriteria = previousSearchCriteriaRef.current;
    const searchFilterChanged = !isEqual(updatedSearchCriteria, previousCriteria);

    if (searchFilterChanged) {
      const parsedSearchCriteria: any = queryString.parse(stringifiedSearchCriteria)
      for (let key in parsedSearchCriteria) {
        if (["id", "requestParam", "layoutCodes"].includes(key)) {
          parsedSearchCriteria.pageNumber = 0
          break
        }
      }
      dispatch(getPageMappings(queryString.stringify(parsedSearchCriteria), null));
      previousSearchCriteriaRef.current = updatedSearchCriteria; // Update previous criteria
    }
  }, [searchCriteria]);


  const onPageChange = (pageNumber: any) => {
    setSearchCriteria({ ...searchCriteria, pageNumber: pageNumber })
  }

  return (
    <>
      <PageMappingSubHeader />

      <div className="mt-4 campaign-list bg-white mb-[54px]">
        <table className="w-full">
          <thead className="bg-[#343434]">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[20%]">
                ID
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[30%]">
                {" "}
                Request Param
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[25%]">
                Layout Code's
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[15%]">
                Status
              </th>
              <th className="px-4 py-3 text-left text-xs font-semibold text-white w-[10%]">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-3 text-left text-xs text-white w-[20%]">
                <input
                  value={searchCriteria.id}
                  onChange={(e) =>
                    setSearchCriteria({ ...searchCriteria, id: e.target.value })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                  type="text"
                />
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[30%]">
                <input
                  value={searchCriteria.requestParam}
                  onChange={(e) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      requestParam: e.target.value,
                    })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                  type="text"
                />
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[25%]">
                <input
                  value={searchCriteria.layoutCodes}
                  onChange={(e) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      layoutCodes: e.target.value,
                    })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs"
                  type="text"
                />
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[15%]">
                <select
                  value={searchCriteria.isWebView}
                  onChange={(e) =>
                    setSearchCriteria({
                      ...searchCriteria,
                      isWebView: e.target.value,
                    })
                  }
                  className="w-full h-7 rounded border text-[#3c3c3c] border-[#EAEAEA] p-1 outline-none text-xs bg-white"
                >
                  <option>Select An Option</option>
                  <option value={"true"}>True</option>
                  <option value={"false"}>False</option>
                </select>
              </td>
              <td className="px-4 py-3 text-left text-xs text-white w-[10%]"></td>
            </tr>

            {isLoading ? (
              <Loader />
            ) : (
              <PageMappingListItem data={pageMappings?.data || []} />
            )}
          </tbody>
        </table>
      </div>
      <CommonPagination
        data={pageMappings}
        PAGE_SIZE={GET_PAGEMAPPING_DEFAULT_PAGE_SIZE}
        onPageChange={onPageChange}
      />


    </>
  );
};
export default PageMappingList;
