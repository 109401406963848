import { all, fork } from "redux-saga/effects";
import authSaga from "./authSaga";
import lmsSaga from "./lmsSaga";
import adsenseSaga from "./adsenseSaga";
import campaginSaga from "./campaignSaga";
import aiPoweredSaga from "./aiPoweredSaga"
import rmSaga from "./reviewsSaga";
import blocksSaga from "./blocksSaga";
import commonSaga from "./commonSaga";
import productTagSaga from "./productTagsSaga";
import fbtSaga from "./fbtSaga";
import serviceabilitySaga from "./serviceabilitySaga";
import qnaSaga from "./qnaSaga";
import plaFeedSaga from "./plaFeedSaga";
import faqSaga from "./faqSaga";
import attributeSaga from "./attributeSaga";
import pageMappingSaga from "./pageMappingSaga";
import articleSaga from "./articleSaga";

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(lmsSaga),
    fork(adsenseSaga),
    fork(campaginSaga),
    fork(aiPoweredSaga),
    fork(blocksSaga),
    fork(commonSaga),
    fork(productTagSaga),
    fork(fbtSaga),
    fork(rmSaga),
    fork(serviceabilitySaga),
    fork(qnaSaga),
    fork(plaFeedSaga),
    fork(faqSaga),
    fork(attributeSaga),
    fork(pageMappingSaga),
    fork(articleSaga)
  ]);
}
