import { useDispatch } from "react-redux";
import GeneralBlockElementForm from "./GeneralBlockElementForm";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { createGeneralBlock, deleteCategoryBlockElement, updateGeneralBlock } from "../../../core/actionCreators/blocksActionCreator";
import Loader from "../Loader/Loader";


const GeneralBlockParentForm = (props: any) => {
    const { pageMode, blockData, loaderFlag } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((s: any) => s.authStore);

    const [generalBlockPayload, setGeneralBlockPayload] = useState({
        id: "",
        name: "",
        title: "",
        label: "",
        status: "true",
        associatedLayouts: [],
        generalElementList: [],
    } as any);

    useEffect(() => {
        if (pageMode === "EDIT" && Object.keys(blockData).length) {
            setGeneralBlockPayload(blockData);
        } else {
            setGeneralBlockPayload({
                ...generalBlockPayload,
                generalElementList: [
                    {
                        id: uuidv4(),
                        name: "",
                        label: ""
                    },
                ],
            });
        }
    }, [blockData]);

    const createGeneralBlockHandler = () => {
        let data: any = { ...generalBlockPayload };
        delete data.id;
        let headers = {
            userId: user.id || 33,
        };
        delete data.associatedLayouts
        data.status = JSON.parse(data.status)
        data.generalElementList.forEach((element: any) => {
            if (typeof element.id === 'string') {
                delete element.id
            }
        });
        dispatch(
            createGeneralBlock({ data: data, headers: headers }, (resp: any) => {
                toast.success("Created successfully.");
                navigate("/blocks/general");
            })
        );
    };

    const updateGeneralBlockHandler = () => {
        let data: any = { ...generalBlockPayload };
        let headers = {
            userId: user.id || 33,
        };
        let params = {
            id: data.id,
        };
        delete data.associatedLayouts
        data.generalElementList.forEach((element: any) => {
            if (typeof element.id === 'string') {
                delete element.id
            }
        });

        dispatch(
            updateGeneralBlock({ params, data: data, headers }, (resp: any) => {
                if (!resp.status) {
                    toast.error(resp.statusDescription)
                    return
                }
                toast.success("Updated successfully.");
            })
        );
    };

    const removeElementHandler = (elementId: any) => {
        const isConfirmed = window.confirm('Are you sure want to delete?')
        if (!isConfirmed) {
            return
        }

        const elementIndex = generalBlockPayload.generalElementList.findIndex(
            (d: any) => d.id === elementId
        );
        if (elementIndex === -1) {
            toast.error("Something went wrong.");
            return;
        }
        if (pageMode === "EDIT") {
            const element = generalBlockPayload.generalElementList[elementIndex]
            if (element.id && typeof element.id === 'number') {
                dispatch(
                    deleteCategoryBlockElement(
                        {
                            blockId: generalBlockPayload.id,
                            blockElementId: element.id,
                            userId: user.id || 33,
                        },
                        (resp: any) => {
                            if (!resp.status) {
                                toast.error(resp.statusDescription);
                                return;
                            }
                            generalBlockPayload.generalElementList.splice(elementIndex, 1);
                            setGeneralBlockPayload({ ...generalBlockPayload });
                            return;
                        }
                    )
                );
            }
        }
        generalBlockPayload.generalElementList.splice(elementIndex, 1);
        setGeneralBlockPayload({ ...generalBlockPayload });
    };

    const addElementHandler = () => {
        generalBlockPayload.generalElementList.push({
            id: uuidv4(),
            name: "",
            label: ""
        });
        setGeneralBlockPayload({ ...generalBlockPayload });
    };


    const navigateToLayoutPageHandler = (layoutId: number) => {
        navigate(`/blocks/layout/${layoutId}`);
    }


    return (
        <>
            <div className="p-4 pb-8">
                <div className="w-full bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5">
                    <form action="">
                        <div className="flex w-full mb-6 last:mb-0">
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Name*</label>
                                <input
                                    value={generalBlockPayload.name}
                                    onChange={(e) =>
                                        setGeneralBlockPayload({
                                            ...generalBlockPayload,
                                            name: e.target.value,
                                        })
                                    }
                                    className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                    type="text"
                                />
                            </div>
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Label</label>
                                <input value={generalBlockPayload.label}
                                    onChange={(e) =>
                                        setGeneralBlockPayload({
                                            ...generalBlockPayload,
                                            label: e.target.value,
                                        })
                                    }
                                    className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="flex w-full mb-6 last:mb-0">
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Title</label>
                                <input value={generalBlockPayload.title}
                                    onChange={(e) =>
                                        setGeneralBlockPayload({
                                            ...generalBlockPayload,
                                            title: e.target.value
                                        })
                                    }
                                    className="w-full h-9 rounded border text-[#555] border-[#EAEAEA] px-2.5 outline-none text-sm font-light"
                                    type="text"
                                />
                            </div>
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Status*</label>
                                <select value={generalBlockPayload.status}
                                    onChange={(e) =>
                                        setGeneralBlockPayload({
                                            ...generalBlockPayload,
                                            status: e.target.value,
                                        })
                                    } className="w-full h-9 rounded border text-sm text-[#555] border-[#EAEAEA] px-2.5 outline-none bg-white font-light">
                                    <option value={"true"} >Active</option>
                                    <option value={"false"}>Inactive</option>
                                </select>
                            </div>
                        </div>

                        {/* Show Associated Layout if edit mode */}
                        {pageMode === "EDIT" && generalBlockPayload.associatedLayouts && Array.isArray(generalBlockPayload.associatedLayouts) && generalBlockPayload.associatedLayouts.length > 0 && <div className="flex w-full mb-6 last:mb-0">
                            <div className="w-1/2 px-2">
                                <label className="block text-xs mb-1.5 text-[#5E5D5D] font-medium">Associated Layouts</label>
                                <ul className="list-none p-0">
                                    {generalBlockPayload.associatedLayouts.map((d: any, index: number) =>
                                        <li key={index} className="mb-1 last:mb-0">
                                            <p onClick={() => navigateToLayoutPageHandler(d.layout_id)} className="text-blue-500 hover:underline">{d.layoutName}</p>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>}
                    </form>
                </div>

                {generalBlockPayload?.generalElementList?.map(
                    (d: any, index: number) => (
                        <GeneralBlockElementForm
                            key={index}
                            data={d}
                            generalBlockPayload={generalBlockPayload}
                            setGeneralBlockPayload={setGeneralBlockPayload}
                            removeElementHandler={removeElementHandler}
                        />
                    )
                )}

                <div className="mt-5">
                    {loaderFlag ? <Loader /> : <button onClick={addElementHandler} className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none">Add Block</button>}
                    {loaderFlag ? <Loader /> : <button onClick={pageMode === "EDIT" ? updateGeneralBlockHandler : createGeneralBlockHandler} className="h-9 py-2 px-4 bg-[#3b86ff1a] text-xs text-[#3B86FF] border border-[#3B86FF] rounded focus:outline-none ml-5">{pageMode === "EDIT" ? "Update" : "Create"}</button>}
                </div>
            </div>
        </>
    )
}
export default GeneralBlockParentForm;