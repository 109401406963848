
const TopSellers = (props: any) => {

    const { articlePayload, setArticlePayload } = props;

    return (
        <>
            <div className="bg-white py-4 px-2.5 shadow-[0_1px_4px_rgba(0,0,0,.2)] rounded mb-5 cursor-pointer">
                <h1 className="mb-2"><b>Top Sellers</b></h1>
                <textarea
                    className="w-full p-2 border border-gray-300 outline-none"
                    placeholder="Enter msns here" value={articlePayload.msns} onChange={(e) => setArticlePayload({ ...articlePayload, msns: e.target.value })}
                />
            </div>
        </>
    )
}

export default TopSellers;