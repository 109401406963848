import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { deleteBrandBlock } from "../../../core/actionCreators/blocksActionCreator";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import DeleteBin5FillIcon from "remixicon-react/DeleteBin5FillIcon";
import Edit2FillIcon from "remixicon-react/Edit2FillIcon";


const BrandListItem = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { user } = useSelector((s: any) => s.authStore);
  const { data } = props;

  const updateBrandBlockNavigateHandler = (id: any) => {
    navigate(`/blocks/brand/${id}`);
  };

  const deleteBrandBlockHandler = (id: any) => {
    const isConfirmed = window.confirm('Are you sure want to delete?')
    if (isConfirmed) {
      dispatch(deleteBrandBlock({ id: id, userId: user.id || 33 }, (resp: any) => {
        if (!resp.status) {
          toast.error(resp.statusDescription)
          return
        }
        toast.success("Deleted successfully")
        window.location.reload()
      }))
    }
    return
  };

  return (
    <>
      {data.map((_d: any) => (
        <tr className="[&:nth-child(odd)]:bg-[#f7f7f7]">
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d.id}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d.label}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d.name}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d.category}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d.elementCount}
          </td>
          <td className="px-4 py-3 text-left text-xs text-[#3F3F3F]">
            {_d.status ? "True" : "False"}
          </td>
          <td
            onClick={() => updateBrandBlockNavigateHandler(_d.id)}
            className="px-4 py-3 text-left text-xs text-[#337ab7] font-semibold cursor-pointer"
          >
            <Edit2FillIcon size={14} />
          </td>
          <td
            onClick={() => deleteBrandBlockHandler(_d.id)}
            className="px-4 py-3 text-left text-xs text-[#337ab7] font-semibold cursor-pointer"
          >
            <DeleteBin5FillIcon size={14} />
          </td>
        </tr>
      ))}
    </>
  );
};
export default BrandListItem;
